/** @format */

import { EyeOutlined } from "@ant-design/icons";
import { message, Typography } from "antd";
import { noop } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { finalize, mergeMap, of, tap } from "rxjs";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectUser } from "../../../../app/reducers/auth.reducer";
import { selectLigne, updateLigne, updateLineId } from "../../../../app/reducers/ligne.reducer";
import Edit from "../../../../assets/icons/edit.svg";
import SingleLoading from "../../../../components/SingleLoading";
import { CaracteristiqueAPI } from "../../../../domain/apis/caracteristique.api";
import { UniteAPI } from "../../../../domain/apis/unite.api";
import { DATE_FORMAT } from "../../../../helper/calendarHelper";
import { doOnSubscribe, useRxEffect } from "../../../../helper/rxjs.helper";
import { Caracteristiques, Unite, UserLevels } from "../../../../models";
import { SelectContactValue } from "../Contacts/SelectContactValue";
import styles from "./ListLigne.module.css";

const { Paragraph } = Typography;

enum LignState {
  EDITING = "editing",
  CONFIRMED = "confirmed",
  PENDING = "pending",
}

interface LignType {
  name: string;
  state: LignState;
}

const initLigne: LignType[] = [
  { name: "Client", state: LignState.PENDING },
  { name: "Commercial pilote", state: LignState.PENDING },
  { name: "Assignation", state: LignState.PENDING },
  { name: "Période", state: LignState.PENDING },
  { name: "Indexation", state: LignState.PENDING },
  { name: "Trajet", state: LignState.PENDING },
  { name: "Contacts", state: LignState.PENDING },
  { name: "Chargement", state: LignState.PENDING },
  { name: "Allocation", state: LignState.PENDING },
  { name: "Budget", state: LignState.PENDING },
];

const ListLigne = (props: { currentStage: number; editable: boolean }) => {
  const dispatch = useAppDispatch();
  const lineData = useAppSelector(selectLigne);
  const { currentStage, editable } = props;
  const [listSection, setListSection] = useState<LignType[]>(initLigne);
  const [ligneIdStr, setLigneIdStr] = useState("X".repeat(20));
  const [loading, setLoading] = useState<boolean>(false);
  const [listUnit, setListUnit] = useState<Unite[]>([]);
  const currentUser = useAppSelector(selectUser);
  useRxEffect(() => {
    setLoading(true);
    return UniteAPI.fetchAll().pipe(
      tap({
        next: (unites: Unite[]) => {
          setListUnit(unites);
          setLoading(false);
        },
      })
    );
  }, []);

  useEffect(() => {
    updateStage(currentStage);
    if (!lineData.idLigne) {
      let ligneId = "";
      if (lineData.client) {
        ligneId += lineData.client.code;
      } else {
        ligneId += "XXXXX";
      }
      if (lineData.dateInit) {
        // ligneId += lineData.dateInit
        ligneId += moment(lineData.dateInit).format("YYMM").toString();
      } else {
        ligneId += "XXXX";
      }
      if (lineData.paysDepart?.code) {
        ligneId += lineData.paysDepart?.code.substring(0, 2);
      } else {
        ligneId += "XX";
      }

      if (lineData.departementDepart?.code) {
        ligneId += lineData.departementDepart?.code.substring(0, 2);
      } else {
        ligneId += "XX";
      }

      if (lineData.paysArrivee?.code) {
        ligneId += lineData.paysArrivee?.code.substring(0, 2);
      } else {
        ligneId += "XX";
      }

      if (lineData.departementArrivee?.code) {
        ligneId += lineData.departementArrivee?.code.substring(0, 2);
      } else {
        ligneId += "XX";
      }

      ligneId = ligneId + "X".repeat(20 - ligneId.length);
      setLigneIdStr(ligneId);
      dispatch(updateLineId({ ligneId }));
    } else {
      setLigneIdStr(lineData.idLigne);
    }
  }, [currentStage, lineData]);

  const updateStage = (key: number) => {
    let filterLigne: LignType[];
    if (editable) {
      filterLigne = initLigne.map((el, index) => {
        let stateLigne =
          key === index ? LignState.EDITING : lineData.isCompleted ? LignState.CONFIRMED : key > index ? LignState.CONFIRMED : LignState.PENDING;
        if (index > key && key >= 7) {
          stateLigne = LignState.PENDING;
        }
        return {
          ...el,
          state: stateLigne,
        };
      });
    } else {
      filterLigne = initLigne.map((el) => {
        return {
          ...el,
          state: LignState.CONFIRMED,
        };
      });
    }

    setListSection(filterLigne);
  };

  const contactType = (type?: string) => {
    switch (type) {
      case SelectContactValue.EDI:
        return "E";
      case SelectContactValue.MAIL:
        return "M";
      case SelectContactValue.PLATEFORME:
        return "P";
      case SelectContactValue.TELEPHONE:
        return "T";
    }
  };

  const renderAltName = (key: number, state: LignState) => {
    switch (state) {
      case LignState.PENDING:
        return;
      case LignState.EDITING:
        if (!editable) return;
        if (currentUser?.typeRole === UserLevels.ADMIN || currentUser?.typeRole === UserLevels.SUPER_ADMIN) {
          return "En cours";
        }
        return;
      default:
        break;
    }

    let altName = "";

    switch (key) {
      case 0:
        altName = `${lineData.client?.code} - ${lineData.client?.nom}`;
        break;
      case 1:
        altName = `${lineData.commercial?.code} - ${lineData.commercial?.nom}`;
        break;
      case 2:
        altName =
          (lineData.societe?.code ? lineData.societe?.code : "") +
          (lineData.agence?.code ? "/ " + lineData.agence?.code : "") +
          (lineData.exploitant?.code ? "/ " + lineData.exploitant?.code : "");
        break;
      case 3:
        altName = !lineData.typeDuree
          ? `Du ${moment(lineData.dateInit).format(DATE_FORMAT)} - Au ${moment(lineData.dateFin).format(DATE_FORMAT)}`
          : `Du ${moment(lineData.dateInit).format(DATE_FORMAT)} - ${Number(lineData.duree)} ${
              Number(lineData.duree) > 1 && lineData.typeDuree == "AN" ? "ANS" : lineData.typeDuree
            }`;
        break;
      case 4:
        altName = `${lineData.typeGasoil == "Indice" ? "Indice Gasoil" : "Prix Gasoil"} ${lineData.typeTunnel ? "/ Tunnel" : ""}`;
        break;
      case 5:
        altName = `${
          lineData.villeDepart && lineData.villeArrivee
            ? lineData.villeDepart + " - " + lineData.villeArrivee
            : lineData.villeDepart && lineData.villeArrivee == null
            ? lineData.villeDepart
            : lineData.villeArrivee && lineData.villeDepart == null
            ? "- " + lineData.villeArrivee
            : ""
        }`;

        break;
      case 6:
        altName = `${contactType(lineData.reception) ? contactType(lineData.reception) + "/" : ""}
        ${contactType(lineData.chargementContact) ? contactType(lineData.chargementContact) + "/" : ""}
        ${contactType(lineData.livraison) ? contactType(lineData.livraison) + "/" : ""}`;
        break;
      case 7:
        altName = lineData.chargement && lineData.chargement?.length > 1 ? (lineData.plages ? "Multiple" : "Unique") : "Unique";
        break;
      case 8:
        altName = lineData.alloue
          ? `${lineData.alloue[0]?.volumeTotalClient} / ${responseVolumeFrequency(lineData.alloue[0]?.volumeFrequencyId)} - ${
              lineData.alloue[0]?.tauxAlloue
            }%`
          : "";
        break;
      case 9:
        altName = lineData.chargement && lineData.chargement?.length > 1 ? "Multiple" : "Unique";
        break;
    }

    return altName;
  };

  const changeStage = (key: number) => {
    dispatch(
      updateLigne({
        ligne: {
          ...lineData,
          currentStage: key,
        },
      })
    );
    CaracteristiqueAPI.update(Number(lineData.id), {
      currentStage: key,
    })
      .pipe(
        tap({
          // next: (caracteristique: Caracteristiques) => {
          //   dispatch(fetchLigne({ ligne: caracteristique }));
          // },
        })
      )
      .subscribe({
        error: noop,
      });
  };

  const viewLigne = (index: number) => {
    dispatch(
      updateLigne({
        ligne: {
          ...lineData,
          currentStage: index,
        },
      })
    );
  };

  const updateLigneId = (value: string) => {
    if (value.trim().length === 0) {
      message.error("Merci de compléter toutes les colonnes.").then((r) => console.log(r));
    } else {
      setLigneIdStr(value);
      if (value !== lineData.idLigne) {
        CaracteristiqueAPI.checkUnquieIdLigne(value)
          .pipe(
            doOnSubscribe(() => setLoading(true)), // Show loading on start
            mergeMap((isUnique: boolean) => {
              if (!isUnique) {
                return of(false);
              } else {
                return CaracteristiqueAPI.update(Number(lineData.id), {
                  idLigne: value,
                }).pipe(
                  tap({
                    next: (caracteristique: Caracteristiques) => {
                      dispatch(
                        updateLigne({
                          ligne: {
                            ...lineData,
                            idLigne: caracteristique.idLigne,
                          },
                        })
                      );
                    },
                  }),
                  mergeMap(() => of(true))
                );
              }
            }),
            tap({
              next: (isUpdate: boolean) => {
                if (!isUpdate) {
                  message.error("L'ID Ligne existe déjà").then((r) => console.log(r));
                  lineData.idLigne && setLigneIdStr(lineData.idLigne);
                }
              },
            }),
            finalize(() => setLoading(false)) // Hide loading on end
          )
          .subscribe({
            error: noop,
          });
      }
    }
  };

  const responseVolumeFrequency = (id?: number) => {
    const unit = listUnit.filter((el) => el.id === id);
    if (unit.length > 0) {
      return unit[0]?.nom;
    }
    return listUnit[0]?.nom;
  };

  return (
    <>
      {loading && <SingleLoading />}
      <ul className={styles.listLigne}>
        <li className={styles.firstLigne}>
          <span className="shrink-0">ID Ligne :</span>
          <Paragraph
            editable={
              editable
                ? {
                    icon: <img className={styles.editIcon} src={Edit} alt="Edit" />,
                    tooltip: false,
                    onChange: (value) => updateLigneId(value),
                    maxLength: 20,
                  }
                : false
            }
            className={styles.customLigne}
          >
            {ligneIdStr}
          </Paragraph>
        </li>
        {listSection.map((el: LignType, index: number) => {
          return (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <li key={index} className={!editable ? (lineData.currentStage === index ? styles.editing : styles[el.state]) : styles[el.state]}>
                <span>{el.name}</span>
                {el.state === LignState.CONFIRMED && editable && (
                  <img className={styles.editIcon} src={Edit} alt="Edit" onClick={() => changeStage(index)} />
                )}

                {<span className={styles.textCaracteristiques}>{renderAltName(index, el.state)}</span>}
              </li>
              {!editable && (
                <div className={styles.iconEye} onClick={() => viewLigne(index)}>
                  <EyeOutlined />
                </div>
              )}
            </div>
          );
        })}
      </ul>
    </>
  );
};

export default ListLigne;

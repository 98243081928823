/** @format */

import { Col, Row, Typography } from "antd";
import { AppColors } from "../../constants/colors.const";
import ProgressOutline from "../ProgressOutline/ProgressOutline";
import IndicatorTutorialView from "../IndicatorTutorial";
import React from "react";

type FooterCalendarProps = {
  moyenneDeLotsMax: number;
  showAssociationRate: boolean;
  isShowTutorial: boolean;
};

const FooterCalendar = (props: FooterCalendarProps) => {
  return (
    <footer style={{ marginTop: "24px" }}>
      <Row>
        <Col span={4}>
          <Row>
            <Col>
              <Typography.Title level={5} style={{ fontSize: "12px" }}>
                Aujourd&apos;hui
              </Typography.Title>
            </Col>
            <Col>
              <div
                style={{
                  width: "18px",
                  height: "18px",
                  borderRadius: "3px",
                  backgroundColor: "#3293E8",
                  marginLeft: "16px",
                }}
              />
            </Col>
          </Row>
        </Col>
        {props.showAssociationRate && (
          <Col span={6}>
            <Row style={{ justifyContent: "space-around" }}>
              <Col>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography.Title level={5} style={{ fontSize: "12px" }}>
                    Taux d&apos;association
                  </Typography.Title>
                  {props.isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView
                        hint={
                          <>
                            Pour une date donnée, nombre de lots pris (pré-affectés) moyen par un Transporteur, par rapport au nombre de lots
                            renseignés moyen dans Demande Client.
                          </>
                        }
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
              <Col>
                {[
                  { color: "rgba(235,72,65,0.3)", label: "de 0% à 25%" },
                  { color: "rgba(244,136,71,0.3)", label: "de 25% à 50%" },
                  { color: "rgba(255,200,74,0.3)", label: "de 50% à 75%" },
                  { color: "rgba(166,195,76,0.3)", label: "de 75% à 100%" },
                  { color: "rgba(78,192,78,0.3)", label: "100 %" },
                ].map((item, index) => (
                  <Row
                    key={`AssociationRate${index}`}
                    style={{
                      marginTop: "4px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <div
                          style={{
                            width: "18px",
                            height: "18px",
                            borderRadius: "3px",
                            backgroundColor: item.color,
                          }}
                        />
                      </div>
                      <span
                        style={{
                          fontSize: "12px",
                          marginLeft: "4px",
                        }}
                      >
                        {item.label}
                      </span>
                    </div>
                  </Row>
                ))}
              </Col>
            </Row>
          </Col>
        )}
        <Col span={6}>
          <Row style={{ justifyContent: "space-around" }}>
            <Col>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography.Title level={5} style={{ fontSize: "12px" }}>
                  Moyenne de lots
                </Typography.Title>
                {props.isShowTutorial ? (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView
                      hint={
                        <>
                          Représentation du nombre de lots moyen par jour, avec un contour complété à 100% pour le nombre de lots par jour maximal
                          présent sur le calendrier.
                        </>
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Col>
            {[12.5, 25, 50, 75, 100].map((item) => (
              <Col key={`MoyenneDeLots${item}`}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        width: "18px",
                        height: "18px",
                        borderRadius: "3px",
                        backgroundColor: AppColors.COLOR_CALENDAR_DEFAULT_SECONDARY,
                      }}
                    />
                    <ProgressOutline progress={item} />
                  </div>
                  <span
                    style={{
                      fontSize: "9px",
                      fontWeight: "bold",
                      marginTop: "4px",
                    }}
                  >
                    {Math.round((item / 100) * props.moyenneDeLotsMax)}
                  </span>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </footer>
  );
};

export default FooterCalendar;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransporteurDetail } from '../../../models/transporteurDetail.model';
import { RootState } from '../../store';

interface CurrentTransporteurDetailReducer {
  transporteurDetail?: TransporteurDetail;
}

const initialState: CurrentTransporteurDetailReducer = {
  transporteurDetail: {}
};

export const CurrentTransporteurDetailSlice = createSlice({
  name: 'currentTransporteurDetail',
  initialState,
  reducers: {
    UpdateCurrentTransporteurDetail: (state, action: PayloadAction<TransporteurDetail>) => {
      state.transporteurDetail = action.payload
    },
    ResetCurrentTransporter: () => {
      return {transporteurDetail: {}}
    }
  },
});

export const { UpdateCurrentTransporteurDetail, ResetCurrentTransporter } = CurrentTransporteurDetailSlice.actions;

export const getCurrentTransporteurDetail = (state: RootState) => state.currentTransporteurDetail.transporteurDetail;
export default CurrentTransporteurDetailSlice.reducer;

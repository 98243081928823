/** @format */

import { Input, InputProps } from "antd";
import "./customInput.css";

const CustomInput = (props: InputProps) => {
  return (
    <div className="customInput" style={props.style}>
      <Input {...props} />
    </div>
  );
};

export default CustomInput;

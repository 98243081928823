/** @format */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { DemandeClient } from "../models";
import { Lots } from "../models/lots.model";
import { DemandeClientSubType, DemandeClientType } from "./calendarHelper";
import { DemandeClientAPI } from "../domain/apis/demandeClient.api";
import { UpsertDemandeClientRequestParams } from "../models/requests/upsertDemandeClient.model";
import { Observable } from "rxjs";
import { UpsertDemandeClientResponse } from "../models/responses/upsertDemandeClient.model";

export const submitDemandeClient = (data: DemandeClient, lotsInput: Lots[]): Observable<UpsertDemandeClientResponse> => {
  // Create or update new demande client
  const isMoisGlobal = data.moisGlobalOptions && data.type === DemandeClientType.MOIS && data.subType === DemandeClientSubType.GLOBAL;
  const requestData: UpsertDemandeClientRequestParams = {
    demandeClient: data,
    demandeClientDates: generateDemandeClientDates(data, lotsInput, data.type, data.subType),
    moisGlobalOptions: isMoisGlobal ? data.moisGlobalOptions : undefined,
  };
  return DemandeClientAPI.upsertDemandeClient(requestData);
  // return (
  //   data.id !== undefined
  //     ? DemandeClientAPI.update(data.id, data).pipe(map(() => data.id ?? 0))
  //     : DemandeClientAPI.create(data).pipe(map((demandeClient: DemandeClient) => demandeClient.id ?? 0))
  // ).pipe(
  //   mergeMap((id: number) => {
  //     const isMoisGlobal = data.moisGlobalOptions && data.type === DemandeClientType.MOIS && data.subType === DemandeClientSubType.GLOBAL;
  //     let updateDatesAndOptionsObservables: Observable<void | MoisGlobalOption>[] = [
  //       DemandeClientAPI.updateAllDemandeClientDatesById(id, {
  //         demandeClientDates: generateDemandeClientDates(data, lotsInput, data.type, data.subType),
  //       }),
  //     ];
  //     if (isMoisGlobal) {
  //       updateDatesAndOptionsObservables = updateDatesAndOptionsObservables.concat(createOrUpdateMoisGlobalOptions(id, data.moisGlobalOptions ?? []));
  //     }
  //     return combineLatest(updateDatesAndOptionsObservables);
  //   })
  // );
};

const generateDemandeClientDates = (demandeClient: DemandeClient, lotsInput: Lots[], type?: DemandeClientType, subType?: DemandeClientSubType) => {
  if (type === DemandeClientType.SEMAINE && subType === DemandeClientSubType.GLOBAL) {
    return lotsInput.map((el) => {
      let newLotMin;
      let newLotMax;
      if (demandeClient.totalMinLot) {
        newLotMin = demandeClient.totalMinLot / 7;
      }
      if (demandeClient.totalMaxLot) {
        newLotMax = demandeClient.totalMaxLot / 7;
      }
      return {
        ...el,
        lotMin: newLotMin,
        lotMax: newLotMax,
        demandeClientId: demandeClient.id,
      };
    });
  } else if (type === DemandeClientType.MOIS && subType === DemandeClientSubType.GLOBAL) {
    return lotsInput.map((el) => {
      let newLotMin;
      let newLotMax;
      const moisGlobalOption = demandeClient.moisGlobalOptions?.find((mois) => new Date(el.date).getMonth() === mois.month);
      if (moisGlobalOption && moisGlobalOption.totalMinLot) {
        newLotMin = (moisGlobalOption.totalMinLot * 12) / 365;
      }
      if (moisGlobalOption && moisGlobalOption.totalMaxLot) {
        newLotMax = (moisGlobalOption.totalMaxLot * 12) / 365;
      }
      return {
        ...el,
        lotMin: newLotMin,
        lotMax: newLotMax,
        demandeClientId: demandeClient.id,
      };
    });
  }
  return lotsInput.map((el) => {
    return { ...el, demandeClientId: demandeClient.id };
  });
};

// const createOrUpdateMoisGlobalOptions = (demandeId: number, moisOptions: MoisGlobalOption[]): Observable<MoisGlobalOption>[] => {
//   return moisOptions
//     .filter((el) => el.totalMaxLot && el.totalMinLot)
//     .map((el) =>
//       MoisGlobalOptionAPI.createOrUpdate({
//         ...el,
//         demandeClientId: demandeId,
//       })
//     );
// };

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransporteurDetail } from '../../../models/transporteurDetail.model';
import { RootState } from '../../store';

const initialState: TransporteurDetail = {};

export const NewTransporteurDetailSlice = createSlice({
  name: 'newTransporteurDetail',
  initialState,
  reducers: {
    UpdateNewTransporteurDetail: (state, action: PayloadAction<TransporteurDetail>) => {
      state = {
        ...state,
        ...action.payload
      }
      return state
    },
    ResetNewTransporter: () => {
      return {}
    }
  },
});

export const { UpdateNewTransporteurDetail, ResetNewTransporter } = NewTransporteurDetailSlice.actions;

export const getNewTransporteurDetail = (state: RootState) => state.newTransporteurDetail;
export default NewTransporteurDetailSlice.reducer;

import { AppColors } from "../../constants/colors.const";

export interface AssociateRateFilterInterface {
  id: number;
  min: number;
  max: number;
  colorSecondary: string;
  colorPrimary: string;
}

export const associateRateFilterColors: AssociateRateFilterInterface[] = [
  {
    id: 1,
    min: 0,
    max: 25,
    colorSecondary: AppColors.COLOR_CALENDAR_RED_SECONDARY,
    colorPrimary: AppColors.COLOR_CALENDAR_RED_PRIMARY,
  },
  {
    id: 2,
    min: 25,
    max: 50,
    colorSecondary: AppColors.COLOR_CALENDAR_ORANGE_SECONDARY,
    colorPrimary: AppColors.COLOR_CALENDAR_ORANGE_PRIMARY,
  },
  {
    id: 3,
    min: 50,
    max: 75,
    colorSecondary: AppColors.COLOR_CALENDAR_YELLOW_SECONDARY,
    colorPrimary: AppColors.COLOR_CALENDAR_YELLOW_PRIMARY,
  },
  {
    id: 4,
    min: 75,
    max: 100,
    colorSecondary: AppColors.COLOR_CALENDAR_LIGHT_GREEN_SECONDARY,
    colorPrimary: AppColors.COLOR_CALENDAR_LIGHT_GREEN_PRIMARY,
  },
  {
    id: 5,
    min: 100,
    max: 1000,
    colorSecondary: AppColors.COLOR_CALENDAR_GREEN_SECONDARY,
    colorPrimary: AppColors.COLOR_CALENDAR_GREEN_PRIMARY,
  },
];
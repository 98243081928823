/** @format */

import { DatePicker, Form } from "antd";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import React, { useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { getCurrentDemande } from "../../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import { useDispatch } from "react-redux";
import { UpdateNewDemande } from "../../../../app/reducers/DemandeClient/NewDemande.reducer";
import moment, { Moment } from "moment";

import styles from "../LineMenu.module.css";
import { UpdateDemandeState } from "../../../../app/reducers/DemandeClient/DemandeState.reducer";
import IndicatorTutorialView from "../../../../components/IndicatorTutorial";
import { selectIsShowTutorial } from "../../../../app/reducers/indicatorTutorial.reducer";

interface SelectTimeInterface {
  enableTime?: boolean;
  chargementTime?: Moment;
  dechargementTime?: Moment;
}

const SelectTime = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const currentDemande = useAppSelector(getCurrentDemande);
  const [formValue, setFormValue] = useState<SelectTimeInterface>({
    enableTime: !!currentDemande?.chargementTime || !!currentDemande?.dechargementTime,
    chargementTime: currentDemande?.chargementTime ? moment(currentDemande?.chargementTime) : undefined,
    dechargementTime: currentDemande?.dechargementTime ? moment(currentDemande?.dechargementTime) : undefined,
  });
  const isShowTutorial = useAppSelector(selectIsShowTutorial);

  const onValuesChange = () => {
    const getValues: SelectTimeInterface = form.getFieldsValue();
    setFormValue(getValues);
    const chargementTime = getValues.enableTime && getValues.chargementTime ? moment(getValues.chargementTime).toDate() : undefined;
    const dechargementTime = getValues.enableTime && getValues.dechargementTime ? moment(getValues.dechargementTime).toDate() : undefined;
    if (!getValues.enableTime) {
      form.setFieldsValue({});
    }
    console.log("getValues?.enableTime", getValues?.enableTime);
    dispatch(
      UpdateDemandeState({
        enableTime: getValues?.enableTime,
      })
    );
    dispatch(
      UpdateNewDemande({
        chargementTime,
        dechargementTime,
      })
    );
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10, offset: 2 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  return (
    <Form
      {...formItemLayout}
      labelAlign="left"
      form={form}
      name="selectTime"
      initialValues={formValue}
      onValuesChange={onValuesChange}
      style={{ marginBottom: "0.5rem" }}
    >
      <Form.Item
        name="enableTime"
        valuePropName="checked"
        className={[styles.smallLabel, styles.rendezVous].join(" ")}
        style={{ marginBottom: "0.25rem" }}
      >
        <CustomCheckbox>
          <div style={{ display: "flex", alignItems: "center" }}>
            Rendez-vous{" "}
            {isShowTutorial && (
              <div style={{ marginLeft: "0.5rem" }}>
                <IndicatorTutorialView hint={<>À cocher s&apos;il y a une heure de Chargement et de Livraison convenues.</>} />
              </div>
            )}
          </div>
        </CustomCheckbox>
      </Form.Item>
      <div style={{ position: "relative" }}>
        <Form.Item
          name="chargementTime"
          label={<div style={{ display: "flex", alignItems: "center" }}>Chargement</div>}
          className={styles.smallLabel}
        >
          <DatePicker
            picker="time"
            placeholder="00 : 00"
            format="HH:mm"
            className="custom-datepicker-timer"
            suffixIcon={false}
            disabled={!formValue.enableTime}
          />
        </Form.Item>
        {isShowTutorial && (
          <div style={{ top: "0.5rem", position: "absolute", left: "50%", zIndex: 5, marginLeft: "3.25rem" }}>
            <IndicatorTutorialView hint={<>Heure de Chargement.</>} />
          </div>
        )}
      </div>

      <div style={{ position: "relative" }}>
        <Form.Item
          name="dechargementTime"
          label={<div style={{ display: "flex", alignItems: "center" }}>Déchargement</div>}
          className={styles.smallLabel}
        >
          <DatePicker
            picker="time"
            placeholder="00 : 00"
            format="HH:mm"
            className="custom-datepicker-timer"
            disabled={!formValue.enableTime}
            suffixIcon={false}
          />
        </Form.Item>
        {isShowTutorial && (
          <div style={{ top: "0.5rem", position: "absolute", left: "50%", zIndex: 5, marginLeft: "3.25rem" }}>
            <IndicatorTutorialView hint={<>Heure de Livraison.</>} />
          </div>
        )}
      </div>
    </Form>
  );
};

export default SelectTime;

/** @format */

import { SelectContactValue } from "./SelectContactValue";
import IndicatorTutorialView from "../../../../components/IndicatorTutorial";
import React from "react";

const SwitchTitle = (props: { title?: string; isShowTutorial?: boolean }) => {
  const { title } = props;
  switch (title) {
    case SelectContactValue.PLATEFORME:
      return (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            Lien
            {props.isShowTutorial ? (
              <div style={{ marginLeft: "0.5rem" }}>
                <IndicatorTutorialView
                  hint={
                    <>
                      Lien à utiliser pour la <i>Réception de Commandes</i>.
                    </>
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      );
    case SelectContactValue.MAIL:
      return (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            Adresse
            {props.isShowTutorial ? (
              <div style={{ marginLeft: "0.5rem" }}>
                <IndicatorTutorialView
                  hint={
                    <>
                      Adresse à utiliser pour la <i>Prise de rendez-vous Chargement</i>.
                    </>
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      );
    case SelectContactValue.TELEPHONE:
      return (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            Numéro
            {props.isShowTutorial ? (
              <div style={{ marginLeft: "0.5rem" }}>
                <IndicatorTutorialView
                  hint={
                    <>
                      Numéro à utiliser pour la <i>Prise de rendez-vous Livraison</i>.
                    </>
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      );
    default:
      return <></>;
  }
};

export default SwitchTitle;

/** @format */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosResponse } from "axios";
import { from, map, Observable } from "rxjs";
import { Method, request } from "../../helper/request.helper";
import { Client } from "../../models";
import { LoopbackAPI } from "./loopbackApi.api";

const COMPONENT_NAME = "Commercials";

export class CommercialAPI implements LoopbackAPI<Client> {
  find = (filter?: unknown): Observable<Client[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params: {
          filter,
        },
      })
    ).pipe(map((response: AxiosResponse<Client[]>) => response.data));
  };

  count = (): Observable<number> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/count`,
      })
    ).pipe(map((response: AxiosResponse<number>) => response.data));
  };

  patch = (id: number, data: any): Observable<void> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbCreate = (data: any): Observable<void> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbDelete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/${id}`,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static fetchAll = (params?: unknown): Observable<Client[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params,
      })
    ).pipe(map((response: AxiosResponse<Client[]>) => response.data));
  };
}

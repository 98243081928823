/** @format */

import PopupConfirm from "../../../components/PopupConfirm";
import { useAppSelector } from "../../../app/hooks";
import * as React from "react";
import { useEffect, useState } from "react";
import SuccessIcon from "../../../assets/icons/checked.svg";
import { getCurrentDemande } from "../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import SingleLoading from "../../../components/SingleLoading";
import { notification } from "antd";
import { fetchDemandeClients, selectCaracteristiquesLigne } from "../../../app/reducers/demandeClient.reducer";
import { useDispatch } from "react-redux";
import { ActionType, UpdateDemandeState } from "../../../app/reducers/DemandeClient/DemandeState.reducer";
import { useNavigate } from "react-router-dom";
import { DemandeClientAPI } from "../../../domain/apis/demandeClient.api";
import { finalize, mergeMap, noop, Observable, of, tap } from "rxjs";
import { DemandeClient } from "../../../models";
import { getPopup, PopupType, updatePopUp } from "../../../app/reducers/DemandeClient/Popup.reducer";
import { doOnSubscribe } from "../../../helper/rxjs.helper";
import { DemandeClientSubType, DemandeClientType } from "../../../helper/calendarHelper";
import { CaracteristiquesLigneAPI } from "../../../domain/apis/caracteristiquesLigne.api";
import { GetPrePprateResponse } from "../../../models/responses/getPrePprate.model";
import { GetPrePprateRequestParams } from "../../../models/requests/getPrePprate.model.ts";
import moment from "moment";

const PopupContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentType = useAppSelector(getPopup);
  const currentDemande = useAppSelector(getCurrentDemande);
  const currentLigne = useAppSelector(selectCaracteristiquesLigne);
  const [showPopup, setShowPopup] = useState<PopupType>(currentType.type);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [diffTotalTransporteurs, setDiffTotalTransporteurs] = useState<{ diff: string; startDate: string; endDate: string }[]>([]);

  useEffect(() => {
    setShowPopup(currentType.type);
  }, [currentType]);

  const checkPprateDecrease$ = (): Observable<boolean> => {
    const data: GetPrePprateRequestParams[] = generateChangedDates(currentDemande);
    if (
      currentLigne.caracteristiques?.ppRate != undefined &&
      currentLigne.caracteristiques.ppRate >= (currentLigne.caracteristiques?.tauxParcPropre ?? 0)
    ) {
      return CaracteristiquesLigneAPI.getPrePprate(Number(currentLigne.id), data).pipe(
        mergeMap((response: GetPrePprateResponse) => {
          if (response.ppRate != undefined && response.ppRate < (currentLigne.caracteristiques?.tauxParcPropre ?? 0)) {
            dispatch(updatePopUp({ type: PopupType.PPRATE_DECREASE }));
            return of(true);
          }
          return removeDemandeClient$();
        })
      );
    } else {
      return removeDemandeClient$();
    }
  };

  const generateChangedDates = (currentDemande?: DemandeClient): GetPrePprateRequestParams[] => {
    const currentDemandeDates = currentDemande?.demandeClientDates ?? [];
    const allDates: string[] = currentDemandeDates.map((demandeDate) => demandeDate.date ?? "").filter((date) => date.length > 0);
    return allDates.map((date: string) => {
      let diff: number;
      const currentDemandeDate = currentDemandeDates.find((demandeDate) => demandeDate.date === date);
      if (currentDemande?.type === DemandeClientType.SEMAINE && currentDemande?.subType === DemandeClientSubType.GLOBAL) {
        diff = -((currentDemande?.totalMaxLot ?? 0) / 7);
      } else if (currentDemande?.type === DemandeClientType.MOIS && currentDemande?.subType === DemandeClientSubType.GLOBAL) {
        const currentTotalMaxLot = currentDemande?.moisGlobalOptions?.find((item) => item.month === new Date(date).getMonth())?.totalMaxLot ?? 0;
        diff = -((currentTotalMaxLot * 12) / 365);
      } else {
        diff = -(currentDemandeDate?.lotMax ?? 0);
      }
      return {
        ...(currentDemande?.type == DemandeClientType.JOUR ||
        (currentDemande?.type == DemandeClientType.SEMAINE && currentDemande?.subType === DemandeClientSubType.JOUR) ||
        (currentDemande?.type == DemandeClientType.MOIS &&
          (currentDemande?.subType === DemandeClientSubType.JOUR || currentDemande?.subType === DemandeClientSubType.AUTRE))
          ? { dJour: diff }
          : currentDemande?.type == DemandeClientType.SEMAINE
          ? { dSemaine: diff }
          : { dMois: diff }),
        date: date,
      };
    });
  };

  const removeDemandeClient$ = (): Observable<boolean> => {
    if (!(currentDemande && currentDemande.id)) {
      notification.info({
        key: "notFound",
        message: "Impossible de trouver le client de la demande client",
        placement: "bottom",
      });
      return of(true);
    }
    setShowPopup(PopupType.NONE);
    return DemandeClientAPI.delete(currentDemande.id).pipe(
      mergeMap(() => DemandeClientAPI.fetchAllByCaracteristiquesLigneId(Number(currentLigne.id))),
      tap({
        next: (demandeClients: DemandeClient[]) => {
          // Clear current selected demande client if needed
          dispatch(UpdateDemandeState({ actionType: ActionType.VIEW }));
          // Reload demande clients list
          dispatch(fetchDemandeClients({ demandeClients }));
          // Show popup success
          setShowPopup(PopupType.SUCCESS_DELETE);
        },
        error: () => {
          // Show popup error
          setShowPopup(PopupType.ERROR);
        },
      }),
      mergeMap(() => of(true))
    );
  };

  const changePopupToNone = () => {
    setShowPopup(PopupType.NONE);
    dispatch(updatePopUp({ type: PopupType.NONE }));
  };

  if (isLoading) {
    return <SingleLoading />;
  }

  switch (showPopup) {
    case PopupType.SUCCESS:
      return (
        <PopupConfirm
          visible={true}
          okText="Terminer"
          title="Programme enregistré"
          onOk={changePopupToNone}
          onCancel={changePopupToNone}
          hideClose={true}
        >
          <img src={SuccessIcon} alt="Success" style={{ width: "2rem", marginBottom: "1rem" }} />
          <p>Le programme a bien été enregistré.</p>
        </PopupConfirm>
      );
    case PopupType.SUCCESS_CREATE_LINE:
      return (
        <PopupConfirm
          visible={true}
          okText="Terminer"
          title="Ligne enregistrée"
          onOk={() => {
            changePopupToNone();
            navigate(`/dashboard/lines-contracted/${currentLigne.id}`);
          }}
          onCancel={changePopupToNone}
        >
          <img src={SuccessIcon} alt="Success" style={{ width: "2rem", marginBottom: "1rem" }} />
          <p>La ligne a bien été enregistrée.</p>
        </PopupConfirm>
      );
    case PopupType.ERROR:
      return (
        <PopupConfirm visible={true} okText="Terminer" title="Une erreur s'est produite." onOk={changePopupToNone} onCancel={changePopupToNone}>
          <p>Veuillez réessayer plus tard.</p>
        </PopupConfirm>
      );
    case PopupType.DIFF_TOTAL_TRANSPORTEUR:
      return (
        <PopupConfirm
          visible={true}
          cancelText="Non"
          okText="Oui"
          title="Attention"
          onCancel={changePopupToNone}
          onOk={() => {
            changePopupToNone();
            checkPprateDecrease$()
              .pipe(
                doOnSubscribe(() => setIsLoading(true)), // Show loading on start
                tap({
                  error: () => {
                    dispatch(updatePopUp({ type: PopupType.ERROR }));
                  },
                }),
                finalize(() => setIsLoading(false)) // Hide loading on end
              )
              .subscribe({ error: noop });
          }}
          hideClose={true}
          childrenDetail={
            <>
              <span>
                Le nombre de lots pris passe en moyenne quotidiennement :
                {currentType.diffTotalTransporteurs.map((diffTotalTransporteur) =>
                  diffTotalTransporteur.startDate === diffTotalTransporteur.endDate ? (
                    <>
                      <br />- de {diffTotalTransporteur.diff.toFixed(2)} du {moment(diffTotalTransporteur.startDate).format("DD/MM/YYYY")}
                    </>
                  ) : (
                    <>
                      <br />- de {diffTotalTransporteur.diff.toFixed(2)} du {moment(diffTotalTransporteur.startDate).format("DD/MM/YYYY")} au{" "}
                      {moment(diffTotalTransporteur.endDate).format("DD/MM/YYYY")}
                    </>
                  )
                )}
              </span>
            </>
          }
          showDetail={true}
        >
          <span>
            Êtes-vous sûr de vouloir modifier la partie Demande Client sachant que la partie Transporteurs a déjà été complétée ?
            <br />
            Les taux pris seront conservés bien que la Demande Client ait changé. Merci de confirmer la cohérence
          </span>
        </PopupConfirm>
      );
    case PopupType.DELETE:
      return (
        <PopupConfirm
          visible={true}
          cancelText="Non"
          okText="Oui"
          title="Attention"
          onCancel={changePopupToNone}
          onOk={() => {
            changePopupToNone();
            checkPprateDecrease$()
              .pipe(
                doOnSubscribe(() => setIsLoading(true)), // Show loading on start
                tap({
                  error: () => {
                    dispatch(updatePopUp({ type: PopupType.ERROR }));
                  },
                }),
                finalize(() => setIsLoading(false)) // Hide loading on end
              )
              .subscribe({ error: noop });
          }}
          hideClose={true}
        >
          <span>Êtes-vous sûr de vouloir supprimer l&apos;ensemble de dates sélectionné ?</span>
        </PopupConfirm>
      );
    case PopupType.SUCCESS_DELETE:
      return (
        <PopupConfirm
          visible={true}
          okText="Terminer"
          title="Élément supprimé"
          onOk={changePopupToNone}
          onCancel={changePopupToNone}
          hideClose={true}
        >
          <span>
            <img src={SuccessIcon} style={{ width: "2rem", marginBottom: "1rem" }} alt="Success" />
            <p>L’ensemble de dates a bien été supprimé</p>
          </span>
        </PopupConfirm>
      );
    case PopupType.PPRATE_DECREASE:
      return (
        <PopupConfirm
          visible={true}
          cancelText="Non"
          okText="Oui"
          title="Attention"
          onCancel={changePopupToNone}
          onOk={() => {
            changePopupToNone();
            removeDemandeClient$()
              .pipe(
                doOnSubscribe(() => setIsLoading(true)), // Show loading on start
                tap({
                  error: () => {
                    dispatch(updatePopUp({ type: PopupType.ERROR }));
                  },
                }),
                finalize(() => setIsLoading(false)) // Hide loading on end
              )
              .subscribe({ error: noop });
          }}
          hideClose={true}
        >
          <span>Le taux de Parc Propre Contractuel n&apos;atteindra plus l&apos;objectif fixé pour cette ligne. Souhaitez-vous continuer?</span>
        </PopupConfirm>
      );
    default:
      return <></>;
  }
};

export default PopupContainer;

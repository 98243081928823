/** @format */

import { Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import CustomInputNumber from "../../../../components/CustomInputNumber";
import IndicatorTutorialView from "../../../../components/IndicatorTutorial";
import { Chargement, Unite } from "../../../../models";
import { Alloue } from "../../../../models/alloue.model";
import styles from "../Allocation/Allocation.module.css";

const { Option } = Select;

interface AlloueProps {
  index: number;
  itemData: Alloue;
  plageState: boolean;
  actionSave: boolean;
  listUnit: Unite[];
  taux: boolean;
  setTaux: (status: boolean) => void;
  submitCallBack: (index: number, statusItem: boolean) => void;
  itemCallBack: (index: number, item: FormModal) => void;
  isShowTutorial: boolean;
  chargementsLigne?: Chargement[];
  editAble: boolean;
}

interface FormModal {
  id?: number;
  volumeTotalClient?: number;
  taux?: boolean;
  volumeAlloue?: number;
  tauxAlloue?: number;
  volumeUnityId?: number;
  volumeFrequencyId?: number;
}

const AllocationItem = (props: AlloueProps) => {
  const [form] = Form.useForm();

  const { actionSave, itemData, plageState, listUnit, taux, setTaux, index, submitCallBack, chargementsLigne, itemCallBack, editAble } = props;
  const [volumeUnityList, setVolumeUnityList] = useState<Unite[]>([]);
  const [volumeFrequencyList, setVolumeFrequencyList] = useState<Unite[]>([]);
  const [formValue, setFormValue] = useState<FormModal>({
    volumeTotalClient: itemData.volumeTotalClient,
    taux: itemData.taux,
    volumeAlloue: itemData.volumeAlloue,
    tauxAlloue: itemData.tauxAlloue,
    volumeUnityId: itemData.volumeUnityId,
    volumeFrequencyId: itemData.volumeFrequencyId,
  });

  useEffect(() => {
    setUnitToChargement();
    form.setFieldsValue({
      volumeTotalClient: itemData.volumeTotalClient,
      taux: itemData.taux ? itemData.taux : false,
      volumeAlloue: itemData.volumeAlloue,
      tauxAlloue: itemData.tauxAlloue,
      volumeUnityId: itemData.volumeUnityId ? itemData.volumeUnityId : listUnit.find((el) => el.nom === "lot")?.id,
      volumeFrequencyId: itemData.volumeFrequencyId ? itemData.volumeFrequencyId : listUnit.find((el) => el.nom === "Mois")?.id,
    });
    setFormValue({
      ...formValue,
      volumeTotalClient: itemData.volumeTotalClient,
      taux: itemData.taux ? itemData.taux : false,
      volumeAlloue: itemData.volumeAlloue,
      tauxAlloue: itemData.tauxAlloue,
      volumeUnityId: itemData.volumeUnityId ? itemData.volumeUnityId : volumeUnityList[0]?.id,
      volumeFrequencyId: itemData.volumeFrequencyId ? itemData.volumeFrequencyId : volumeFrequencyList[0]?.id,
    });
  }, [itemData, listUnit]);

  useEffect(() => {
    if (actionSave) {
      form.submit();
    }
  }, [actionSave]);

  const onFinish = (values: FormModal) => {
    submitCallBack(index, true);
    itemCallBack(index, {
      id: itemData.id,
      volumeTotalClient: values.volumeTotalClient,
      taux: taux,
      volumeAlloue: values.volumeAlloue,
      tauxAlloue: values.tauxAlloue,
      volumeUnityId: values.volumeUnityId,
      volumeFrequencyId: values.volumeFrequencyId,
    });
  };

  const onFinishFailed = () => {
    submitCallBack(index, false);
  };
  const setUnitToChargement = () => {
    const itemChargement = chargementsLigne ? chargementsLigne[index] : undefined;

    if (itemChargement?.palettesMin || itemChargement?.palettesMax) {
      const arrayVolumeUnity = listUnit.filter((item) => item.nom == "lot" || item.nom == "tonne" || item.nom == "palette");
      setVolumeUnityList(arrayVolumeUnity);
    } else {
      const arrayVolumeUnity = listUnit.filter((item) => item.nom == "lot" || item.nom == "tonne");
      setVolumeUnityList(arrayVolumeUnity);
    }
    const arrayVolumeFrequency = listUnit.filter((item) => item.nom == "Mois" || item.nom == "An");
    setVolumeFrequencyList(arrayVolumeFrequency);
  };
  const onValuesChange = () => {
    const getFieldsValue = form.getFieldsValue();
    if (taux && (getFieldsValue.tauxAlloue || getFieldsValue.volumeTotalClient)) {
      form.setFieldsValue({
        ...getFieldsValue,
        tauxAlloue: getFieldsValue.tauxAlloue,
        volumeAlloue:
          getFieldsValue.volumeTotalClient && getFieldsValue.tauxAlloue
            ? Number((getFieldsValue.volumeTotalClient * getFieldsValue.tauxAlloue) / 100).toFixed(0)
            : null,
      });
      setFormValue({
        ...getFieldsValue,
        tauxAlloue: getFieldsValue.tauxAlloue,
        volumeAlloue:
          getFieldsValue.volumeTotalClient && getFieldsValue.tauxAlloue
            ? Number((getFieldsValue.volumeTotalClient * getFieldsValue.tauxAlloue) / 100).toFixed(0)
            : null,
      });
    }
    if (!taux && (getFieldsValue.volumeAlloue || getFieldsValue.volumeTotalClient)) {
      form.setFieldsValue({
        ...getFieldsValue,
        volumeAlloue: getFieldsValue.volumeAlloue,
        tauxAlloue:
          getFieldsValue.volumeAlloue && getFieldsValue.volumeTotalClient
            ? Number((getFieldsValue.volumeAlloue / getFieldsValue.volumeTotalClient) * 100).toFixed(0)
            : null,
      });
      setFormValue({
        ...getFieldsValue,
        volumeAlloue: getFieldsValue.volumeAlloue,
        tauxAlloue:
          getFieldsValue.volumeAlloue && getFieldsValue.volumeTotalClient
            ? Number((getFieldsValue.volumeAlloue / getFieldsValue.volumeTotalClient) * 100).toFixed(0)
            : null,
      });
    }
    setFormValue(getFieldsValue);
  };
  const volumeAlloueUnit = () => {
    if (volumeUnityList.length > 0 && volumeFrequencyList.length > 0) {
      const unit = volumeUnityList.filter((el) => el.id === formValue.volumeUnityId);
      const frequency = volumeFrequencyList.filter((el) => el.id === formValue.volumeFrequencyId);

      if (unit.length > 0 && unit[0].nom && frequency.length > 0 && frequency[0].nom) {
        return `${unit[0].nom} / ${frequency[0].nom}`;
      }
      return `${volumeUnityList[0].nom} / ${volumeFrequencyList[0].nom}`;
    }
    return;
  };
  return (
    <Form
      style={{ padding: "0 5px" }}
      form={form}
      name="clientForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      colon={false}
      onValuesChange={onValuesChange}
      disabled={!editAble}
    >
      {plageState &&
        chargementsLigne &&
        chargementsLigne.length > 0 &&
        (chargementsLigne[index]?.ftl ? (
          <div style={{ margin: "auto", textAlign: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              MPL: FTL
              {props.isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView hint={"Plage MPL de la colonne."} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div style={{ margin: "auto", textAlign: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              MPL: {chargementsLigne[index]?.mplMin} / {chargementsLigne[index]?.mplMax}
              {props.isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView hint={"Plage MPL de la colonne."} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ))}
      <ul className={styles.inputChargement}>
        <li className={styles.liCenter}>
          <Row style={{ alignItems: "center" }}>
            <Col>
              <Form.Item name="volumeTotalClient" rules={[{ required: true, message: "" }]} className={styles.fullHeight}>
                <CustomInputNumber step={1} precision={0} min={1} max={9999999} />
              </Form.Item>
            </Col>
            <Col style={{ flex: 1, paddingLeft: 8 }}>
              <Row>
                <Col span={13}>
                  <Form.Item name="volumeUnityId" rules={[{ required: true, message: "" }]} className={styles.fullHeight}>
                    <Select bordered={false}>
                      {volumeUnityList.map((el, index) => {
                        return (
                          <Option key={index} value={el.id}>
                            {el.nom}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item name="volumeFrequencyId" rules={[{ required: true, message: "" }]} className={styles.fullHeight}>
                    <Select bordered={false}>
                      {volumeFrequencyList.map((el, index) => {
                        return (
                          <Option key={index} value={el.id}>
                            {el.nom}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </li>
        <li className={styles.liCenter}>
          <Row style={{ alignItems: "center" }}>
            {taux ? (
              <Form.Item name="tauxAlloue" rules={[{ required: true, message: "" }]} className={styles.fullHeight}>
                <CustomInputNumber step={1} precision={0} readOnly={!taux} min={1} max={100} suffix="%" />
              </Form.Item>
            ) : (
              <Form.Item name="volumeAlloue" rules={[{ required: true, message: "" }]} className={styles.fullHeight}>
                <CustomInputNumber step={1} precision={0} readOnly={taux} min={1} max={formValue.volumeTotalClient} suffix={volumeAlloueUnit()} />
              </Form.Item>
            )}
            {index == 0 && (
              <Col style={{ flex: 1, display: "flex", justifyContent: "flex-end" }} offset={1}>
                <Form.Item valuePropName="checked" name="taux" style={{ marginBottom: 0 }}>
                  <CustomCheckbox onChange={() => setTaux(!taux)} checked={taux} disabled={!editAble}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Taux
                      {props.isShowTutorial ? (
                        <div style={{ marginLeft: "0.5rem" }}>
                          <IndicatorTutorialView hint={"À cocher si Taux alloué à saisir, sinon Volume alloué à saisir."} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </CustomCheckbox>
                </Form.Item>
              </Col>
            )}
          </Row>
        </li>
      </ul>
      <ul className={styles.inputChargement}>
        <li className={styles.liCenter}>
          {!taux ? (
            <Form.Item name="tauxAlloue" rules={[{ required: true, message: "" }]} className={styles.fullHeight}>
              <CustomInputNumber step={1} precision={0} readOnly={!taux} min={1} max={100} suffix="%" />
            </Form.Item>
          ) : (
            <Form.Item name="volumeAlloue" rules={[{ required: true, message: "" }]} className={styles.fullHeight}>
              <CustomInputNumber step={1} precision={0} readOnly={taux} min={1} max={formValue.volumeTotalClient} suffix={volumeAlloueUnit()} />
            </Form.Item>
          )}
        </li>
      </ul>
    </Form>
  );
};

export default AllocationItem;

/** @format */

import { message, Select } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { finalize, noop, tap } from "rxjs";
import { useAppSelector } from "../../../../app/hooks";
import { selectCaracteristiquesLigne } from "../../../../app/reducers/demandeClient.reducer";
import { getCurrentDemande } from "../../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import { ActionType, selectDemandeState } from "../../../../app/reducers/DemandeClient/DemandeState.reducer";
import { getListPays } from "../../../../app/reducers/DemandeClient/ListPays.reducer";
import { UpdateNewDemande } from "../../../../app/reducers/DemandeClient/NewDemande.reducer";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import PopupConfirm from "../../../../components/PopupConfirm";
import SingleLoading from "../../../../components/SingleLoading";
import { HolidayAPI } from "../../../../domain/apis/holiday.api";
import { getRangeDate } from "../../../../helper/getRangeDate";
import { doOnSubscribe } from "../../../../helper/rxjs.helper";
import { Pays } from "../../../../models";
import IndicatorTutorialView from "../../../../components/IndicatorTutorial";
import { selectIsShowTutorial } from "../../../../app/reducers/indicatorTutorial.reducer";

const { Option } = Select;

interface HolidayProps {
  getHolidays: (value: string[]) => void;
}

/* eslint-disable react/display-name */
const HolidaySelect = (props: HolidayProps) => {
  const { getHolidays } = props;
  const dispatch = useDispatch();
  const currentLigne = useAppSelector(selectCaracteristiquesLigne).caracteristiques;
  const demandeState = useAppSelector(selectDemandeState);
  const currentDemande = useAppSelector(getCurrentDemande);
  const pays = useAppSelector(getListPays);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);

  const [checkedHoliday, setCheckedHoliday] = useState<boolean>(currentDemande?.saufJoursFeries ? currentDemande?.saufJoursFeries : false);
  const [PopupHoliday, setPopupHoliday] = useState<boolean>(false);
  const [listHoliday, setListHoliday] = useState<string[]>([]);
  const [countryCode, setCountryCode] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getHolidays(listHoliday);
  }, [listHoliday]);

  const checkHoliday = (e: CheckboxChangeEvent) => {
    setCheckedHoliday(e.target.checked);
    if (e.target.checked) {
      if (pays.length > 1) {
        setPopupHoliday(true);
      } else {
        // Call request for first pay
        if (pays[0]?.code) {
          setCountryCode(pays[0].code);
          getListHoliday(pays[0].code);
        }
      }
    } else {
      setListHoliday([]);
      dispatch(UpdateNewDemande({ saufJoursFeries: false }));
    }
  };

  const getListHoliday = (countryCode: string) => {
    if (currentLigne) {
      setIsLoading(true);
      const [startDate, endDate] = getRangeDate(currentLigne);
      const startYear = startDate?.slice(0, 4);
      const endYear = endDate?.slice(0, 4);

      HolidayAPI.getHolidays(countryCode, Number(startYear), Number(endYear))
        .pipe(
          doOnSubscribe(() => setIsLoading(true)), // Show loading on start
          tap({
            next: (response: Map<string, { date: string }[]>) => {
              let getHoliday: string[] = [];
              Object.keys(response).map((key: string) => {
                const holidays: { date: string }[] = response.get(key) ?? [];
                getHoliday = getHoliday.concat(holidays.map((item: { date: string }) => item.date.replaceAll("-", "/")));
              });
              setListHoliday(getHoliday);
            },
            error: () => {
              message.error("Merci de compléter toutes les colonnes.");
            },
          }),
          finalize(() => setIsLoading(false)) // Hide loading on end
        )
        .subscribe({
          error: noop,
        });
      dispatch(UpdateNewDemande({ saufJoursFeries: true, countryCode }));
    } else {
      message.error("Erreur").then();
    }
  };

  const ConfirmHoliday = () => {
    // call request for selected pay
    if (countryCode) {
      setPopupHoliday(false);
      getListHoliday(countryCode);
    } else {
      message.error("Veuillez sélectionner le code du pays").then();
    }
  };

  return (
    <>
      {isLoading && <SingleLoading />}
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <CustomCheckbox
          checked={checkedHoliday}
          style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
          onChange={(e) => checkHoliday(e)}
          disabled={demandeState.actionType === ActionType.VIEW}
        >
          Sauf jours fériés
        </CustomCheckbox>
        {isShowTutorial ? (
          <div style={{ marginLeft: "0.5rem" }}>
            <IndicatorTutorialView hint={<>Les jours fériés ne seront pas inclus dans la sélection.</>} />
          </div>
        ) : (
          <></>
        )}
      </div>

      <PopupConfirm
        visible={PopupHoliday}
        cancelText="Annuler"
        okText="Valider"
        title="Jours fériés"
        onCancel={() => {
          setPopupHoliday(false);
          setCheckedHoliday(false);
        }}
        onOk={ConfirmHoliday}
      >
        <p>Vous souhaitez retirer les jours fériés de quel pays ?</p>

        <Select
          showArrow={true}
          bordered={true}
          style={{ width: "100%", borderRadius: "12px", textAlign: "left" }}
          onChange={(value: string) => {
            setCountryCode(value);
          }}
          getPopupContainer={(trigger) => trigger.parentNode}
          value={countryCode}
        >
          {pays.length > 0 &&
            pays.map((pay: Pays) => (
              <Option key={`countryCode${pay.code}`} value={pay.code}>
                {pay.code} - {pay.nom}
              </Option>
            ))}
        </Select>
      </PopupConfirm>
    </>
  );
};

export default HolidaySelect;

/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export enum PopupType {
  NONE = "none",
  SUCCESS = "success",
  ERROR = "error",
  DELETE = "delete",
  SUCCESS_DELETE = "success_delete",
  SUCCESS_CREATE_LINE = "success_create_line",
  // HAVE_TRANSPORTEUR = "have_transporteur",
  DIFF_TOTAL_TRANSPORTEUR = "diff_total_transporteur",
  PPRATE_DECREASE = "pprate_decrease",
}

const initialState: { type: PopupType; toggle: boolean; diffTotalTransporteurs: { diff: number; startDate: string; endDate: string }[] } = {
  type: PopupType.NONE,
  toggle: false,
  diffTotalTransporteurs: [],
};

export const PopupSlice = createSlice({
  name: "PopupType",
  initialState,
  reducers: {
    updatePopUp: (
      state,
      action: PayloadAction<{ type: PopupType; diffTotalTransporteurs?: { diff: number; startDate: string; endDate: string }[] }>
    ) => {
      state.type = action.payload.type;
      state.toggle = !state.toggle;
      state.diffTotalTransporteurs = action.payload.diffTotalTransporteurs ?? [];
    },
  },
});

export const { updatePopUp } = PopupSlice.actions;

export const getPopup = (state: RootState) => state.popupType;

export default PopupSlice.reducer;

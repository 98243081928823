import { useEffect, useRef } from "react";
import styles from "./progressOutline.module.css";

type ProgressOutlineProps = {
  progress: number;
};

const ProgressOutline = (props: ProgressOutlineProps) => {
  const progressRef = useRef<HTMLDivElement | null>(null);
  const progressRef2 = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // draw
    if (
      progressRef.current === null ||
      progressRef.current === undefined ||
      progressRef2.current === null ||
      progressRef2.current === undefined
    )
      return;

    const offsetWidth = progressRef.current.offsetWidth;
    const offsetHeight = progressRef.current.offsetHeight;
    const totalLength = offsetWidth * 2 + offsetHeight * 2;
    const borderLen = (props.progress / 100) * totalLength;

    let backgroundPosFirstHalf = undefined;
    let backgroundSizeFirstHalf = undefined;
    let backgroundPosSecondHalf = undefined;
    let backgroundSizeSecondHalf = undefined;

    if (borderLen <= offsetWidth / 2) {
      backgroundPosFirstHalf =
        "background-position: 9px 0px, 17px 0px, 18px 17px, 0px 18px";
      backgroundSizeFirstHalf =
        "background-size: " +
        Math.round((borderLen / offsetWidth) * 100) +
        "% 1px, 1px 0%, 0% 1px, 1px 0%";
    } else if (borderLen <= offsetWidth / 2 + offsetHeight) {
      backgroundPosFirstHalf =
        "background-position: 9px 0px, 17px 0px, 18px 17px, 0px 18px";
      backgroundSizeFirstHalf =
        "background-size: 50% 1px, 1px " +
        Math.round(((borderLen - offsetWidth / 2) / offsetHeight) * 100) +
        "%, 0% 1px, 1px 0%";
    } else if (borderLen <= offsetWidth + offsetHeight) {
      backgroundPosFirstHalf =
        "background-position: 9px 0px, 17px 0px, " +
        (18 - Math.round(borderLen - offsetWidth / 2 - offsetHeight)) +
        "px 17px, 0px 18px";
      backgroundSizeFirstHalf =
        "background-size: 50% 1px, 1px 100%, " +
        Math.round(
          ((borderLen - offsetWidth / 2 - offsetHeight) / offsetWidth) * 100
        ) +
        "% 1px, 1px 0%";
    } else if (borderLen <= offsetWidth / 2 + offsetWidth + offsetHeight) {
      backgroundPosFirstHalf =
        "background-position: 9px 0px, 17px 0px, 9px 17px, 0px 18px";
      backgroundSizeFirstHalf =
        "background-size: 50% 1px, 1px 100%, 50% 1px, 1px 0%";

      backgroundPosSecondHalf =
        "background-position: 9px 0px, 17px 0px, " +
        (9 - (borderLen - offsetWidth - offsetHeight)) +
        "px 17px, 0px 18px";
      backgroundSizeSecondHalf =
        "background-size: 0% 1px, 1px 0%, " +
        Math.round(
          ((borderLen - offsetWidth - offsetHeight) / offsetWidth) * 100
        ) +
        "% 1px, 1px 0%";
    } else if (borderLen <= offsetWidth / 2 + offsetWidth + offsetHeight * 2) {
      backgroundPosFirstHalf =
        "background-position: 9px 0px, 17px 0px, 9px 17px, 0px 18px";
      backgroundSizeFirstHalf =
        "background-size: 50% 1px, 1px 100%, 50% 1px, 1px 0%";

      backgroundPosSecondHalf =
        "background-position: 9px 0px, 17px 0px, 0px 17px, 0px " +
        (18 - (borderLen - offsetWidth / 2 - offsetWidth - offsetHeight)) +
        "px";
      backgroundSizeSecondHalf =
        "background-size: 0% 1px, 1px 0%, 100% 1px, 1px 100%";
    } else {
      backgroundPosFirstHalf =
        "background-position: 9px 0px, 17px 0px, 9px 17px, 0px 18px";
      backgroundSizeFirstHalf =
        "background-size: 50% 1px, 1px 100%, 50% 1px, 1px 0%";

      backgroundPosSecondHalf =
        "background-position: 0px 0px, 17px 0px, 0px 17px, 0px 0px";
      backgroundSizeSecondHalf =
        "background-size: " +
        Math.round(
          ((borderLen - offsetWidth / 2 - offsetWidth - offsetHeight * 2) /
            offsetWidth) *
            100
        ) +
        "% 1px, 1px 0%, 100% 1px, 1px 100%";
    }

    if (backgroundPosFirstHalf !== undefined) {
      progressRef.current.setAttribute(
        "style",
        [backgroundPosFirstHalf, backgroundSizeFirstHalf].join("; ")
      );
    }
    if (backgroundPosSecondHalf !== undefined) {
      progressRef2.current.setAttribute(
        "style",
        [backgroundPosSecondHalf, backgroundSizeSecondHalf].join("; ")
      );
    }
  }, [progressRef, progressRef2]);
  return props.progress > 0 ? (
    <>
      <div className={styles.dayProgress} ref={progressRef} />
      <div className={styles.dayProgress} ref={progressRef2} />
    </>
  ) : (
    <></>
  );
};

export default ProgressOutline;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  associateRateFilterColors,
  AssociateRateFilterInterface,
} from "../../../models/constants/associateRateFilter.const";
import { RootState } from "../../store";

interface FilterDatesByAssociateRateInterface {
  filterAssociateRates?: AssociateRateFilterInterface[];
}

const initialState: FilterDatesByAssociateRateInterface = {
  filterAssociateRates: [],
};

export const FilterDatesByAssociateRateSlice = createSlice({
  name: "filterDatesByAssociateRate",
  initialState,
  reducers: {
    resetFilterAssociateRate: (
      state,
    ) => {
      state.filterAssociateRates = [];
    },
    selectedFilterAssociateRate: (
      state,
      action: PayloadAction<AssociateRateFilterInterface>
    ) => {
      const isSelected =
        state.filterAssociateRates
          ?.map((item) => item.id)
          .includes(action.payload.id) ?? false;
      if (isSelected) {
        // Remove selected
        state.filterAssociateRates = state.filterAssociateRates?.filter(
          (item) => item.id !== action.payload.id
        );
      } else {
        // Add new selected to list
        state.filterAssociateRates = [
          ...(state.filterAssociateRates ?? []),
          ...associateRateFilterColors.filter(
            (item) => item.id === action.payload.id
          ),
        ];
      }
    },
  },
});

export const { resetFilterAssociateRate, selectedFilterAssociateRate } =
  FilterDatesByAssociateRateSlice.actions;

export const selectFilterAssociateRates = (state: RootState) =>
  state.filterDatesByAssociateRate.filterAssociateRates;

export default FilterDatesByAssociateRateSlice.reducer;

/** @format */

import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Row, Select, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { combineLatest, noop, tap } from "rxjs";
import { useAppSelector } from "../../../../app/hooks";
import { selectIsShowTutorial } from "../../../../app/reducers/indicatorTutorial.reducer";
import {
  selectChoixTransporter,
  selectDemandeClients,
  selectLigne,
  selectLigneRequest,
  updateLigne,
  updateLigneRequest,
} from "../../../../app/reducers/ligne.reducer";
import SearchIcon from "../../../../assets/icons/search.svg";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import CustomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";
import IndicatorTutorialView from "../../../../components/IndicatorTutorial";
import SingleLoading from "../../../../components/SingleLoading";
import { ChargementAPI } from "../../../../domain/apis/chargement.api";
import { MarchandiseAPI } from "../../../../domain/apis/marchandise.api";
import { ModeTransportAPI } from "../../../../domain/apis/modeTransports.api";
import { TypeVehiculeAPI } from "../../../../domain/apis/typeVehicule.api";
import { makeRandom } from "../../../../helper/makeRandom";
import { useRxEffect } from "../../../../helper/rxjs.helper";
import { Budget, Chargement, ModeTransport } from "../../../../models";
import { Alloue } from "../../../../models/alloue.model";
import { Marchandise } from "../../../../models/marchandise.model";
import { TypeVehicule } from "../../../../models/typevehicule.model";
import styles from "./Chargement.module.css";
import ChargementItem from "./ChargementItem";
import PopupConfirm from "../../../../components/PopupConfirm";
import { CaracteristiquesPopupType, UpdateCaracteristiquesPopup } from "../../../../app/reducers/Caracteristiques/CaracteristiquesPopup.reducer";

const { Title } = Typography;
const { Option } = Select;

let globalSubmitArr: boolean[] = [];
let removeItemIndex: number | undefined = undefined;
let impactDemandeClientsCounter: number | undefined = undefined;
let impactChoixTransporteursCounter: number | undefined = undefined;

const ChargementView = (props: { editable: boolean }) => {
  const { editable } = props;
  const ligneData = useAppSelector(selectLigne);
  const ligneRequest = useAppSelector(selectLigneRequest);
  const [form] = Form.useForm();
  const isShowTutorial = useAppSelector(selectIsShowTutorial);

  const [loading, setLoading] = useState<boolean>(false);

  const [plageState, setPlageState] = useState(false);
  const [layout, setLayout] = useState<number>(8);
  const [actionSave, setActionSave] = useState<boolean>(false);
  const [chargementData, setChargementData] = useState<Chargement[]>([{}]);
  const [alloueData, setAlloueData] = useState<Alloue[]>([{}]);
  const [budgetData, setBudgetData] = useState<Budget[]>([{}]);
  const [modeTransport, setModeTransport] = useState<ModeTransport[]>([]);
  const [typeVihicle, setTypeVihicle] = useState<TypeVehicule[]>([]);
  const [marchandise, setMarchandise] = useState<Marchandise[]>([]);
  const [firstDuree, setFirstDuree] = useState<number>();
  const [submitArr, setSubmitArr] = useState<number>(-1);
  const [showPopupEditChargement, setShowPopupEditChargement] = useState<boolean>(false);

  const demandeClients = useAppSelector(selectDemandeClients);
  const choixTransporters = useAppSelector(selectChoixTransporter);

  const [ftlSelected, setFtlSelected] = useState<number>(-1);
  const dispatch = useDispatch();
  const sliderRef = useRef<Slider | null>(null);

  const [settings, setSettings] = useState({
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  useEffect(() => {
    if (ligneData.chargement && ligneData.chargement.length > 0) {
      setChargementData(ligneData.chargement);
    }
    if (ligneData.alloue && ligneData.alloue.length > 0) {
      setAlloueData(ligneData.alloue);
    }
    if (ligneData.budget && ligneData.budget.length > 0) {
      setBudgetData(ligneData.budget);
    }
    if (ligneData.plages) {
      setPlageState(ligneData.plages);
    }
    form.setFieldsValue({
      modeTransportId: ligneData.modeTransportId,
      typeVehiculeId: ligneData.typeVehiculeId,
      marchandiseId: ligneData.marchandiseId,
      adr: ligneData.adr,
      besoinsSpecifiques: ligneData.besoinsSpecifiques,
    });
    if (ligneData.chargement && ligneData.chargement.length > 0) {
      setFirstDuree(ligneData.chargement[0].dureeChargement);
    }
  }, [ligneData]);

  useEffect(() => {
    if (plageState && chargementData.length > 1) {
      setLayout(16);
      setSettings({
        ...settings,
        slidesToShow: window.innerWidth > 1200 ? 2 : 1,
      });
    } else {
      setLayout(8);
      setSettings({
        ...settings,
        slidesToShow: 1,
      });
    }

    const checkFtl = chargementData.some((el, index) => {
      if (el.ftl) {
        setFtlSelected(index);
        return true;
      }
      return false;
    });

    if (!checkFtl) {
      setFtlSelected(-1);
    }
  }, [plageState, chargementData, window.innerWidth]);

  useEffect(() => {
    if (actionSave) {
      const values = form.getFieldsValue();
      if ((!plageState && globalSubmitArr.length === 1) || globalSubmitArr.length === chargementData.length) {
        // Delete other item exclude first item
        if (!plageState && chargementData.length > 1) {
          setLoading(true);
          ChargementAPI.fetchAll({
            filter: {
              where: {
                caracteristiquesId: ligneData.id,
              },
            },
          })
            .pipe(
              tap((chargements: Chargement[]) => {
                setLoading(false);
                const arrIds = chargementData.map((el) => el.id).filter((el) => !!el);
                if (arrIds.some((item) => chargements.map((el) => el.id).includes(item))) {
                  dispatch(UpdateCaracteristiquesPopup({ type: CaracteristiquesPopupType.ERROR_EDIT_DELETED_ITEM }));
                  return;
                } else {
                  chargementData.map((el, index) => {
                    if (index !== 0) {
                      ChargementAPI.delete(Number(el.id)).subscribe({
                        error: noop,
                      });
                    }
                  });
                }
              })
            )
            .subscribe({
              error: noop,
            });
        }
        setActionSave(false);
        if (globalSubmitArr.every((el) => el)) {
          const data = {
            modeTransportId: Number(values.modeTransportId),
            typeVehiculeId: Number(values.typeVehiculeId),
            marchandiseId: Number(values.marchandiseId),
            adr: values.adr,
            plages: plageState,
            besoinsSpecifiques: values.besoinsSpecifiques,
            currentStage: Number(ligneData.currentStage) + 1,
            chargement: chargementData,
            alloue: alloueData,
            budget: budgetData,
          };
          dispatch(
            updateLigneRequest({
              ligneRequest: {
                ...ligneRequest,
                ...data,
              },
            })
          );
          dispatch(
            updateLigne({
              ligne: {
                ...ligneData,
                ...data,
              },
            })
          );
          return;
        }
        sliderRef.current?.slickGoTo(globalSubmitArr.indexOf(false));
        message.error("Merci de compléter toutes les colonnes.").then((r) => console.log(r));
        return;
      }
    }
  }, [submitArr]);

  useRxEffect(() => {
    setLoading(true);
    return combineLatest([ModeTransportAPI.fetchAll(), TypeVehiculeAPI.fetchAll(), MarchandiseAPI.fetchAll()]).pipe(
      tap({
        next: ([modeTransports, typeVehicules, marchandises]: [ModeTransport[], TypeVehicule[], Marchandise[]]) => {
          setModeTransport(modeTransports);
          setTypeVihicle(typeVehicules);
          setMarchandise(marchandises);
          setLoading(false);
        },
      })
    );
  }, []);

  const plageOnChange = (e: CheckboxChangeEvent) => {
    setPlageState(e.target.checked);
    if (!e.target.checked) {
      setLayout(8);
      setSettings({
        ...settings,
        slidesToShow: 1,
      });
    } else {
      if (chargementData.length > 1) {
        setLayout(16);
        setSettings({
          ...settings,
          slidesToShow: window.innerWidth > 1200 ? 2 : 1,
        });
      }
      const resetChargement = [...chargementData, {}];
      setChargementData(resetChargement);
      resetChargement.pop();
      setChargementData(resetChargement);
    }
  };

  const onFinish = () => {
    setActionSave(true);
    setSubmitArr(-1);
    globalSubmitArr = [];
  };

  const submitHandel = (index: number, statusItem: boolean) => {
    setTimeout(() => {
      globalSubmitArr[index] = statusItem;
      setSubmitArr(index);
    }, index * 10);
  };

  const addItem = () => {
    if (!editable) {
      return;
    }
    if (chargementData.length >= 32) {
      message.error("Vous ne pouvez plus ajouter!").then();
      return;
    }
    setChargementData([
      ...chargementData,
      {
        dureeChargement: firstDuree,
      },
    ]);
    setAlloueData([...alloueData, {}]);
    setBudgetData([...budgetData, {}]);
    sliderRef.current?.slickGoTo(chargementData.length);
  };

  const updateItem = (index: number, itemData: Chargement) => {
    if (index === 0) {
      setFirstDuree(itemData.dureeChargement);
    }

    setChargementData((prevState) => {
      const copyChargementData = [...prevState];
      copyChargementData[index] = itemData;
      return copyChargementData;
    });
  };

  const removeItem = (index: number) => {
    removeItemIndex = index;

    impactDemandeClientsCounter = demandeClients.filter(
      (demandeClient) => demandeClient.caracteristiquesLigne?.chargementId == chargementData[index].id
    ).length;
    impactChoixTransporteursCounter = choixTransporters.filter(
      (choixTransporter) => choixTransporter.caracteristiquesLigne?.chargementId == chargementData[index].id
    ).length;

    if (impactDemandeClientsCounter > 0 || impactChoixTransporteursCounter > 0) {
      setShowPopupEditChargement(true);
    } else {
      onConfirmChargement();
    }
  };

  const onConfirmChargement = () => {
    setShowPopupEditChargement(false);
    if (removeItemIndex === undefined) {
      return;
    }
    const currentListChargements = [...chargementData];
    currentListChargements.splice(removeItemIndex, 1);
    setChargementData(currentListChargements);

    const currentListAlloues = [...alloueData];
    currentListAlloues.splice(removeItemIndex, 1);
    setAlloueData(currentListAlloues);

    const currentListBudgets = [...budgetData];
    currentListBudgets.splice(removeItemIndex, 1);
    setBudgetData(currentListBudgets);
  };

  const onFinishFailed = () => {
    message.error("Merci de compléter toutes les colonnes.").then((r) => console.log(r));
  };

  return (
    <>
      {loading && <SingleLoading />}
      <PopupConfirm
        visible={showPopupEditChargement}
        title="Attention"
        okText="Oui"
        cancelText="Non"
        onOk={onConfirmChargement}
        onCancel={() => setShowPopupEditChargement(false)}
        showDetail
        hideClose
        childrenDetail={
          <>
            <h3>Nombre de détails Demande Client supprimés : {impactDemandeClientsCounter}</h3>
            <h3>Nombre de détails Transporteurs supprimés : {impactChoixTransporteursCounter}</h3>
          </>
        }
      >
        <>
          <p>Êtes-vous sûr de vouloir supprimer cette colonne sachant que celle-ci a déjà des éléments complétés ?</p>
          <p>Tous les éléments renseignés pour cette colonne dans Demande Client et Transporteurs seront supprimés.</p>
        </>
      </PopupConfirm>
      <Row>
        <Col xs={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
          <Title level={3} className={styles.title}>
            Chargement
          </Title>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 18, offset: 8 }} xl={{ span: 8, offset: 8 }}>
          <CustomCheckbox style={{ marginBottom: "1rem" }} onChange={(e) => plageOnChange(e)} checked={plageState} disabled={!editable}>
            <div style={{ display: "flex", alignItems: "center" }}>
              Plages
              {isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView hint={"À cocher si le prix est défini pour une plage de valeurs (sur MPL, Poids, Palettes)."} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </CustomCheckbox>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
          <p style={{ textAlign: "center" }}>MPL - Poids</p>
        </Col>
      </Row>
      <Row style={{ paddingRight: plageState ? "30px" : 0 }}>
        <Col xs={6} xl={8}>
          <ul className={styles.lableChargement} style={{ marginBottom: "2.75rem" }}>
            {(plageState || !chargementData[0].ftl) && (
              <li>
                <div style={{ display: "flex", alignItems: "center" }}>
                  MPL
                  {isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView
                        hint={
                          <>
                            Valeur ou plage de <i>mètres plancher</i> (en mètres) définie pour cette ligne.
                          </>
                        }
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
            )}
            {plageState && <li>{null}</li>}
            <li>
              <div style={{ display: "flex", alignItems: "center" }}>
                Poids
                {isShowTutorial ? (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView
                      hint={
                        <>
                          Valeur ou plage de <i>Poids</i> (en tonnes) définie pour cette ligne.
                        </>
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </li>
            <li>
              <div style={{ display: "flex", alignItems: "center" }}>
                Palettes
                {isShowTutorial ? (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView
                      hint={
                        <>
                          Valeur ou plage de <i>Palettes</i> définie pour cette ligne.
                        </>
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </li>
            <li>{null}</li>
            <li>{null}</li>
          </ul>
          <ul className={styles.lableChargement}>
            <li>
              <div style={{ display: "flex", alignItems: "center" }}>
                Durée chargement
                {isShowTutorial ? (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView hint={"Durée chargement (en heures)."} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </li>
            <li>Délai Livraison</li>
            <li>
              <div style={{ display: "flex", alignItems: "center" }}>
                Matin
                {isShowTutorial ? (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView
                      hint={
                        <>
                          Délai de livraison si chargement le <i>Matin</i> du jour J (arrivée à J -&gt; <b>A</b> ; J+1 -&gt; <b>B</b> ; J+2 -&gt;{" "}
                          <b>C</b> ; …).
                        </>
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </li>
            <li>
              <div style={{ display: "flex", alignItems: "center" }}>
                Après-midi
                {isShowTutorial ? (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView
                      hint={
                        <>
                          Délai de livraison si chargement le l&apos;<i>Après-midi</i> du jour J (arrivée à J -&gt; <b>A</b> ; J+1 -&gt; <b>B</b> ;
                          J+2 -&gt; <b>C</b> ; …).
                        </>
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </li>
          </ul>
        </Col>
        <Col xs={18} md={14} lg={12} xl={layout} className={styles.groupChargement}>
          <Slider {...settings} ref={sliderRef}>
            {chargementData.map((el, index) => {
              if (plageState || index < 1) {
                return (
                  <div key={makeRandom(4)}>
                    <ChargementItem
                      index={index}
                      itemData={el}
                      plageState={plageState}
                      actionSave={actionSave}
                      parentCallback={(item) => removeItem(item)}
                      ligneId={ligneData.id}
                      itemCallBack={(index, item) => updateItem(index, item)}
                      submitCallBack={(index: number, statusItem: boolean) => submitHandel(index, statusItem)}
                      ftlSelected={ftlSelected}
                      isShowTutorial={isShowTutorial}
                      editAble={editable}
                      cbLoading={setLoading}
                    />
                  </div>
                );
              }
            })}
          </Slider>
          {plageState && <PlusOutlined className={styles.plusChargement} style={{ color: "#fff" }} onClick={addItem} />}
          <div className={styles.plusChargementTutorial}>
            {plageState && isShowTutorial ? (
              <div style={{ marginLeft: "0.5rem" }}>
                <IndicatorTutorialView
                  hint={
                    <>
                      Ajouter une colonne sur cette ligne contractualisée pour définir une <i>Allocation</i> ou un <i>Budget</i> différent sur une
                      autre <i>Plage</i>. Les informations saisies dans les sections précédentes, et certaines de la première colonne, seront communes
                      à toutes les autres.
                    </>
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
      <Form form={form} name="clientForm" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" colon={false} disabled={!editable}>
        <Row>
          <Col span={8} offset={8}>
            <p
              style={{
                textAlign: "center",
                marginTop: "2.5rem",
                marginBottom: "1rem",
              }}
            >
              Détails
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <ul className={styles.lableChargement}>
              <li>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Mode de Transport
                  {isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={"Mode de Transport tel que Route, Route/Rail, Route/Maritime, …"} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
              <li>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Type véhicule
                  {isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={"Type de véhicule tel que Tautliner, Méga, Citerne, ..."} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
              <li>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Nature marchandise
                  {isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={"Nature de marchandise tel que Tautliner, Méga, Citerne, ..."} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
              <li>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Besoins spécifiques
                  {isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={"Commentaire sur ce qui ne peut pas être complété ailleurs."} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
            </ul>
          </Col>
          <Col xs={18} md={14} lg={12} xl={8} style={{ padding: "0 5px", marginLeft: "-5px" }}>
            <ul className={styles.inputChargement}>
              <li>
                <Row style={{ alignItems: "center" }}>
                  <Col span={16}>
                    <Form.Item name="modeTransportId" style={{ marginBottom: 0 }} rules={[{ required: true, message: "" }]}>
                      <CustomSelect
                        showSearch={true}
                        bordered={false}
                        style={{ height: "2rem" }}
                        suffixIcon={<img className={styles.searchIcon} src={SearchIcon} alt="Search" />}
                      >
                        {modeTransport.map((el) => {
                          return (
                            <Option key={el.id} value={el.id}>
                              {el.code} - {el.nom}
                            </Option>
                          );
                        })}
                      </CustomSelect>
                    </Form.Item>
                  </Col>
                </Row>
              </li>
              <li>
                <Row>
                  <Col span={16}>
                    <Form.Item name="typeVehiculeId" rules={[{ required: true, message: "" }]}>
                      <CustomSelect
                        showSearch={true}
                        bordered={false}
                        style={{ height: "2rem" }}
                        suffixIcon={<img className={styles.searchIcon} src={SearchIcon} alt="Search" />}
                      >
                        {typeVihicle.map((el) => {
                          return (
                            <Option key={el.id} value={el.id}>
                              {el.code} - {el.nom}
                            </Option>
                          );
                        })}
                      </CustomSelect>
                    </Form.Item>
                  </Col>
                </Row>
              </li>
              <li>
                <Row style={{ alignItems: "center" }}>
                  <Col span={16}>
                    <Form.Item name="marchandiseId" rules={[{ required: true, message: "" }]}>
                      <CustomSelect
                        showSearch={true}
                        bordered={false}
                        style={{ height: "2rem" }}
                        suffixIcon={<img className={styles.searchIcon} src={SearchIcon} alt="Search" />}
                      >
                        {marchandise.map((el) => {
                          return (
                            <Option key={el.id} value={el.id}>
                              {el.code} - {el.nom}
                            </Option>
                          );
                        })}
                      </CustomSelect>
                    </Form.Item>
                  </Col>
                  <Col span={7} offset={1}>
                    <Form.Item valuePropName="checked" name="adr" style={{ marginBottom: 0 }}>
                      <CustomCheckbox disabled={!editable}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          ADR
                          {isShowTutorial ? (
                            <div style={{ marginLeft: "0.5rem" }}>
                              <IndicatorTutorialView hint={"À cocher si cette ligne transporte des marchandises classées ADR."} />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </CustomCheckbox>
                    </Form.Item>
                  </Col>
                </Row>
              </li>
              <li>
                <Form.Item name="besoinsSpecifiques">
                  <CustomInput />
                </Form.Item>
              </li>
            </ul>
          </Col>
        </Row>
        <Row style={{ justifyContent: chargementData.length > 1 ? "center" : "flex-start" }}>
          <Col xs={18} md={14} lg={12} xl={8} offset={8}>
            <Form.Item className={styles.submit}>
              {editable && (
                <Button type="primary" htmlType="submit" className={`${styles.bt} ${styles.buttonFull}`}>
                  Confirmer
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ChargementView;

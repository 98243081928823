/** @format */

import { Col, Form, Radio, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { DemandeClientSubType, DemandeClientType } from "../../../../helper/calendarHelper";
import { ActionType, selectDemandeState, UpdateDemandeState } from "../../../../app/reducers/DemandeClient/DemandeState.reducer";
import { useAppSelector } from "../../../../app/hooks";
import { getCurrentDemande } from "../../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import { useDispatch } from "react-redux";
import { UpdateNewDemande } from "../../../../app/reducers/DemandeClient/NewDemande.reducer";
import { UpdateDatesObj } from "../../../../app/reducers/DemandeClient/DatesObject.reducer";
import ToastDateSemaineGlobal from "../../components/ToastDateSemaineGlobal";
import { selectCaracteristiquesLigne } from "../../../../app/reducers/demandeClient.reducer";
import { selectIsShowTutorial } from "../../../../app/reducers/indicatorTutorial.reducer";
import IndicatorTutorialView from "../../../../components/IndicatorTutorial";

interface FormModal {
  mainType: DemandeClientType;
  subType?: DemandeClientSubType;
}

const SelectDateType = () => {
  const dispatch = useDispatch();

  const currentDemande = useAppSelector(getCurrentDemande);
  const currentLigne = useAppSelector(selectCaracteristiquesLigne).caracteristiques;
  const currentState = useAppSelector(selectDemandeState);
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<FormModal>({
    mainType: DemandeClientType.JOUR,
    subType: undefined,
  });
  const isShowTutorial = useAppSelector(selectIsShowTutorial);

  useEffect(() => {
    dispatch(UpdateDemandeState(formValue));
    form.setFieldsValue(formValue);
    dispatch(
      UpdateNewDemande({
        type: formValue.mainType,
        subType: formValue.mainType !== DemandeClientType.JOUR ? formValue.subType : undefined,
        demandeClientDates: [],
      })
    );
  }, [formValue]);

  useEffect(() => {
    if (currentDemande?.type) {
      setFormValue({
        mainType: currentDemande?.type,
        subType: currentDemande?.subType,
      });
    }
  }, [currentDemande]);

  const onValuesChange = () => {
    const getFieldsValue = form.getFieldsValue();
    let subType = getFieldsValue.subType;
    if (getFieldsValue.mainType === DemandeClientType.SEMAINE && subType === DemandeClientSubType.AUTRE) {
      subType = DemandeClientSubType.JOUR;
    }

    if (getFieldsValue.mainType !== DemandeClientType.JOUR && !subType) {
      subType = DemandeClientSubType.JOUR;
    }

    setFormValue({
      ...getFieldsValue,
      subType,
    });

    dispatch(UpdateDatesObj([]));
  };

  return (
    <Form form={form} name="DateTypeForm" initialValues={formValue} onValuesChange={onValuesChange}>
      {currentLigne && formValue.mainType === DemandeClientType.SEMAINE && formValue.subType === DemandeClientSubType.GLOBAL && (
        <ToastDateSemaineGlobal currentLigne={currentLigne} type={formValue.mainType} subType={formValue.subType} />
      )}
      <span className="bigTitle" style={{ marginBottom: "0.5rem" }}>
        Ajouter par :
      </span>
      <Row>
        <Col span={9}>
          <Form.Item name="mainType">
            <Radio.Group disabled={currentState.actionType === ActionType.UPDATE}>
              <Space direction="vertical">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Radio value={DemandeClientType.JOUR}>
                    Jour
                    {isShowTutorial && (
                      <div style={{ position: "absolute", right: "-1rem", top: "0.25rem" }}>
                        <IndicatorTutorialView hint={<>Détail Jour, sélection date par date.</>} />
                      </div>
                    )}
                  </Radio>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Radio value={DemandeClientType.SEMAINE}>
                    Semaine{" "}
                    {isShowTutorial && (
                      <div style={{ position: "absolute", right: "-1rem", top: "0.25rem" }}>
                        <IndicatorTutorialView hint={<>Détail Semaine, sélection semaine par semaine.</>} />
                      </div>
                    )}
                  </Radio>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Radio value={DemandeClientType.MOIS}>
                    Mois
                    {isShowTutorial && (
                      <div style={{ position: "absolute", right: "-1rem", top: "0.25rem" }}>
                        <IndicatorTutorialView hint={<>Détail Mois, sélection mois par mois.</>} />
                      </div>
                    )}
                  </Radio>
                </div>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
        {formValue.mainType !== DemandeClientType.JOUR && (
          <Col span={15}>
            <Form.Item name="subType" className="subType">
              <Radio.Group
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", position: "relative", width: "50%", margin: 0 }}>
                  <Radio value={DemandeClientSubType.JOUR}>
                    Jour
                    {isShowTutorial && (
                      <div style={{ position: "absolute", top: "-0.25rem", right: "-0.5rem" }}>
                        <IndicatorTutorialView
                          hint={
                            formValue.mainType === DemandeClientType.SEMAINE ? (
                              <>
                                Détail par Jour de la Semaine. Il est possible de désélectionner des Jours qui ne sont pas inclus en cliquant sur le
                                calendrier.
                              </>
                            ) : (
                              <>
                                Détail par Jour du Mois. Il est possible de désélectionner des Jours qui ne sont pas inclus en cliquant sur le
                                calendrier.
                              </>
                            )
                          }
                        />
                      </div>
                    )}
                  </Radio>
                </div>
                <div style={{ display: "flex", alignItems: "center", position: "relative", width: "50%", margin: 0 }}>
                  <Radio value={DemandeClientSubType.GLOBAL}>
                    Global
                    {isShowTutorial && (
                      <div style={{ position: "absolute", top: "-0.25rem", right: "-0.575rem" }}>
                        <IndicatorTutorialView
                          hint={
                            formValue.mainType === DemandeClientType.SEMAINE ? (
                              <>
                                Détail pour une Semaine entière. Il est possible de désélectionner des Semaines qui ne sont pas incluses en cliquant
                                sur le calendrier.
                              </>
                            ) : (
                              <>
                                Détail par Mois entier. Il est possible de désélectionner des Mois qui ne sont pas inclus en cliquant sur le
                                calendrier.
                              </>
                            )
                          }
                        />
                      </div>
                    )}
                  </Radio>
                </div>
                {formValue.mainType === DemandeClientType.MOIS && (
                  <div style={{ display: "flex", alignItems: "center", width: "50%", margin: 0 }}>
                    <Radio value={DemandeClientSubType.AUTRE}>
                      Autre
                      {isShowTutorial && (
                        <div style={{ position: "absolute", right: "-0.5rem", top: "-0.25rem" }}>
                          <IndicatorTutorialView
                            hint={
                              <>
                                Détail par Mois en rang Jour. Il est possible de désélectionner des Jours qui ne sont pas inclus en cliquant sur le
                                calendrier.
                              </>
                            }
                          />
                        </div>
                      )}
                    </Radio>
                  </div>
                )}
              </Radio.Group>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default SelectDateType;

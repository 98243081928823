/** @format */

import * as React from "react";
import { useState } from "react";
import styles from "./PopupConfirm.module.css";

interface ConfirmProps {
  visible?: boolean;
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  afterClose?: () => void;
  title?: React.ReactNode;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
  children?: React.ReactNode;
  childrenDetail?: React.ReactNode;
  hideClose?: boolean;
  showDetail?: boolean;
}

const PopupConfirm = (props: ConfirmProps) => {
  const { visible, title, okText, cancelText, children, onOk, onCancel, hideClose, showDetail, childrenDetail } = props;
  const [showDetailList, setShowDetailList] = useState<boolean>(false);

  return visible ? (
    <div className={["ant-modal-wrap", styles.popupWrap].join(" ")}>
      <div role="dialog" className="ant-modal">
        <div className={["ant-modal-content", styles.popupContent].join(" ")}>
          {!hideClose && (
            <button type="button" aria-label="Close" className="ant-modal-close" onClick={onCancel ? onCancel : undefined}>
              <span className="ant-modal-close-x">
                <span role="img" aria-label="close" className="anticon anticon-close ant-modal-close-icon">
                  <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                  </svg>
                </span>
              </span>
            </button>
          )}
          <div className={styles.popupHeader}>{title}</div>
          <div className={styles.popupBody}>{children}</div>
          <div className={styles.popupFooter}>
            <div className={styles.groupButton}>
              {cancelText && (
                <button type="button" className={styles.cancelButton} onClick={onCancel ? onCancel : undefined}>
                  <span>{cancelText}</span>
                </button>
              )}

              <button type="button" className={styles.okButton} onClick={onOk ? onOk : undefined}>
                {okText ? <span>{okText}</span> : <span>OK</span>}
              </button>
            </div>
          </div>
          {showDetail && (
            <>
              <button
                type="button"
                className={styles.detailButton}
                style={{ color: showDetailList ? "#fff" : "#000", background: showDetailList ? "#5E5E5E" : "#EDEDED" }}
                onClick={() => setShowDetailList(!showDetailList)}
              >
                <span>{"Détails"}</span>
              </button>
              {showDetailList && <div className={styles.detail}>{childrenDetail}</div>}
            </>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default PopupConfirm;

/** @format */

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { noop, tap } from "rxjs";
import { useAppSelector } from "../../../../app/hooks";
import {
  CaracteristiquesPopupType,
  getCaracteristiquesPopup,
  UpdateCaracteristiquesPopup,
} from "../../../../app/reducers/Caracteristiques/CaracteristiquesPopup.reducer";
import { selectLigne } from "../../../../app/reducers/ligne.reducer";
import { CaracteristiqueAPI } from "../../../../domain/apis/caracteristique.api";
import PopupContainer from "./PopupContainer";

const PopupDetail = () => {
  const dispatch = useDispatch();
  const currentType = useAppSelector(getCaracteristiquesPopup);
  const [showPopup, setShowPopup] = useState<CaracteristiquesPopupType>(currentType.type);
  const lineData = useAppSelector(selectLigne);
  const navigate = useNavigate();

  useEffect(() => {
    setShowPopup(currentType.type);
  }, [currentType]);

  const removePopup = () => {
    setShowPopup(CaracteristiquesPopupType.NONE);
    dispatch(UpdateCaracteristiquesPopup({ type: CaracteristiquesPopupType.NONE }));
  };

  const cancelCreateEdit = () => {
    removePopup();
    if (lineData.id && lineData.isCompleted) {
      if (lineData.editById) {
        CaracteristiqueAPI.upsertCaracteristiques({
          id: Number(lineData.id),
          editById: null,
        });
      }
      navigate("/dashboard");
    } else {
      CaracteristiqueAPI.delete(lineData.id as number)
        .pipe(
          tap(() => {
            navigate("/dashboard");
          })
        )
        .subscribe({ error: noop });
    }
  };

  const editDeletedItem = () => {
    removePopup();
    navigate("/dashboard");
  };

  switch (showPopup) {
    case CaracteristiquesPopupType.ERROR_EDIT_MORE_ONE_PERSON:
      return (
        <PopupContainer okText="Terminer" onOk={removePopup}>
          <p>
            Cette partie est déjà en cours de modification par un autre utilisateur.
            <br />
            Réessayez lorsque ce dernier ne sera plus en train de modifier.
          </p>
        </PopupContainer>
      );

    case CaracteristiquesPopupType.ERROR_EDIT_DELETED_ITEM:
      return (
        <PopupContainer okText="Terminer" onOk={editDeletedItem}>
          <p>Ces modifications ne seront pas enregistrées car l&apos;élément a été supprimé. </p>
        </PopupContainer>
      );
    case CaracteristiquesPopupType.CANCEL:
      return (
        <PopupContainer
          okText="Oui"
          cancelText="Non"
          onOk={() => {
            cancelCreateEdit();
          }}
        >
          <>
            <p>Êtes-vous sûr de vouloir annuler la saisie ?</p>

            <p>
              Si c&apos;est une nouvelle ligne, celle-ci ne sera pas sauvegardée ;
              <br />
              si c&apos;est une modification, la dernière version sera rétablie.
            </p>
          </>
        </PopupContainer>
      );
    case CaracteristiquesPopupType.EDIT_PERIODE:
      return (
        <PopupContainer okText="Oui" cancelText="Non">
          <p>Les détails de Demande Client conservés ne seront que ceux qui sont inclus dans ces nouvelles dates. Souhaitez-vous continuer ?</p>
        </PopupContainer>
      );
    case CaracteristiquesPopupType.EDIT_PERIODE_WITH_TRANSPORTEURS:
      return (
        <PopupContainer okText="Oui" cancelText="Non">
          <p>
            Les détails de Demande Client et de Transporteurs conservés ne seront que ceux qui sont inclus dans ces nouvelles dates. Souhaitez-vous
            continuer ?
          </p>
        </PopupContainer>
      );
    case CaracteristiquesPopupType.EDIT_TRAJET:
      return (
        <PopupContainer okText="Oui" cancelText="Non">
          <>
            <p>Certains Transporteurs déjà sélectionnés ne sont pas disponibles sur cette ligne Dept-Dept. Souhaitez-vous continuer ?</p>
            <br />
            <p>Ceux-ci seront alors supprimés de la partieTransporteurs. Ajoutez-les au préalable dans BDP pour les conserver.</p>
          </>
        </PopupContainer>
      );
    case CaracteristiquesPopupType.EDIT_ALLOCATION:
      return (
        <PopupContainer okText="Oui" cancelText="Non">
          <p>Le taux de Parc Propre Contractuel n&apos;atteindra plus l&apos;objectif fixé pour cette ligne. Souhaitez-vous continuer?</p>
        </PopupContainer>
      );
    case CaracteristiquesPopupType.EDIT_BUDGET:
      return (
        <PopupContainer okText="Oui" cancelText="Non">
          <>
            <p>Le Budget Transport devient plus petit que certains prix convenus avec des Transporteurs. Souhaitez-vous continuer ?</p>
            <br />
            <p>Ceci signifie que, si le prix des Transporteurs concernés n&apos;est pas renégocié, la marge attendue ne sera pas respectée.</p>
          </>
        </PopupContainer>
      );
    default:
      return <></>;
  }
};

export default PopupDetail;

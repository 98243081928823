/** @format */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosResponse } from "axios";
import { from, map, Observable } from "rxjs";
import { Method, request } from "../../helper/request.helper";
import { CalculateAssociationRateRequestParams } from "../../models/requests/calculateAssociationRate.model";
import { CaracteristiquesLigneCompleteResponse } from "../../models/responses/completeLinesSearch.model";
import { CaracteristiquesLigneTransporterResponse } from "../../models/responses/transporterSearch.model";
import { CaracteristiquesLigneSearchRequestParams } from "../../models/requests/caracteristiquesLigneSearch.model";
import { LoopbackAPI } from "./loopbackApi.api";
import { CaracteristiquesLignes, MoyenneDeLots } from "../../models";
import { AssociationRate } from "../../models/associationRate.model";
import { CalculateDiffTotalTransporteurResponse } from "../../models/responses/calculateDiffTotalTransporteur.model";
import { CalculateDiffTotalTransporteurRequestParams } from "../../models/requests/calculateDiffTotalTransporteur.model";
import { GetPrePprateResponse } from "../../models/responses/getPrePprate.model";
import { GetPrePprateRequestParams } from "../../models/requests/getPrePprate.model.ts";

const COMPONENT_NAME = "CaracteristiquesLignes";

export class CaracteristiquesLigneAPI implements LoopbackAPI<CaracteristiquesLignes> {
  find = (filter?: unknown): Observable<CaracteristiquesLignes[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params: {
          filter,
        },
      })
    ).pipe(map((response: AxiosResponse<CaracteristiquesLignes[]>) => response.data));
  };

  count = (): Observable<number> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/count`,
      })
    ).pipe(map((response: AxiosResponse<number>) => response.data));
  };

  patch = (id: number, data: any): Observable<void> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbCreate = (data: any): Observable<void> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbDelete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/${id}`,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static completeLinesSearch = (
    data: CaracteristiquesLigneSearchRequestParams,
    limit?: number,
    offset?: number
  ): Observable<CaracteristiquesLigneCompleteResponse> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/search`,
        data,
        params: {
          limit,
          offset,
        },
      })
    ).pipe(map((response: AxiosResponse<CaracteristiquesLigneCompleteResponse>) => response.data));
  };

  static transporterSearch = (
    data: CaracteristiquesLigneSearchRequestParams,
    limit?: number,
    offset?: number
  ): Observable<CaracteristiquesLigneTransporterResponse> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/search`,
        data,
        params: {
          limit,
          offset,
        },
      })
    ).pipe(map((response: AxiosResponse<CaracteristiquesLigneTransporterResponse>) => response.data));
  };

  static fetchDetailsById = (caracteristiquesLigneId: number): Observable<CaracteristiquesLignes> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/${caracteristiquesLigneId}`,
        params: {
          filter: {
            include: [
              {
                relation: "caracteristiques",
                scope: {
                  include: [
                    "client",
                    "societe",
                    "exploitant",
                    "indiceGasoil",
                    "chargement",
                    "account",
                    "commercial",
                    "clientFacture",
                    "societeFacture",
                    "agence",
                    "lieuxDepart",
                    "lieuxArrivee",
                    "coutsAdditionnel",
                    "paysDepart",
                    "paysArrivee",
                    "departementDepart",
                    "departementArrivee",
                    "chargement",
                    "marchandise",
                    {
                      relation: "alloue",
                      scope: {
                        include: [
                          {
                            relation: "chargement",
                          },
                          {
                            relation: "volumeFrequency",
                          },
                        ],
                      },
                    },
                    {
                      relation: "budget",
                      scope: {
                        include: {
                          relation: "chargement",
                        },
                      },
                    },
                  ],
                },
              },
              {
                relation: "chargement",
                scope: {
                  include: ["alloue", "budget"],
                },
              },
            ],
          },
        },
      })
    ).pipe(map((response: AxiosResponse<CaracteristiquesLignes>) => response.data));
  };

  static calculateBatchAverage = (caracteristiquesLigneId: number): Observable<MoyenneDeLots> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/calculateBatchAverage`,
        params: {
          caracteristiquesLigneId,
        },
      })
    ).pipe(map((response: AxiosResponse<MoyenneDeLots>) => response.data));
  };

  static calculateAssociationRate = (
    caracteristiquesLigneId: number,
    data: CalculateAssociationRateRequestParams[]
  ): Observable<AssociationRate[]> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/calculateAssociationRate`,
        params: {
          caracteristiquesLigneId,
        },
        data,
      })
    ).pipe(map((response: AxiosResponse<AssociationRate[]>) => response.data));
  };

  static calculateDiffTotalTransporteur = (
    caracteristiquesLigneId: number,
    data: CalculateDiffTotalTransporteurRequestParams[]
  ): Observable<CalculateDiffTotalTransporteurResponse[]> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/calculateDiffTotalTransporteur`,
        params: {
          caracteristiquesLigneId,
        },
        data,
      })
    ).pipe(map((response: AxiosResponse<CalculateDiffTotalTransporteurResponse[]>) => response.data));
  };

  static getPrePprate = (caracteristiquesLigneId: number, data: GetPrePprateRequestParams[], type?: string): Observable<GetPrePprateResponse> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/getPrePprate`,
        params: {
          caracteristiquesLigneId,
          type: type ?? "DEMANDE_CLIENT",
        },
        data,
      })
    ).pipe(map((response: AxiosResponse<GetPrePprateResponse>) => response.data));
  };

  static calcPrePprate = (data: { caracteristiquesLigneId: number; transporteurId: number; isGroup: boolean }) => {
    return request({
      method: Method.GET,
      url: `/${COMPONENT_NAME}/getPrePprateTransporteur`,
      params: {
        caracteristiquesLigneId: data.caracteristiquesLigneId,
        transporteurId: data.transporteurId,
        isGroup: data.isGroup,
      },
    });
  };
}

/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState: {
  isShowTutorial: boolean;
} = {
  isShowTutorial: localStorage.getItem("isShowTutorial") === "true",
};

const indicatorTutorialSlice = createSlice({
  name: "indicatorTutorial",
  initialState,
  reducers: {
    toggleShowIndicatorTutorial: (state) => {
      localStorage.setItem("isShowTutorial", (!state.isShowTutorial).toString());
      state.isShowTutorial = !state.isShowTutorial;
    },
    showIndicatorTutorial: (state) => {
      state.isShowTutorial = true;
      localStorage.setItem("isShowTutorial", "true");
    },
    resetIndicatorTutorial: (state) => {
      state.isShowTutorial = false;
      localStorage.setItem("isShowTutorial", "false");
    },
  },
});

export const { toggleShowIndicatorTutorial, showIndicatorTutorial, resetIndicatorTutorial } = indicatorTutorialSlice.actions;

export const selectIsShowTutorial = (state: RootState) => state.indicatorTutorial.isShowTutorial;

export default indicatorTutorialSlice.reducer;

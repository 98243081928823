/** @format */

import { DemandeClient } from "../../../../models";
import { DemandeClientSubType, DemandeClientType } from "../../../../helper/calendarHelper";
import { useEffect, useState } from "react";
import LotsJour from "../Forms/Jour/LotsJour";
import { Lots } from "../../../../models/lots.model";
import SemaineJour from "../Forms/Semaine/SemaineJour";
import SemaineGlobal from "../Forms/Semaine/SemaineGlobal";
import MoisJour from "../Forms/Mois/MoisJour";
import MoisGlobal from "../Forms/Mois/MoisGlobal";
import MoisAutre from "../Forms/Mois/MoisAutre";

interface PreviewProps {
  currentDemande: DemandeClient;
}

const PreviewDemande = (props: PreviewProps) => {
  const { currentDemande } = props;
  const [lotsDate, setLotsDate] = useState<Lots[]>([]);

  useEffect(() => {
    if (currentDemande?.demandeClientDates) {
      const filterLots = currentDemande.demandeClientDates.map((el) => {
        return {
          date: el.date ? el.date : "",
          lotMin: el.lotMin ? el.lotMin : undefined,
          lotMax: el.lotMax ? el.lotMax : undefined,
        };
      });
      setLotsDate(filterLots);
    }
  }, [currentDemande]);

  return (
    <div className="previewDemande">
      {currentDemande.type === DemandeClientType.JOUR && <LotsJour lotsDate={lotsDate} />}
      {currentDemande.type === DemandeClientType.SEMAINE && (
        <>
          {currentDemande.subType === DemandeClientSubType.JOUR && <SemaineJour />}
          {currentDemande.subType === DemandeClientSubType.GLOBAL && <SemaineGlobal />}
        </>
      )}
      {currentDemande.type === DemandeClientType.MOIS && (
        <>
          {currentDemande.subType === DemandeClientSubType.JOUR && <MoisJour />}
          {currentDemande.subType === DemandeClientSubType.GLOBAL && <MoisGlobal />}
          {currentDemande.subType === DemandeClientSubType.AUTRE && <MoisAutre />}
        </>
      )}
    </div>
  );
};

export default PreviewDemande;

/** @format */

import { Button, Col, Row } from "antd";
import CustomInput from "../../../../../components/CustomInput";
import styles from "./Jour.module.css";
import CustomInputNumber from "../../../../../components/CustomInputNumber";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../../../../app/hooks";
import { getSelectedDate, UpdateSelectedDates } from "../../../../../app/reducers/DemandeClient/SelectedDates.reducer";
import { UpdateDatesObj } from "../../../../../app/reducers/DemandeClient/DatesObject.reducer";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { DateInput } from "../../../../../components/FullCalendar";
import { Lots } from "../../../../../models/lots.model";
import { getCurrentDemande } from "../../../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import LotsJour from "./LotsJour";
import { UpdateNewDemande } from "../../../../../app/reducers/DemandeClient/NewDemande.reducer";
import { AppColors } from "../../../../../constants/colors.const";
import IndicatorTutorialView from "../../../../../components/IndicatorTutorial";
import { selectIsShowTutorial } from "../../../../../app/reducers/indicatorTutorial.reducer";

const JourInput = () => {
  const getDateStore = useAppSelector(getSelectedDate);
  const currentDemande = useAppSelector(getCurrentDemande);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);
  const dispatch = useDispatch();
  const currentDateSelect = getDateStore.currentDate;
  const [lotsDate, setLotsDate] = useState<Lots[]>([]);
  const [min, setMin] = useState<number | undefined>(undefined);
  const [max, setMax] = useState<number | undefined>(undefined);
  const [groupSelection, setGroupSelection] = useState<string[]>([]);

  useEffect(() => {
    setGroupSelection([]);
  }, []);

  useEffect(() => {
    if (currentDateSelect) {
      if (groupSelection.includes(currentDateSelect.date)) {
        const currentList = [...groupSelection];
        const index = currentList.indexOf(currentDateSelect.date);
        if (index !== -1) {
          currentList.splice(index, 1);
        }
        setGroupSelection(currentList);
      } else {
        setGroupSelection([...groupSelection].concat([currentDateSelect.date]));
      }
    }
  }, [currentDateSelect]);

  useEffect(() => {
    if (currentDemande?.demandeClientDates) {
      const filterLots = currentDemande.demandeClientDates.map((el) => {
        return {
          date: el.date ? el.date : "",
          lotMin: el.lotMin ? el.lotMin : undefined,
          lotMax: el.lotMax ? el.lotMax : undefined,
        };
      });
      setLotsDate(filterLots);
    }
  }, [currentDemande]);

  useEffect(() => {
    const oldSelected = lotsDate.map((item) => item.date).filter((el) => !groupSelection.includes(el));
    const dateObject: DateInput[] = [];
    const color =
      groupSelection.length === 0
        ? {
            color: "#fff",
            background: AppColors.COLOR_CALENDAR_DEFAULT_PRIMARY,
          }
        : { background: AppColors.COLOR_CALENDAR_DEFAULT_SECONDARY };
    oldSelected.map((el) => {
      dateObject.push({
        ...color,
        date: el,
      });
    });

    groupSelection.map((el) => {
      dateObject.push({
        date: el,
        color: "#fff",
        background: AppColors.COLOR_CALENDAR_DEFAULT_PRIMARY,
      });
    });

    dispatch(UpdateDatesObj(dateObject));
  }, [lotsDate, groupSelection]);

  useEffect(() => {
    dispatch(UpdateNewDemande({ demandeClientDates: lotsDate }));
  }, [lotsDate]);

  const AddLots = () => {
    const lotArr: Lots[] = [];
    if (min && max) {
      groupSelection.map((el) => {
        lotArr.push({
          date: el,
          lotMin: min,
          lotMax: max,
        });
      });
    }

    setMin(undefined);
    setMax(undefined);
    setGroupSelection([]);

    // TODO: Filter Group selection
    const filterLots = lotsDate.filter((el) => !groupSelection.includes(el.date));
    setLotsDate(lotArr.concat(filterLots));
    dispatch(UpdateSelectedDates({ currentDate: undefined }));
  };

  const onChangeMin = (value: number | string | null) => {
    if (value) {
      setMin(Number(value));
    } else {
      setMin(undefined);
    }
  };

  const onChangeMax = (value: number | string | null) => {
    if (value) {
      setMax(Number(value));
    } else {
      setMax(undefined);
    }
  };

  const removeLot = (index: number) => {
    const newLots = [...lotsDate];
    newLots.splice(index, 1);
    setLotsDate(newLots);
  };

  return (
    <div>
      <Row style={{ alignItems: "end" }}>
        <Col span={5}>
          <span className="smallTitle">Sélection</span>
        </Col>
        <Col span={9}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="smallTitle">Date</span>
            {isShowTutorial ? (
              <div style={{ marginLeft: "0.5rem" }}>
                <IndicatorTutorialView hint={<>Dates sélectionnées en cliquant sur le calendrier. Possibilité d&apos;en sélectionner plusieurs.</>} />
              </div>
            ) : (
              <></>
            )}
          </div>
          <CustomInput
            className={styles.dateInput}
            placeholder="jj/mm/aaaa"
            readOnly={true}
            value={
              groupSelection.length > 0
                ? groupSelection.length === 1
                  ? moment(groupSelection[0]).format("DD/MM/YY")
                  : `${groupSelection.length} dates`
                : ""
            }
          />
        </Col>
        <Col span={10}>
          <span className="smallTitle">Lot(s)</span>
          <div className={styles.groupLots}>
            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
              <CustomInputNumber
                className={styles.lotValue}
                placeholder="Min"
                value={min}
                min={max ? max / 5 : 1}
                onChange={onChangeMin}
                precision={0}
              />
              {isShowTutorial ? (
                <div style={{ right: "-0.25rem", top: "0.375rem", position: "absolute" }}>
                  <IndicatorTutorialView hint={<>Nombre de lots minimum pour les dates sélectionnées.</>} />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
              <CustomInputNumber
                className={styles.lotValue}
                placeholder="Max"
                value={max}
                max={min ? min * 5 : undefined}
                onChange={onChangeMax}
                precision={0}
              />
              {isShowTutorial ? (
                <div style={{ right: "-0.25rem", top: "0.375rem", position: "absolute" }}>
                  <IndicatorTutorialView hint={<>Nombre de lots maximum pour les dates sélectionnées.</>} />
                </div>
              ) : (
                <></>
              )}
            </div>
            {groupSelection.length > 0 && min && max && (
              <Button
                className={styles.addLotsButton}
                icon={<ArrowRightOutlined style={{ fontSize: "10px", color: "white" }} />}
                onClick={AddLots}
                disabled={max < min}
              />
            )}
          </div>
        </Col>
      </Row>

      <div className={styles.jourSelection}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className="smallTitle">Sélection</span>
          {isShowTutorial ? (
            <div style={{ marginLeft: "0.5rem" }}>
              <IndicatorTutorialView hint={<>Ensemble des dates sélectionnées avec le nombre de lots minimum et maximum associés.</>} />
            </div>
          ) : (
            <></>
          )}
        </div>
        <LotsJour lotsDate={lotsDate} onRemove={(id) => removeLot(id)} />
      </div>
    </div>
  );
};

export default JourInput;

/** @format */

import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { tap } from "rxjs";
import { useAppSelector } from "../../../../app/hooks";
import { selectIsShowTutorial } from "../../../../app/reducers/indicatorTutorial.reducer";
import { selectLigne, selectLigneRequest, updateLigneRequest } from "../../../../app/reducers/ligne.reducer";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import CustomInputNumber from "../../../../components/CustomInputNumber";
import IndicatorTutorialView from "../../../../components/IndicatorTutorial";
import { CountryCodeAPI } from "../../../../domain/apis/countryCode.api";
import { useRxEffect } from "../../../../helper/rxjs.helper";
import { CountryCode } from "../../../../models/countryCode.model";
import UpdateLigne from "../../UpdateLigne";
import styles from "../main.module.css";
import ContactSelect from "./ContactSelect";
import { SelectContactValue } from "./SelectContactValue";
import SelectPhone from "./SelectPhone";
import SwitchTitle from "./SwitchTitle";

const { Title } = Typography;

interface FormModal {
  reception?: string;
  receptionCodeId?: number;
  receptionValue?: string;

  delaiAcceptation?: number;
  toutes?: boolean;

  chargementContact?: string;
  chargementContactCodeId?: number;
  chargementContactValue?: string;

  livraison?: string;
  livraisonCodeId?: number;
  livraisonValue?: string;
}

const Contacts = (props: { editable: boolean }) => {
  const { editable } = props;
  const lineData = useAppSelector(selectLigne);
  const ligneRequest = useAppSelector(selectLigneRequest);
  const dispatch = useDispatch();
  const isShowTutorial = useAppSelector(selectIsShowTutorial);
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<FormModal>({
    ...lineData,
    reception: lineData.reception || SelectContactValue.PLATEFORME,
    chargementContact: lineData.chargementContact || SelectContactValue.PLATEFORME,
    livraison: lineData.livraison || SelectContactValue.PLATEFORME,
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [fieldsError, setFieldsError] = useState<(string | number)[]>([]);
  const [countryCode, setCountryCode] = useState<CountryCode[]>([]);

  useRxEffect(
    () =>
      CountryCodeAPI.fetchAll().pipe(
        tap({
          next: (countryCodes: CountryCode[]) => {
            setCountryCode(countryCodes);
          },
        })
      ),
    []
  );

  useEffect(() => {
    form.validateFields();
  });

  useEffect(() => {
    form
      .validateFields()
      .then()
      .catch(() => {
        checkError();
      });
  }, []);

  const onFinish = (values: FormModal) => {
    dispatch(
      updateLigneRequest({
        ligneRequest: {
          ...ligneRequest,
          ...values,
          currentStage: Number(lineData.currentStage) + 1,
        },
      })
    );
    setIsSubmit(true);
  };

  const checkError = () => {
    const ErrorList = form.getFieldsError().filter((item) => item.errors.length > 0);

    let errorArr: (string | number)[] = [];

    ErrorList.map((el) => {
      errorArr = [...errorArr, ...el.name];
    });

    setFieldsError(errorArr);
  };

  const onValuesChange = () => {
    const getFieldsValue = form.getFieldsValue();
    const filterValue = {
      ...getFieldsValue,
      receptionValue: getFieldsValue.reception === formValue.reception ? getFieldsValue.receptionValue : undefined,
      receptionCodeId: getFieldsValue.reception === formValue.reception ? getFieldsValue.receptionCodeId : undefined,
      chargementContactValue: getFieldsValue.chargementContact === formValue.chargementContact ? getFieldsValue.chargementContactValue : undefined,
      chargementContactCodeId: getFieldsValue.chargementContact === formValue.chargementContact ? getFieldsValue.chargementContactCodeId : undefined,
      livraisonValue: getFieldsValue.livraison === formValue.livraison ? getFieldsValue.livraisonValue : undefined,
      livraisonCodeId: getFieldsValue.livraison === formValue.livraison ? getFieldsValue.livraisonCodeId : undefined,
    };

    setFormValue(filterValue);

    form.setFieldsValue(filterValue);

    setTimeout(() => {
      checkError();
    }, 10);
  };

  const onFinishFailed = () => {
    checkError();
    message.error("Merci de compléter toutes les colonnes.").then((r) => console.log(r));
  };

  const [disableDelai, setDisableDelai] = useState(false);

  const onChangeToutes = (e: CheckboxChangeEvent) => {
    if (e.target.checked) setDisableDelai(true);
    else setDisableDelai(false);
  };

  const SwitchType = (type?: string) => {
    switch (type) {
      case SelectContactValue.MAIL:
        return "email";
      case SelectContactValue.PLATEFORME:
        return "url";
      default:
        return "string";
    }
  };

  // var regex = new RegExp(
  //   "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
  // );
  // var without_regex = new RegExp("^([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
  // var str = "url";
  // if (regex.test(str) || without_regex.test(str)) {
  //   alert("Successful");
  // } else {
  //   alert("No match");
  // }

  return (
    <>
      {isSubmit && <UpdateLigne />}
      <Form
        form={form}
        name="plateformesForm"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        onFinishFailed={onFinishFailed}
        initialValues={formValue}
        colon={false}
        autoComplete="off"
        className={styles.wrapFormPlateformes}
        disabled={!editable}
      >
        <Title level={3} className={styles.title}>
          Contacts
        </Title>

        <Row>
          <Col span={18} offset={6}>
            <ContactSelect
              name="reception"
              title="Réception Commandes"
              highlight={true}
              isShowTutorial={isShowTutorial}
              hint={
                <>
                  Choisir le type de contact à utiliser pour la <i>Récéption des Commandes</i>.
                </>
              }
            />
          </Col>
        </Row>

        <Row className={styles.formPlateformes}>
          <Col span={6} className={styles.labelContact} style={{ display: "flex", justifyContent: "end" }}>
            <SwitchTitle title={formValue.reception} isShowTutorial={isShowTutorial} />
          </Col>
          <Col span={15}>
            {formValue.reception === SelectContactValue.TELEPHONE ? (
              <SelectPhone keyCode="receptionCodeId" keyPhone="receptionValue" fieldsError={fieldsError} countryCode={countryCode} />
            ) : (
              <Form.Item
                name="receptionValue"
                className={[
                  fieldsError.includes("receptionValue")
                    ? styles.contactError
                    : formValue.reception !== SelectContactValue.EDI
                    ? styles.contactValid
                    : styles.contactDisable,
                  styles.contactInput,
                ].join(" ")}
                rules={[
                  {
                    type: formValue.reception !== "plateform" ? SwitchType(formValue.reception) : "string",
                    pattern: new RegExp(
                      "^(https?:\\/\\/)?" + // validate protocol
                        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
                        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
                        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
                        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
                        "(\\#[-a-z\\d_]*)?$",
                      "i"
                    ),
                    required: formValue.reception !== SelectContactValue.EDI,
                    message: "",
                  },
                ]}
              >
                <Input bordered={false} disabled={formValue.reception === SelectContactValue.EDI} />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row className={styles.formPlateformes}>
          <Col span={24} xs={24} sm={8} lg={6} className={styles.labelContact} style={{ display: "flex", justifyContent: "end" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              Délai acceptation
              {isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView
                    hint={
                      <>
                        Délai d&apos;acceptation maximal des <i>Commandes</i> reçues.
                      </>
                    }
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </Col>
          <Col className={styles.flexAlignCenter}>
            <Form.Item name="delaiAcceptation" className={styles.marginBottomTitle} style={{ marginLeft: "-12px" }}>
              <CustomInputNumber
                step={1}
                min={1}
                max={24}
                precision={0}
                suffix="h"
                disabled={disableDelai || !editable}
                style={{
                  color: disableDelai ? "#d7d7d7" : "#000",
                  background: disableDelai ? "#fff" : "#EDEDED",
                }}
              />
            </Form.Item>
            <Form.Item valuePropName="checked" name="toutes" label="" className={styles.marginBottomTitle}>
              <CustomCheckbox name="disabled" disabled={!editable} onChange={onChangeToutes}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Toutes
                  {isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView
                        hint={
                          <>
                            À cocher si toutes les <i>Commandes</i>, sans exception, doivent être acceptées.
                          </>
                        }
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </CustomCheckbox>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={18} offset={6}>
            <div
              style={{
                marginTop: "2rem",
                marginBottom: "1rem",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              Prise de Rendez-vous
            </div>
            <ContactSelect
              name="chargementContact"
              title="Chargement"
              isShowTutorial={isShowTutorial}
              hint={
                <>
                  Choisir le type de contact à utiliser pour la <i>Prise de rendez-vous Chargement</i>.
                </>
              }
            />
          </Col>
        </Row>

        <Row className={styles.formPlateformes}>
          <Col span={6} className={styles.labelContact} style={{ display: "flex", justifyContent: "end" }}>
            <SwitchTitle title={formValue.chargementContact} isShowTutorial={isShowTutorial} />
          </Col>
          <Col span={15}>
            {formValue.chargementContact === SelectContactValue.TELEPHONE ? (
              <SelectPhone keyCode="chargementContactCodeId" keyPhone="chargementContactValue" fieldsError={fieldsError} countryCode={countryCode} />
            ) : (
              <Form.Item
                name="chargementContactValue"
                rules={[
                  {
                    type: formValue.chargementContact !== "plateform" ? SwitchType(formValue.chargementContact) : "string",
                    pattern: new RegExp(
                      "^(https?:\\/\\/)?" + // validate protocol
                        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
                        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
                        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
                        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
                        "(\\#[-a-z\\d_]*)?$",
                      "i"
                    ),
                    required: formValue.chargementContact !== SelectContactValue.EDI,
                    message: "",
                  },
                ]}
                className={[
                  fieldsError.includes("chargementContactValue")
                    ? styles.contactError
                    : formValue.chargementContact !== SelectContactValue.EDI
                    ? styles.contactValid
                    : styles.contactDisable,
                  styles.contactInput,
                ].join(" ")}
              >
                <Input bordered={false} disabled={formValue.chargementContact === SelectContactValue.EDI} />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row
          style={{
            marginTop: "2rem",
          }}
        >
          <Col span={18} offset={6}>
            <ContactSelect
              name="livraison"
              title="Livraison"
              isShowTutorial={isShowTutorial}
              hint={
                <>
                  Choisir le type de contact à utiliser pour la <i>Prise de rendez-vous Livraison</i>.
                </>
              }
            />
          </Col>
        </Row>

        <Row className={styles.formPlateformes}>
          <Col span={6} className={styles.labelContact} style={{ display: "flex", justifyContent: "end" }}>
            <SwitchTitle title={formValue.livraison} isShowTutorial={isShowTutorial} />
          </Col>
          <Col span={15}>
            {formValue.livraison === SelectContactValue.TELEPHONE ? (
              <SelectPhone keyCode="livraisonCodeId" keyPhone="livraisonValue" fieldsError={fieldsError} countryCode={countryCode} />
            ) : (
              <Form.Item
                name="livraisonValue"
                rules={[
                  {
                    type: formValue.livraison !== "plateform" ? SwitchType(formValue.livraison) : "string",
                    pattern: new RegExp(
                      "^(https?:\\/\\/)?" + // validate protocol
                        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
                        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
                        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
                        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
                        "(\\#[-a-z\\d_]*)?$",
                      "i"
                    ),
                    required: formValue.livraison !== SelectContactValue.EDI,
                    message: "",
                  },
                ]}
                className={[
                  fieldsError.includes("livraisonValue")
                    ? styles.contactError
                    : formValue.livraison !== SelectContactValue.EDI
                    ? styles.contactValid
                    : styles.contactDisable,
                  styles.contactInput,
                ].join(" ")}
              >
                <Input bordered={false} disabled={formValue.livraison === SelectContactValue.EDI} />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Form.Item className={styles.submit + " " + styles.formPlateformes}>
          {editable && (
            <Button type="primary" htmlType="submit" className={styles.bt}>
              Confirmer
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default Contacts;

import {MonthOfYear} from "../../../helper/calendarHelper";

export const MonthsArr = [
  MonthOfYear.JANUARY,
  MonthOfYear.FEBRUARY,
  MonthOfYear.MARCH,
  MonthOfYear.APRIL,
  MonthOfYear.MAY,
  MonthOfYear.JUNE,
  MonthOfYear.JULY,
  MonthOfYear.AUGUST,
  MonthOfYear.SEPTEMBER,
  MonthOfYear.OCTOBER,
  MonthOfYear.NOVEMBER,
  MonthOfYear.DECEMBER,
];

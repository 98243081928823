/** @format */

import { Col, Form, Row, Select } from "antd";
import styles from "../main.module.css";
import React, { ReactNode } from "react";
import { SelectContactValue } from "./SelectContactValue";
import IndicatorTutorialView from "../../../../components/IndicatorTutorial";

const { Option } = Select;

const ContactSelect = (props: { name: string; title: string; highlight?: boolean; isShowTutorial?: boolean; hint?: string | ReactNode }) => {
  const { name, title, highlight } = props;
  return (
    <Row style={{ alignItems: "center" }}>
      <Col span={10} className={highlight ? styles.highlight : ""}>
        {title}
      </Col>
      <Col span={14}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Form.Item name={name} style={{ marginBottom: 0 }}>
            <Select style={{ width: 132 }}>
              <Option value={SelectContactValue.PLATEFORME}>Plateforme</Option>
              <Option value={SelectContactValue.EDI}>EDI</Option>
              <Option value={SelectContactValue.MAIL}>Mail</Option>
              <Option value={SelectContactValue.TELEPHONE}>Téléphone</Option>
            </Select>
          </Form.Item>
          {props.isShowTutorial ? (
            <div style={{ marginLeft: "0.5rem" }}>
              <IndicatorTutorialView hint={props.hint ?? ""} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ContactSelect;

/** @format */

import { Checkbox, Col, Row } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { of, tap } from "rxjs";
import { useAppSelector } from "../../../../../../app/hooks";
import { selectCaracteristiquesLigne } from "../../../../../../app/reducers/demandeClient.reducer";
import { getCurrentDemande } from "../../../../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import { UpdateDatesObj } from "../../../../../../app/reducers/DemandeClient/DatesObject.reducer";
import { ActionType, selectDemandeState } from "../../../../../../app/reducers/DemandeClient/DemandeState.reducer";
import { UpdateNewDemande } from "../../../../../../app/reducers/DemandeClient/NewDemande.reducer";
import { getSelectedDate } from "../../../../../../app/reducers/DemandeClient/SelectedDates.reducer";
import CustomInputNumber from "../../../../../../components/CustomInputNumber";
import { DateInput } from "../../../../../../components/FullCalendar";
import { AppColors } from "../../../../../../constants/colors.const";
import { HolidayAPI } from "../../../../../../domain/apis/holiday.api";
import { getRangeDate } from "../../../../../../helper/getRangeDate";
import { useRxEffect } from "../../../../../../helper/rxjs.helper";
import { capitalizeFirstLetter } from "../../../../../../helper/stringExtension";
import { isValidDate } from "../../../../../../helper/validateDate";
import { MoisGlobalOption } from "../../../../../../models";
import { Lots } from "../../../../../../models/lots.model";
import ToastDateMoisGlobal from "../../../../components/ToastDateMoisGlobal";
import { MonthsArr } from "../../../../const/form.const";
import HolidaySelect from "../../HolidaySelect";
import IndicatorTutorialView from "../../../../../../components/IndicatorTutorial";
import { selectIsShowTutorial } from "../../../../../../app/reducers/indicatorTutorial.reducer";

interface LotsMois {
  id?: number;
  month: number;
  label: string;
  totalMinLot?: number;
  totalMaxLot?: number;
  selected?: boolean;
  disable?: boolean;
}

const MoisGlobal = () => {
  const dispatch = useDispatch();
  const currentDemande = useAppSelector(getCurrentDemande);
  const currentLigne = useAppSelector(selectCaracteristiquesLigne).caracteristiques;
  const demandeState = useAppSelector(selectDemandeState);
  const clickedDate = useAppSelector(getSelectedDate).currentDate;
  const isShowTutorial = useAppSelector(selectIsShowTutorial);

  const listMonths: LotsMois[] = [];
  MonthsArr.map((month) => {
    listMonths[month] = {
      id: undefined,
      month,
      label: capitalizeFirstLetter(moment().month(month).format("MMMM")),
      totalMinLot: undefined,
      totalMaxLot: undefined,
      disable: false,
    };
  });

  if (currentDemande?.moisGlobalOptions) {
    currentDemande.moisGlobalOptions.map((el) => {
      if (el.month !== undefined && el.totalMaxLot && el.totalMinLot) {
        listMonths[el.month] = {
          id: el.id,
          month: el.month,
          label: capitalizeFirstLetter(moment().month(el.month).format("MMMM")),
          totalMinLot: el.totalMinLot,
          totalMaxLot: el.totalMaxLot,
          selected: true,
        };
      }
    });
  }

  const [lotMonths, setLotMonths] = useState<LotsMois[]>(listMonths);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [monthAvailable, setMonthAvailable] = useState<number[]>([]);
  const [whiteList, setWhiteList] = useState<string[]>([]);
  const [holidays, setHolidays] = useState<string[]>([]);
  const [montSelected, setMonthSelected] = useState<number | undefined>();
  const [isInit, setIsInit] = useState<boolean>(false);

  useRxEffect(() => {
    if (currentLigne && currentDemande && currentDemande.countryCode) {
      const [startDate, endDate] = getRangeDate(currentLigne);
      const startYear = startDate?.slice(0, 4);
      const endYear = endDate?.slice(0, 4);

      return HolidayAPI.getHolidays(currentDemande.countryCode, Number(startYear), Number(endYear)).pipe(
        tap({
          next: (response: Map<string, { date: string }[]>) => {
            let getHoliday: string[] = [];
            Object.keys(response).map((key: string) => {
              const holidays: { date: string }[] = response.get(key) ?? [];
              getHoliday = getHoliday.concat(holidays.map((item: { date: string }) => item.date.replaceAll("-", "/")));
            });
            setHolidays(getHoliday);
          },
        })
      );
    }
    return of(true);
  }, []);

  useEffect(() => {
    if (currentLigne) {
      const [startDate, endDate] = getRangeDate(currentLigne);
      let dateInit = moment(startDate).startOf("month").format("YYYY/MM/DD");
      const monthArr = [];

      while (endDate > dateInit) {
        monthArr.push(new Date(dateInit).getMonth());
        dateInit = moment(dateInit).add(1, "month").format("YYYY/MM/DD");
      }

      setMonthAvailable(monthArr);
    }
  }, [currentLigne]);

  useEffect(() => {
    setTimeout(() => {
      if (demandeState.actionType === ActionType.UPDATE) {
        if (currentDemande && currentDemande?.demandeClientDates) {
          const selectedDateArr: string[] = [];
          currentDemande?.demandeClientDates.map((el) => {
            if (el?.date) {
              selectedDateArr.push(el.date);
            }
          });
          setSelectedDates(selectedDateArr);
          setIsInit(true);
        }
      } else {
        setIsInit(true);
      }
    }, 50);
  }, [currentDemande]);

  useEffect(() => {
    if (isInit) {
      changeSelectDate();
    }
  }, [lotMonths.filter((el) => el.selected).length]);

  // Update selected Date when click a date in calendar
  useEffect(() => {
    if (isInit) {
      changeSelectDate(true);
    }
  }, [clickedDate]);

  useEffect(() => {
    const dateObj: DateInput[] = [];
    const lotsDate: Lots[] = [];
    selectedDates.map((el) => {
      const month = new Date(el).getMonth();
      lotsDate.push({
        date: el,
        lotMin: lotMonths[month].totalMinLot,
        lotMax: lotMonths[month].totalMaxLot,
      });
      dateObj.push({
        date: el,
        color: "#fff",
        background: AppColors.COLOR_CALENDAR_DEFAULT_PRIMARY,
      });
    });
    dispatch(UpdateNewDemande({ demandeClientDates: lotsDate }));
    dispatch(UpdateDatesObj(dateObj));
  }, [selectedDates]);

  useEffect(() => {
    const moisGlobal: MoisGlobalOption[] = lotMonths
      .filter((el) => el.selected && el.totalMinLot && el.totalMaxLot)
      .map((el) => {
        return {
          id: el.id,
          month: el.month,
          totalMinLot: el.totalMinLot,
          totalMaxLot: el.totalMaxLot,
        };
      });
    dispatch(UpdateNewDemande({ moisGlobalOptions: moisGlobal }));
  }, [lotMonths]);

  function changeSelectDate(dateClick?: boolean) {
    if (demandeState.actionType !== ActionType.VIEW) {
      const selectedMonths = lotMonths.filter((el) => el.selected).map((el) => el.month);

      if (currentLigne) {
        const [dateInit, dateFin] = getRangeDate(currentLigne);
        let startDate = dateInit;
        let endDate = dateFin;
        const startMonthInit = moment(startDate).startOf("month").format("YYYY/MM/DD");
        const endMonthFin = moment(endDate).endOf("month").format("YYYY/MM/DD");
        if (selectedMonths.includes(new Date(startDate).getMonth()) && startDate > startMonthInit) {
          startDate = moment(startDate).endOf("month").add(1, "days").format("YYYY/MM/DD");
        }
        if (selectedMonths.includes(new Date(endDate).getMonth()) && endDate < endMonthFin) {
          endDate = moment(endDate).startOf("month").subtract(1, "days").format("YYYY/MM/DD");
        }

        const startYear = new Date(dateInit).getFullYear();
        const endYear = new Date(dateFin).getFullYear();

        const newDates: string[] = [];
        const newWhiteList: string[] = [];

        for (let year = startYear; year <= endYear; year++) {
          selectedMonths.map((month) => {
            if (dateClick && clickedDate && !holidays.includes(clickedDate.date)) {
              const startMonth = year + "/" + ("0" + (month + 1)).slice(-2) + "/01";
              const endMonth = year + "/" + ("0" + (month + 1)).slice(-2) + "/31";
              if (startMonth <= clickedDate.date && endMonth >= clickedDate.date) {
                // ignore select this date
                // check if this month deselect => remove select this month
                const dateItem: string[] = [];
                for (let y = startYear; y <= endYear; y++) {
                  if (year !== y) {
                    dateItem.push(y + "/" + ("0" + (month + 1)).slice(-2) + "/01");
                  }
                }
                if (whiteList.every((el) => !dateItem.includes(el))) {
                  setLotMonths(
                    [...lotMonths].map((el) => {
                      if (el.month !== month) {
                        return el;
                      }
                      return {
                        ...el,
                        selected: false,
                      };
                    })
                  );
                }
                return;
              }
              for (let day = 1; day <= 31; day++) {
                const date = year + "/" + ("0" + (month + 1)).slice(-2) + "/" + ("0" + day).slice(-2);
                if (whiteList.includes(date) && isValidDate(date) && startDate <= date && endDate >= date && !holidays.includes(date)) {
                  newWhiteList.push(date);
                  if (!selectedDates.includes(date)) {
                    newDates.push(date);
                  }
                }
              }
              setSelectedDates(newDates);
              return;
            }

            for (let day = 1; day <= 31; day++) {
              const date = year + "/" + ("0" + (month + 1)).slice(-2) + "/" + ("0" + day).slice(-2);
              if (isValidDate(date) && startDate <= date && endDate >= date && !holidays.includes(date)) {
                newWhiteList.push(date);
                if (!selectedDates.includes(date)) {
                  newDates.push(date);
                }
              }
            }
          });
        }

        const removeDate: string[] = whiteList.filter((date) => !newWhiteList.includes(date));

        setWhiteList(newWhiteList);
        setSelectedDates(
          [...selectedDates, ...newDates]
            .filter((el) => !removeDate.includes(el))
            .filter(function (x, i, a) {
              return a.indexOf(x) == i;
            })
        );
      }
    }
  }

  const checkMonth = (e: CheckboxChangeEvent, month: number) => {
    const newMonths = [...lotMonths];
    const index = newMonths.findIndex((el) => el.month === month);
    newMonths[index].selected = e.target.checked;
    setLotMonths(newMonths);
    if (e.target.checked) {
      setMonthSelected(month);
    } else {
      setMonthSelected(undefined);
    }
  };

  const deSelectMonth = (month: number | undefined) => {
    if (month && !monthAvailable.includes(month)) {
      const newMonths = [...lotMonths];
      const index = newMonths.findIndex((el) => el.month === month);
      newMonths[index].selected = false;
    }
  };

  const onChangeMin = (value: number, month: number) => {
    const changedLots = [...lotMonths];
    changedLots[month].totalMinLot = value;
    setLotMonths(changedLots);
    setTimeout(() => {
      changeSelectDate(true);
    }, 50);
  };

  const onChangeMax = (value: number, month: number) => {
    const changedLots = [...lotMonths];
    changedLots[month].totalMaxLot = value;
    setLotMonths(changedLots);
    setTimeout(() => {
      changeSelectDate(true);
    }, 50);
  };

  const changeHolidays = (values: string[]) => {
    const addDays = whiteList.filter((el) => holidays.includes(el));
    const removeDays = selectedDates.filter((el) => values.includes(el));
    setSelectedDates([...selectedDates, ...addDays].filter((el) => !removeDays.includes(el)));
    setHolidays(values);
  };

  return (
    <div className="MoisGlobal">
      {currentLigne && (
        <ToastDateMoisGlobal
          currentLigne={currentLigne}
          currentMonth={montSelected}
          callBack={(status: boolean) => {
            if (status) {
              deSelectMonth(montSelected);
            }
          }}
        />
      )}
      <Row>
        <Col span={7} offset={10}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="smallTitle" style={{ paddingLeft: "0.75rem" }}>
              Min
            </span>
          </div>
        </Col>
        <Col span={7}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="smallTitle" style={{ paddingLeft: "0.75rem" }}>
              Max
            </span>
          </div>
        </Col>
      </Row>
      {lotMonths.map((item, index) => {
        return (
          <Row key={item.month}>
            <Col span={10}>
              <Checkbox
                key={item.month}
                checked={item.selected}
                disabled={!monthAvailable.includes(item.month) || demandeState.actionType === ActionType.VIEW}
                onChange={(e) => checkMonth(e, item.month)}
              >
                {item.label}
              </Checkbox>
            </Col>
            <Col span={7}>
              <CustomInputNumber
                style={{ height: "1.375rem" }}
                bordered={false}
                value={lotMonths[item.month].totalMinLot}
                placeholder="-"
                disabled={demandeState.actionType === ActionType.VIEW || !item.selected}
                onChange={(e) => onChangeMin(Number(e), item.month)}
              />
              {index === 0 && isShowTutorial && (
                <div style={{ position: "absolute", right: "2rem", top: "0.25rem" }}>
                  <IndicatorTutorialView hint={<>Nombre de lots minimum pour le mois complet.</>} />
                </div>
              )}
            </Col>
            <Col span={7}>
              <CustomInputNumber
                style={{ height: "1.375rem" }}
                bordered={false}
                value={lotMonths[item.month].totalMaxLot}
                placeholder="-"
                disabled={demandeState.actionType === ActionType.VIEW || !item.selected}
                onChange={(e) => onChangeMax(Number(e), item.month)}
              />
              {index === 0 && isShowTutorial && (
                <div style={{ position: "absolute", right: "2rem", top: "0.25rem" }}>
                  <IndicatorTutorialView hint={<>Nombre de lots maximum pour le mois complet.</>} />
                </div>
              )}
            </Col>
          </Row>
        );
      })}
      <HolidaySelect getHolidays={(values) => changeHolidays(values)} />
    </div>
  );
};

export default MoisGlobal;

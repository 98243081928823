/** @format */

import { DownloadOutlined } from "@ant-design/icons";
import { Button } from 'antd';
import axios from "axios";
import { chain, isNull, uniqBy } from "lodash";
import moment from "moment";
import parse, { ParseResult } from "papaparse";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { combineLatest, finalize, from, mergeMap, noop, Observable, tap } from "rxjs";
import * as XLSX from "xlsx";
import EditIcon from "../../../../assets/icons/edit.svg";
import SingleLoading from "../../../../components/SingleLoading";
import { AppColors } from "../../../../constants/colors.const";
import { AgenceAPI } from "../../../../domain/apis/agence.api";
import { CaracteristiqueAPI } from "../../../../domain/apis/caracteristique.api";
import { ClientAPI } from "../../../../domain/apis/client.api";
import { CommercialAPI } from "../../../../domain/apis/commercial.api";
import { CountryCodeAPI } from "../../../../domain/apis/countryCode.api";
import { DepartementAPI } from "../../../../domain/apis/departement.api";
import { DeviseAPI } from "../../../../domain/apis/devise.api";
import { DroitAPI } from "../../../../domain/apis/droit.api";
import { IndiceGasoilAPI } from "../../../../domain/apis/indiceGasoil.api";
import { LieuxAPI } from "../../../../domain/apis/lieux.api";
import { MarchandiseAPI } from "../../../../domain/apis/marchandise.api";
import { ModeTransportAPI } from "../../../../domain/apis/modeTransports.api";
import { PayAPI } from "../../../../domain/apis/pay.api";
import { SocieteAPI } from "../../../../domain/apis/societe.api";
import { TypeVehiculeAPI } from "../../../../domain/apis/typeVehicule.api";
import { UniteAPI } from "../../../../domain/apis/unite.api";
import { VilleAPI } from "../../../../domain/apis/ville.api";
import { makeRandom } from "../../../../helper/makeRandom";
import { doOnSubscribe, useRxEffect } from "../../../../helper/rxjs.helper";
import {
  Agence,
  Caracteristiques,
  CaracteristiquesTypeDuree,
  Client,
  Commercial, CoutsAdditionnel,
  Departement,
  Devise,
  IndiceGasoil,
  Lieux,
  ModeTransport,
  Pays,
  Societe,
  Unite,
  Ville
} from '../../../../models';
import { CountryCode } from "../../../../models/countryCode.model";
import { Droit } from "../../../../models/droit.model";
import { Marchandise } from "../../../../models/marchandise.model";
import { TypeVehicule } from "../../../../models/typevehicule.model";
import styles from "../../ContractLines.module.css";
import { SelectContactValue } from "../Contacts/SelectContactValue";
import CopyTable from "./CopyTable";
import "./Import.css";
import ModalSussess from "./ModalSuccess";
import { CoutsAdditionnelAPI } from '../../../../domain/apis/coutsAdditionnel.api';
import { PTVLocation } from '../Trajet/Trajet';

interface CompleteLines extends Caracteristiques {
  selected?: boolean;
  budgettransport?: number;
  codearrivee?: string;
  codedepart?: string;
  codepostalarrivee?: string;
  codepostaldepart?: string;
  codepaysarrivee?: string;
  codepaysdepart?: string;
  ftl?: boolean;
  id?: number;
  ligneid?: string;
  margevisee?: string;
  mplmax?: string;
  mplmin?: string;
  nomarrivee?: string;
  nomdepart?: string;
  poidsmax?: string;
  poidsmin?: string;
  palettesmin?: string;
  palettesmax?: string;
  prixcontractualise?: number;
  prixcontractualisedevise?: string;
  prixcontractualiseunity?: string;
  vehicule?: string;
  volumealloue?: number;
  volumeunite?: string;
  chargements?: ChargementInterface[];
  alloues?: AlloueInterface[];
  budgets?: BudgetInterface[];
  couts?: CoutsAdditionnelInterface[];
  ligneChargement?: ChargementInterface;
  ligneAlloue?: AlloueInterface;
  ligneBudget?: BudgetInterface;
  ligneCout?: CoutsAdditionnelInterface;
  paysArriveeCode?: string;
  paysDepartCode?: string;
  lieuxDepartcode?: string;
  lieuxArrivercode?: string;
  villeDepartNom?: string;
  villeArriveeNom?: string;
  departementDepartCode?: string;
  departementArriveeCode?: string;
  clientCode?: number;
  indiceGasoilCode?: number;
  clientFactureCode?: number;
  commercialCode?: number;
  societeCode?: number;
  societeFactureCode?: number;
  agenceCode?: number;
  exploitantCode?: number;
  marchandiseCode?: number;
  typeVehiculeCode?: number;
  modeTransportCode?: number;
  index?: number;
}

interface ChargementInterface {
  mplmin?: number;
  mplmax?: number;
  ftl?: boolean;
  poidsmin?: number;
  poidsmax?: number;
  palettesmin?: number;
  palettesmax?: number;
  echangepalettes?: boolean;
  dureechargement?: number;
  matin?: string;
  apresmidi?: string;
  caracteristiquesid?: number;
}

interface AlloueInterface {
  volumetotalclient?: number;
  taux?: boolean;
  volumealloue?: number;
  tauxalloue?: number;
  volumeunityid?: number;
  volumeunitynom?: string;
  volumefrequencyid?: number;
  volumefrequencynom?: string;
  volumefrequency?: Unite;
}

interface BudgetInterface {
  prixcontractualise?: number;
  margevisee?: number;
  budgettransport?: number;
  prixcontractualisedeviseid?: number;
  prixcontractualisedevisenom?: string;
  prixcontractualiseunityid?: number;
  prixcontractualiseunitynom?: string;
}

interface CoutsAdditionnelInterface {
  type?: string;
  montant?: number;
  deviseid?: number;
  devisenom?: string;
  caracteristiquesid?: number;
}

interface ErrorImportInterface {
  error?: boolean;
  type?: string;
  index?: number;
  key?: string;
  value?: string;
  uniqueId?: string;
}

export interface LinesImported {
  data: CompleteLines;
  error: ErrorImportInterface;
}

enum SelectValueInput {
  plateforme = "plateform",
  edi = "edi",
  mail = "mail",
  téléphone = "telephone",
}

enum Condition {
  maxMpl = 13.6,
  maxYear = 6,
  maxDistance = 8000,
  maxAn = 5,
  maxMois = 60,
  maxPos = 60,
  maxPallet = 70,
  maxDureeChar = 24,
  maxVolume = 9999999,
  maxIndiceGasoil = 1000,
  maxPrix = 20000,
  maxMargevisee = 1000,
}

const frequenceRevision = ["Mensuel", "Trimestriel", "Annuel"];

const ImportLines = () => {
  const navigate = useNavigate();
  const typeGas = ["Indice", "Prix GO"];
  const typeTunnel = ["Indice", "Prix ctr."];
  const [loading, setLoading] = useState(true);
  const [isCopy, setIsCopy] = useState(false);
  const [header, setHeader] = useState<string[]>([]);
  const [dataParse, setDataParse] = useState<string[][]>([]);
  const [listLineShow, setListLineShow] = useState<CompleteLines[]>([]);
  const [listLine, setListLine] = useState<LinesImported[]>([]);
  const [listLineSuccess, setListLineSuccess] = useState<LinesImported[]>([]);
  const [errorImport, setErrorImport] = useState<ErrorImportInterface[]>([]);
  const [clientList, setClientList] = useState<Client[]>([]);
  const [clientFactureList, setClientFactureList] = useState<Client[]>([]);
  const [societeList, setSocieteList] = useState<Societe[]>([]);
  const [societeFactureList, setSocieteFactureList] = useState<Societe[]>([]);
  const [listLieux, setListLieux] = useState<Lieux[]>([]);
  const [listPays, setListPays] = useState<Pays[]>([]);
  const [listDepartement, setListDepartement] = useState<Departement[]>([]);
  const [listVille, setListVille] = useState<Ville[]>([]);
  const [exploitantList, setExploitantList] = useState<Droit[]>([]);
  const [indiceGasoils, setIndiceGasoils] = useState<IndiceGasoil[]>([]);
  const [agenceList, setAgenceList] = useState<Agence[]>([]);
  const [commercial, setCommercial] = useState<Commercial[]>([]);
  const [marchandise, setMarchandise] = useState<Marchandise[]>([]);
  const [typeVihicle, setTypeVihicle] = useState<TypeVehicule[]>([]);
  const [modeTransport, setModeTransport] = useState<ModeTransport[]>([]);
  const [listUnit, setListUnit] = useState<Unite[]>([]);
  const [listDevise, setListDevise] = useState<Devise[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [countryCode, setCountryCode] = useState<CountryCode[]>([]);
  const [allCaracteristiques, setAllCaracteristiques] = useState<Caracteristiques[]>([]);
  const [listCountOptions, setListCountOptions] = useState<string[]>([]);

  useRxEffect(
    () =>
      from(initData()).pipe(
        doOnSubscribe(() => setLoading(true)), // Show loading on start
        mergeMap(() =>
          combineLatest([
            getClients(),
            getSociete(),
            getLieux(),
            getPays(),
            getDepartement(),
            getVille(),
            getExploitant(),
            getAgence(),
            getCommercial(),
            getIndiceGasoils(),
            getMarchandise(),
            getModeTransport(),
            getTypeVihicle(),
            getUnity(),
            getDevises(),
            getCountryCode(),
            getCaracteristiques(),
              getCouts(),
          ])
        ),
        finalize(() => setLoading(false)) // Hide loading on end
      ),
    []
  );

  const initData = async () => {
    parse.parse("https://api.transport.develop.bfast-vn.net/api/containers/templates/download/1ae01368-b881-4695-b1db-3dd846ddd9bf.csv", {
      download: true,
      dynamicTyping: true,
      skipEmptyLines: "greedy",
      complete: function (results: ParseResult<string[]>) {
        console.log(results);
        setHeader(results.data[0]);
        if (results.data[4]) {
          results.data.splice(4, 5);
        }
        setDataParse(results.data);
        // initDataCaracteristiques(results.data);
      },
    });
  };

  const onParsed = (res: string[][]) => {
    parseToCaracteristiques(res);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        const bstr = event?.target?.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_csv(ws);
        const parsed: ParseResult<string[]> = parse.parse(data, {
          skipEmptyLines: "greedy", // skip empty lines and lines include spaces
        });
        parseToCaracteristiques(parsed.data);
        e.target.files = null;
        e.target.value = "";
        console.log(parsed);
      };
      if (e.target.files[0].type === "text/csv") {
        fileReader.readAsText(e.target.files[0], "utf-8");
      } else {
        fileReader.readAsBinaryString(e.target.files[0]);
      }
    }
  };

  const parseToCaracteristiques = async (parsed: string[][]) => {
    console.log(parsed, "data parsed");
    if (parsed && parsed.length > 0) {
      setErrorImport([]);
      setListLine([]);
      setListLineShow([]);
      const data: string[] = parsed[1];
      const arrData: LinesImported[] = [];
      const arrError: ErrorImportInterface[] = [];
      const arrErrorId: ErrorImportInterface[] = [];
      for (let idx = 4; idx < parsed.length; idx++) {
        let objectCreate: CompleteLines = { index: idx - 3 };
        let chargment: ChargementInterface = {};
        let alloue: AlloueInterface = {};
        let budgets: BudgetInterface = {};
        const couts: CoutsAdditionnelInterface[] = [];
        let error: ErrorImportInterface = {};
        data.forEach((value: string, index) => {
          // const a = parsed[idx][index];
          switch (parsed[3][index]) {
            case "number":
              if (parsed[idx][index] && !value.includes("_")) {
                if (isNaN(parseFloat(parsed[idx][index]))) {
                  error = {
                    error: true,
                    type: "req",
                    index: idx - 3,
                    key: value,
                    uniqueId: value,
                  };
                  arrError.push(error);
                }
              }
              break;
            case "boolean":
              if (
                !value.includes("_") &&
                parsed[idx][index] &&
                parsed[idx][index].toString().toLocaleLowerCase() !== "true" &&
                parsed[idx][index].toString().toLocaleLowerCase() !== "false"
              ) {
                error = {
                  error: true,
                  type: "req",
                  index: idx - 3,
                  key: value,
                  uniqueId: value,
                };
                arrError.push(error);
              }
              break;
            case "Date":
              if (parsed[idx][index]) {
                const [day, month, year] = parsed[idx][index].split('/');
                if (isNaN(Date.parse(new Date(+day, +month, +year).toString()))) {
                  error = {
                    error: true,
                    type: "req",
                    index: idx - 3,
                    key: value,
                    uniqueId: value,
                  };
                  arrError.push(error);
                } else {
                  parsed[idx][index] = [month, day, year].join('/');
                }
              }
              break;
          }
          let valueData;
          if (parsed[3][index] === "boolean") {
            valueData = !isNull(parsed[idx][index]) ? parsed[idx][index].toString().toLocaleUpperCase() === "TRUE" : false;
          } else {
            valueData = !isNull(parsed[idx][index])
              ? parsed[3][index] === "number"
                ? !isNaN(parseFloat(parsed[idx][index]))
                  ? parseFloat(parsed[idx][index])
                  : undefined
                : parsed[3][index] === "boolean"
                ? parsed[idx][index].toString().toLocaleUpperCase() === "TRUE"
                : parsed[idx][index]
              : null;
          }
          if (value.startsWith("chargment")) {
            const key = value.slice(value.indexOf("_") + 1, value.length);
            chargment = { ...chargment, [key]: valueData };
            objectCreate = {
              ...objectCreate,
              ligneChargement: chargment,
            };
          } else if (value.startsWith("alloue")) {
            const key = value.slice(value.indexOf("_") + 1, value.length);
            alloue = { ...alloue, [key]: valueData };
            objectCreate = {
              ...objectCreate,
              ligneAlloue: alloue,
            };
          } else if (value.startsWith("budget")) {
            const key = value.slice(value.indexOf("_") + 1, value.length);
            budgets = { ...budgets, [key]: valueData };
            objectCreate = {
              ...objectCreate,
              ligneBudget: budgets,
            };
          } else if (value.startsWith("couts")) {
            const key = value.slice(value.indexOf("_") + 1, value.length);
            const valueChargment = (parsed[idx][index].includes('/') && !parsed[idx][index].includes(';')) ? parsed[idx][index].split("/") : parsed[idx][index].split(";");
            valueChargment.forEach((char: string, idxCouts: number) => {
              if (char !== "") {
                if (char && parsed[3][index] === "number" && char.trim() !== "NA" && isNaN(parseFloat(char))) {
                  error = {
                    error: true,
                    type: "req",
                    index: idx - 3,
                    key: `${value} ${idxCouts + 1}`,
                    uniqueId: value,
                  };
                  arrError.push(error);
                }
                if (char && parsed[3][index] === "boolean" && char.toLocaleLowerCase() !== "true" && char.toLocaleLowerCase() !== "false") {
                  error = {
                    error: true,
                    type: "req",
                    index: idx - 3,
                    key: `${value} ${idxCouts + 1}`,
                    uniqueId: value,
                  };
                  arrError.push(error);
                }
                const valueData =
                  parsed[3][index] === "number"
                    ? char.trim() !== "NA"
                      ? parseFloat(char)
                      : undefined
                    : parsed[3][index] === "boolean"
                    ? char.toLocaleLowerCase() === "true"
                    : char;
                const charg = { [key]: valueData };
                if (!couts[idxCouts]) {
                  couts.push(charg);
                } else {
                  couts[idxCouts] = { ...couts[idxCouts], ...charg };
                }
              }
            });
            // couts = { ...couts, [key]: valueData };
            objectCreate = {
              ...objectCreate,
              couts: couts,
            };
          } else {
            if ((value === "reception" || value === "chargementContact" || value === "livraison") && valueData) {
              valueData = SelectValueInput[valueData.toString().toLocaleLowerCase() as keyof typeof SelectValueInput];
            }
            objectCreate = {
              ...objectCreate,
              [value]: valueData,
            };
          }
          if (
            (parsed[2][index] === "require" && (isNull(parsed[idx][index]) || parsed[idx][index] === "")) ||
            (parsed[2][index] === "number" && isNaN(parseFloat(parsed[idx][index])))
          ) {
            error = {
              error: true,
              type: "req",
              index: idx - 3,
              key: value,
              uniqueId: value,
            };
            arrError.push(error);
          }
        });
        arrData.push({
          data: {
            ...objectCreate,
          },
          error: error,
        });
      }
      mergeData(arrData, arrError, arrErrorId);
      getDataShow(arrData, arrError, arrErrorId);
    }
  };

  const mergeData = (arrData: LinesImported[], arrError: ErrorImportInterface[], arrErrorId: ErrorImportInterface[]) => {
    const groupData = chain(arrData)
      .groupBy((ref: LinesImported) => {
        if (ref.data.ligneid !== "") {
          return ref.data.ligneid;
        }
      })
      .map((value2) => {
        return value2;
      })
      .value();
    groupData.forEach((it) => {
      if (it[0] && it[0].data.ligneid !== "") {
        if (it.length > 32) {
          it.forEach((val) => {
            arrError.push({
              error: true,
              type: "length",
              index: val.data.index,
              value: val.data.ligneid,
              key: "length 32",
              uniqueId: "length 32",
            });
          });
        }
        let err = "";
        let isCoutsErr: string[] = [];
        const aspi = it.map((val) => val.data.ligneChargement);
        const unqMatin = uniqBy(aspi, (item) => item && item.matin);
        const unqapresmidi = uniqBy(aspi, (item) => item && item.apresmidi);
        if (unqMatin.length > 1) {
          err += `matin,`;
        }
        if (unqapresmidi.length > 1) {
          err += `apresmidi,`;
        }
        const allCouts = it.map((value) => value.data.couts);
        allCouts.forEach((allCout) => {
          if (allCout && allCouts[0]) {
            if (allCout.length === 0) {
              isCoutsErr = ['type', 'devisenom', 'montant']
            } else {
              const same = checkSameCouts(allCout, allCouts[0]);
              if (same) {
                isCoutsErr = isCoutsErr.concat(same);
              }
            }
          }
        });
        let uniqueCoutType = [];
        isCoutsErr = uniqBy(isCoutsErr, (err) => err).map(cout => {
            switch (cout) {
              case 'type':
                return 'Couts Additionnel Type';
              case 'devisenom':
                return 'Couts Additionnel Devise Nom';
              case 'montant':
                return 'Couts Additionnel Montant';
              default:
                return ''
            }
        });
        if (allCouts && allCouts.length > 0 && allCouts[0]) {
          uniqueCoutType = uniqBy(allCouts[0], (item) => item && item.type);
        }
        it.forEach((val) => {
          if (allCouts.length > 0 && allCouts[0] && uniqueCoutType.length < allCouts[0].length) {
            arrError.push({
              error: true,
              type: "req",
              index: val.data.index,
              key: "Couts Additionnel Type",
              uniqueId: "Couts Additionnel Type",
            });
          }
          if (allCouts.length > 10) {
            arrError.push({
              error: true,
              type: "req",
              index: val.data.index,
              key: "Couts Additionnel Type",
              uniqueId: "Couts Additionnel Type",
            });
          }
          const isEqual = shallowEqual(val.data, it[0].data);
          if (!isEqual.isSame) {
            const arrKey = isEqual.key.split(" ");
            const keyFind = arrKey.filter((value) => !err.includes(value));
            err += `${keyFind.join()}`;
          }
        });
        err += isCoutsErr.length > 0 ? (err.length > 0 ? `,${isCoutsErr.join()} ` : `${isCoutsErr.join()} `) : '';
        const ftl = it.filter((value) => value.data.ligneChargement && value.data.ligneChargement.ftl);
        const plages = it.filter((value) => !value.data.plages && value.data.ligneChargement);
        if (plages.length > 1) {
          it.forEach((val) => {
            arrError.push({
              error: true,
              type: "req",
              index: val.data.index,
              key: "plages",
              uniqueId: "plages",
            });
          });
        }
        if (ftl.length > 1) {
          it.forEach((val) => {
            arrError.push({
              error: true,
              type: "req",
              index: val.data.index,
              key: "ftl",
              uniqueId: "ftl",
            });
          });
        }
        // err.forEach(value => {
        if (err.length > 0) {
          it.forEach((val) => {
            arrErrorId.push({
              error: true,
              type: "unique id",
              index: val.data.index,
              key: err,
              value: val.data.ligneid,
              uniqueId: "ligneId",
            });
          });
        }
        //
        // });
      }
    });
    console.log(groupData);
  };

  const shallowEqual = (ligne1: CompleteLines, ligne2: CompleteLines) => {
    const keys1 = Object.keys(ligne1);
    let keyFind = "";
    for (const key of keys1) {
      if (key !== "ligneChargement" && key !== "ligneAlloue" && key !== "ligneBudget" && key !== "ligneCout" && key !== "couts" && key !== "index") {
        if (ligne1[key as keyof typeof ligne1] !== ligne2[key as keyof typeof ligne1]) {
          if (!keyFind.includes(key)) {
            keyFind += ` ${key}`;
          }
        }
      }
    }
    return { isSame: keyFind.length === 0, key: keyFind };
    // return { isSame: true };
  };

  const checkSameCouts = (ligne1: CoutsAdditionnelInterface[], ligne2: CoutsAdditionnelInterface[]) => {
    const keyFind: string[] = [];
    for (let i = 0; i < (ligne1.length > ligne2.length ? ligne1.length : ligne2.length); i++) {
        const check = ligne1[i] ? ligne1[i] : ligne2[i]
        for (const key of ['type', 'devisenom', 'montant']) {
          if (!ligne2[i] || !ligne1[i]) {
            if(check[key as keyof typeof check]) {
              keyFind.push(key);
            }
          } else {
            if (ligne1[i][key as keyof typeof check] !== ligne2[i][key as keyof typeof check]) {
              if (!keyFind.includes(key)) {
                keyFind.push(key);
              }
            }
          }
        }
    }
    return keyFind;
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const isValidHttpUrl = (urlstring: string) => {
    try {
      const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
          '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
      return urlPattern.test(urlstring);
    } catch (err) {
      return false;
    }
  };

  const checkYear = (date: Date) => {
    const dateInit = new Date(date);
    const today = new Date();
    return isNaN(dateInit.valueOf()) || dateInit.getFullYear() - today.getFullYear() > Condition.maxYear;
  };

  const checkDateFin = (dateIni: Date, date: Date) => {
    const dateInit = new Date(dateIni);
    dateInit.setDate(dateInit.getDate() + 30);
    const dateFin = new Date(date);
    return dateFin.getTime() < dateInit.getTime();
  };

  const checkDuree = (type?: string, duree?: number) => {
    if (
      duree &&
      type &&
      (type.toLocaleLowerCase() === CaracteristiquesTypeDuree.MOIS.toLocaleLowerCase() ||
        type.toLocaleLowerCase() === CaracteristiquesTypeDuree.AN.toLocaleLowerCase())
    ) {
      return type.toLocaleLowerCase() === CaracteristiquesTypeDuree.MOIS.toLocaleLowerCase() ? duree <= Condition.maxMois : duree <= Condition.maxAn;
    } else {
      return false;
    }
  };

  const checker = (value?: string, matin?: string) => {
    //const t = ['k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u','v', 'w',	'x', 'y', 'z']
    const prohibited = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"];

    if (matin && value) {
      const idxMatin = prohibited.indexOf(matin.trim().toLocaleLowerCase());
      const idxApres = prohibited.indexOf(value.trim().toLocaleLowerCase());
      if (idxMatin < 0 || idxApres < 0) {
        return true;
      } else {
        const newArr = prohibited.slice(0, idxMatin);
        return newArr.indexOf(value.trim().toLocaleLowerCase()) > 0;
      }
    }
    return false;
  };

  const checkChargement = (chargement: ChargementInterface, arrError: ErrorImportInterface[], value: LinesImported, index: number) => {
    if (!chargement.ftl && value.data.plages && !chargement.mplmax) getError(arrError, index, value, "mplmax");
    if (!chargement.ftl && value.data.plages && !chargement.poidsmax) getError(arrError, index, value, "poidsmax");
    if (checker(chargement.apresmidi, chargement.matin)) getError(arrError, index, value, "Apres midi matin");
    if (chargement.ftl && value.data.plages && (chargement.mplmin || chargement.mplmax)) getError(arrError, index, value, "mplmin mplmax");
    if (chargement.ftl && !chargement.poidsmin) getError(arrError, index, value, "poidsmin");
    if (!chargement.ftl && (!chargement.mplmin || chargement.mplmin > Condition.maxMpl || chargement.mplmin < 0))
      getError(arrError, index, value, "mplmin");
    if (!chargement.ftl && chargement.mplmax && (chargement.mplmax > Condition.maxMpl || chargement.mplmax <= 0))
      getError(arrError, index, value, "mplmax");
    if (!chargement.ftl && value.data.plages && chargement.mplmax && chargement.mplmin && chargement.mplmax <= chargement.mplmin)
      getError(arrError, index, value, "mplmin mplmax");
    if (!chargement.poidsmin || chargement.poidsmin > Condition.maxPos || chargement.poidsmin < 0) getError(arrError, index, value, "poidsmin");
    if (!chargement.ftl && value.data.plages && chargement.poidsmax && (chargement.poidsmax > Condition.maxPos || chargement.poidsmax <= 0))
      getError(arrError, index, value, "poidsmax");
    if (!chargement.ftl && value.data.plages && chargement.poidsmax && chargement.poidsmin && chargement.poidsmax <= chargement.poidsmin)
      getError(arrError, index, value, "poidsmin poidsmax");
    if (!chargement.ftl && chargement.palettesmin && (chargement.palettesmin > Condition.maxPallet || chargement.palettesmin < 0))
      getError(arrError, index, value, "palettesmin");
    if (
      !chargement.ftl &&
      value.data.plages &&
      chargement.palettesmax &&
      (chargement.palettesmax > Condition.maxPallet || chargement.palettesmax <= 0)
    )
      getError(arrError, index, value, "palettesmax");
    if (chargement.palettesmin && !chargement.palettesmax) {
      getError(arrError, index, value, "palettesmax");
    }
    if (!chargement.palettesmin && chargement.palettesmax) {
      getError(arrError, index, value, "palettesmin");
    }
    if (!chargement.ftl && value.data.plages && chargement.palettesmax && chargement.palettesmin && chargement.palettesmax <= chargement.palettesmin)
      getError(arrError, index, value, "palettesmin palettesmax");
    if (!chargement.dureechargement || chargement.dureechargement < 0 || chargement.dureechargement > Condition.maxDureeChar)
      getError(arrError, index, value, "dureechargement");
  };

  const checkAlloue = (alloue: AlloueInterface, arrError: ErrorImportInterface[], value: LinesImported, index: number) => {
    const frecen = listUnit.find((value) => value.nom?.toLocaleLowerCase() === alloue.volumefrequencynom?.toLocaleLowerCase());
    const unity = listUnit.find((dev) => dev.nom && ["lot", "tonne", "palette"].includes(dev.nom) && dev.nom?.toLocaleLowerCase() === alloue.volumeunitynom?.toLocaleLowerCase());
    if (!frecen) {
      getError(arrError, index, value, "volumefrequencynom");
    }
    if (!unity) {
      getError(arrError, index, value, "volumeunitynom");
    }
    alloue.volumeunityid = unity ? unity.id : 0;
    alloue.volumefrequencyid = frecen ? frecen.id : 0;
    alloue.taux = false;
    delete alloue.volumefrequencynom;
    delete alloue.volumeunitynom;
    if (!alloue.volumetotalclient) {
      getError(arrError, index, value, `volumetotalclient`);
    } else {
      if (!alloue.taux) {
        if (!alloue.volumealloue) {
          getError(arrError, index, value, `volumealloue`);
        } else {
          alloue.tauxalloue = Math.floor((alloue.volumealloue * 100) / alloue.volumetotalclient);
        }
      }
    }
    if (!alloue.volumetotalclient || alloue.volumetotalclient > Condition.maxVolume || alloue.volumetotalclient < 0)
      getError(arrError, index, value, "volumetotalclient");
    if (!alloue.volumealloue || alloue.volumealloue > Condition.maxVolume || alloue.volumealloue <= 0)
      getError(arrError, index, value, "volumealloue");
    if (alloue.volumealloue && alloue.volumetotalclient && alloue.volumetotalclient <= alloue.volumealloue)
      getError(arrError, index, value, "volumealloue volumetotalclient");
    if (!alloue.tauxalloue || alloue.tauxalloue > 100 || alloue.tauxalloue <= 0) getError(arrError, index, value, "tauxalloue");
  };

  const checkBudget = (budget: BudgetInterface, arrError: ErrorImportInterface[], value: LinesImported, index: number, arrData: LinesImported[]) => {
    const frecen = listDevise.find((dev) => dev.nom?.toLocaleLowerCase() === budget.prixcontractualisedevisenom?.toLocaleLowerCase());
    if (index !== 0 && arrData[0] && frecen && frecen.id !== arrData[0].data?.ligneBudget?.prixcontractualisedeviseid) {
      arrData.forEach((it, idx) => {
        getError(arrError, idx, it, "Prix Contractualise Devise Nom");
      })
    }
    const unity = listUnit.find((dev) => dev.nom && ["lot", "tonne", "palette"].includes(dev.nom) && dev.nom?.toLocaleLowerCase() === budget.prixcontractualiseunitynom?.toLocaleLowerCase());
    budget.prixcontractualisedeviseid = frecen ? frecen.id : 0;
    budget.prixcontractualiseunityid = unity ? unity.id : 0;
    delete budget.prixcontractualisedevisenom;
    delete budget.prixcontractualiseunitynom;
    const margeVisee = budget.margevisee;
    if (margeVisee) {
      let calcBudget = Number(budget.prixcontractualise) / (Number(margeVisee) / 100 + 1);
      const currentChargement: ChargementInterface | undefined = value.data.ligneChargement;
      if (currentChargement) {
        if (budget.prixcontractualiseunityid === 2) {
          calcBudget = (Number(budget.prixcontractualise) * Number(currentChargement.poidsmin)) / (Number(margeVisee) / 100 + 1);
        }
        if (budget.prixcontractualiseunityid === 3) {
          calcBudget = (Number(budget.prixcontractualise) * Number(currentChargement.palettesmin)) / (Number(margeVisee) / 100 + 1);
        }
      }
      budget.budgettransport = parseFloat(calcBudget.toFixed(0));
    }
    if (
      value.data.ligneChargement &&
      budget.prixcontractualiseunityid === 3 &&
      ((value.data.ligneChargement.ftl && !value.data.ligneChargement.palettesmin) ||
        (!value.data.ligneChargement.ftl && !value.data.ligneChargement.palettesmin))
    )
      getError(arrError, index, value, "prix contractualise unityid");
    if (!budget.prixcontractualise || budget.prixcontractualise > Condition.maxPrix || budget.prixcontractualise < 0)
      getError(arrError, index, value, "prixcontractualise");
    if (!budget.prixcontractualise || budget.prixcontractualise > Condition.maxPrix || budget.prixcontractualise < 0)
      getError(arrError, index, value, "prixcontractualise");
    if (!budget.margevisee || budget.margevisee > Condition.maxMargevisee || budget.margevisee <= 0) getError(arrError, index, value, "margevisee");
  };

  const checkCouts = (couts: CoutsAdditionnelInterface[], arrError: ErrorImportInterface[], value: LinesImported, index: number, arrData: LinesImported[]) => {
    couts.forEach((cout) => {
      // cout.devisenom = value.data.chargements.
      if (cout.devisenom) {
        if (!cout.type) getError(arrError, index, value, `Couts Additionnel Type`);
        if (!cout.montant) getError(arrError, index, value, `Couts Additionnel Montant`);
      }
      if (cout.type) {
        const type = listCountOptions.find((opt) => opt.toLocaleLowerCase() === cout.type?.toLocaleLowerCase());
        if (!type) {
          getError(arrError, index, value, `Couts Additionnel Type`);
        }
        if (!cout.montant) getError(arrError, index, value, `Couts Additionnel Montant`);
      }
      if (cout.montant) {
        if (!cout.type) getError(arrError, index, value, `Couts Additionnel Type`);
      }
      if (arrData[0] && arrData[0].data.ligneBudget?.prixcontractualisedeviseid) {
        cout.deviseid = arrData[0].data.ligneBudget.prixcontractualisedeviseid
      }
      delete cout.devisenom;
    });
  };

  const checkContact = (key: string, type: string, data: LinesImported, arrError: ErrorImportInterface[], value?: string, codeId?: number) => {
    switch (key) {
      case SelectContactValue.EDI:
        if (value) {
          getError(
            arrError,
            data.data.index ? data.data.index - 1 : 0,
            data,
            `${type === "rep" ? "Reception" : type === "char" ? "Chargement" : "Livraison"} Value`
          );
        }
        if (codeId) {
          getError(
            arrError,
            data.data.index ? data.data.index - 1 : 0,
            data,
            `${type === "rep" ? "Reception" : type === "char" ? "Chargement" : "Livraison"} Code Id`
          );
        }
        break;
      case SelectContactValue.MAIL:
        if (!value) {
          getError(
            arrError,
            data.data.index ? data.data.index - 1 : 0,
            data,
            `${type === "rep" ? "Reception" : type === "char" ? "Chargement" : "Livraison"} Value`
          );
        }
        if (value) {
          if (!validateEmail(value)) {
            getError(
              arrError,
              data.data.index ? data.data.index - 1 : 0,
              data,
              `${type === "rep" ? "Reception" : type === "char" ? "Chargement" : "Livraison"} Value`
            );
          }
        }
        if (codeId) {
          getError(
            arrError,
            data.data.index ? data.data.index - 1 : 0,
            data,
            `${type === "rep" ? "Reception" : type === "char" ? "Chargement" : "Livraison"} Code Id`
          );
        }
        break;
      case SelectContactValue.PLATEFORME:
        if (!value) {
          getError(
            arrError,
            data.data.index ? data.data.index - 1 : 0,
            data,
            `${type === "rep" ? "Reception" : type === "char" ? "Chargement" : "Livraison"} Value`
          );
        }
        if (value) {
          if ((!value.startsWith('http://') || !value.startsWith('https://')) && !isValidHttpUrl(value)) {
            getError(
              arrError,
              data.data.index ? data.data.index - 1 : 0,
              data,
              `${type === "rep" ? "Reception" : type === "char" ? "Chargement" : "Livraison"} Value`
            );
          }
        }
        if (codeId) {
          getError(
            arrError,
            data.data.index ? data.data.index - 1 : 0,
            data,
            `${type === "rep" ? "Reception" : type === "char" ? "Chargement" : "Livraison"} Code Id`
          );
        }
        break;
      case SelectContactValue.TELEPHONE:
        if (!value) {
          getError(
            arrError,
            data.data.index ? data.data.index - 1 : 0,
            data,
            `${type === "rep" ? "Reception" : type === "char" ? "Chargement" : "Livraison"} Value`
          );
        }
        if (!codeId) {
          getError(
            arrError,
            data.data.index ? data.data.index - 1 : 0,
            data,
            `${type === "rep" ? "Reception" : type === "char" ? "Chargement" : "Livraison"} Code Id`
          );
        } else {
          const contry = countryCode.find((value) => codeId && value.code === `+${codeId}`);
          if (!contry) {
            getError(
              arrError,
              data.data.index ? data.data.index - 1 : 0,
              data,
              `${type === "rep" ? "Reception" : type === "char" ? "Chargement" : "Livraison"} Code Id`
            );
          } else {
            if (type === "char") data.data.chargementContactCodeId = contry.id;
            if (type === "rep") data.data.receptionCodeId = contry.id;
            if (type === "liv") data.data.livraisonCodeId = contry.id;
          }
        }
        break;
      default:
        getError(
          arrError,
          data.data.index ? data.data.index - 1 : 0,
          data,
          `${type === "rep" ? "Reception" : type === "char" ? "Chargement Contact" : "Livraison"}`
        );
        break;
    }
  };

  const getDataShow = async (arrData: LinesImported[], arrError: ErrorImportInterface[], arrErrorId: ErrorImportInterface[]) => {
    for (const value of arrData) {
      const lieuxDepart = listLieux.find((cl) => (cl.code?.toLocaleLowerCase() === value.data.lieuxDepartcode?.toLocaleLowerCase()) && cl.chargementBoleen);
      // no required
      if (lieuxDepart) {
        value.data.lieuxDepartId = lieuxDepart.id;
          const paysDept = listPays.find((cl) => cl.id === lieuxDepart.paysId);
          if (paysDept) {
            value.data.paysDepart = paysDept;
            value.data.paysDepartId = paysDept.id;
            value.data.paysDepartCode = paysDept.code;
          }
          const depDep = listDepartement.find((value) => value.id === lieuxDepart.departementId);
          if (depDep) {
            value.data.departementDepart = depDep;
            value.data.departementDepartId = depDep.id;
          }
          const villeDep = listVille.find((value) => value.id === lieuxDepart.villeId);
          if (villeDep) {
            value.data.villeDepart = villeDep.nom;
            if (!value.data.codepostaldepart) {
              value.data.codepostaldepart = villeDep.codePostal;
            }
          }
          value.data.adresseDepart = lieuxDepart.adresse;
      }
      const lieuxArrivee = listLieux.find((cl) => (cl.code?.toLocaleLowerCase() === value.data.lieuxArrivercode?.toLocaleLowerCase()) && cl.livraisonBoleen);
      // no required
      if (lieuxArrivee) {
        value.data.lieuxArriveeId = lieuxArrivee.id;
          const paysArr = listPays.find((cl) => cl.id === lieuxArrivee.paysId);
          if (paysArr) {
            value.data.paysArrivee = paysArr;
            value.data.paysArriveeId = paysArr.id;
            value.data.paysArriveeCode = paysArr.code;
          }
          const depArr = listDepartement.find((value) => value.id === lieuxArrivee.departementId);
          if (depArr) {
            value.data.departementArrivee = depArr;
            value.data.departementArriveeId = depArr.id;
          }
          const villeArr = listVille.find((value) => value.id === lieuxArrivee.villeId);
          if (villeArr) {
            value.data.villeArrivee = villeArr.nom;
            if (!value.data.codepostalarrivee) {
              value.data.codepostalarrivee = villeArr.codePostal;
            }
          }
          value.data.adresseArrivee = lieuxArrivee.adresse;
      }
      if (value.data.paysDepartCode) {
        const paysDepart = listPays.find((cl) => cl.code?.toLocaleLowerCase() === value.data.paysDepartCode?.toLocaleLowerCase());
        const paysArrivee = listPays.find((cl) => cl.code?.toLocaleLowerCase() === value.data.paysArriveeCode?.toLocaleLowerCase());
        if (paysDepart) {
          value.data.paysDepart = paysDepart;
          value.data.paysDepartId = paysDepart.id;
        }
        if (paysArrivee) {
          value.data.paysArrivee = paysArrivee;
          value.data.paysArriveeId = paysArrivee.id;
        }
      }
      const departementDepart = listDepartement.find((cl) => cl.code == value.data.departementDepartCode  && cl.paysId === value.data.paysDepartId);
      if (departementDepart) {
        value.data.departementDepart = departementDepart;
      }
      const departementArrivee = listDepartement.find((cl) => cl.code == value.data.departementArriveeCode && cl.paysId === value.data.paysArriveeId);
      if (departementArrivee) {
        value.data.departementArrivee = departementArrivee;
      }

      const distanceArrive: PTVLocation = {
            street: value.data.adresseArrivee,
            state: value.data.departementArriveeCode,
            country: value.data.paysArrivee ? value.data.paysArrivee.nom : "",
            postalCode: value.data.codepostalarrivee
          }
      const distanceDep: PTVLocation = {
          street: value.data.adresseDepart,
          state: value.data.departementDepartCode,
          country: value.data.paysDepart ? value.data.paysDepart.nom : "",
          postalCode: value.data.codepostaldepart
        };
      if (!value.data.distanceReelle) {
        if (value.data.codepostalarrivee && value.data.paysArrivee && value.data.paysArrivee.nom) {
          // {
          //   country: paysData.find((item) => item.id === values.paysId)?.nom,
          //       state: undefined,
          //     postalCode: values.codePostal,
          //     locality: newVille,
          //     street: values.adresse,
          // }
          const department = listDepartement.find(it => it.paysId === value.data.paysArriveeId && it.code === value.data.codepostalarrivee?.slice(0, 2) && it.code === value.data.departementArriveeCode);
          if (!department && !value.data.distanceReelle) {
            getError(arrError, arrData.indexOf(value), value, "codepostalarriveecode");
          }
          distanceArrive.country = value.data.paysArrivee.nom;
          distanceArrive.postalCode = value.data.codepostalarrivee;
          // distanceArrive = `${value.data.paysArrivee.nom} ${value.data.codepostalarrivee}`;
        } else if (value.data.villeArriveeNom && !value.data.codepostalarrivee) {
          const departementArrivee = listDepartement.find((cl) => cl.code === value.data.departementArriveeCode && cl.paysId === value.data.paysArriveeId);
          if (departementArrivee) {
            distanceArrive.country = value.data.paysArrivee ? value.data.paysArrivee.nom : "";
            distanceArrive.locality = value.data.villeArriveeNom;
            distanceArrive.state = departementArrivee.code;
            // distanceArrive = `${value.data.paysArrivee ? value.data.paysArrivee.nom : ""} ${departementArrivee.code} ${value.data.villeArriveeNom}`;
          }
        } else if (!value.data.villeArriveeNom && !value.data.codepostalarrivee) {
          const departementArrivee = listDepartement.find((cl) => cl.code === value.data.departementArriveeCode && cl.paysId === value.data.paysArriveeId);
          if (departementArrivee) {
            const villeDep = listVille.find((value) => value.departementId === departementArrivee.id && value.capitale);
            if (villeDep) {
              distanceArrive.locality = villeDep.nom;
              // distanceArrive = villeDep.nom;
            } else {
              getError(arrError, arrData.indexOf(value), value, "villeArriveeNomDistance");
            }
          }
        }
        if (value.data.codepostaldepart && value.data.paysDepart && value.data.paysDepart.nom) {
          distanceDep.country = value.data.paysDepart.nom;
          distanceDep.postalCode = value.data.codepostaldepart;
          const department = listDepartement.find(it => it.paysId === value.data.paysDepartId && it.code === value.data.codepostaldepart?.slice(0, 2) && it.code === value.data.departementDepartCode);
          if (!department && !value.data.distanceReelle) {
            getError(arrError, arrData.indexOf(value), value, "codepostaldepartcode");
          }
          // distanceDep = `${value.data.paysDepart.nom} ${value.data.codepostaldepart}`;
        } else if (value.data.villeDepartNom && !value.data.codepostaldepart) {
          const departementDep = listDepartement.find((cl) => cl.code === value.data.departementDepartCode && cl.paysId === value.data.paysDepartId);
          if (departementDep) {
            distanceDep.country = value.data.paysDepart ? value.data.paysDepart.nom : "";
            distanceDep.locality = value.data.villeDepartNom;
            distanceDep.state = departementDep.code;
            // distanceDep = `${value.data.paysDepart ? value.data.paysDepart.nom : ""} ${departementDep.code} ${value.data.villeDepartNom}`;
          }
        } else if (!value.data.villeDepartNom && !value.data.codepostaldepart) {
          const departementDep = listDepartement.find((cl) => cl.code === value.data.departementDepartCode && cl.paysId === value.data.paysDepartId);
          if (departementDep) {
            const villeDep = listVille.find((value) => value.departementId === departementDep.id && value.capitale);
            if (villeDep) {
              // distanceDep = `${value.data.paysDepart ? value.data.paysDepart.nom : ''} ${departementDep.code} ${villeDep.nom}`;
              distanceDep.locality = villeDep.nom;
            } else {
              getError(arrError, arrData.indexOf(value), value, "villeArriveeNomDistance");
            }
          }
        }
        if (distanceArrive && distanceDep) {
          await fetchMap(distanceArrive, distanceDep, value, arrError, arrData.indexOf(value));
        }
      }
    }

    const lines = arrData.map((value, idx) => {
      const obj: CompleteLines = { ...value.data };
      if (value.data.ligneid) {
        const existed = allCaracteristiques.find((it) => it.idLigne === value.data.ligneid);
        if (existed) {
          getError(arrError, idx, value, "Ligne Id");
        }
      }
      if (value.data.tauxParcPropre && (value.data.tauxParcPropre < 1 || value.data.tauxParcPropre > 100)) {
        getError(arrError, idx, value, "Taux ParcPropre");
      }
      if (value.data.reception) {
        checkContact(value.data.reception, "rep", value, arrError, value.data.receptionValue, value.data.receptionCodeId);
      }
      if (value.data.chargementContact) {
        checkContact(value.data.chargementContact, "char", value, arrError, value.data.chargementContactValue, value.data.chargementContactCodeId);
      }
      if (value.data.livraison) {
        checkContact(value.data.livraison, "liv", value, arrError, value.data.livraisonValue, value.data.livraisonCodeId);
      }
      if (value.data.toutes && value.data.delaiAcceptation) {
        getError(arrError, idx, value, "Delai Acceptation");
      }
      if (!value.data.toutes && !value.data.delaiAcceptation) {
        getError(arrError, idx, value, "Delai Acceptation");
      }
      if (value.data.typeGasoil && typeGas.indexOf(value.data.typeGasoil) < 0) {
        getError(arrError, idx, value, "typeGasoil");
      }
      if (value.data.typeTunnel && typeTunnel.indexOf(value.data.typeTunnel) < 0) {
        getError(arrError, idx, value, "typeTunnel");
      }
      if (value.data.typeGasoil && value.data.valueGasoil) {
        if (value.data.typeGasoil === typeGas[0]) {
          if (value.data.valueGasoil < 0 || value.data.valueGasoil > 1000) {
            getError(arrError, idx, value, "valueGasoil");
          }
        } else {
          if (value.data.valueGasoil < 0 || value.data.valueGasoil > 10) {
            getError(arrError, idx, value, "valueGasoil");
          }
        }
      }
      if (value.data.typeGasoil && value.data.typeGasoil === typeGas[0]) {
        if (value.data.valueGasoil && (value.data.valueGasoil < 0 || value.data.valueGasoil > 1000)) {
          getError(arrError, idx, value, "valueGasoil");
        }
      }
      if (value.data.typeGasoil && value.data.typeGasoil !== typeGas[0]) {
        if (value.data.valueGasoil && (value.data.valueGasoil < 0 || value.data.valueGasoil > 10)) {
          getError(arrError, idx, value, "valueGasoil");
        }
      }
      if (value.data.partGasoil && (value.data.partGasoil <= 0 || value.data.partGasoil > 100)) {
        getError(arrError, idx, value, "Part Gasoil");
      }
      if (value.data.typeTunnel) {
        if (value.data.plageMin === undefined) {
          getError(arrError, idx, value, "plageMin");
        }
        if (value.data.plageMax === undefined) {
          getError(arrError, idx, value, "plageMax");
        }
        if (value.data.plageMax && (value.data.plageMax > 100 || value.data.plageMax < 0)) {
          getError(arrError, idx, value, "plageMax");
        }
        if (value.data.plageMin) {
          if (value.data.plageMin > 0) {
            getError(arrError, idx, value, "plageMin");
          }
          value.data.plageMin = Math.abs(value.data.plageMin);
          if ((value.data.plageMin > 100)) {
            getError(arrError, idx, value, "plageMin");
          }
        }
        if (value.data.plageMax === 0 && value.data.plageMin === 0) {
          getError(arrError, idx, value, "plageMin plageMax");
        }
        if (!value.data.frequenceRevisionGasoil) {
          value.data.frequenceRevisionGasoil = "Mensuel";
        }
        if (value.data.frequenceRevisionGasoil && !frequenceRevision.includes(value.data.frequenceRevisionGasoil)) {
          getError(arrError, idx, value, "frequenceRevisionGasoil");
        }
        if (value.data.frequenceRevisionTunnel && !frequenceRevision.includes(value.data.frequenceRevisionTunnel)) {
          getError(arrError, idx, value, "frequenceRevisionTunnel");
        }
      }
      const tunnel = {
        frequenceRevisionTunnel: value.data.frequenceRevisionTunnel,
        typeTunnel: value.data.typeTunnel,
        plageMin: value.data.plageMin,
        plageMax: value.data.plageMax,
      }
      const arrProp = [];
      for (const property of Object.keys(tunnel)) {
        if (tunnel[property as keyof typeof tunnel] === undefined || tunnel[property as keyof typeof tunnel] === '') {
          arrProp.push(property);
        }
      }
      if (arrProp.length > 0 && arrProp.length < 4) {
        arrProp.forEach(it => {
          getError(arrError, idx, value, it);
        })
      }
      if (!value.data.dateFin && value.data.duree && !Number.isInteger(value.data.duree)) {
        getError(arrError, idx, value, "duree");
      }
      if (value.data.dateInit && checkYear(value.data.dateInit)) {
        getError(arrError, idx, value, "dateInit");
      }
      if (value.data.dateInit && value.data.dateFin && (checkYear(value.data.dateFin) || checkDateFin(value.data.dateInit, value.data.dateFin))) {
        getError(arrError, idx, value, "dateFin");
      }
      if (value.data.dateInit && value.data.dateFin) {
        if (new Date(value.data.dateFin).getTime() < new Date(value.data.dateInit).getTime()) {
          getError(arrError, idx, value, "dateInit dateFin");
        }
      }

      if (value.data.dateFin && value.data.typeDuree && value.data.duree) {
        getError(arrError, idx, value, "dateFin typeDuree duree");
      }
      if (!value.data.dateFin) {
        if (!value.data.typeDuree || !value.data.duree || value.data.typeDuree === "" || !checkDuree(value.data.typeDuree, value.data.duree)) {
          getError(arrError, idx, value, "duree");
        }
      } else {
        value.data.typeDuree = undefined;
        value.data.duree = undefined;
      }
      const client = clientList.find((cl) => cl.code == value.data.clientCode);
      if (client) {
        value.data.client = client;
        value.data.clientId = client.id;
        obj.client = client;
      } else {
        getError(arrError, idx, value, "clientCode");
      }
      if (value.data.paysDepartCode) {
        const paysDepart = listPays.find((cl) => cl.code?.toLocaleLowerCase() === value.data.paysDepartCode?.toLocaleLowerCase());
        const paysArrivee = listPays.find((cl) => cl.code?.toLocaleLowerCase() === value.data.paysArriveeCode?.toLocaleLowerCase());
        if (paysDepart) {
          obj.codepaysdepart = paysDepart.code;
          value.data.paysDepart = paysDepart;
          value.data.paysDepartId = paysDepart.id;
        } else {
          getError(arrError, idx, value, "paysDepartCode");
        }
        if (paysArrivee) {
          obj.codepaysarrivee = paysArrivee.code;
          value.data.paysArrivee = paysArrivee;
          value.data.paysArriveeId = paysArrivee.id;
        } else {
          getError(arrError, idx, value, "paysArriveeCode");
        }
      } else {
        getError(arrError, idx, value, "paysDepartCode");
      }
      const departementDepart = listDepartement.find((cl) => cl.code == value.data.departementDepartCode && cl.paysId === value.data.paysDepartId);
      if (!departementDepart) {
        getError(arrError, idx, value, "departementDepartCode");
      } else {
        value.data.departementDepartId = departementDepart.id;
        value.data.departementDepart = departementDepart;
        if (value.data.codepostaldepart) {
          const villes = listVille.filter(value => value.departementId = departementDepart.id).find(el => el.codePostal === value.data.codepostaldepart );
          if (!villes) {
            getError(arrError, idx, value, "codepostaldepart");
          }
        }
      }
      const departementArrivee = listDepartement.find((cl) => cl.code == value.data.departementArriveeCode && cl.paysId === value.data.paysArriveeId);
      if (!departementArrivee) {
        getError(arrError, idx, value, "departementArriveeCode");
      } else {
        value.data.departementArriveeId = departementArrivee.id;
        value.data.departementArrivee = departementArrivee;
        if (value.data.codepostalarrivee) {
          const villes = listVille.filter(value => value.departementId = departementArrivee.id).find(el => el.codePostal === value.data.codepostalarrivee);
          if (!villes) {
            getError(arrError, idx, value, "codepostalarrivee");
          }
        }
      }
      // no required
      if (value.data.villeDepartNom) {
        obj.codedepart = value.data.villeDepartNom;
        obj.villeDepart = value.data.villeDepartNom;
        value.data.villeDepart = value.data.villeDepartNom;
      }
      // no required
      if (value.data.villeArriveeNom) {
        obj.codearrivee = value.data.villeArriveeNom;
        obj.villeArrivee = value.data.villeArriveeNom;
        value.data.villeArrivee = value.data.villeArriveeNom;
      }
      // no required
      if (value.data.codepostaldepart) {
        obj.codepostaldepart = value.data.codepostaldepart;
      }
      // no required
      if (value.data.codepostalarrivee) {
        obj.codepostalarrivee = value.data.codepostalarrivee;
      }
      if (value.data.distance && value.data.distance > Condition.maxDistance) {
        getError(arrError, idx, value, "Distance");
      }

      if (value.data.distanceReelle && value.data.distanceReelle > Condition.maxDistance) {
        getError(arrError, idx, value, "Distance Reelle");
      }
      // calculate distance
      const societe = societeList.find((cl) => cl.code == value.data.societeCode);
      if (!societe) {
        getError(arrError, idx, value, "societeCode");
      } else {
        value.data.societeId = societe.id;
      }
      const clientFac = clientFactureList.find((cl) => cl.code == value.data.clientFactureCode);
      const societeFacture = societeFactureList.find((cl) => cl.code == value.data.societeFactureCode);
      const isValidGasoil = arrError.find(it => it.key === 'typeGasoil' && it.index === idx);
      const indiceGasoil = isValidGasoil ? indiceGasoils.find((cl) => cl.code == value.data.indiceGasoilCode) :
          indiceGasoils.find((cl) => cl.code == value.data.indiceGasoilCode && cl.type === value.data.typeGasoil);
      const commercialId = commercial.find((cl) => cl.code == value.data.commercialCode);
      const agence = agenceList.find((cl) => cl.code == value.data.agenceCode);
      const marchandiseid = marchandise.find((cl) => cl.code == value.data.marchandiseCode);
      const typeVehiculeId = typeVihicle.find((cl) => cl.code == value.data.typeVehiculeCode);
      const modeTransportId = modeTransport.find((cl) => cl.code == value.data.modeTransportCode);
      const chargement = value.data.ligneChargement;
      const alloue = value.data.ligneAlloue;
      const budget = value.data.ligneBudget;
      const couts = value.data.ligneCout;
      if (chargement) {
        obj.poidsmin = chargement.poidsmin?.toString();
        obj.poidsmax = chargement.poidsmax?.toString();
        obj.palettesmin = chargement.palettesmin?.toString();
        obj.palettesmax = chargement.palettesmax?.toString();
      }
      if (alloue) {
        obj.volumealloue = alloue.volumealloue;
        const listVolumeUnitId = listUnit.filter((el) => el.nom && alloue.volumeunitynom === el.nom && ["lot", "tonne", "palette"].includes(el.nom));
        if (listVolumeUnitId.length > 0) {
          obj.volumeunite = listVolumeUnitId[0].nom;
        } else {
          getError(arrError, idx, value, "volumeunitynnom");
        }
        const listVolumeFreqId = listUnit.filter((el) => alloue.volumefrequencynom?.toLocaleLowerCase() === el.nom?.toLocaleLowerCase());
        if (listVolumeFreqId.length <= 0) {
          getError(arrError, idx, value, "Volume Frequency");
        }
      }
      if (budget) {
        const listBudgetUnitId = listUnit.filter((el) => budget.prixcontractualiseunitynom === el.nom && el.nom && ["lot", "tonne", "palette"].includes(el.nom));
        const listBudgetDeviseId = listDevise.filter((el) => budget.prixcontractualisedevisenom?.toLocaleLowerCase() === el.nom?.toLocaleLowerCase());
        obj.budgettransport = budget.budgettransport;
        obj.prixcontractualise = budget.prixcontractualise;
        obj.prixcontractualiseunity = listBudgetUnitId.length > 0 ? listBudgetUnitId[0].nom : "";
        obj.prixcontractualisedevise = listBudgetDeviseId.length > 0 ? listBudgetDeviseId[0].nom : "";
        obj.margevisee = budget.margevisee ? budget.margevisee.toFixed(2) : "";
        if (listBudgetUnitId.length === 0) {
          getError(arrError, idx, value, "Prix Contractualise Unity Nom");
        }
        if (listBudgetDeviseId.length === 0) {
          getError(arrError, idx, value, "Prix Contractualise Devise Nom");
        }
      }
      if (couts && value.data.checkCoutsAdditionnels) {
        const listCoutsDeviseId = listDevise.filter((el) => couts.devisenom?.toLocaleLowerCase() === el.nom?.toLocaleLowerCase());
        if (listCoutsDeviseId.length === 0) {
          getError(arrError, idx, value, "Couts Additionnel Devise Nom");
        }
      }
      if (!clientFac) {
        getError(arrError, idx, value, "clientFactureCode");
      } else {
        value.data.clientFactureId = clientFac.id;
      }
      if (!societeFacture) {
        getError(arrError, idx, value, "societeFactureCode");
      } else {
        value.data.societeFactureId = societeFacture.id;
      }
      if (!indiceGasoil) {
        getError(arrError, idx, value, "indiceGasoilCode");
      } else  {
        value.data.indiceGasoilId = indiceGasoil.id;
        value.data.indiceGasoil = indiceGasoil;
      }
      if (!commercialId) {
        getError(arrError, idx, value, "commercialCode");
      } else {
        value.data.commercialId = commercialId.id;
      }
      if (!agence && value.data.agenceCode !== 0) {
        getError(arrError, idx, value, "agenceCode");
      }
      if (agence && societe) {
        value.data.agenceId = agence.id
        const exploitant = exploitantList.find(
          (cl) => cl.exploitant && cl.exploitant.code == value.data.exploitantCode && cl.agenceId === agence.id && cl.societeId === societe?.id
        );
        if (!exploitant && value.data.exploitantCode && value.data.exploitantCode !== 0) {
          getError(arrError, idx, value, "exploitantCode");
        }
      }
      if (!marchandiseid) {
        getError(arrError, idx, value, "marchandiseCode");
      } else {
        obj.marchandise = marchandiseid;
        value.data.marchandiseId = marchandiseid.id;
      }
      if (!typeVehiculeId) {
        getError(arrError, idx, value, "typeVehiculeCode");
      } else {
        obj.vehicule = typeVehiculeId.nom;
        value.data.typeVehiculeId = typeVehiculeId.id;
        obj.typeVehiculeId = typeVehiculeId.id;
      }
      if (!modeTransportId) {
        getError(arrError, idx, value, "modeTransportCode");
      } else {
        value.data.modeTransportId  = modeTransportId.id;
      }
      if (value.data.ligneChargement) {
        checkChargement(value.data.ligneChargement, arrError, value, idx);
      }
      if (value.data.ligneAlloue) {
        checkAlloue(value.data.ligneAlloue, arrError, value, idx);
      }
      if (value.data.ligneBudget) {
        checkBudget(value.data.ligneBudget, arrError, value, idx, arrData);
      }
      if (value.data.couts) {
        checkCouts(value.data.couts, arrError, value, idx, arrData);
      }
      value.data.parcPropreContractuel = !!(value.data.tauxParcPropre && value.data.tauxParcPropre > 0);
      value.data.checkCoutsAdditionnels = value.data.couts  && value.data.couts.length > 0;
      if (!value.data.ligneid) {
        const ddate = value.data.dateInit ? new Date(value.data.dateInit) : new Date();
        let ligneId =
          value.data.client?.code +
          moment
            .unix(ddate.getTime() / 1000)
            .format("YYMM")
            .toString() +
          value.data.paysDepart?.code?.substring(0, 2) +
          value.data.departementDepart?.code?.substring(0, 2) +
          value.data.paysArrivee?.code?.substring(0, 2) +
          value.data.departementArrivee?.code?.substring(0, 2);
        ligneId = ligneId.substring(0, 16) + makeRandom(4).toLocaleUpperCase();
        value.data.ligneid = ligneId;
        obj.ligneid = ligneId;
      }
      return obj;
    });
    arrData.forEach((value) => {
      const exits = arrError.find((err) => err.index === value.data.index);
      if (exits && !value.error.error) {
        value.error = exits;
      }
      const exitsId = arrErrorId.find((err) => err.index === value.data.index);
      if (exitsId && !value.error.error) {
        value.error = exitsId;
      }
    });
    let uniErr = uniqBy(arrError, (item) => [item.key, item.index].join());
    const uniErrId = uniqBy(arrErrorId, (item) => [item.value].join());
    uniErr = uniErr.concat(uniErrId);
    const success = arrData.filter((el) => !el.error.error);
    setListLineSuccess(success);
    setErrorImport(uniErr);
    setListLine(arrData);
    console.log(lines);
    setListLineShow(lines);
  };

  const getError = (arrError: ErrorImportInterface[], idx: number, value: LinesImported, key: string, uniqueId?: string) => {
    const err = {
      error: true,
      type: "req",
      index: idx + 1,
      key: key,
      uniqueId: uniqueId ? uniqueId : key,
    };
    arrError.push(err);
    value.error = err;
  };

  const getClients = (): Observable<Client[]> => {
    return ClientAPI.fetchAll().pipe(
      tap({
        next: (clients: Client[]) => {
          setClientList(clients);
          setClientFactureList(clients);
        },
      })
    );
  };

  const getSociete = (): Observable<Societe[]> => {
    return SocieteAPI.fetchAll().pipe(
      tap({
        next: (societes: Societe[]) => {
          setSocieteList(societes);
          setSocieteFactureList(societes);
        },
      })
    );
  };

  const getLieux = (): Observable<Lieux[]> => {
    return LieuxAPI.fetchAll().pipe(
      tap({
        next: (lieuxs: Lieux[]) => {
          setListLieux(lieuxs);
        },
      })
    );
  };

  const getPays = (): Observable<Pays[]> => {
    return PayAPI.fetchAll().pipe(
      tap({
        next: (pays: Pays[]) => {
          setListPays(pays);
        },
      })
    );
  };

  const getDepartement = (): Observable<Departement[]> => {
    return DepartementAPI.fetchAll().pipe(
      tap({
        next: (departements: Departement[]) => {
          setListDepartement(departements);
        },
      })
    );
  };

  const getVille = (): Observable<Ville[]> => {
    return VilleAPI.fetchAll().pipe(
      tap({
        next: (villes: Ville[]) => {
          setListVille(villes);
        },
      })
    );
  };

  const getExploitant = (): Observable<Droit[]> => {
    return DroitAPI.fetchAll({
      filter: {
        include: ["societe", "agence", "exploitant"],
      },
    }).pipe(
      tap({
        next: (droits: Droit[]) => {
          setExploitantList(droits);
        },
      })
    );
  };

  const getAgence = () => {
    return AgenceAPI.fetchAll().pipe(
      tap({
        next: (agences: Agence[]) => {
          setAgenceList(agences);
        },
      })
    );
  };

  const getIndiceGasoils = (): Observable<IndiceGasoil[]> => {
    return IndiceGasoilAPI.fetchAll().pipe(
      tap({
        next: (indiceGasoils: IndiceGasoil[]) => {
          setIndiceGasoils(indiceGasoils);
        },
      })
    );
  };

  const getCommercial = (): Observable<IndiceGasoil[]> => {
    return CommercialAPI.fetchAll().pipe(
      tap({
        next: (commercials: Commercial[]) => {
          setCommercial(commercials);
        },
      })
    );
  };

  const getMarchandise = (): Observable<Marchandise[]> => {
    return MarchandiseAPI.fetchAll().pipe(
      tap({
        next: (marchandises: Marchandise[]) => {
          setMarchandise(marchandises);
        },
      })
    );
  };

  const getTypeVihicle = (): Observable<TypeVehicule[]> => {
    return TypeVehiculeAPI.fetchAll().pipe(
      tap({
        next: (typeVehicules: TypeVehicule[]) => {
          setTypeVihicle(typeVehicules);
        },
      })
    );
  };

  const getModeTransport = (): Observable<ModeTransport[]> => {
    return ModeTransportAPI.fetchAll().pipe(
      tap({
        next: (modeTransports: ModeTransport[]) => {
          setModeTransport(modeTransports);
        },
      })
    );
  };

  const getUnity = (): Observable<Unite[]> => {
    return UniteAPI.fetchAll().pipe(
      tap({
        next: (unites: Unite[]) => {
          setListUnit(unites);
        },
      })
    );
  };

  const getDevises = (): Observable<Devise[]> => {
    return DeviseAPI.fetchAll().pipe(
      tap({
        next: (devises: Devise[]) => {
          setListDevise(devises);
        },
      })
    );
  };

  const getCountryCode = () => {
    return CountryCodeAPI.fetchAll().pipe(
      tap({
        next: (countryCodes: CountryCode[]) => {
          setCountryCode(countryCodes);
        },
      })
    );
  };

  const getCaracteristiques = () => {
    return CaracteristiqueAPI.fetchAll({
      filter: {
        fields: ["idLigne"],
      },
    }).pipe(
      tap({
        next: (caracteristiques: Caracteristiques[]) => {
          setAllCaracteristiques(caracteristiques);
        },
      })
    );
  };

  const getCouts = () => {
    return CoutsAdditionnelAPI.get({
      filter: {
        where: {
          isBase: true,
        },
      },
    }).pipe(
        tap({
          next: (res: CoutsAdditionnel[]) => {
            const coutType = res.map((el) => el.type);
            setListCountOptions(coutType as string[]);
          },
        })
    )
  }

  const editStep = () => {
    console.log(12);
  };

  const onSubmit = () => {
    listLine.forEach((ligne) => {
      if (!ligne.error || !ligne.error.error) {
        if (!ligne.data.ligneid) {
          const ddate = ligne.data.dateInit ? new Date(ligne.data.dateInit) : new Date();
          let ligneId =
            ligne.data.client?.code +
            moment
              .unix(ddate.getTime() / 1000)
              .format("YYMM")
              .toString() +
            ligne.data.paysDepart?.code?.substring(0, 2) +
            ligne.data.departementDepart?.code?.substring(0, 2) +
            ligne.data.paysArrivee?.code?.substring(0, 2) +
            ligne.data.departementArrivee?.code?.substring(0, 2);
          ligneId = ligneId.substring(0, 16) + makeRandom(4).toLocaleUpperCase();
          ligne.data.ligneid = ligneId;
        }
      }
    });
    setLoading(true);
    const dataReq = listLine.filter((value) => !value.error || !value.error.error).map((value) => value.data);
    const groupData = chain(dataReq)
      .groupBy((ref: CompleteLines) => ref.ligneid)
      .map((value2) => value2)
      .value()
      .map((it) => {
        let newLigne: CompleteLines = {};
        const chargements: ChargementInterface[] = [];
        const alloues: AlloueInterface[] = [];
        const budgets: BudgetInterface[] = [];
        it.forEach((value) => {
          if (value.ligneChargement) chargements?.push(value.ligneChargement);
          if (value.ligneAlloue) alloues?.push(value.ligneAlloue);
          if (value.ligneBudget) budgets?.push(value.ligneBudget);
          newLigne = {
            ...value,
          };
          console.log(newLigne);
        });
        newLigne.chargements = chargements;
        newLigne.budgets = budgets;
        newLigne.alloues = alloues;
        return newLigne;
      });
    // call api
    CaracteristiqueAPI.insertWithFiles({ data: groupData })
      .pipe(
        doOnSubscribe(() => setLoading(true)), // Show loading on start
        tap({
          next: () => {
            setLoading(false);
            setOpenPopup(true);
          },
          error: () => {
            setErrorImport([]);
            const err = {
              error: true,
              type: "server",
              index: 0,
              key: "",
            };
            setErrorImport([...errorImport, err]);
          },
        }),
        finalize(() => setLoading(false)) // Hide loading on end
      )
      .subscribe({
        error: noop,
      });
  };

  const handleDone = () => {
    navigate(`/dashboard/lines-complete`);
  };

  const fetchMap = async (address: PTVLocation, departd: PTVLocation, value: LinesImported, arrErr: ErrorImportInterface[], index: number) => {
    try {
      const arrAddress = await getLocation(address);
      const depAddress = await getLocation(departd);
      if (arrAddress.length === 0 || depAddress.length === 0) {
        const villeArrive = arrErr.find(it => it.key  === 'villeArriveeNomDistance');
        const villeDep = arrErr.find(it => it.key  === 'villeDepartNom');
        const codePosttalarr = arrErr.find(it => it.key  === 'codepostalarriveecode');
        const codePosttaldep = arrErr.find(it => it.key  === 'codepostaldepartcode');
        if (villeArrive || villeDep && !value.data.distanceReelle) {
          arrErr.push({
            error: true,
            type: "distance",
            index: index + 1,
            key: 'Capitale [Département Départ] and/ or [Département Arrivée] introuvable. Prévenez votre administrateur.',
            uniqueId: 'distance',
          })
        }
        if (codePosttalarr || codePosttaldep && !value.data.distanceReelle) {
          arrErr.push({
            error: true,
            type: "distance",
            index: index + 1,
            key: 'Ce Département n\'est pas cohérent avec le Code Postal.',
            uniqueId: 'distance',
          });
        }
        if (codePosttalarr) arrErr.splice(arrErr.indexOf(codePosttalarr), 1);
        if (codePosttaldep) arrErr.splice(arrErr.indexOf(codePosttaldep), 1);
        if (villeArrive) arrErr.splice(arrErr.indexOf(villeArrive), 1);
        if (villeDep) arrErr.splice(arrErr.indexOf(villeDep), 1);
        if (!value.data.distanceReelle) {
          arrErr.push({
            error: true,
            type: "distance",
            index: index + 1,
            key: 'La distance n\'a pas pu être calculée. Merci de vérifier les champs qui définissent le Départ et l\'Arrivée',
            uniqueId: 'distance',
          });
        }
        // notification.warning({
        //   key: "notCalc",
        //   message: "La distance n'a pas pu être calculée. Merci de vérifier les champs qui définissent le Départ et l'Arrivée",
        //   placement: "top",
        // });
      } else {
        const location = arrAddress[0].referencePosition;
        const locationDes = depAddress[0].referencePosition;
        const origins = `${location.latitude},${location.longitude}`;
        console.log(origins);
        const destinations = `${locationDes.latitude},${locationDes.longitude}`;
        console.log(destinations);
        value.data.distance = await getDistance(origins, destinations);
        if (value.data.distance === 0 && !value.data.distanceReelle) {
          arrErr.push({
            error: true,
            type: "distance",
            index: index + 1,
            key: 'La distance n\'a pas pu être calculée. Merci de vérifier les champs qui définissent le Départ et l\'Arrivée',
            uniqueId: 'distance',
          });
        }
        console.log(value.data.distance, "getdistance");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getLocation = async (address: PTVLocation) => {
    const response = await axios({
      method: "GET",
      url: "https://api.myptv.com/geocoding/v1/locations/by-address",
      headers: {
        ApiKey: "RVVfNzUwYzJlMjZlYmQ4NDA5YzhiMjg3YTc4OTY5NzMxMTM6OWQxYjRmY2MtZTdiMi00NjE1LThiMjEtZmI5OGRhMWYyZWJk",
      },
      params: address,
    })
    return response.data.locations;
  };

  const getDistance = async (origins: string, destinations: string) => {
    const res = await axios({
      method: "GET",
      url: `https://api.myptv.com/routing/v1/routes?waypoints=${destinations}&waypoints=${origins}&options[trafficMode]=AVERAGE`,
      headers: {
        ApiKey: "RVVfNzUwYzJlMjZlYmQ4NDA5YzhiMjg3YTc4OTY5NzMxMTM6OWQxYjRmY2MtZTdiMi00NjE1LThiMjEtZmI5OGRhMWYyZWJk",
      },
    })
    return +(+res.data.distance / 1000).toFixed(2);
  };

  const switchMode = (value: boolean) => {
    setIsCopy(value);
    setErrorImport([]);
    setListLine([]);
    setListLineSuccess([]);
    setListLineShow([]);
  };

  return (
    <div className="tab-container">
      <ModalSussess
        isOpen={openPopup}
        title="Importation de fichiers"
        message="Le fichier a bien été importé"
        onDone={handleDone}
        handleCancel={() => setOpenPopup(false)}
      />
      <span className="title">Importation de données</span>
      <div style={{ marginTop: "30px" }}>
        {loading && <SingleLoading />}
        <div className="tab">
          <Button
            onClick={() => switchMode(false)}
            style={{
              backgroundColor: !isCopy ? AppColors.COLOR_CALENDAR_DEFAULT_PRIMARY : "#EDEDED",
              color: !isCopy ? "white" : "black",
            }}
          >
            Importer un fichier
          </Button>
          <Button
            onClick={() => switchMode(true)}
            style={{
              backgroundColor: isCopy ? AppColors.COLOR_CALENDAR_DEFAULT_PRIMARY : "#EDEDED",
              color: isCopy ? "white" : "black",
              marginLeft: "20px",
            }}
          >
            Importer par copier/coller
          </Button>
        </div>
        <div className="body">
          {!isCopy && (
            <div className="header">
              <span>*Fichier (.xls, .xlsx, .csv)</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "70%",
                }}
              >
                <label htmlFor="get_file">
                  <Button
                    onClick={() => document?.getElementById("get_file")?.click()}
                    className={`${[styles.bt, styles.btBlue].join(" ")}`}
                    icon={<DownloadOutlined />}
                  >
                    Choisir un fichier
                  </Button>
                </label>
                <input type="file" accept=".xlsx, .xls, .csv" id="get_file" onChange={(e) => handleChange(e)} style={{ display: "none" }} />
              </div>
            </div>
          )}
          <div style={{ width: "100%", overflow: "scroll" }}>
            {!isCopy && (
              <table className="tableLine">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Ligne Id</th>
                    <th>Client</th>
                    <th>Départ</th>
                    <th>Arrivée</th>
                    <th>Distance</th>
                    <th>MPL</th>
                    <th>Poids (t)</th>
                    <th>Palet.</th>
                    <th>Véhicule</th>
                    <th>Marchand.</th>
                    <th>Allocation</th>
                    <th>Unité</th>
                    <th>Budget</th>
                    <th>Transp.</th>
                    <th>Unité</th>
                    <th>Devise</th>
                    <th>Marge</th>
                  </tr>
                </thead>
                <tbody>
                  {listLineShow.map((el, index) => {
                    return (
                      <tr key={index} className={listLine[index].error && listLine[index].error.error ? "error" : ""}>
                        <td>{index + 1}</td>
                        <td>{el.ligneid}</td>
                        <td>{el.client?.nom}</td>
                        <td>
                          {el.codepaysdepart}/{el.codepostaldepart}/{el.codedepart}
                        </td>
                        <td>
                          {el.codepaysarrivee}/{el.codepostalarrivee}/{el.codearrivee}
                        </td>
                        <td>{el.distanceReelle ? el.distanceReelle : el.distance} km</td>
                        <td>
                          {el.ligneChargement?.ftl
                            ? `${el.ligneChargement?.mplmin ? el.ligneChargement?.mplmin : ""}${
                                el.ligneChargement?.mplmax ? " - " + el.ligneChargement?.mplmax : ""
                              }`
                            : "FTL"}
                        </td>
                        <td>
                          {el.ligneChargement?.ftl
                            ? `${el.ligneChargement?.poidsmin ? el.ligneChargement?.poidsmin : ""}${
                                el.ligneChargement?.poidsmax ? " - " + el.ligneChargement?.poidsmax : ""
                              }`
                            : el.ligneChargement?.poidsmin
                            ? el.ligneChargement?.poidsmin
                            : ""}
                        </td>
                        <td>
                          {el.ligneChargement?.ftl
                            ? `${el.ligneChargement?.palettesmin ? el.ligneChargement?.palettesmin : ""}${
                                el.ligneChargement?.palettesmax ? " - " + el.ligneChargement?.palettesmax : ""
                              }`
                            : el.ligneChargement?.palettesmin
                            ? el.ligneChargement?.palettesmin
                            : ""}
                        </td>
                        <td>{el.vehicule}</td>
                        <td>{el.marchandise?.nom}</td>
                        <td>{el.ligneAlloue?.volumealloue}</td>
                        <td>lot</td>
                        <td>{el.ligneBudget?.prixcontractualise}</td>
                        <td>{el.ligneBudget?.budgettransport}</td>
                        <td>{el.prixcontractualiseunity}</td>
                        <td>{el.prixcontractualisedevise}</td>
                        <td>{el.margevisee}</td>
                        {!isCopy && (
                          <td style={{ width: "9rem" }} className="action">
                            <img style={{ marginRight: "1rem" }} src={EditIcon} alt="Edit" onClick={() => editStep()} />
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          {isCopy && <CopyTable header={header} dataParse={dataParse} listLine={listLine} onParse={onParsed} />}

          {listLineShow.length > 0 && errorImport.length > 0 && (
            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                  backgroundColor: "#F44B4B",
                  color: "white",
                  padding: "10px 15px",
                }}
              >
                {`Attention, ${errorImport.length} ${errorImport.length > 1 ? "erreurs" : "erreur"} à été détectée`}
              </div>
              <div
                style={{
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                  backgroundColor: "#FAE8E8",
                  padding: "10px 15px",
                }}
              >
                {errorImport.map((err, idx) => {
                  return (
                    <div
                      style={{
                        borderBottom: "1px solid #BCBCBC",
                        padding: "5px",
                      }}
                      key={idx}
                    >
                      {err.type === "req" && (
                        <div>
                          <span>{`Ligne ${err.index} : Le champ `}</span>
                          <span style={{ fontWeight: "bold" }}>{err.key}</span> est invalide ou non rempli.
                        </div>
                      )}
                      {err.type === "distance" && (
                          <div>
                            <span>{`Ligne ${err.index} : `}</span>
                            <span>{err.key}</span>
                          </div>
                      )}
                      {err.type?.startsWith("unique") && (
                        <div>
                          <span>{`Les lignes importées avec le même ID `}</span>
                          <span style={{ fontWeight: "bold" }}>{err.value}</span>{" "}
                          {`n'ont pas les mêmes caractéristiques. Merci de modifier les ID si ce ne sont pas les mêmes lignes, sinon les données communes sont à corriger: `}
                          <span style={{ fontWeight: "bold" }}>{err.key}</span>
                        </div>
                      )}
                      {err.type?.startsWith("length") && (
                        <div>
                          <span>{`LignesID : `}</span>
                          <span style={{ fontWeight: "bold" }}>{err.value}</span> {`Le nombre de Lignes ayant le même ID est > 32.`}
                        </div>
                      )}
                      {err.type === "server" && (
                        <div>
                          <span>Server Error</span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {listLineShow.length > 0 && listLineSuccess.length > 0 && (
            <div>
              <div
                style={{
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                  backgroundColor: "#2BBC47",
                  padding: "10px 15px",
                  color: "white",
                  marginTop: "20px",
                }}
              >
                {`${listLineSuccess.length} ${listLineSuccess.length > 1 ? "importations" : "importation"} réussie`}
              </div>
              <div
                style={{
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                  backgroundColor: "#ECFAEF",
                  padding: "10px 15px",
                }}
              >
                {`${listLineSuccess.length} ${listLineSuccess.length > 1 ? "lignes ont été importées" : "ligne a été importée"} correctement.`}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <Button onClick={() => onSubmit()} className="bt btGreen">
                  Confirmer l’importation
                </Button>
              </div>
            </div>
          )}
          {listLineShow.length === 0 && (
            <div style={{ marginTop: "30px" }}>
              <div
                style={{
                  backgroundColor: AppColors.COLOR_CALENDAR_DEFAULT_PRIMARY,
                  borderRadius: "10px 10px 0 0",
                  padding: "10px 15px",
                  color: "white",
                }}
              >
                Informations pour l’import de fichiers
              </div>
              <div className="guide">
                <div>- Les données doivent être disposées par colonnes.</div>
                <div>
                  - L’ordre des colonnes est défini par la première ligne, les titres suivants peuvent être utilisés pour définir l’ordre des colonnes
                  : adresse e-mail, téléphone, nom, prénom…
                </div>
                <div>
                  - Si aucun titre n’est trouvé, les colonnes seront automatiquement détectées en fonction de la position des numéros et adresses
                  e-mails, mais aucun champ additionnel comme le nom ou le prénom ne sera ajouté.
                </div>
                <div>
                  - Astuce : les fichiers .xlsx (disponible dès Excel 2007) et .csv sont plus légers et seront donc plus rapides à envoyer et traiter.
                </div>
                <div style={{ textAlign: "center", marginTop: "30px" }}>
                  <a
                    href="https://api.transport.staging.bfast-vn.net/api/containers/templates/download/611ec8f3-42f0-4824-a641-6b4f3804b098.xlsx"
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "2px",
                    }}
                  >
                    Télécharger l’exemple de fichier
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImportLines;

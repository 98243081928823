/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface NewFavoriteSlotState {
  slotGMT: string;
}

const initialState: NewFavoriteSlotState = {
  slotGMT: "",
};

export const newFavoriteSlotSlice = createSlice({
  name: "newFavoriteSLot",
  initialState,
  reducers: {
    addGMT: (state, action: PayloadAction<string>) => {
      state.slotGMT = action.payload;
    },
  },
});

export const { addGMT } = newFavoriteSlotSlice.actions;
export const getGMT = (state: RootState) => state.newFavoriteSlot.slotGMT;
export default newFavoriteSlotSlice.reducer;

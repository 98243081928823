/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteToken } from "../../helper/userToken";
import { Account } from "../../models";
import { RootState } from "../store";

interface AuthReducer {
  user?: Account;
}

const initialState: AuthReducer = {
  user: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<{ user: Account }>) => {
      state.user = action.payload?.user;
    },
    userLogout: (state) => {
      state.user = undefined;
      deleteToken();
    },
  },
});

export const { updateUser, userLogout } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;

/** @format */

export enum ModalSearchTypeArr {
  CODE = "Code",
  NOM = "Nom",
  PAYS = "Pays",
  DEPT = "Dept",
  VILLE = "Ville",
  ADRESSE = "Adresse",
}

export enum TypeLieux {
  CHARGEMENT = "Chargement",
  LIVRAISON = "Livraison",
}

export const ModalArraySearchType = [
  {
    id: ModalSearchTypeArr.CODE,
    value: "Code",
  },
  {
    id: ModalSearchTypeArr.NOM,
    value: "Nom",
  },
  {
    id: ModalSearchTypeArr.PAYS,
    value: "Pays",
  },
  {
    id: ModalSearchTypeArr.DEPT,
    value: "Dept",
  },
  {
    id: ModalSearchTypeArr.VILLE,
    value: "Ville",
  },
  {
    id: ModalSearchTypeArr.ADRESSE,
    value: "Adresse",
  },
];

export interface CustomSearchInterface {
  field: string;
  textSearch: string[];
}

export enum SearchOptionsType {
  EQUAL = "=",
  NOT_EQUAL = "!=",
}

/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { DemandeClientSubType, DemandeClientType } from "../../../helper/calendarHelper";

export enum ActionType {
  VIEW = "view",
  CREATE = "create",
  UPDATE = "update",
}

interface DemandeStateReducer {
  actionType: ActionType;
  mainType?: DemandeClientType;
  subType?: DemandeClientSubType;
  enableTime?: boolean;
  editAble: boolean;
}

const initialState: DemandeStateReducer = {
  actionType: ActionType.VIEW,
  mainType: undefined,
  subType: undefined,
  editAble: true,
};

export const DemandeStateSlice = createSlice({
  name: "demandeState",
  initialState,
  reducers: {
    UpdateDemandeState: (
      state,
      action: PayloadAction<{
        actionType?: ActionType;
        mainType?: DemandeClientType;
        subType?: DemandeClientSubType;
        enableTime?: boolean;
        editAble?: boolean;
      }>
    ) => {
      state.actionType = action.payload?.actionType ? action.payload?.actionType : state.actionType;
      state.mainType = action.payload?.mainType ? action.payload?.mainType : state.mainType;
      state.subType = action.payload?.subType ? action.payload?.subType : state.subType;
      state.enableTime = action.payload?.enableTime !== undefined ? action.payload?.enableTime : state.enableTime;
      state.editAble = action.payload?.editAble !== undefined ? action.payload?.editAble : state.editAble;
    },
  },
});

export const { UpdateDemandeState } = DemandeStateSlice.actions;

export const selectDemandeState = (state: RootState) => state.demandeState;

export default DemandeStateSlice.reducer;

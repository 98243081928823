/** @format */

import { Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import CustomInputNumber from "../../../../components/CustomInputNumber";
import IndicatorTutorialView from "../../../../components/IndicatorTutorial";
import { Budget, Chargement, Devise, Unite } from "../../../../models";
import styles from "./Budget.module.css";

const { Option } = Select;

interface BudgetProps {
  index: number;
  itemData: Budget;
  plageState: boolean;
  actionSave: boolean;
  listDevise: Devise[];
  listUnit: Unite[];
  itemCallBack: (index: number, item: FormModal) => void;
  submitCallBack: (index: number, statusItem: boolean) => void;
  isShowTutorial: boolean;
  chargementsLigne?: Chargement[];
  editAble: boolean;
  callBackChange: (data: Budget) => void;
  firstItem?: Budget;
}

interface FormModal {
  prixContractualise?: number;
  prixContractualiseDeviseId?: number;
  prixContractualiseUnityId?: number;
  margeVisee?: number;
  budgetTransport?: number;
}

const BudgetItem = (props: BudgetProps) => {
  const {
    index,
    plageState,
    itemData,
    actionSave,
    submitCallBack,
    listDevise,
    listUnit,
    chargementsLigne,
    itemCallBack,
    editAble,
    callBackChange,
    firstItem,
  } = props;

  const [acceptUnits, setAcceptUnits] = useState(["lot", "tonne"]);
  const [currentChargement, setCurrentChargement] = useState<Chargement>();
  const [currency, setCurrency] = useState("€");
  const [formValue, setFormValue] = useState<FormModal>(itemData);
  const [form] = Form.useForm();

  useEffect(() => {
    if (index !== 0 && firstItem) {
      form.setFieldsValue({
        ...formValue,
        prixContractualiseDeviseId: firstItem?.prixContractualiseDeviseId,
      });
      const itemPrix = listDevise.find((item) => item.id === firstItem?.prixContractualiseDeviseId);
      itemPrix?.nom && setCurrency(itemPrix?.nom);
    }
  }, [firstItem]);

  useEffect(() => {
    if (currentChargement?.palettesMin || currentChargement?.palettesMax) {
      setAcceptUnits(["lot", "tonne", "palette"]);
    }
  }, [currentChargement]);

  useEffect(() => {
    let defaulValue = {
      ...itemData,
    };
    if (!defaulValue.prixContractualiseDeviseId) {
      defaulValue = {
        ...defaulValue,
        prixContractualiseDeviseId: listDevise.find((el) => el.nom === "€")?.id,
      };
    }
    if (!defaulValue.prixContractualiseUnityId) {
      defaulValue = {
        ...defaulValue,
        prixContractualiseUnityId: listUnit.find((el) => el.nom === "tonne")?.id,
      };
    }
    form.setFieldsValue(defaulValue);
    const itemPrix = listDevise.find((el) => el.id === itemData.prixContractualiseDeviseId);
    itemPrix?.nom && setCurrency(itemPrix?.nom);
  }, [itemData, listDevise, listUnit]);

  useEffect(() => {
    if (chargementsLigne && chargementsLigne?.length > 0) {
      setCurrentChargement(chargementsLigne[index]);
    }
  }, [chargementsLigne, index]);

  useEffect(() => {
    if (actionSave) {
      form.submit();
    }
  }, [actionSave]);

  const onFinish = (values: FormModal) => {
    submitCallBack(index, true);
    const dataCb = {
      ...itemData,
      ...values,
      budgetTransport: values.budgetTransport ? Math.floor(values.budgetTransport) : undefined,
    };
    delete dataCb.chargement;
    itemCallBack(index, dataCb);
  };

  const onFinishFailed = () => {
    submitCallBack(index, false);
  };

  const onValuesChange = () => {
    const getFieldsValue: FormModal = form.getFieldsValue();
    setFormValue(getFieldsValue);
    const valueMoney = listDevise.find((el) => el.id === getFieldsValue.prixContractualiseDeviseId);
    const valueUnit = listUnit.find((el) => el.id === getFieldsValue.prixContractualiseUnityId);
    if (valueMoney?.nom) {
      setCurrency(valueMoney.nom);
    }
    const prixContract = getFieldsValue.prixContractualise;
    const margeVisee = getFieldsValue.margeVisee;

    if (margeVisee) {
      let calcBudget = Number(prixContract) / (Number(margeVisee) / 100 + 1);

      if (valueUnit?.nom === "tonne") {
        calcBudget = (Number(prixContract) * Number(currentChargement?.poidsMin)) / (Number(margeVisee) / 100 + 1);
      }
      if (valueUnit?.nom === "palette") {
        calcBudget = (Number(prixContract) * Number(currentChargement?.palettesMin)) / (Number(margeVisee) / 100 + 1);
      }

      form.setFieldsValue({
        budgetTransport: calcBudget.toFixed(0),
      });
    }

    if (index === 0) {
      callBackChange(getFieldsValue);
    }
  };

  return (
    <Form
      form={form}
      style={{ padding: "0 0 0 5px" }}
      onFinishFailed={onFinishFailed}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      disabled={!editAble}
    >
      {plageState &&
        chargementsLigne &&
        chargementsLigne.length > 0 &&
        (chargementsLigne[index]?.ftl ? (
          <div style={{ margin: "auto", textAlign: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              MPL: FTL
              {props.isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView hint={"Plage MPL de la colonne."} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div style={{ margin: "auto", textAlign: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              MPL: {chargementsLigne[index]?.mplMin} / {chargementsLigne[index]?.mplMax}
              {props.isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView hint={"Plage MPL de la colonne."} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ))}
      <ul className={styles.inputBudget}>
        <li>
          <Row style={{ alignItems: "center" }}>
            <Col span={8}>
              <Form.Item name="prixContractualise" rules={[{ required: true, message: "" }]}>
                <CustomInputNumber min={1} precision={0} max={20000} />
              </Form.Item>
            </Col>
            <Col span={15} offset={1}>
              <Row style={{ alignItems: "center" }}>
                <Col span={11}>
                  <Form.Item name="prixContractualiseDeviseId">
                    <Select bordered={false} disabled={index !== 0}>
                      {listDevise.map((el, index) => {
                        return (
                          <Option key={index} value={el.id}>
                            {el.nom}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <p className="mb-3">/</p>
                </Col>
                <Col span={12}>
                  <Form.Item name="prixContractualiseUnityId">
                    <Select bordered={false}>
                      {listUnit
                        .filter((item) => {
                          if (item.nom != null) {
                            return acceptUnits.includes(item.nom);
                          }
                          return false;
                        })
                        .map((el, index) => {
                          return (
                            <Option key={index} value={el.id}>
                              {el.nom}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </li>
        <li>
          <Row style={{ alignItems: "center" }}>
            <Col span={8}>
              <Form.Item name="margeVisee" rules={[{ required: true, message: "" }]}>
                <CustomInputNumber min={0.1} precision={1} max={1000} />
              </Form.Item>
            </Col>
            <Col span={14} offset={2}>
              %
            </Col>
          </Row>
        </li>
      </ul>
      <ul className={styles.inputBudget}>
        <li>
          <Row style={{ alignItems: "center" }}>
            <Col span={8}>
              <Form.Item name="budgetTransport" rules={[{ required: true, message: "" }]}>
                <CustomInputNumber min={0} precision={0} readOnly={true} />
              </Form.Item>
            </Col>
            <Col span={14} offset={2}>
              {`${currency} / lot`}
            </Col>
          </Row>
        </li>
      </ul>
    </Form>
  );
};

export default BudgetItem;

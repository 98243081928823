import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {DemandeClient} from "../../../models";

const initialState: DemandeClient = {};

export const NewDemandeSlice = createSlice({
  name: 'newDemande',
  initialState,
  reducers: {
    UpdateNewDemande: (state, action: PayloadAction<DemandeClient>) => {
      state = {
        ...state,
        ...action.payload
      }
      return state
    }
  },
});

export const { UpdateNewDemande } = NewDemandeSlice.actions;

export const getNewDemande = (state: RootState) => state.newDemande;
export default NewDemandeSlice.reducer;

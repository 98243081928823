/** @format */

import * as React from "react";
import styles from "./indicatorTutorial.module.css";
import { Tooltip } from "antd";
import { ReactNode } from "react";
import infoIcon from "../../assets/images/infoIcon.png";

const IndicatorTutorialView = (props: { hint: string | ReactNode }) => {
  return (
    <Tooltip
      placement="bottomLeft"
      arrowPointAtCenter={true}
      title={props.hint}
      overlayClassName={styles.tooltip}
      color={"#DCDCDC"}
      overlayInnerStyle={{ color: "black", borderRadius: "0.5rem" }}
    >
      <div className={styles.indicatorTutorial}>
        <img src={infoIcon} alt="infoIcon" />
      </div>
    </Tooltip>
  );
};

export default IndicatorTutorialView;

/** @format */

import { Col, Form, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import CustomInput from "../../../../components/CustomInput";
import CustomInputNumber from "../../../../components/CustomInputNumber";
import IndicatorTutorialView from "../../../../components/IndicatorTutorial";
import { Chargement } from "../../../../models";
import styles from "./Chargement.module.css";
import { ChargementAPI } from "../../../../domain/apis/chargement.api";
import { CaracteristiquesPopupType, UpdateCaracteristiquesPopup } from "../../../../app/reducers/Caracteristiques/CaracteristiquesPopup.reducer";
import { useAppDispatch } from "../../../../app/hooks";
import { noop, tap } from "rxjs";

enum TypeInput {
  MATIN = "MATIN",
  MIDI = "MIDI",
}

interface FormModal {
  mplMin?: number;
  mplMax?: number;
  ftl?: boolean;
  poidsMin?: number;
  poidsMax?: number;
  palettesMin?: number;
  palettesMax?: number;
  dureeChargement?: number;
  matin?: string;
  apresMidi?: string;
}

interface ChargementProps {
  index: number;
  plageState: boolean;
  itemData: Chargement;
  actionSave: boolean;
  parentCallback: (id: number) => void;
  itemCallBack: (index: number, item: FormModal) => void;
  submitCallBack: (index: number, statusItem: boolean) => void;
  ligneId: number | undefined;
  ftlSelected: number;
  isShowTutorial: boolean;
  editAble: boolean;
  cbLoading: (state: boolean) => void;
}

type minMaxInterface = {
  minMPL: number;
  maxMPL: number;
  minPoid: number;
  maxPoid: number;
  minPalette: number;
  maxPalette: number;
};

const ChargementItem = (props: ChargementProps) => {
  const { plageState, index, itemData, actionSave, parentCallback, itemCallBack, submitCallBack, ligneId, ftlSelected, editAble, cbLoading } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const palettesMinInput = useRef<HTMLInputElement>(null);
  const palettesMaxInput = useRef<HTMLInputElement>(null);

  const [ftlState, setFtlState] = useState<boolean>(false);
  const [matin, setMatin] = useState<string | undefined>();
  const [apresMidi, setApresMidi] = useState<string | undefined>();
  const [limitValue, setLimitValue] = useState<minMaxInterface>({
    minMPL: 0,
    maxMPL: 13.6,
    minPoid: 0,
    maxPoid: 60,
    minPalette: 0,
    maxPalette: 70,
  });
  const [checkPalettes, setCheckPalettes] = useState(false);

  useEffect(() => {
    form.validateFields(["palettesMin", "palettesMax"]).then();
  }, [checkPalettes, form]);

  useEffect(() => {
    if (actionSave) {
      form.submit();
    }
  }, [actionSave]);

  useEffect(() => {
    const filterData = {
      ...itemData,
    };
    if (itemData?.ftl !== undefined) {
      setFtlState(itemData?.ftl);
      if (itemData.ftl) {
        filterData.poidsMax = undefined;
        filterData.palettesMax = undefined;
      }
    }
    form.setFieldsValue(filterData);

    setLimitValue({
      minMPL: !plageState || ftlState ? 13.6 : itemData.mplMin === 13.6 ? 13.5 : itemData.mplMin ? Number(itemData.mplMin) + 0.1 : 0.1,
      maxMPL: !plageState || ftlState ? 13.6 : itemData.mplMax ? Number(itemData.mplMax) - 0.1 : 13.5,
      minPoid: itemData.poidsMin === 60 ? 59.9 : itemData.poidsMin ? Number(itemData.poidsMin) + 0.1 : 0.1,
      maxPoid: !plageState || ftlState ? 60 : itemData.poidsMax ? Number(itemData.poidsMax) - 0.1 : 59.9,
      minPalette: itemData.palettesMin === 70 ? 69 : itemData.palettesMin ? Number(itemData.palettesMin) + 1 : 2,
      maxPalette: !plageState || ftlState ? 70 : itemData.palettesMax ? Number(itemData.palettesMax) - 1 : 69,
    });
  }, [itemData]);

  const onPalettesChange = () => {
    if (!(plageState && !ftlState)) {
      setCheckPalettes(false);
      return;
    }
    const palettesMin = palettesMinInput.current?.value ?? "";
    const palettesMax = palettesMaxInput.current?.value ?? "";
    setCheckPalettes(!((palettesMin.length == 0 && palettesMax.length == 0) || (palettesMin.length > 0 && palettesMax.length > 0)));
  };

  const onFinish = (values: FormModal) => {
    submitCallBack(index, true);
    itemCallBack(index, {
      ...itemData,
      ...values,
      mplMin: values.ftl ? undefined : values.mplMin,
      mplMax: values.ftl ? 13.6 : values.mplMax,
    });
  };

  const onFinishFailed = () => {
    submitCallBack(index, false);
  };

  const deleteItem = () => {
    if (!editAble) {
      return;
    }
    // find current chargement and check this item deleted or not?
    cbLoading(true);
    ChargementAPI.fetchAll({
      filter: {
        where: {
          caracteristiquesId: ligneId,
        },
      },
    })
      .pipe(
        tap((chargements: Chargement[]) => {
          cbLoading(false);
          if (itemData.id && !chargements.map((el) => el.id).includes(itemData.id)) {
            dispatch(UpdateCaracteristiquesPopup({ type: CaracteristiquesPopupType.ERROR_EDIT_DELETED_ITEM }));
          } else {
            parentCallback(index);
          }
        })
      )
      .subscribe({
        error: noop,
      });
  };

  const onKeyPress = (e: React.KeyboardEvent, type: TypeInput) => {
    const acceptKey = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"];

    if (acceptKey.every((item) => item !== e.key && item.toUpperCase() !== e.key)) {
      e.preventDefault();
      return false;
    }

    let specialCharRegex = new RegExp("[a-jA-J@.' ,-]");

    if (type === TypeInput.MATIN) {
      specialCharRegex = new RegExp("[a-jA-J@.' ,-]");
      setMatin(e.key);
      if (apresMidi) {
        specialCharRegex = new RegExp(`[a-${apresMidi.toLowerCase()}A-${apresMidi.toUpperCase()}@.' ,-]`);
      }
      const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (!specialCharRegex.test(pressedKey)) {
        e.preventDefault();
        return false;
      }
    }

    if (type === TypeInput.MIDI) {
      specialCharRegex = new RegExp("[a-jA-J@.' ,-]");
      setApresMidi(e.key);
      if (matin) {
        specialCharRegex = new RegExp(`[${matin.toLowerCase()}-j${matin.toUpperCase()}-J@.' ,-]`);
      }
      const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (!specialCharRegex.test(pressedKey)) {
        e.preventDefault();
        return false;
      }
    }
  };

  const updateItem = () => {
    const values = form.getFieldsValue();
    itemCallBack(index, {
      ...itemData,
      ...values,
    });
  };

  return (
    <Form
      style={{ padding: "0 5px" }}
      form={form}
      name="chargementItem"
      onFinish={onFinish}
      onValuesChange={updateItem}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      colon={false}
      className={styles.formChargement}
      disabled={!editAble}
    >
      <ul className={styles.inputChargement}>
        {(plageState || !ftlState) && (
          <li>
            <Row style={{ alignItems: "center" }}>
              {!ftlState ? (
                <Col span={10}>
                  <Form.Item name="mplMin" rules={[{ required: true, message: "" }]}>
                    <CustomInputNumber suffix="m" step={0.1} precision={1} max={limitValue.maxMPL} min={plageState ? 0 : 0.1} />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={24} style={{ marginBottom: "2px" }}>
                  <Form.Item />
                </Col>
              )}
              {plageState && !ftlState && (
                <>
                  <Col span={4} className="mb-1 text-center">
                    à
                  </Col>
                  <Col span={10}>
                    <Form.Item name="mplMax" rules={[{ required: true, message: "" }]}>
                      <CustomInputNumber min={limitValue.minMPL} max={13.6} suffix="m" step={0.1} precision={1} />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={10} offset={1}>
                <Form.Item valuePropName="checked" name="ftl">
                  <CustomCheckbox
                    disabled={(ftlSelected >= 0 && ftlSelected !== index) || !editAble}
                    onChange={(e) => {
                      setFtlState(e.target.checked);
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      FTL
                      {props.isShowTutorial ? (
                        <div style={{ marginLeft: "0.5rem" }}>
                          <IndicatorTutorialView hint={"À cocher si cette ligne est un FTL, ou considéré comme tel."} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </CustomCheckbox>
                </Form.Item>
              </Col>
            </Row>
          </li>
        )}
        <li>
          <Row style={{ alignItems: "center" }}>
            <Col span={10}>
              <Form.Item name="poidsMin" rules={[{ required: true, message: "" }]}>
                <CustomInputNumber min={plageState ? 0 : 0.1} max={limitValue.maxPoid} suffix="t" step={0.1} precision={1} />
              </Form.Item>
            </Col>
            {plageState && (
              <>
                <Col span={4} className="mb-1 text-center">
                  {!ftlState && "à"}
                </Col>
                <Col span={10}>
                  {!ftlState && (
                    <Form.Item name="poidsMax" rules={[{ required: true, message: "" }]}>
                      <CustomInputNumber min={limitValue.minPoid} max={60} suffix="t" step={0.1} precision={1} />
                    </Form.Item>
                  )}
                </Col>
              </>
            )}
          </Row>
        </li>
        <li>
          <Row style={{ alignItems: "center" }}>
            <Col span={10}>
              <Form.Item name="palettesMin" rules={[{ required: checkPalettes, message: "" }]}>
                <CustomInputNumber
                  innerref={palettesMinInput}
                  min={1}
                  suffix="nb"
                  precision={0}
                  max={limitValue.maxPalette}
                  onChange={onPalettesChange}
                />
              </Form.Item>
            </Col>
            {plageState && (
              <>
                <Col span={4} className="mb-1 text-center">
                  {!ftlState && "à"}
                </Col>
                <Col span={10}>
                  {!ftlState && (
                    <Form.Item name="palettesMax" rules={[{ required: checkPalettes, message: "" }]}>
                      <CustomInputNumber
                        innerref={palettesMaxInput}
                        min={limitValue.minPalette}
                        max={70}
                        suffix="nb"
                        precision={0}
                        onChange={onPalettesChange}
                      />
                    </Form.Item>
                  )}
                </Col>
              </>
            )}
            {!plageState && ftlState && (
              <Col span={5} style={{ marginLeft: "auto" }}>
                <Form.Item valuePropName="checked" name="ftl">
                  <CustomCheckbox
                    onChange={(e) => {
                      setFtlState(e.target.checked);
                    }}
                    disabled={!editAble}
                  >
                    FTL
                  </CustomCheckbox>
                </Form.Item>
              </Col>
            )}
          </Row>
        </li>
        <li>
          {index === 0 && (
            <Form.Item valuePropName="checked" name="echangePalettes" style={{ marginLeft: "0.75rem" }}>
              <CustomCheckbox disabled={!editAble}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Échange palettes
                  {props.isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={"À cocher si un échange de palettes est prévu au lieu de livraison."} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </CustomCheckbox>
            </Form.Item>
          )}
        </li>
        {index !== 0 && (
          <>
            <span className={styles.deleteItem} onClick={deleteItem}>
              <img src={DeleteIcon} alt="delete" />
            </span>
            {props.isShowTutorial ? (
              <div
                style={{
                  position: "absolute",
                  bottom: "1.2rem",
                  right: "-0.2rem",
                }}
              >
                <IndicatorTutorialView hint={"Supprimer la colonne concernée."} />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </ul>
      {index === 0 ? (
        <p className={styles.titleDetail}>Durée - Délais</p>
      ) : (
        <p className={styles.titleDetail} style={{ opacity: 0 }}>
          Durée - Délais
        </p>
      )}
      <ul className={styles.inputChargement}>
        <li>
          <Row style={{ alignItems: "center" }}>
            <Col span={12}>
              <Form.Item name="dureeChargement" rules={[{ required: true, message: "" }]}>
                <CustomInputNumber min={0} max={24} suffix="h" step={0.1} precision={1} />
              </Form.Item>
            </Col>
          </Row>
        </li>
        <li style={{ minHeight: "2rem" }}>{null}</li>
        <li style={{ height: "3.125rem" }}>
          {index === 0 && (
            <Row style={{ alignItems: "center" }}>
              <Col span={12}>
                <Form.Item name="matin" rules={[{ required: true, message: "" }]}>
                  <CustomInput value={matin} suffix="&nbsp;" maxLength={1} onKeyPress={(e: React.KeyboardEvent) => onKeyPress(e, TypeInput.MATIN)} />
                </Form.Item>
              </Col>
              <Col span={12}>{`(ex. : C <=> J + 2)`}</Col>
            </Row>
          )}
        </li>
        <li style={{ height: "3.125rem" }}>
          {index === 0 && (
            <Row style={{ alignItems: "center" }}>
              <Col span={12}>
                <Form.Item name="apresMidi" rules={[{ required: true, message: "" }]}>
                  <CustomInput suffix="&nbsp;" maxLength={1} onKeyPress={(e: React.KeyboardEvent) => onKeyPress(e, TypeInput.MIDI)} />
                </Form.Item>
              </Col>
            </Row>
          )}
        </li>
      </ul>
    </Form>
  );
};

export default ChargementItem;

/** @format */

import { notification } from "antd";
import { getRangeDate } from "../../../helper/getRangeDate";
import { Caracteristiques } from "../../../models";
import moment from "moment";
import { useAppSelector } from "../../../app/hooks";
import { selectDemandeState } from "../../../app/reducers/DemandeClient/DemandeState.reducer";
import { DATE_FORMAT, DemandeClientSubType, DemandeClientType } from "../../../helper/calendarHelper";

export interface IgnoreDate {
  currentLigne: Caracteristiques;
  currentMonth?: number | undefined;
  callBack: (status: boolean) => void;
}

const ToastDateMoisGlobal = (props: IgnoreDate) => {
  const { currentLigne, currentMonth, callBack } = props;
  const demandeState = useAppSelector(selectDemandeState);

  if (
    typeof currentMonth === "undefined" ||
    (demandeState.mainType !== DemandeClientType.MOIS && demandeState.subType !== DemandeClientSubType.GLOBAL)
  ) {
    return <></>;
  }

  const [dateInit, dateFin] = getRangeDate(currentLigne);
  const startDate = new Date(dateInit);
  const endDate = new Date(dateFin);

  const dateArr = [];

  if (endDate.getMonth() === currentMonth || startDate.getMonth() === currentMonth) {
    if (startDate.getDate() !== 1) {
      dateArr.push(`de ${moment(startDate).format(DATE_FORMAT)} à ${moment(startDate).endOf("month").format(DATE_FORMAT)}`);
    }

    if (endDate.getDate() !== moment(endDate).endOf("month").date()) {
      dateArr.push(`de ${moment(endDate).startOf("month").format(DATE_FORMAT)} à ${moment(endDate).format(DATE_FORMAT)}`);
    }
  }

  if (dateArr.length > 0) {
    callBack(true);
    notification.warning({
      key: "notiStartEnd",
      message: "Attention",
      description: `Attention, les jours se trouvant en début et fin de contrat (${dateArr.join(" et ")}) ne seront pas pris en compte.`,
    });
  } else {
    callBack(false);
  }

  return null;
};

export default ToastDateMoisGlobal;

/** @format */

import * as React from "react";
import { SelectProps } from "antd/lib/select";
import { Select } from "antd";
import "./customSelect.css";

const CustomSelect = (
  props: SelectProps & {
    children?: React.ReactNode[];
  }
) => {
  const { children, style } = props;
  return (
    <div className="customSelect" style={{ ...style }}>
      <Select
        {...props}
        filterOption={(input, option) => {
          let label = undefined;
          if (typeof option?.children === "string") {
            label = option?.children;
          }
          if (typeof option?.children === "object") {
            label = option?.children?.join(" ").toString();
          }
          if (label) {
            return label.toLowerCase().includes(input.toLowerCase());
          }
          return false;
        }}
      >
        {children}
      </Select>
    </div>
  );
};

export default CustomSelect;

/** @format */

import { CloseCircleFilled } from "@ant-design/icons";
import { Input, Modal, Pagination, Select } from "antd";
import * as lodash from "lodash";
import { useEffect, useMemo, useState } from "react";
import { finalize, Observable, tap } from "rxjs";
import SearchIcon from "../../../../../assets/icons/search.svg";
import SingleLoading from "../../../../../components/SingleLoading";
import { LieuxAPI } from "../../../../../domain/apis/lieux.api";
import { doOnSubscribe } from "../../../../../helper/rxjs.helper";
import { LieuxSearchResponse } from "../../../../../models/responses/lieuxSearch.model";
import { CustomSearchInterface, ModalArraySearchType, ModalSearchTypeArr } from "../../../const/contractLine.const";
import { LieuxSearch, LocationType } from "../models/TrajetModel";
import styles from "./modal.module.css";

const { Option } = Select;

type Props = {
  isOpen?: boolean;
  onSubmit: (lieux: LieuxSearch) => void;
  onCancel?: () => void;
  type: LocationType;
};

interface ModalLieuxRes {
  total: number;
  data: LieuxSearch[];
}

const ModalLieux = ({ isOpen, onSubmit, onCancel, type }: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState<string>(ModalSearchTypeArr.CODE);
  const [searchFields, setSearchFields] = useState<CustomSearchInterface[]>([]);
  const [selectedLieux, setSelectedLieux] = useState<LieuxSearch | undefined>();
  const [lieuxs, setLieuxs] = useState<ModalLieuxRes>({
    total: 0,
    data: [],
  });
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  // useRxEffect(() => {
  //   if (isOpen) {
  //     return getLieuxs();
  //   }
  //   return of(true);
  // }, [isOpen, limit, page, searchFields.length]);

  useMemo(() => {
    getLieuxs().subscribe();
  }, [limit, page, searchFields.length]);

  useEffect(() => {
    setSearchTerm("");
    setSearchFields([]);
    setSearchType(ModalSearchTypeArr.CODE);
    setPage(1);
    setSelectedLieux(undefined);
  }, [isOpen]);

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === "Enter") {
      onSubmitSearch();
    }
  };

  const onSelectLieux = (lieuxData: LieuxSearch) => {
    setSelectedLieux(lieuxData);
  };

  const onSubmitSearch = () => {
    const searchArr = searchTerm
      .split(";")
      .filter((el) => el.trim().length > 0)
      .map((el) => el.replaceAll(",", "."));
    const searchFieldsState = searchFields;
    const newSearchFieldState: CustomSearchInterface = {
      field: searchType,
      textSearch: searchArr,
    };
    const textSearchFieldsState: string[] = [];
    searchFieldsState
      .filter((item) => item.field === searchType)
      .map((item) => item.textSearch)
      .forEach((item) => item.forEach((itemTextSearch) => textSearchFieldsState.push(itemTextSearch)));
    const unionSearchFields = lodash.union(textSearchFieldsState, newSearchFieldState.textSearch);
    const difference = lodash.difference(unionSearchFields, textSearchFieldsState);

    if (difference.length > 0) {
      searchFieldsState.push({
        field: newSearchFieldState.field,
        textSearch: difference,
      });
      setSearchFields(searchFieldsState);
    }
    setPage(1);
    setSearchTerm("");
  };

  function getLieuxs(): Observable<LieuxSearchResponse> {
    return LieuxAPI.search(searchFields, limit, (page - 1) * limit, type).pipe(
      doOnSubscribe(() => setLoading(true)), // Show loading on start
      tap({
        next: (lieuxSearchResponse: LieuxSearchResponse) => {
          setLieuxs(lieuxSearchResponse);
        },
      }),
      finalize(() => setLoading(false)) // Hide loading on end
    );
  }

  const removeSearchTags = (index: number) => {
    const data = searchFields;
    data.splice(index, 1);
    setSearchFields([...data]);
  };

  const clearFilter = () => {
    setSearchFields([]);
    setSearchType(ModalSearchTypeArr.CODE);
    setPage(1);
    setSearchTerm("");
  };

  return (
    <>
      <Modal width={1112} visible={isOpen} footer={null} onCancel={onCancel}>
        <div className={styles.title_modal}>
          <span>Liste Codes Lieu</span>
        </div>
        <div style={{ marginTop: "24px" }} className={styles.title_modal}>
          <span className={styles.subtitle}>Ci-dessous la liste des Codes Lieu pris en compte dans ce logiciel</span>
        </div>
        <div className={styles.modal_search}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={styles.searchBox}>
              <Select
                style={{
                  borderWidth: "0 1px 0 0",
                  borderRight: "1px solid #BCBCBC",
                  borderRadius: "0",
                  borderColor: "#BCBCBC",
                }}
                defaultValue={ModalArraySearchType[0].id}
                bordered={false}
                value={searchType}
                onChange={(e) => setSearchType(e)}
              >
                {ModalArraySearchType.map((el, index) => {
                  return (
                    <Option key={index} value={el.id}>
                      {el.value}
                    </Option>
                  );
                })}
              </Select>
              <Input
                id="search"
                name="search"
                bordered={false}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Rechercher"
                onKeyPress={handleKeyPress}
              />
              <button className={styles.searchbtn} type="button" onClick={onSubmitSearch} style={{ cursor: "pointer" }}>
                <img src={SearchIcon} alt="Search" />
              </button>
            </div>
            {searchFields?.length > 0 && (
              <div className={styles.list_tag}>
                {searchFields.map((e, index) => {
                  return (
                    <div key={index} className={styles.tag_container}>
                      <div className={styles.tag}>
                        <span>
                          {e.field} - {e.textSearch.toString()}
                        </span>
                        <CloseCircleFilled
                          onClick={() => removeSearchTags(index)}
                          color="EDEDED"
                          style={{
                            position: "absolute",
                            top: "-5px",
                            right: "-8px",
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <span onClick={clearFilter} style={{ textDecoration: "underline", cursor: "pointer" }}>
              Réinitialiser
            </span>
          </div>
          <div className={styles.result}>{searchFields.length > 0 && `${lieuxs?.total} ${lieuxs?.total > 1 ? "résultats" : "résultat"}`} </div>
        </div>
        {loading && <SingleLoading />}
        <table className={styles.tableLine}>
          <thead>
            <tr>
              <th>Code</th>
              <th>Nom</th>
              <th>Pays</th>
              <th>Dept</th>
              <th>Ville</th>
              <th>Adresse</th>
            </tr>
          </thead>
          <tbody>
            {lieuxs?.data.map((e, index) => {
              return (
                <tr
                  key={index}
                  onClick={() => e && onSelectLieux(e)}
                  style={{
                    background: selectedLieux?.id === e.id ? "#014289" : "#F5F6F9",
                  }}
                >
                  <td style={{ color: selectedLieux?.id === e.id ? "#fff" : "#333" }}>{e.code}</td>
                  <td style={{ color: selectedLieux?.id === e.id ? "#fff" : "#333" }}>{e.nom}</td>
                  <td style={{ color: selectedLieux?.id === e.id ? "#fff" : "#333" }}>{e.paysNom}</td>
                  <td style={{ color: selectedLieux?.id === e.id ? "#fff" : "#333" }}>{e.departementCode}</td>
                  <td style={{ color: selectedLieux?.id === e.id ? "#fff" : "#333" }}>{e.villeNom}</td>
                  <td style={{ color: selectedLieux?.id === e.id ? "#fff" : "#333" }}>{e?.adresse}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          style={{ margin: "1rem 0", float: "right" }}
          total={lieuxs?.total}
          defaultCurrent={1}
          current={page}
          pageSize={limit}
          onChange={(page, pageSize) => {
            setLimit(pageSize);
            setPage(page);
          }}
        />
        <div style={{ clear: "both" }} />
        {selectedLieux && (
          <div className={styles.modalfooter}>
            <button
              onClick={() => {
                onSubmit(selectedLieux);
              }}
              style={{
                color: "white",
                fontSize: "16px",
                cursor: "pointer",
                background: "#014289",
                width: "183px",
              }}
              className={`${[styles.bt, styles.btBlue].join(" ")}`}
            >
              Valider
            </button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ModalLieux;

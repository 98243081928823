import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';

export enum PopupType {
  NONE = "none",
  SUCCESS = "success",
  ERROR = "error",
  DELETE = "delete",
  SUCCESS_DELETE = "success_delete",
  SUCCESS_CREATE_LINE = "success_create_line",
}

const initialState: {type: PopupType} = {
  type: PopupType.NONE
};

export const TransPopupSlice = createSlice({
  name: 'transPopup',
  initialState,
  reducers: {
    UpdateTransPopUp: (state, action: PayloadAction<{type: PopupType}>) => {
      state.type = action.payload.type;
    }
  },
});

export const { UpdateTransPopUp } = TransPopupSlice.actions;

export const getTransPopup = (state: RootState) => state.transPopup;
export default TransPopupSlice.reducer;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {DemandeClient} from "../../../models";

interface CurrentDemandeReducer {
  demande?: DemandeClient;
}

const initialState: CurrentDemandeReducer = {
  demande: {}
};

export const CurrentDemandeSlice = createSlice({
  name: 'currentDemande',
  initialState,
  reducers: {
    UpdateCurrentDemande: (state, action: PayloadAction<DemandeClient>) => {
      state.demande = action.payload
    }
  },
});

export const { UpdateCurrentDemande } = CurrentDemandeSlice.actions;

export const getCurrentDemande = (state: RootState) => state.currentDemande.demande;
export default CurrentDemandeSlice.reducer;

/** @format */

import loadable from "@loadable/component";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/fr";
import { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { AppConfig } from "./AppConfig";
import GlobalLoading from "./components/GlobalLoading.component";
import NotFound from "./features/404";
import ContractLines from "./features/contractLine/ContractLines";
import LineSchedule from "./features/lines/LineSchedule";
import AuthGuard from "./guard/AuthGuard";
import GuestGuard from "./guard/GuestGuard";
import DashBoardLayout from "./layouts/Dashboard";
import { UserLevels } from "./models";

const LoginComponent = loadable(() => import("./features/auth/login/Login"));

const DashboardView = loadable(() => import("./features/dashboard/DashboardView"));

const NewContractComponent = loadable(() => import("./features/contractLine/NewContract"));

const CompleteLinesComponent = loadable(() => import("./features/lines/CompleteLines"));

const ContractedLinesComponent = loadable(() => import("./features/transporteur/TransporteurList/index"));

const TablesComponent = loadable(() => import("./features/tables/Tables"));

const CreateRoleComponent = loadable(() => import("./features/utilisateur/createUpdate"));

const ListUserComponent = loadable(() => import("./features/utilisateur/index"));

const TransporterSchedule = loadable(() => import("./features/transporteur/TransporteurSchedule"));

const FavoriteSlotView = loadable(() => import("./features/favoriteSlot/index"));

function App() {
  moment.locale("fr");
  return (
    <BrowserRouter basename={AppConfig.routerBase}>
      <Suspense fallback={<GlobalLoading />}>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route
            path="/login"
            element={
              <GuestGuard>
                <LoginComponent />
              </GuestGuard>
            }
          />
          <Route
            path="/dashboard"
            element={
              <AuthGuard>
                <DashBoardLayout>
                  <DashboardView />
                </DashBoardLayout>
              </AuthGuard>
            }
          />
          <Route
            path="/dashboard/contracted-line"
            element={
              <AuthGuard>
                <DashBoardLayout>
                  <NewContractComponent />
                </DashBoardLayout>
              </AuthGuard>
            }
          />

          <Route path="/dashboard/contracted-line">
            <Route
              path=":lineId"
              element={
                <AuthGuard>
                  <DashBoardLayout>
                    <ContractLines />
                  </DashBoardLayout>
                </AuthGuard>
              }
            />
          </Route>

          <Route
            path="/dashboard/lines-complete"
            element={
              <AuthGuard>
                <DashBoardLayout>
                  <CompleteLinesComponent />
                </DashBoardLayout>
              </AuthGuard>
            }
          />

          <Route path="/dashboard/lines-complete">
            <Route
              path=":caracteristiquesLigneId"
              element={
                <AuthGuard>
                  <DashBoardLayout fullWidth={true}>
                    <LineSchedule />
                  </DashBoardLayout>
                </AuthGuard>
              }
            />
          </Route>

          <Route
            path="/dashboard/lines-contracted"
            element={
              <AuthGuard>
                <DashBoardLayout>
                  <ContractedLinesComponent />
                </DashBoardLayout>
              </AuthGuard>
            }
          />

          <Route path="/dashboard/lines-contracted">
            <Route
              path=":caracteristiquesLigneId"
              element={
                <AuthGuard>
                  <DashBoardLayout>
                    <TransporterSchedule />
                  </DashBoardLayout>
                </AuthGuard>
              }
            />
          </Route>

          <Route
            path="/dashboard/tables"
            element={
              <AuthGuard>
                <DashBoardLayout>
                  <TablesComponent />
                </DashBoardLayout>
              </AuthGuard>
            }
          />

          <Route path="/dashboard/favorite-slot">
            <Route
              path=":choixTransporteurId"
              element={
                <AuthGuard>
                  <DashBoardLayout>
                    <FavoriteSlotView />
                  </DashBoardLayout>
                </AuthGuard>
              }
            />
          </Route>

          <Route
            path="/layout-guard"
            element={
              <AuthGuard>
                <DashBoardLayout />
              </AuthGuard>
            }
          />
          <Route
            path="/dashboard/user-detail"
            element={
              <AuthGuard levels={[UserLevels.ADMIN, UserLevels.SUPER_ADMIN]}>
                <DashBoardLayout>
                  <CreateRoleComponent />
                </DashBoardLayout>
              </AuthGuard>
            }
          />

          <Route path="/dashboard/user-detail">
            <Route
              path=":userId"
              element={
                <AuthGuard levels={[UserLevels.ADMIN, UserLevels.SUPER_ADMIN]}>
                  <DashBoardLayout>
                    <CreateRoleComponent />
                  </DashBoardLayout>
                </AuthGuard>
              }
            />
          </Route>

          <Route
            path="/reset-password"
            element={
              <DashBoardLayout>
                <CreateRoleComponent />
              </DashBoardLayout>
            }
          />

          <Route
            path="/dashboard/list-user"
            element={
              <AuthGuard levels={[UserLevels.ADMIN, UserLevels.SUPER_ADMIN]}>
                <DashBoardLayout>
                  <ListUserComponent />
                </DashBoardLayout>
              </AuthGuard>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

/** @format */

import { Col, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { of, tap } from "rxjs";
import { useAppSelector } from "../../../../../app/hooks";
import { selectCaracteristiquesLigne } from "../../../../../app/reducers/demandeClient.reducer";
import { getCurrentDemande } from "../../../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import { UpdateDatesObj } from "../../../../../app/reducers/DemandeClient/DatesObject.reducer";
import { ActionType, selectDemandeState } from "../../../../../app/reducers/DemandeClient/DemandeState.reducer";
import { UpdateNewDemande } from "../../../../../app/reducers/DemandeClient/NewDemande.reducer";
import { getSelectedDate } from "../../../../../app/reducers/DemandeClient/SelectedDates.reducer";
import CustomInputNumber from "../../../../../components/CustomInputNumber";
import { DateInput } from "../../../../../components/FullCalendar";
import { AppColors } from "../../../../../constants/colors.const";
import { HolidayAPI } from "../../../../../domain/apis/holiday.api";
import { getRangeDate } from "../../../../../helper/getRangeDate";
import { useRxEffect } from "../../../../../helper/rxjs.helper";
import { Lots } from "../../../../../models/lots.model";
import HolidaySelect from "../HolidaySelect";
import styles from "./Semaine.module.css";
import { selectIsShowTutorial } from "../../../../../app/reducers/indicatorTutorial.reducer";
import IndicatorTutorialView from "../../../../../components/IndicatorTutorial";

const SemaineGlobal = () => {
  const dispatch = useDispatch();
  const currentDemande = useAppSelector(getCurrentDemande);
  const currentLigne = useAppSelector(selectCaracteristiquesLigne).caracteristiques;
  const clickedDate = useAppSelector(getSelectedDate).currentDate;
  const demandeState = useAppSelector(selectDemandeState);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);

  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [min, setMin] = useState<number | undefined>(currentDemande?.totalMinLot);
  const [max, setMax] = useState<number | undefined>(currentDemande?.totalMaxLot);
  const [whiteList, setWhiteList] = useState<string[]>([]);
  const [holidays, setHolidays] = useState<string[]>([]);

  useRxEffect(() => {
    if (currentLigne && currentDemande && currentDemande.countryCode) {
      const [startDate, endDate] = getRangeDate(currentLigne);
      const startYear = startDate?.slice(0, 4);
      const endYear = endDate?.slice(0, 4);

      return HolidayAPI.getHolidays(currentDemande.countryCode, Number(startYear), Number(endYear)).pipe(
        tap({
          next: (response: Map<string, { date: string }[]>) => {
            let getHoliday: string[] = [];
            Object.keys(response).map((key: string) => {
              const holidays: { date: string }[] = response.get(key) ?? [];
              getHoliday = getHoliday.concat(holidays.map((item: { date: string }) => item.date.replaceAll("-", "/")));
            });
            setHolidays(getHoliday);
          },
        })
      );
    }
    return of(true);
  }, []);

  useEffect(() => {
    if (demandeState.actionType !== ActionType.VIEW) {
      const getDates: string[] = [];
      if (currentDemande?.demandeClientDates) {
        currentDemande.demandeClientDates.map((el) => {
          if (el.date) {
            getDates.push(el.date);
          }
        });
      }

      const acceptedDates: string[] = [];
      if (currentLigne) {
        let [startDate, endDate] = getRangeDate(currentLigne);
        const startWeekInit = moment(startDate).startOf("week").format("YYYY/MM/DD");
        const endWeekFin = moment(endDate).endOf("week").format("YYYY/MM/DD");
        if (startDate > startWeekInit) {
          startDate = moment(startDate).endOf("week").add(1, "days").format("YYYY/MM/DD");
        }
        if (endDate < endWeekFin) {
          endDate = moment(endDate).startOf("week").subtract(1, "days").format("YYYY/MM/DD");
        }

        for (let d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
          const date = d.getFullYear() + "/" + ("0" + (Number(d.getMonth()) + 1)).slice(-2) + "/" + ("0" + d.getDate()).slice(-2);
          acceptedDates.push(date);
        }
      }

      setWhiteList(acceptedDates);

      if (currentDemande?.demandeClientDates) {
        setSelectedDates(getDates);
      } else {
        setSelectedDates(acceptedDates);
      }
    }
  }, [currentDemande]);

  // Update selected Date when click a date in calendar
  useEffect(() => {
    if (clickedDate && whiteList.includes(clickedDate.date) && !holidays.includes(clickedDate.date)) {
      const currentWeek: string[] = [];
      const startOfWeek = moment(clickedDate.date).startOf("week").format("YYYY/MM/DD");
      const endOfWeek = moment(clickedDate.date).endOf("week").format("YYYY/MM/DD");
      for (let d = new Date(startOfWeek); d <= new Date(endOfWeek); d.setDate(d.getDate() + 1)) {
        const date = d.getFullYear() + "/" + ("0" + (Number(d.getMonth()) + 1)).slice(-2) + "/" + ("0" + d.getDate()).slice(-2);
        if (currentLigne && !holidays.includes(date)) {
          const [startDate, endDate] = getRangeDate(currentLigne);
          if (startDate <= date && endDate >= date) {
            currentWeek.push(date);
          }
        }
      }
      if (currentWeek.every((el) => selectedDates.includes(el))) {
        setSelectedDates(selectedDates.filter((el) => !currentWeek.includes(el)));
      } else {
        setSelectedDates([...selectedDates, ...currentWeek]);
      }
    }
  }, [clickedDate]);

  useEffect(() => {
    const dateObj: DateInput[] = [];
    selectedDates.map((el) => {
      dateObj.push({
        date: el,
        color: "#fff",
        background: AppColors.COLOR_CALENDAR_DEFAULT_PRIMARY,
      });
    });
    dispatch(UpdateDatesObj(dateObj));
  }, [selectedDates]);

  useEffect(() => {
    const lotsDate: Lots[] = selectedDates.map((el) => {
      return {
        date: el,
        lotMin: min,
        lotMax: max,
      };
    });

    dispatch(UpdateNewDemande({ totalMinLot: min, totalMaxLot: max, demandeClientDates: lotsDate }));
  }, [selectedDates, min, max]);

  const onChangeMin = (value: number | string | null) => {
    if (value) {
      setMin(Number(value));
    } else {
      setMin(undefined);
    }
  };

  const onChangeMax = (value: number | string | null) => {
    if (value) {
      setMax(Number(value));
    } else {
      setMax(undefined);
    }
  };

  const changeHolidays = (values: string[]) => {
    const addDays = whiteList.filter((el) => holidays.includes(el));
    const removeDays = selectedDates.filter((el) => values.includes(el));
    setSelectedDates([...selectedDates, ...addDays].filter((el) => !removeDays.includes(el)));
    setHolidays(values);
  };

  return (
    <div className="SemaineGlobal" style={{ marginTop: "1rem" }}>
      <Row style={{ alignItems: "flex-end" }}>
        <Col span={10}>
          <span className="smallTitle">Total</span>
        </Col>
        <Col span={5}>
          <span className={["smallTitle", styles.smallTitle].join(" ")}>Min</span>
          <CustomInputNumber
            className={styles.lotValue}
            precision={0}
            value={min}
            onChange={onChangeMin}
            disabled={demandeState.actionType === ActionType.VIEW}
          />
          {isShowTutorial && (
            <div style={{ position: "absolute", right: "0", top: "1.25rem" }}>
              <IndicatorTutorialView hint={<>Nombre de lots minimum pour les semaines sélectionnées.</>} />
            </div>
          )}
        </Col>
        <Col span={5}>
          <span className={["smallTitle", styles.smallTitle].join(" ")}>Max</span>
          <CustomInputNumber
            className={styles.lotValue}
            precision={0}
            value={max}
            onChange={onChangeMax}
            disabled={demandeState.actionType === ActionType.VIEW}
          />
          {isShowTutorial && (
            <div style={{ position: "absolute", right: "0", top: "1.25rem" }}>
              <IndicatorTutorialView hint={<>Nombre de lots maximum pour les semaines sélectionnées.</>} />
            </div>
          )}
        </Col>
      </Row>

      <HolidaySelect getHolidays={(values) => changeHolidays(values)} />
    </div>
  );
};

export default SemaineGlobal;

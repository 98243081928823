/** @format */

import * as React from "react";
import { CheckboxProps } from "antd/lib/checkbox";
import { Checkbox } from "antd";
import styles from "./customCheckbox.module.css";
import { ReactNode } from "react";

const CustomCheckbox = (
  props: CheckboxProps & {
    children: string | ReactNode;
  }
) => {
  const { children } = props;
  const componentProps: CheckboxProps = {
    ...props,
    className: `${[styles.customCheckbox, "customCheckbox"].join(" ")} ${props.className ? props.className : ""}`,
  };

  return <Checkbox {...componentProps}>{children}</Checkbox>;
};

export default CustomCheckbox;

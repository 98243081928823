/** @format */

import { Col, Row } from "antd";
import SingleLoading from "../../components/SingleLoading";
import LineMenu from "./LineMenu";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchDemandeData, selectBatchAverages, selectDemandeClients, selectMoyenneDeLotsMax } from "../../app/reducers/demandeClient.reducer";
import FullCalendar, { DateInput } from "../../components/FullCalendar";
import { UpdateSelectedDates } from "../../app/reducers/DemandeClient/SelectedDates.reducer";
import { useAppSelector } from "../../app/hooks";
import { getDateObject } from "../../app/reducers/DemandeClient/DatesObject.reducer";
import PopupContainer from "./components/PopupContainer";
import { getRangeDate } from "../../helper/getRangeDate";
import { BatchAverage, CaracteristiquesLignes, DemandeClient, MoyenneDeLots } from "../../models";
import FooterCalendar from "../../components/Footer/FooterCalendar";
import moment from "moment";
import HeaderCalendar from "../../components/FullCalendar/HeaderCalendar";
import { AppColors } from "../../constants/colors.const";
import { DemandeClientAPI } from "../../domain/apis/demandeClient.api";
import { CaracteristiquesLigneAPI } from "../../domain/apis/caracteristiquesLigne.api";
import { finalize, tap, of, combineLatest, mergeMap } from "rxjs";
import { useRxEffect, doOnSubscribe } from "../../helper/rxjs.helper";
import { selectIsShowTutorial } from "../../app/reducers/indicatorTutorial.reducer";
import DemandeHistory from "./components/DemandeHistory";

const LineSchedule: React.FC = () => {
  const { caracteristiquesLigneId } = useParams();
  const dispatch = useDispatch();
  const caracteristiquesLigne = useAppSelector((state) => state.demandeClient.caracteristiquesLigne);
  const demandeClients = useAppSelector(selectDemandeClients);
  const currentDateObj = useAppSelector(getDateObject);
  const currentBatchAvg = useAppSelector(selectBatchAverages);
  const moyenneDeLotsMax = useAppSelector(selectMoyenneDeLotsMax);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [dateCalendar, setDateCalendar] = useState<{
    start: string;
    end: string;
  }>({ start: "", end: "" });
  const [dateInputObj, setDateInputObj] = useState<DateInput[]>([]);
  const [oldInputDate, setOldInputDate] = useState<DateInput[]>([]);
  const [dateState, setDateState] = useState<boolean>(true);

  const currentYear = moment().get("years");
  const [startYear, setStartYear] = useState(Number(currentYear));
  const [endYear, setEndYear] = useState(Number(currentYear));
  const [selectedYear, setSelectedYear] = useState(Number(currentYear));
  const onSelectYear = (year: string | number) => {
    if (Number(year) !== selectedYear) {
      setSelectedYear(Number(year));
    }
  };

  useRxEffect(
    () =>
      combineLatest([
        // Fetch caracteristiques ligne information
        CaracteristiquesLigneAPI.fetchDetailsById(Number(caracteristiquesLigneId)),
        // Fetch demande clients information
        DemandeClientAPI.fetchAllByCaracteristiquesLigneId(Number(caracteristiquesLigneId)),
      ]).pipe(
        doOnSubscribe(() => setLoading(true)), // Show loading on start
        mergeMap(([caracteristiquesLigne, demandeClients]: [CaracteristiquesLignes, DemandeClient[]]) => {
          const currentLigne = caracteristiquesLigne.caracteristiques;

          if (caracteristiquesLigne && currentLigne) {
            const [ligneDateInit, ligneDateFin] = getRangeDate(currentLigne);

            setDateCalendar({
              start: ligneDateInit,
              end: ligneDateFin,
            });

            const startYear = Number(ligneDateInit.split("/")[0]);
            const endYear = Number(ligneDateFin.split("/")[0]);

            setStartYear(startYear);
            setEndYear(endYear);
            if (!(currentYear >= startYear && currentYear <= endYear)) {
              setSelectedYear(Number(startYear));
            }

            if (caracteristiquesLigne.id && ligneDateInit && ligneDateFin) {
              return CaracteristiquesLigneAPI.calculateBatchAverage(caracteristiquesLigne.id).pipe(
                mergeMap((moyenneDeLots: MoyenneDeLots) =>
                  of({
                    caracteristiquesLigne,
                    demandeClients,
                    ligneDateInit,
                    ligneDateFin,
                    max: moyenneDeLots.max,
                    batchAverages: moyenneDeLots.batchAverages,
                  })
                )
              );
            }
          }

          return of({
            caracteristiquesLigne,
            demandeClients,
          });
        }),
        tap({
          next: ({
            caracteristiquesLigne,
            demandeClients,
            max,
            batchAverages,
          }: {
            caracteristiquesLigne: CaracteristiquesLignes;
            demandeClients: DemandeClient[];
            max?: number;
            batchAverages?: BatchAverage[];
          }) => {
            // Update fetched data to reducer
            dispatch(
              fetchDemandeData({
                caracteristiquesLigne,
                demandeClients,
                moyenneDeLotsMax: max ?? 24,
                batchAverages: batchAverages ?? [],
              })
            );
          },
          error: () => {
            // Navigate outside
            navigate("/dashboard/lines-complete");
          },
        }),
        finalize(() => setLoading(false)) // Hide loading on end
      ),
    []
  );

  useEffect(() => {
    let listDemande: (string | undefined)[] = [];
    if (demandeClients) {
      demandeClients.map((el) => {
        let arrDate = el.demandeClientDates?.map((item) => item.date?.toString());
        if (arrDate) {
          arrDate = arrDate.filter((item) => item !== undefined);
          listDemande = [...listDemande, ...arrDate];
        }
      });
      const arrDate: DateInput[] = [];
      listDemande.map((el) => {
        const currentAvg = currentBatchAvg.find((item) => item.date === el)?.value;
        if (el !== undefined) {
          arrDate.push({
            date: el,
            background: AppColors.COLOR_CALENDAR_DEFAULT_SECONDARY,
            progress: Number(currentAvg),
          });
        }
      });
      setOldInputDate(arrDate);
    }
  }, [demandeClients]);

  useEffect(() => {
    const newCurrentDateObj = currentDateObj.map((el) => {
      const index = oldInputDate.findIndex((item) => item.date === el.date);
      return {
        ...el,
        progress: oldInputDate[index]?.progress ? oldInputDate[index]?.progress : undefined,
      };
    });
    setDateInputObj([...oldInputDate, ...newCurrentDateObj]);
  }, [currentDateObj, oldInputDate]);

  const selectDate = (date: string) => {
    dispatch(
      UpdateSelectedDates({
        currentDate: {
          date,
          state: dateState,
        },
      })
    );
    setDateState(!dateState);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <DemandeHistory />
      <div style={{ width: "76%" }}>
        {loading && <SingleLoading />}
        <PopupContainer />
        <Row style={{ marginTop: "3rem" }}>
          <Col span={5}>
            <LineMenu />
          </Col>
          <Col span={19} style={{ paddingLeft: "2rem" }}>
            <HeaderCalendar
              caracteristiquesLigne={caracteristiquesLigne}
              startYear={startYear}
              endYear={endYear}
              selectedYear={selectedYear}
              onSelectYear={onSelectYear}
              isShowTutorial={isShowTutorial}
            />
            <FullCalendar
              selectedYear={selectedYear}
              startDate={dateCalendar?.start}
              endDate={dateCalendar?.end}
              selectedDates={dateInputObj}
              onChangeDate={(item: string) => selectDate(item)}
              isShowTooltips={false}
            />
            <FooterCalendar moyenneDeLotsMax={moyenneDeLotsMax} showAssociationRate={false} isShowTutorial={isShowTutorial} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LineSchedule;

/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BatchAverage, CaracteristiquesLignes, DemandeClient } from "../../models";
import { RootState } from "../store";

const initialState: {
  caracteristiquesLigne: CaracteristiquesLignes;
  demandeClients: DemandeClient[];
  moyenneDeLotsMax: number;
  batchAverages: BatchAverage[];
  loadingData: boolean;
  loadingAction: boolean;
} = {
  caracteristiquesLigne: {},
  demandeClients: [],
  moyenneDeLotsMax: 24,
  batchAverages: [],
  loadingData: false,
  loadingAction: false,
};

const demandeClientSlice = createSlice({
  name: "demandeClient",
  initialState,
  reducers: {
    resetData: (state) => {
      state.caracteristiquesLigne = initialState.caracteristiquesLigne;
      state.demandeClients = initialState.demandeClients;
      state.moyenneDeLotsMax = initialState.moyenneDeLotsMax;
      state.batchAverages = initialState.batchAverages;
      state.loadingData = initialState.loadingData;
      state.loadingAction = initialState.loadingAction;
    },
    fetchDemandeData: (
      state,
      action: PayloadAction<{
        caracteristiquesLigne: CaracteristiquesLignes;
        demandeClients: DemandeClient[];
        moyenneDeLotsMax: number;
        batchAverages: BatchAverage[];
      }>
    ) => {
      state.loadingData = false;
      state.caracteristiquesLigne = action.payload?.caracteristiquesLigne;
      state.demandeClients = action.payload?.demandeClients;
      state.moyenneDeLotsMax = action.payload?.moyenneDeLotsMax;
      state.batchAverages = action.payload?.batchAverages;
    },
    fetchDemandeClients: (state, action: PayloadAction<{ demandeClients: DemandeClient[] }>) => {
      state.loadingData = false;
      state.demandeClients = action.payload?.demandeClients;
    },

    loadingData: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.loadingData = action.payload.loading;
    },
    loadingAction: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.loadingAction = action.payload.loading;
    },
    updateHistoryData: (
      state,
      action: PayloadAction<{ demandeClients: DemandeClient[]; moyenneDeLotsMax: number; batchAverages: BatchAverage[] }>
    ) => {
      state.demandeClients = action.payload.demandeClients;
      state.moyenneDeLotsMax = action.payload.moyenneDeLotsMax;
      state.batchAverages = action.payload.batchAverages;
    },
  },
});

export const { fetchDemandeData, fetchDemandeClients, loadingData, loadingAction, updateHistoryData } = demandeClientSlice.actions;
export const selectCaracteristiquesLigne = (state: RootState) => state.demandeClient.caracteristiquesLigne;
export const selectDemandeClients = (state: RootState) => state.demandeClient.demandeClients;
export const selectMoyenneDeLotsMax = (state: RootState) => state.demandeClient.moyenneDeLotsMax;
export const selectBatchAverages = (state: RootState) => state.demandeClient.batchAverages;
export const selectLoadingData = (state: RootState) => state.demandeClient.loadingData;
export const selectLoadingAction = (state: RootState) => state.demandeClient.loadingAction;

export default demandeClientSlice.reducer;

/** @format */

import { useEffect, useState } from "react";
import axios from "axios";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import L, { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-polylinedecorator";
import { notification, Spin } from "antd";
import { FormModel } from "../models/TrajetModel";
import { Pays, Ville } from "../../../../../models";
import { fetchLocation } from "../trajet.helper";
import { AppConfig } from "../../../../../AppConfig";

/* eslint-disable @typescript-eslint/no-explicit-any */
function PolylineDecorator({ center, polyline }: { center: any; polyline: any[] }) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;
    map.panTo(new L.LatLng(center[1], center[0]));
  }, [JSON.stringify(center), polyline]);

  useEffect(() => {
    if (!map) return;
    const newPolyline: any = polyline.map((el) => {
      return [el[1], el[0]];
    });

    L.polyline(newPolyline).addTo(map);

    if (newPolyline.length > 0) {
      map.fitBounds(newPolyline);
    }
  }, [map, polyline]);

  return null;
}

type MapProps = {
  initData: FormModel;
  paysData: Pays[];
  villeData: Ville[];
  departLatlng?: string | null;
  arriveLatlng?: string | null;
};

const MapDirection = (props: MapProps) => {
  const { departLatlng, arriveLatlng, initData, paysData, villeData } = props;
  const [listCoors, setListCoors] = useState<LatLngExpression[]>([]);
  const [center, setCenter] = useState<any>([2.3391913768362786, 48.85350635761789]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [departLocation, setDepartLocation] = useState<string | undefined | null>(departLatlng);
  const [arriveLocation, setArriveLocation] = useState<string | undefined | null>(arriveLatlng);

  useEffect(() => {
    if (departLocation && arriveLocation) {
      calcRouter(departLocation, arriveLocation);
      return;
    }
    if (departLocation === null || arriveLocation === null) {
      setListCoors([]);
    }
  }, [departLocation, arriveLocation]);

  useEffect(() => {
    getLocation();
  }, [initData, paysData, villeData]);

  async function getLocation() {
    if (paysData.length > 0) {
      setIsLoading(true);
      const [fetchDepart, fetchArrive] = await Promise.all([
        fetchLocation({
          location: {
            lieuxId: initData.lieuxDepartId,
            paysId: initData.paysDepartId,
            departementId: initData.departementDepartId,
            codePostal: initData.codePostalDepart,
            ville: initData.villeDepart,
            adresse: initData.adresseDepart,
          },
          paysData,
          villeData,
        }),
        fetchLocation({
          location: {
            lieuxId: initData.lieuxArriveeId,
            paysId: initData.paysArriveeId,
            departementId: initData.departementArriveeId,
            codePostal: initData.codePostalArrivee,
            ville: initData.villeArrivee,
            adresse: initData.adresseArrivee,
          },
          paysData,
          villeData,
        }),
      ]);
      setIsLoading(false);
      setDepartLocation(fetchDepart);
      setArriveLocation(fetchArrive);
    }
  }

  function calcRouter(startLocation: string, endLocation: string) {
    setIsLoading(true);
    axios({
      method: "GET",
      headers: {
        ApiKey: AppConfig.PTVKey,
      },
      url: `https://api.myptv.com/routing/v1/routes?waypoints=${startLocation}&waypoints=${endLocation}&results=POLYLINE&options[trafficMode]=AVERAGE`,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.data.polyline) {
          const polyLineData = JSON.parse(res.data.polyline);
          if (polyLineData.coordinates && polyLineData.coordinates.length > 0) {
            setListCoors(polyLineData.coordinates);
          }
        }
      })
      .catch(() => {
        setIsLoading(false);
        notification.warning({
          key: "Error",
          message: "Error",
          placement: "top",
        });
      });
    const start = startLocation.split(",");
    const end = endLocation.split(",");
    const newCenter = [(+start[1] + +end[1]) / 2, (+start[0] + +end[0]) / 2];
    if (newCenter) {
      setCenter(newCenter);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <MapContainer key={Math.random()} center={[0, 0]} zoom={12} style={{ height: "500px" }}>
        <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        <PolylineDecorator center={center} polyline={listCoors} />
      </MapContainer>
    </Spin>
  );
};

export default MapDirection;

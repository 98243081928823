/** @format */

import { FunctionComponent, PropsWithChildren, ReactElement, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { finalize, tap } from "rxjs";
import { useAppSelector } from "../app/hooks";
import { selectUser, updateUser, userLogout } from "../app/reducers/auth.reducer";
import GlobalLoading from "../components/GlobalLoading.component";
import { AccountAPI } from "../domain/apis/account.api";
import { doOnSubscribe, useRxEffect } from "../helper/rxjs.helper";
import { getToken } from "../helper/userToken";
import { Account } from "../models";

const AuthGuard: FunctionComponent<PropsWithChildren<{ levels?: string[] }>> = ({ children, levels }) => {
  const dispatch = useDispatch();
  const currentUser = useAppSelector(selectUser);
  const [isLoading, setIsLoading] = useState(true);
  const userToken = getToken();
  const getMe$ = useMemo(
    () =>
      AccountAPI.getMe().pipe(
        doOnSubscribe(() => setIsLoading(true)),
        tap({
          next: (user: Account) => {
            dispatch(
              updateUser({
                user: user,
              })
            );
          },
          error: () => {
            dispatch(userLogout());
          },
        }),
        finalize(() => setIsLoading(false)) // hide loading on end
      ),
    []
  );

  // get user info on start
  useRxEffect(() => getMe$, []);

  if (userToken.length === 0) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return <GlobalLoading />;
  }

  if (userToken) {
    if (levels && !levels.includes(currentUser?.typeRole as string)) {
      return <Navigate to="/layout-guard-roles" />;
    }
    if (currentUser?.activation === false) {
      dispatch(userLogout());
    }
    return children as ReactElement;
  }

  return <Navigate to="/" />;
};

export default AuthGuard;

/** @format */

import { InboxOutlined } from "@ant-design/icons";
import { Button, Form, message, Select, Spin, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { combineLatest, finalize, tap } from "rxjs";
import { useAppSelector } from "../../../app/hooks";
import { selectIsShowTutorial } from "../../../app/reducers/indicatorTutorial.reducer";
import { selectLigne, selectLigneRequest, updateLigneRequest } from "../../../app/reducers/ligne.reducer";
import SearchIcon from "../../../assets/icons/search.svg";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomSelect from "../../../components/CustomSelect";
import IndicatorTutorialView from "../../../components/IndicatorTutorial";
import { AgenceAPI } from "../../../domain/apis/agence.api";
import { DroitAPI } from "../../../domain/apis/droit.api";
import { SocieteAPI } from "../../../domain/apis/societe.api";
import { doOnSubscribe, useRxEffect } from "../../../helper/rxjs.helper";
import { Agence, Client, Exploitant, Societe } from "../../../models";
import { Droit } from "../../../models/droit.model";
import UpdateLigne from "../UpdateLigne";
import styles from "./main.module.css";

const { Title } = Typography;

const { Option } = Select;

interface FormModal {
  societeId: number | null;
  checkFacture: boolean;
  societeFactureId: number | null;
  agenceId: number | null;
  exploitantId: number | null;
}

const Assignation = (props: { editable: boolean }) => {
  const { editable } = props;
  const lineData = useAppSelector(selectLigne);
  const ligneRequest = useAppSelector(selectLigneRequest);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [formValue, setFormValue] = useState<FormModal>({
    societeId: null,
    checkFacture: false,
    societeFactureId: null,
    agenceId: null,
    exploitantId: null,
  });

  const [societeList, setSocieteList] = useState<Societe[]>([]);
  const [societeFactureList, setSocieteFactureList] = useState<Societe[]>([]);
  const [agenceList, setAgenceList] = useState<Agence[]>([]);
  const [exploitantList, setExploitantList] = useState<Exploitant[]>([]);

  const [selectedSociete, setSelectedSociete] = useState(lineData.societeId);
  const [selectedAgence, setSelectedAgence] = useState(lineData.agenceId);
  const [fetching, setFetching] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  const fetchSocietes$ = useMemo(
    () =>
      SocieteAPI.fetchAll().pipe(
        tap({
          next: (societes: Societe[]) => {
            setSocieteList(societes);
            setSocieteFactureList(societes);
          },
        })
      ),
    []
  );
  const fetchAgence$ = useMemo(
    () =>
      AgenceAPI.fetchAll().pipe(
        tap({
          next: (agences: Agence[]) => {
            setAgenceList(agences);
          },
        })
      ),
    []
  );
  const fetchExploitants$ = (societeId?: number, agenceId?: number) =>
    DroitAPI.fetchAll({
      filter: {
        include: ["exploitant"],
        where: {
          societeId,
          agenceId,
        },
      },
    }).pipe(
      tap({
        next: (droits: Droit[]) => {
          const listExp: Exploitant[] = droits
            .filter((el: Droit) => el.exploitant)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map((el: Droit) => el.exploitant!);
          setExploitantList(listExp);
          if (formValue.exploitantId && !listExp.map((el: Exploitant) => el.id).includes(formValue.exploitantId)) {
            form.setFieldsValue({
              exploitantId: null,
            });
          }
        },
      })
    );

  useEffect(() => {
    form.setFieldsValue({
      societeId: lineData.societeId ? Number(lineData.societeId) : null,
      checkFacture: lineData.societeId && lineData.societeId === lineData.societeFactureId,
      societeFactureId: lineData.societeFactureId ? Number(lineData.societeFactureId) : null,
      agenceId: lineData.agenceId ? Number(lineData.agenceId) : null,
      exploitantId: lineData.exploitantId ? Number(lineData.exploitantId) : null,
    });

    if (lineData.societeId) {
      setFormValue({
        societeId: Number(lineData.societeId),
        checkFacture: lineData.societeId === lineData.societeFactureId,
        societeFactureId: Number(lineData.societeFactureId),
        agenceId: Number(lineData.agenceId),
        exploitantId: Number(lineData.exploitantId),
      });
    }
  }, [lineData]);

  useRxEffect(
    () =>
      combineLatest([fetchSocietes$, fetchAgence$, fetchExploitants$(selectedSociete, selectedAgence)]).pipe(
        doOnSubscribe(() => setFetching(true)), // Show loading on start
        finalize(() => setFetching(false)) // Hide loading on end
      ),
    []
  );

  const onValuesChange = () => {
    const getFieldsValue = form.getFieldsValue();
    if (getFieldsValue.checkFacture) {
      setSocieteFactureList(societeList);
      form.setFieldsValue({
        ...getFieldsValue,
        societeFactureId: getFieldsValue.societeId,
      });
      setFormValue({
        ...getFieldsValue,
        societeFactureId: getFieldsValue.societeId,
      });
    } else {
      setSocieteFactureList(societeList.filter((el) => el.id !== Number(getFieldsValue.societeId)));
      form.setFieldsValue({
        ...getFieldsValue,
        societeFactureId: getFieldsValue.societeId !== getFieldsValue.societeFactureId ? getFieldsValue.societeFactureId : "",
      });
      setFormValue({
        ...getFieldsValue,
        societeFactureId: getFieldsValue.societeId !== getFieldsValue.societeFactureId ? getFieldsValue.societeFactureId : "",
      });
    }
    setSelectedSociete(getFieldsValue.societeId);
    setSelectedAgence(getFieldsValue.agenceId);
    fetchExploitants$(getFieldsValue.societeId, getFieldsValue.agenceId)
      .pipe(
        doOnSubscribe(() => setFetching(true)), // Show loading on start
        finalize(() => setFetching(false)) // Hide loading on end
      )
      .subscribe();
  };

  const onFinish = (values: FormModal) => {
    dispatch(
      updateLigneRequest({
        ligneRequest: {
          ...ligneRequest,
          societeId: Number(values.societeId),
          societeFactureId: Number(values.societeFactureId),
          agenceId: Number(values.agenceId),
          exploitantId: Number(values.exploitantId),
          currentStage: Number(lineData.currentStage) + 1,
        },
      })
    );
    setIsSubmit(true);
  };

  const onFinishFailed = () => {
    message.error("Merci de compléter toutes les colonnes.").then((r) => console.log(r));
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <>
      {isSubmit && <UpdateLigne />}
      <Form
        {...formItemLayout}
        form={form}
        name="clientForm"
        initialValues={formValue}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        onValuesChange={onValuesChange}
        colon={false}
        disabled={!editable}
      >
        <Form.Item label=" ">
          <Title level={3} className={styles.title}>
            Assignation
          </Title>
        </Form.Item>

        <Form.Item
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              Société
              {isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView hint={"Société gérant cette ligne."} />
                </div>
              ) : (
                <></>
              )}
            </div>
          }
          name="societeId"
          rules={[{ required: true, message: "" }]}
        >
          <CustomSelect showArrow={false} bordered={false} suffixIcon={<img src={SearchIcon} alt="Search" />}>
            {societeList.map((el: Client) => {
              return (
                <Option key={el.id} value={el.id}>
                  {el.code} - {el.nom}
                </Option>
              );
            })}
          </CustomSelect>
        </Form.Item>
        <Form.Item label=" " valuePropName="checked" name="checkFacture">
          <CustomCheckbox disabled={!editable}>Société qui facture</CustomCheckbox>
        </Form.Item>

        <Form.Item
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              Facturation
              {isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView hint={"Société facturant cette ligne."} />
                </div>
              ) : (
                <></>
              )}
            </div>
          }
          name="societeFactureId"
          rules={[{ required: true, message: "" }]}
        >
          <CustomSelect showArrow={false} bordered={false} disabled={formValue.checkFacture}>
            {societeFactureList.map((el: Client) => {
              return (
                <Option key={el.id} value={el.id}>
                  {el.code} - {el.nom}
                </Option>
              );
            })}
          </CustomSelect>
        </Form.Item>

        <Form.Item
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              Agence
              {isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView hint={"Agence gérant cette ligne."} />
                </div>
              ) : (
                <></>
              )}
            </div>
          }
          name="agenceId"
        >
          <CustomSelect showArrow={false} bordered={false} allowClear={true}>
            {agenceList.map((el: Client) => {
              return (
                <Option key={el.id} value={el.id}>
                  {el.code} - {el.nom}
                </Option>
              );
            })}
          </CustomSelect>
        </Form.Item>

        <Form.Item
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              Exploitant
              {isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView hint={"Exploitant gérant cette ligne."} />
                </div>
              ) : (
                <></>
              )}
            </div>
          }
          name="exploitantId"
        >
          <CustomSelect
            showArrow={false}
            bordered={false}
            allowClear={true}
            disabled={!editable || !selectedAgence || !selectedSociete}
            notFoundContent={
              fetching ? (
                <Spin size="small" />
              ) : (
                <div style={{ textAlign: "center", padding: "1rem" }}>
                  <InboxOutlined style={{ fontSize: "2rem" }} />
                  <br />
                  No Data
                </div>
              )
            }
          >
            {exploitantList.map((el: Client) => {
              return (
                <Option key={el.id} value={el.id}>
                  {el.code} - {el.nom}
                </Option>
              );
            })}
          </CustomSelect>
        </Form.Item>
        <Form.Item label=" " className={styles.submit}>
          {editable && (
            <Button type="primary" htmlType="submit" className={styles.bt}>
              Confirmer
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default Assignation;

/** @format */

import { Col, Row, Tooltip, Typography } from "antd";
import moment from "moment";
import styles from "./FullCalendar.module.css";
import { capitalizeFirstLetter } from "../../helper/stringExtension";
import { CalendarProps, DateInput } from "./index";
import ProgressOutline from "../ProgressOutline/ProgressOutline";
import React from "react";

export interface DateTooltipProps {
  label: string;
  value: string;
}

interface MonthCalendarProps extends CalendarProps {
  month: number;
  year: number;
  isShowTooltips: boolean;
  dateTooltips: Map<string, DateTooltipProps[]>;
}

const MonthCalendar = (props: MonthCalendarProps) => {
  const { startDate, endDate, selectedDates, onChangeDate, holidays, whiteList } = props;

  // Start day of the month
  const startDayOfMonth = moment().set("year", props.year).set("month", props.month).startOf("month").format("YYYY/MM/DD");
  // End day of the month
  const endDayOfMonth = moment().set("year", props.year).set("month", props.month).endOf("month").format("YYYY/MM/DD");
  // First monday of the month
  const startDayOfCalendar = moment(startDayOfMonth).startOf("isoWeeks").startOf("day").format("YYYY/MM/DD");

  // Settings for calendar
  const numberOfRows = 6;
  const numberOfColumns = 7;

  // Initial data for calendar by month
  const calendarDays = new Array(numberOfRows * numberOfColumns);
  for (let i = 0; i < calendarDays.length; i++) {
    calendarDays[i] = moment(startDayOfCalendar).add(i, "days").format("YYYY/MM/DD");
  }

  const isCurrentDate = (value: string) => {
    return value === moment().format("YYYY/MM/DD") && value > startDayOfMonth && value < endDayOfMonth;
  };

  const isDisabled = (date: string) => {
    return (
      (whiteList && !whiteList.includes(date)) ||
      date < startDayOfMonth ||
      date > endDayOfMonth ||
      (startDate && date < startDate) ||
      (endDate && date > endDate)
    );
  };

  return (
    <div className={styles.calendarWrapper}>
      <Typography.Title level={5} style={{ fontSize: "12px", padding: 8, marginTop: 16 }}>
        {capitalizeFirstLetter(moment(startDayOfMonth).format("MMMM"))}
      </Typography.Title>
      {Array(numberOfRows)
        .fill(0)
        .map((_, rowIndex) => {
          return (
            <Row key={`row${rowIndex}`} style={{ justifyContent: "space-between" }}>
              {Array(numberOfColumns)
                .fill(0)
                .map((_, colIndex) => {
                  const day = calendarDays[rowIndex * numberOfColumns + colIndex];
                  const dateTooltips: DateTooltipProps[] = props.dateTooltips.get(day) ?? [];

                  const isCurrent = isCurrentDate(day);
                  const disabled = isDisabled(day);

                  let color = "";
                  let background = "";
                  let border = 0;
                  let progress = 0;

                  if (!disabled) {
                    selectedDates?.map((value: DateInput) => {
                      if (value.date === day && !holidays?.includes(day)) {
                        color = value.color ? value.color : "";
                        background = value.background ? value.background : "";
                        border = value.border ? value.border : 0;
                        progress = value.progress ? value.progress : 0;
                        return;
                      }
                      return;
                    });
                  }

                  return (
                    <Col
                      span={3}
                      key={`col${rowIndex}_${colIndex}`}
                      className={`${[styles.dayContainer, isCurrent ? styles.current : null, disabled ? styles.disabled : null].join(" ")}`}
                      onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        onChangeDate && !disabled && !holidays?.includes(day) ? onChangeDate(day) : null;
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        {!disabled && props.isShowTooltips ? (
                          <Tooltip
                            placement="topRight"
                            arrowPointAtCenter={true}
                            title={
                              <>
                                {dateTooltips.map((item) => (
                                  <Row key={`tooltip${(Math.random() + 1).toString(36).substring(7)}`}>
                                    <Col style={{ width: "90px" }}>{item.label}</Col>
                                    <Col
                                      style={{
                                        width: "34px",
                                        textAlign: "end",
                                        color: item.label === "Restants" && Number(item.value) > 0 ? "red" : "black",
                                      }}
                                    >
                                      {Number(item.value) > 0 ? item.value : 0}
                                    </Col>
                                  </Row>
                                ))}
                              </>
                            }
                            overlayStyle={{
                              fontSize: "0.75rem",
                            }}
                            // overlayClassName={styles.tooltip}
                            color={"#DCDCDC"}
                            overlayInnerStyle={{ color: "black", borderRadius: "0.5rem" }}
                          >
                            <div className={styles.numberContainer}>
                              <span style={{ background: background, color: color, borderWidth: border }}>
                                {day.slice(-2)}
                                {progress > 0 && <ProgressOutline progress={progress} />}
                              </span>
                            </div>
                          </Tooltip>
                        ) : (
                          <div className={styles.numberContainer}>
                            <span style={{ background: background, color: color, borderWidth: border }}>
                              {day.slice(-2)}
                              {progress > 0 && <ProgressOutline progress={progress} />}
                            </span>
                          </div>
                        )}
                      </div>

                      {isCurrent && <div className={styles.dateContainer}>{capitalizeFirstLetter(moment(day).format("dddd"))}</div>}
                    </Col>
                  );
                })}
            </Row>
          );
        })}
    </div>
  );
};

export default MonthCalendar;

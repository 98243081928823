import {notification} from "antd";
import {getRangeDate} from "../../../helper/getRangeDate";
import {Caracteristiques} from "../../../models";
import moment from "moment";
import {DATE_FORMAT, DemandeClientSubType, DemandeClientType} from "../../../helper/calendarHelper";
import {useEffect, useState} from "react";

export interface IgnoreDate {
  currentLigne: Caracteristiques;
  type: DemandeClientType,
  subType: DemandeClientSubType
}

const ToastDateSemaineGlobal = (props: IgnoreDate) => {
  const {currentLigne, type, subType} = props;
  const [dateArr, setDateArr] = useState<string[]>([]);

  useEffect(() => {
    const [startDate, endDate] = getRangeDate(currentLigne);
    const startWeekInit = moment(startDate).startOf("week").format("YYYY/MM/DD");
    const endWeekFin = moment(endDate).endOf("week").format("YYYY/MM/DD");
    const toastArr = [];
    if (startDate > startWeekInit) {
      toastArr.push(`de ${moment(startDate).format(DATE_FORMAT)} à ${moment(startDate).endOf("week").format(DATE_FORMAT)}`);
    }
    if (endDate < endWeekFin) {
      toastArr.push(`de ${moment(endDate).startOf("week").format(DATE_FORMAT)} à ${moment(endDate).format(DATE_FORMAT)}`);
    }
    setDateArr(toastArr);
  }, [type, subType]);

  if (dateArr.length > 0) {
    notification.warning({
      key: "notiStartEnd",
      message: 'Attention',
      onClose: () => {setDateArr([])},
      description:
        `Attention, les jours se trouvant en début et fin de contrat (${dateArr.join(" et ")}) ne seront pas pris en compte.`
    });
  }

  return (
    <></>
  )
}

export default ToastDateSemaineGlobal

/** @format */

import { useEffect } from "react";
import { noop, Observable } from "rxjs";
import { defer } from "rxjs/internal/observable/defer";

/** Example
useRxEffect(() => observable$, []);
*/
export function useRxEffect(factory: () => Observable<unknown>, deps: unknown[]) {
  useEffect(() => {
    const subscription = factory().subscribe({
      error: noop,
    });
    return () => subscription.unsubscribe();
  }, deps);
}

/** Example
import {from} from 'rxjs/observable/from';
from([1, 2, 3])
    .pipe(doOnSubscribe(() => console.log('subscribed to stream')))
    .subscribe(x => console.log(x), null, () => console.log('completed'));
*/
export function doOnSubscribe<T>(onSubscribe: () => void): (source: Observable<T>) => Observable<T> {
  return function inner(source: Observable<T>): Observable<T> {
    return defer(() => {
      onSubscribe();

      return source;
    });
  };
}

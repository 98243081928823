/** @format */

import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth.reducer";
import demandeClientReducer from "./reducers/demandeClient.reducer";
import CurrentDemandeReducer from "./reducers/DemandeClient/CurrentDemande.reducer";
import DatesObjectReducer from "./reducers/DemandeClient/DatesObject.reducer";
import DemandeStateReducer from "./reducers/DemandeClient/DemandeState.reducer";
import NewDemandeReducer from "./reducers/DemandeClient/NewDemande.reducer";
import PopupReducer from "./reducers/DemandeClient/Popup.reducer";
import SelectedDatesReducer from "./reducers/DemandeClient/SelectedDates.reducer";
import ligneReducer from "./reducers/ligne.reducer";
import loadingReducer from "./reducers/loading.reducer";
import CurrentChoixTransporteurReducer from "./reducers/Transporter/CurrentChoixTransporteur.reducer";
import CurrentTransporteurDetailReducer from "./reducers/Transporter/CurrentTransporteurDetail.reducer";
import TransDatesObjectReducer from "./reducers/Transporter/DatesObject.reducer";
import ListTransporteurDetailReducer from "./reducers/Transporter/ListTransporteurDetail.reducer";
import NewTransporteurDetailReducer from "./reducers/Transporter/NewTransporteurDetail.reducer";
import TransSelectedDatesReducer from "./reducers/Transporter/SelectedDates.reducer";
import TransPopupReducer from "./reducers/Transporter/TransPopup.reducer";
import TransporteurDetailDateTypeReducer from "./reducers/Transporter/TransporteurDetailDateType.reducer";
import TransporteurDetailStateReducer from "./reducers/Transporter/TransporteurDetailState.reducer";
import ListPaysReducer from "./reducers/DemandeClient/ListPays.reducer";
import choixTransporteurReducer from "./reducers/transporteur.reducer";
import FilterDatesByAssociateRateReducer from "./reducers/Transporter/FilterDatesByAssociateRate.reducer";
import NewFavoriteSlotReducer from "./reducers/FavoriteSlot/NewSlot.reducer";
import indicatorTutorialReducer from "./reducers/indicatorTutorial.reducer";
import CaracteristiquesPopupReducer from "./reducers/Caracteristiques/CaracteristiquesPopup.reducer";
import indicatorGuideReducer from "./reducers/indicatorGuide.reducer";
import demandeHistoryReducer from "./reducers/DemandeClient/DemandeClientHistory.reducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    loading: loadingReducer,
    ligne: ligneReducer,
    demandeClient: demandeClientReducer,
    choixTransporteur: choixTransporteurReducer,
    demandeState: DemandeStateReducer,
    selectedDates: SelectedDatesReducer,
    DatesObject: DatesObjectReducer,
    transPopup: TransPopupReducer,
    transDate: TransSelectedDatesReducer,
    transDatesObject: TransDatesObjectReducer,
    popupType: PopupReducer,
    currentDemande: CurrentDemandeReducer,
    newDemande: NewDemandeReducer,
    currentTransporteurDetail: CurrentTransporteurDetailReducer,
    newTransporteurDetail: NewTransporteurDetailReducer,
    transporteurDetailDateType: TransporteurDetailDateTypeReducer,
    currentChoixTransporteur: CurrentChoixTransporteurReducer,
    transporteurDetailState: TransporteurDetailStateReducer,
    listTransporteurDetails: ListTransporteurDetailReducer,
    listPays: ListPaysReducer,
    filterDatesByAssociateRate: FilterDatesByAssociateRateReducer,
    newFavoriteSlot: NewFavoriteSlotReducer,
    indicatorTutorial: indicatorTutorialReducer,
    caracteristiquesPopup: CaracteristiquesPopupReducer,
    indicatiorGuide: indicatorGuideReducer,
    demandeHistory: demandeHistoryReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

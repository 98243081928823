/** @format */

import { CaretDownOutlined, CaretLeftOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Button, Col, List, message, Row, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { finalize, mergeMap, noop, Observable, of, tap } from "rxjs";
import { useAppSelector } from "../../../app/hooks";
import { selectUser } from "../../../app/reducers/auth.reducer";
import { CaracteristiquesPopupType, UpdateCaracteristiquesPopup } from "../../../app/reducers/Caracteristiques/CaracteristiquesPopup.reducer";
import { selectCaracteristiquesLigne, selectDemandeClients } from "../../../app/reducers/demandeClient.reducer";
import { getCurrentDemande, UpdateCurrentDemande } from "../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import { UpdateDatesObj } from "../../../app/reducers/DemandeClient/DatesObject.reducer";
import { ActionType, selectDemandeState, UpdateDemandeState } from "../../../app/reducers/DemandeClient/DemandeState.reducer";
import { UpdateNewDemande } from "../../../app/reducers/DemandeClient/NewDemande.reducer";
import { PopupType, updatePopUp } from "../../../app/reducers/DemandeClient/Popup.reducer";
import { UpdateSelectedDates } from "../../../app/reducers/DemandeClient/SelectedDates.reducer";
import DeleteIcon from "../../../assets/icons/delete.svg";
import EditIcon from "../../../assets/icons/edit.svg";
import SingleLoading from "../../../components/SingleLoading";
import { CaracteristiqueAPI } from "../../../domain/apis/caracteristique.api";
import { DemandeClientSubType, DemandeClientType } from "../../../helper/calendarHelper";
import { doOnSubscribe } from "../../../helper/rxjs.helper";
import { Caracteristiques, DemandeClient } from "../../../models";
import PopupDetail from "../../contractLine/components/Popup/PopupDetail";
import AddDemandeClient from "./AddDemandeClient";
import styles from "./LineMenu.module.css";
import PreviewDemande from "./Preview";
import { AxiosError } from "axios";
import { CalculateDiffTotalTransporteurRequestParams } from "../../../models/requests/calculateDiffTotalTransporteur.model";
import { CaracteristiquesLigneAPI } from "../../../domain/apis/caracteristiquesLigne.api";
import { CalculateDiffTotalTransporteurResponse } from "../../../models/responses/calculateDiffTotalTransporteur.model";
import IndicatorTutorialView from "../../../components/IndicatorTutorial";
import { selectIsShowTutorial } from "../../../app/reducers/indicatorTutorial.reducer";

const LineMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentDemande = useAppSelector(getCurrentDemande);
  const ligneData = useAppSelector(selectCaracteristiquesLigne);
  const currentLigne = ligneData.caracteristiques;
  const demandeState = useAppSelector(selectDemandeState);
  const demandeClients = useAppSelector(selectDemandeClients);
  const currentUser = useAppSelector(selectUser);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);

  const [currentState, setCurrentState] = useState<ActionType>(ActionType.VIEW);
  const [demandeClientId, setDemandeClientId] = useState<number | undefined>(undefined);
  const [demandeSelected, setDemandeSelected] = useState<DemandeClient | undefined>(undefined);
  const [listDemandeClients, setListDemandeClient] = useState<DemandeClient[]>(demandeClients);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentState !== demandeState.actionType) {
      setCurrentState(demandeState.actionType);
    }
  }, [demandeState]);

  useEffect(() => {
    setListDemandeClient(demandeClients);
  }, [demandeClients]);

  useEffect(() => {
    dispatch(UpdateDatesObj([]));
    dispatch(
      UpdateSelectedDates({
        currentDate: undefined,
      })
    );
    if (currentState !== ActionType.UPDATE) {
      dispatch(UpdateCurrentDemande({}));
    }
    setDemandeClientId(undefined);
    setDemandeSelected(undefined);
  }, [currentState]);

  const backEditLigne = () => {
    if (currentLigne?.id) {
      CaracteristiqueAPI.fetchDetail(currentLigne?.id)
        .pipe(
          doOnSubscribe(() => setLoading(true)),
          mergeMap((currentLigne: Caracteristiques) => {
            if (currentLigne.updatedAt) {
              const updatedAt = new Date(currentLigne.updatedAt);
              const currentTime = new Date();
              const diff = (currentTime.getTime() - updatedAt.getTime()) / 1000;

              if (currentLigne.editById !== currentUser?.id && diff <= 30) {
                dispatch(UpdateCaracteristiquesPopup({ type: CaracteristiquesPopupType.ERROR_EDIT_MORE_ONE_PERSON }));
                return of(true);
              } else {
                return CaracteristiqueAPI.upsertCaracteristiques({
                  id: Number(currentLigne?.id),
                  currentStage: 9,
                  editById: currentUser?.id,
                }).pipe(
                  tap({
                    next: () => {
                      navigate(`/dashboard/contracted-line/${currentLigne?.id}`);
                    },
                    error: () => {
                      message.error("Erreur");
                    },
                  }),
                  mergeMap(() => of(true))
                );
              }
            } else {
              return of(true);
            }
          }),
          tap({
            error: (errorMessage: AxiosError) => {
              if (errorMessage.response && errorMessage.response.status === 404) {
                dispatch(UpdateCaracteristiquesPopup({ type: CaracteristiquesPopupType.ERROR_EDIT_DELETED_ITEM }));
              }
            },
          }),
          finalize(() => setLoading(false)) // Hide loading on end
        )
        .subscribe({ error: noop });
    }
  };

  const gotoTransport = () => {
    navigate(`/dashboard/lines-contracted/${ligneData?.id}`);
  };

  const createDetail = () => {
    setCurrentState(ActionType.CREATE);
    dispatch(UpdateDemandeState({ actionType: ActionType.CREATE }));
    dispatch(UpdateCurrentDemande({}));
    dispatch(
      UpdateNewDemande({
        type: DemandeClientType.JOUR,
        chargementTime: undefined,
        dechargementTime: undefined,
        demandeClientDates: undefined,
        moisGlobalOptions: undefined,
        totalMaxLot: undefined,
        totalMinLot: undefined,
      })
    );
  };

  const removeDetail = () => {
    setCurrentState(ActionType.VIEW);
    dispatch(UpdateDemandeState({ actionType: ActionType.VIEW }));
  };

  const onExpandCollapse = (demande: DemandeClient) => {
    setDemandeClientId(demande.id === demandeClientId ? undefined : demande.id);
    setDemandeSelected(demande.id === demandeClientId ? undefined : demande);
    if (demande.id === demandeClientId) {
      dispatch(UpdateCurrentDemande({}));
      dispatch(UpdateDatesObj([]));
    } else {
      dispatch(UpdateCurrentDemande(demande));
    }
  };

  /**
   * Edit demande client
   *
   * @param demandeClient object need to edit
   */
  const editItem = (demandeClient: DemandeClient) => {
    dispatch(UpdateCurrentDemande(demandeClient));
    dispatch(UpdateDemandeState({ actionType: ActionType.UPDATE }));
  };

  const removeItem = () => {
    // Check diff total transporteur, if not changed show confirm delete AC7, if changed show B78 AC1
    checkDiffTotalTransporteur$()
      .pipe(
        doOnSubscribe(() => setLoading(true)), // Show loading on start
        tap({
          error: () => {
            dispatch(updatePopUp({ type: PopupType.ERROR }));
          },
        }),
        finalize(() => setLoading(false)) // Hide loading on end
      )
      .subscribe({ error: noop });
    // dispatch(updatePopUp({ type: PopupType.DELETE }));
  };

  const checkDiffTotalTransporteur$ = (): Observable<boolean> => {
    const data: CalculateDiffTotalTransporteurRequestParams[] = generateChangedDates(currentDemande);
    return CaracteristiquesLigneAPI.calculateDiffTotalTransporteur(Number(ligneData?.id), data).pipe(
      mergeMap((response: CalculateDiffTotalTransporteurResponse[]) => {
        const diffs = response
          .filter((diff) => diff.diff !== 0)
          .map((el) => {
            return {
              ...el,
              diff: Number(el.diff?.toFixed(2)),
            };
          });
        if (diffs.length > 0) {
          const diffTransporteurs = [];
          let tempDiff = { diff: 0, startDate: "", endDate: "string" };
          for (let i = 0; i < diffs.length; i++) {
            const diff = diffs[i];
            if (i != 0) {
              const beforeDiff = diffs[i - 1];
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const diffDate = new Date(diff.date!);
              diffDate.setDate(diffDate.getDate() - 1);
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const beforeDiffDate = new Date(beforeDiff.date!);
              if (diff.diff == beforeDiff.diff && diffDate.getTime() === beforeDiffDate.getTime()) {
                // dates in a row
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                tempDiff.endDate = diff.date!;
              } else {
                // add
                diffTransporteurs.push(tempDiff);
                // reset
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                tempDiff = { diff: diff.diff!, startDate: diff.date!, endDate: diff.date! };
              }
            } else {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              tempDiff = { diff: diff.diff!, startDate: diff.date!, endDate: diff.date! };
            }
          }
          diffTransporteurs.push(tempDiff);

          dispatch(
            updatePopUp({
              type: PopupType.DIFF_TOTAL_TRANSPORTEUR,
              diffTotalTransporteurs: diffTransporteurs,
            })
          );
          return of(true);
        } else {
          dispatch(updatePopUp({ type: PopupType.DELETE }));
          return of(true);
        }
      })
    );
  };

  const generateChangedDates = (currentDemande?: DemandeClient): CalculateDiffTotalTransporteurRequestParams[] => {
    const currentDemandeDates = currentDemande?.demandeClientDates ?? [];
    const allDates: string[] = currentDemandeDates.map((demandeDate) => demandeDate.date ?? "").filter((date) => date.length > 0);
    return allDates.map((date: string) => {
      let diff: number;
      const currentDemandeDate = currentDemandeDates.find((demandeDate) => demandeDate.date === date);
      if (currentDemande?.type === DemandeClientType.SEMAINE && currentDemande?.subType === DemandeClientSubType.GLOBAL) {
        diff = -((currentDemande?.totalMaxLot ?? 0) / 7);
      } else if (currentDemande?.type === DemandeClientType.MOIS && currentDemande?.subType === DemandeClientSubType.GLOBAL) {
        const currentTotalMaxLot = currentDemande?.moisGlobalOptions?.find((item) => item.month === new Date(date).getMonth())?.totalMaxLot ?? 0;
        diff = -((currentTotalMaxLot * 12) / 365);
      } else {
        diff = -(currentDemandeDate?.lotMax ?? 0);
      }
      return {
        ...(currentDemande?.type == DemandeClientType.JOUR ||
        (currentDemande?.type == DemandeClientType.SEMAINE && currentDemande?.subType === DemandeClientSubType.JOUR) ||
        (currentDemande?.type == DemandeClientType.MOIS &&
          (currentDemande?.subType === DemandeClientSubType.JOUR || currentDemande?.subType === DemandeClientSubType.AUTRE))
          ? { dJour: diff }
          : currentDemande?.type == DemandeClientType.SEMAINE
          ? { dSemaine: diff }
          : { dMois: diff }),
        date: date,
      };
    });
  };

  const isHavePermissionCUD =
    currentUser?.typeRole === "SUPERADMIN" ||
    currentUser?.typeRole === "ADMIN" ||
    (currentUser?.roles ?? []).map((role) => role.name).includes("STANDARDLIGNE");

  return (
    <div className={styles.menuContainer}>
      {loading && <SingleLoading />}
      <PopupDetail />
      <header style={{ textAlign: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button type="text" onClick={backEditLigne} disabled={!isHavePermissionCUD}>
            <CaretLeftOutlined />
            <span style={{ fontSize: "1rem" }}>Modifier les Caractéristiques</span>
          </Button>
          {isShowTutorial ? (
            <div style={{ marginLeft: "0.5rem" }}>
              <IndicatorTutorialView hint={<>Renvoi à la partie Caractéristiques de la ligne actuelle.</>} />
            </div>
          ) : (
            <></>
          )}
        </div>

        <Typography.Title level={4} style={{ fontSize: "1.5rem" }}>
          Demande Client
        </Typography.Title>
      </header>

      {currentState === ActionType.VIEW && (
        <>
          {listDemandeClients.length > 0 && (
            <>
              <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                <Button className={styles.btGreen} type="primary" onClick={gotoTransport}>
                  Passer aux Transporteurs
                </Button>
                {isShowTutorial && (
                  <div
                    style={{
                      position: "absolute",
                      right: "-1.25rem",
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 10,
                    }}
                  >
                    <IndicatorTutorialView hint={<>Renvoi à la partie Transporteurs de la ligne actuelle.</>} />
                  </div>
                )}
              </div>
              <hr />
            </>
          )}
          <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
            <Button
              className={styles.btAddDetail}
              type="primary"
              htmlType="submit"
              onClick={() => {
                demandeState.editAble && createDetail();
              }}
              disabled={!isHavePermissionCUD}
            >
              Ajouter détails
            </Button>
            {isShowTutorial && (
              <div
                style={{
                  position: "absolute",
                  right: "-1.25rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 10,
                }}
              >
                <IndicatorTutorialView
                  hint={<>Ajouter des détails à propos de la Demande Client au mieux en utilisant les possibilités Jour, Semaine ou Mois.</>}
                />
              </div>
            )}
          </div>
        </>
      )}

      {(currentState === ActionType.CREATE || currentState === ActionType.UPDATE) && (
        <div className={styles.addForm}>
          <AddDemandeClient />
          <div className={styles.btRemoveContainer} style={{ display: "flex", alignItems: "center" }}>
            <Button className={styles.btRemove} icon={<img src={DeleteIcon} alt="Delete" />} onClick={removeDetail} />
            {isShowTutorial && (
              <div style={{ position: "absolute", right: "-0.75rem", top: "1rem", zIndex: 10 }}>
                <IndicatorTutorialView hint={<>Supprimer le détail Demande Client.</>} />
              </div>
            )}
          </div>
        </div>
      )}

      {currentState === ActionType.VIEW &&
        listDemandeClients.map((item) => {
          return demandeSelected && item.id === demandeSelected.id ? (
            <List.Item key={`${demandeSelected.id}_selected`} className={styles.listDemande}>
              <Row className={styles.headerList}>
                <Col span={10}>{demandeSelected.type}</Col>
                <Col span={9}>{demandeSelected.chargementTime ? moment(demandeSelected.chargementTime).format("HH:mm") : "hh:mm"}</Col>
                <Col span={5}>
                  <Button
                    style={{
                      display: "block",
                      backgroundColor: "transparent",
                      border: "none",
                      marginLeft: "auto",
                    }}
                    icon={demandeSelected.id === demandeClientId ? <CaretUpOutlined /> : <CaretDownOutlined />}
                    onClick={() => demandeSelected && onExpandCollapse(demandeSelected)}
                  />
                </Col>
              </Row>
              {demandeSelected.id === demandeClientId && (
                <div className={styles.preview}>
                  <PreviewDemande currentDemande={demandeSelected} />
                </div>
              )}
              {demandeSelected.id === demandeClientId && (
                <div className={styles.quickView}>
                  <footer>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        icon={<img src={EditIcon} alt="Edit" />}
                        onClick={() => demandeSelected && editItem(demandeSelected)}
                        disabled={!isHavePermissionCUD || !demandeState.editAble}
                      />
                      {isShowTutorial ? (
                        <div style={{ marginLeft: "0.5rem" }}>
                          <IndicatorTutorialView hint={<>Modifier ce détail.</>} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        icon={<img src={DeleteIcon} alt="Delete" />}
                        onClick={removeItem}
                        disabled={!isHavePermissionCUD || !demandeState.editAble}
                      />
                      {isShowTutorial ? (
                        <div style={{ marginLeft: "0.5rem" }}>
                          <IndicatorTutorialView hint={<>Supprimer ce détail.</>} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </footer>
                </div>
              )}
            </List.Item>
          ) : (
            <List.Item key={item.id} className={styles.listDemande}>
              <Row className={styles.headerList}>
                <Col span={10}>{item.type}</Col>
                <Col span={9}>{item.chargementTime ? moment(item.chargementTime).format("HH:mm") : "hh:mm"}</Col>
                <Col span={5}>
                  <Button
                    style={{
                      display: "block",
                      backgroundColor: "transparent",
                      border: "none",
                      marginLeft: "auto",
                    }}
                    icon={item.id === demandeClientId ? <CaretUpOutlined /> : <CaretDownOutlined />}
                    onClick={() => item && onExpandCollapse(item)}
                  />
                </Col>
              </Row>
              {item.id === demandeClientId && (
                <div className={styles.preview}>
                  <PreviewDemande currentDemande={item} />
                </div>
              )}
              {item.id === demandeClientId && (
                <div className={styles.quickView}>
                  <footer>
                    <Button icon={<img src={EditIcon} alt="Delete" />} onClick={() => item && editItem(item)} disabled={!isHavePermissionCUD} />
                    <Button icon={<img src={DeleteIcon} alt="Delete" />} onClick={removeItem} disabled={!isHavePermissionCUD} />
                  </footer>
                </div>
              )}
            </List.Item>
          );
        })}
    </div>
  );
};

export default LineMenu;

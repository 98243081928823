/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export enum StepGuide {
  START = "start",
  IN_PROGRESS = "in_progress",
  END = "end",
}

interface IndicatorGuideReducer {
  type: StepGuide;
}

const initialState: IndicatorGuideReducer = {
  type: StepGuide.END,
};

export const indicatorGuideSlice = createSlice({
  name: "indicatorGuide",
  initialState,
  reducers: {
    updateGuide: (state, action: PayloadAction<{ type: StepGuide }>) => {
      state.type = action.payload?.type;
    },
  },
});

export const { updateGuide } = indicatorGuideSlice.actions;

export const getStepGuide = (state: RootState) => state.indicatiorGuide.type;

export default indicatorGuideSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface DatesInterface {
  currentDate?: {
    date: string,
    state: boolean
  };
}

const initialState: DatesInterface = {
  currentDate: undefined
};

export const DatesValueSlice = createSlice({
  name: 'transDate',
  initialState,
  reducers: {
    UpdateTransDates: (state, action: PayloadAction<{ currentDate?: { date: string; state: boolean } }>) => {
      state.currentDate = action.payload.currentDate;
    }
  },
});

export const { UpdateTransDates } = DatesValueSlice.actions;

export const getTransDate = (state: RootState) => state.transDate;
export default DatesValueSlice.reducer;

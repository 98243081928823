import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GroupObjectByMultipleProperties from '../../../helper/demandeClientDate.helper';
import { RootState } from '../../store';

export enum TransDateType {
  JOUR = "Jour",
  SEMAINE_JOUR = "Semaine_Jour",
  SEMAINE_GLOBAL = "Semaine_Global",
  MOIS = "Mois"
}

export interface DatesObjectType {
  date: string;
  min: number;
  max: number;
  lotsClient?: number;
  type?: TransDateType
}

const initialState: DatesObjectType[] = []

export const TransporteurDetailDateTypeSlice = createSlice({
  name: 'transporteurDetailDateType',
  initialState,
  reducers: {
    SetMinMaxTransporteurDetailDateType: (state, action: PayloadAction<DatesObjectType[]>) => {
      // state = GroupObjectByMultipleProperties(action.payload);
      return action.payload
    },
    SetLotsTransporteurDetailDateType: (state, action: PayloadAction<DatesObjectType[]>) => {
      state = GroupObjectByMultipleProperties(action.payload);
      return state
    },
  },
});

export const { SetMinMaxTransporteurDetailDateType, SetLotsTransporteurDetailDateType } = TransporteurDetailDateTypeSlice.actions;

export const GetTransporteurDetailDateType = (state: RootState) => state.transporteurDetailDateType;
export default TransporteurDetailDateTypeSlice.reducer;

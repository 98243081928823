/** @format */

import { Button, Form, message, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { tap } from "rxjs";
import { useAppSelector } from "../../../app/hooks";
import { selectIsShowTutorial } from "../../../app/reducers/indicatorTutorial.reducer";
import { selectLigne, selectLigneRequest, updateLigneRequest } from "../../../app/reducers/ligne.reducer";
import SearchIcon from "../../../assets/icons/search.svg";
import CustomSelect from "../../../components/CustomSelect";
import IndicatorTutorialView from "../../../components/IndicatorTutorial";
import { CommercialAPI } from "../../../domain/apis/commercial.api";
import { useRxEffect } from "../../../helper/rxjs.helper";
import { Client } from "../../../models";
import UpdateLigne from "../UpdateLigne";
import styles from "./main.module.css";

const { Title } = Typography;
const { Option } = Select;

interface FormModal {
  commercialId: number | null;
}

const CommercialPilote = (props: { editable: boolean }) => {
  const { editable } = props;
  const lineData = useAppSelector(selectLigne);
  const [form] = Form.useForm();
  const isShowTutorial = useAppSelector(selectIsShowTutorial);
  const ligneRequest = useAppSelector(selectLigneRequest);
  const dispatch = useDispatch();
  const initForm: FormModal = {
    commercialId: null,
  };

  const [commercial, setCommercial] = useState<Client[]>([]);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      commercialId: lineData.commercialId,
    });
  }, [lineData]);

  useRxEffect(
    () =>
      CommercialAPI.fetchAll({
        clientId: lineData.clientId,
      }).pipe(
        tap({
          next: (commercials: Client[]) => {
            setCommercial(commercials);
          },
        })
      ),
    []
  );

  const onFinish = (values: FormModal) => {
    dispatch(
      updateLigneRequest({
        ligneRequest: {
          ...ligneRequest,
          commercialId: Number(values.commercialId),
          currentStage: Number(lineData.currentStage) + 1,
        },
      })
    );
    setIsSubmit(true);
  };

  const onFinishFailed = () => {
    message.error("Merci de compléter toutes les colonnes.").then((r) => console.log(r));
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <>
      {isSubmit && <UpdateLigne />}
      <Form
        {...formItemLayout}
        form={form}
        name="clientForm"
        initialValues={initForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        colon={false}
        disabled={!editable}
      >
        <Form.Item label=" ">
          <Title level={3} className={styles.title}>
            Commercial pilote
          </Title>
        </Form.Item>
        <Form.Item
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              Code
              {isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView
                    hint={
                      <>
                        Commercial référent, ayant suivi l&apos;appel d&apos;offres ou principal interlocuteur du <i>Client concerné</i>. Le
                        Commercial doit être lié au <i>Client concerné</i> dans Salesforce.
                      </>
                    }
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          }
          name="commercialId"
          rules={[{ required: true, message: "" }]}
        >
          <CustomSelect bordered={false} showSearch={true} suffixIcon={<img className={styles.searchIcon} src={SearchIcon} alt="Search" />}>
            {commercial.map((el: Client) => {
              return (
                <Option key={el.id} value={el.id}>
                  {el.code} - {el.nom}
                </Option>
              );
            })}
          </CustomSelect>
        </Form.Item>
        <Form.Item label=" " className={styles.submit}>
          {editable && (
            <Button type="primary" htmlType="submit" className={styles.bt}>
              Confirmer
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default CommercialPilote;

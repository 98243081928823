/** @format */

import { DatePicker } from "antd";
import { DatePickerProps } from "antd/lib/date-picker";
import * as React from "react";
import styles from "./customDatePicker.module.css";

const CustomDatePicker = (props: DatePickerProps) => {
  return <DatePicker className={styles.customDatePicker + " customDatePicker"} {...props} format="DD-MM-YYYY" placeholder="JJ-MM-AAAA" />;
};

export default CustomDatePicker;

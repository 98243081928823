/** @format */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosResponse } from "axios";
import { from, map, Observable } from "rxjs";
import { Method, request } from "../../helper/request.helper";
import { DemandeClient, DemandeClientDate } from "../../models";
import { LoopbackAPI } from "./loopbackApi.api";
import { UpsertDemandeClientRequestParams } from "../../models/requests/upsertDemandeClient.model";
import { UpsertDemandeClientResponse } from "../../models/responses/upsertDemandeClient.model";
import { DemandeClientHistory } from "../../models/demandeClientHistory";

const COMPONENT_NAME = "DemandeClients";

export class DemandeClientAPI implements LoopbackAPI<DemandeClient> {
  find = (filter?: unknown): Observable<DemandeClient[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params: {
          filter,
        },
      })
    ).pipe(map((response: AxiosResponse<DemandeClient[]>) => response.data));
  };

  count = (): Observable<number> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/count`,
      })
    ).pipe(map((response: AxiosResponse<number>) => response.data));
  };

  patch = (id: number, data: any): Observable<void> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbCreate = (data: any): Observable<void> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbDelete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/${id}`,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static fetchAllByCaracteristiquesLigneId = (id: number): Observable<DemandeClient[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params: {
          filter: {
            include: ["moisGlobalOptions", "demandeClientDates"],
            where: {
              caracteristiquesLigneId: id,
            },
          },
        },
      })
    ).pipe(map((response: AxiosResponse<DemandeClient[]>) => response.data));
  };

  static create = (data: DemandeClient): Observable<DemandeClient> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<DemandeClient>) => response.data));
  };

  static update = (id: number, data: DemandeClient): Observable<void> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static delete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/deleteDemandeClients`,
        params: {
          demandeClientIds: [id],
        },
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static updateAllDemandeClientDatesById = (id: number, data: { demandeClientDates: DemandeClientDate[] }): Observable<void> => {
    return from(
      request({
        method: Method.PUT,
        url: `/${COMPONENT_NAME}/${id}/updateDemandeClientDate`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static deleteDemandeClients = (ids: number[]): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/deleteDemandeClients`,
        params: {
          demandeClientIds: ids,
        },
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static upsertDemandeClient = (data: UpsertDemandeClientRequestParams): Observable<UpsertDemandeClientResponse> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/upsertDemandeClient`,
        data,
      })
    ).pipe(map((response: AxiosResponse<UpsertDemandeClientResponse>) => response.data));
  };

  static getHistoryDemandeClient = (): Observable<DemandeClientHistory> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/getHistoryDemandeClient/`,
      })
    ).pipe(map((response: AxiosResponse<DemandeClientHistory>) => response.data));
  };
}

/** @format */

import { InputNumber, InputNumberProps } from "antd";
import * as React from "react";
import styles from "./customInputNumber.module.css";

const CustomInputNumber = (props: InputNumberProps & { suffix?: React.ReactNode; innerref?: React.Ref<HTMLInputElement> | undefined }) => {
  const { min, max, bordered, innerref } = props;
  return (
    <div className={`${styles.customInputNumber} customInputNumber ${bordered !== undefined && !bordered ? styles.borderedNone : ""}`}>
      <InputNumber
        {...props}
        ref={innerref}
        min={Number(min).toFixed(2)}
        max={Number(max).toFixed(2)}
        formatter={(value) => `${value}`.replace(/\./, ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
        parser={(x) => parseFloat(`${x}`.replace(/,/, "#").replace(/\./g, "").replace(/#/, "."))}
      />
      {props.suffix && <span className={styles.suffix}>{props.suffix}</span>}
    </div>
  );
};

export default CustomInputNumber;

/** @format */

import { Button, Form, message, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { tap } from "rxjs";
import { useAppSelector } from "../../../app/hooks";
import { selectIsShowTutorial } from "../../../app/reducers/indicatorTutorial.reducer";
import { selectLigne, selectLigneRequest, updateLigneRequest } from "../../../app/reducers/ligne.reducer";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomSelect from "../../../components/CustomSelect";
import IndicatorTutorialView from "../../../components/IndicatorTutorial";
import { ClientAPI } from "../../../domain/apis/client.api";
import { useRxEffect } from "../../../helper/rxjs.helper";
import { Client } from "../../../models";
import UpdateLigne from "../UpdateLigne";
import styles from "./main.module.css";

const { Title } = Typography;

const { Option } = Select;

interface FormModal {
  clientId: number | null;
  billedCustomer: boolean;
  clientFactureId: number | null;
}

const ClientView = (props: { editable: boolean }) => {
  const { editable } = props;
  const lineData = useAppSelector(selectLigne);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);
  const dispatch = useDispatch();
  const ligneRequest = useAppSelector(selectLigneRequest);
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<FormModal>({
    clientId: null,
    billedCustomer: false,
    clientFactureId: null,
  });

  const [clientList, setClientList] = useState<Client[]>([]);
  const [clientFactureList, setClientFactureList] = useState<Client[]>([]);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      clientId: lineData.clientId ? Number(lineData.clientId) : null,
      billedCustomer: lineData.clientId && lineData.clientId === lineData.clientFactureId,
      clientFactureId: lineData.clientFactureId ? Number(lineData.clientFactureId) : null,
    });

    if (lineData.clientId) {
      setFormValue({
        clientId: Number(lineData.clientId),
        billedCustomer: lineData.clientId === lineData.clientFactureId,
        clientFactureId: Number(lineData.clientFactureId),
      });
    }
  }, [lineData]);

  useRxEffect(
    () =>
      ClientAPI.fetchAll().pipe(
        tap({
          next: (clients: Client[]) => {
            setClientList(clients);
            setClientFactureList(clients);
          },
        })
      ),
    []
  );
  const onFinish = (values: FormModal) => {
    dispatch(
      updateLigneRequest({
        ligneRequest: {
          ...ligneRequest,
          clientId: Number(values.clientId),
          clientFactureId: Number(values.clientFactureId),
          currentStage: Number(lineData.currentStage) + 1,
        },
      })
    );
    setIsSubmit(true);
  };

  const onValuesChange = () => {
    const getFieldsValue = form.getFieldsValue();
    if (getFieldsValue.billedCustomer) {
      setClientFactureList(clientList);
      form.setFieldsValue({
        ...getFieldsValue,
        clientFactureId: getFieldsValue.clientId,
      });
      setFormValue({
        ...getFieldsValue,
        clientFactureId: getFieldsValue.clientId,
      });
    } else {
      setClientFactureList(clientList.filter((el) => el.id !== Number(getFieldsValue.clientId)));
      form.setFieldsValue({
        ...getFieldsValue,
        clientFactureId: getFieldsValue.clientId !== getFieldsValue.clientFactureId ? getFieldsValue.clientFactureId : "",
      });
      setFormValue({
        ...getFieldsValue,
        clientFactureId: getFieldsValue.clientId !== getFieldsValue.clientFactureId ? getFieldsValue.clientFactureId : "",
      });
    }
  };

  const onFinishFailed = () => {
    message.error("Merci de compléter toutes les colonnes.").then((r) => console.log(r));
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <>
      {isSubmit && <UpdateLigne />}
      <Form
        {...formItemLayout}
        form={form}
        name="clientForm"
        initialValues={formValue}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        onValuesChange={onValuesChange}
        colon={false}
        disabled={!editable}
      >
        <Form.Item label=" ">
          <Title level={3} className={styles.title}>
            Client
          </Title>
        </Form.Item>
        <Form.Item
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              Concerné
              {isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView hint={"Client principal pour lequel cette ligne est réalisée."} />
                </div>
              ) : (
                <></>
              )}
            </div>
          }
          name="clientId"
          rules={[{ required: true, message: "" }]}
        >
          <CustomSelect showArrow={false} bordered={false}>
            {clientList.map((el: Client) => {
              return (
                <Option key={el.id} value={el.id}>
                  {el.code} - {el.nom}
                </Option>
              );
            })}
          </CustomSelect>
        </Form.Item>
        <Form.Item label=" " name="billedCustomer" valuePropName="checked">
          <CustomCheckbox disabled={!editable}>
            <div style={{ display: "flex", alignItems: "center" }}>
              Client Facturé
              {isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView
                    hint={
                      <>
                        À cocher si le <i>Client concerné</i> est le <i>Client facturé</i>.
                      </>
                    }
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </CustomCheckbox>
        </Form.Item>
        <Form.Item
          name="clientFactureId"
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              Facturé
              {isShowTutorial ? (
                <div style={{ marginLeft: "0.5rem" }}>
                  <IndicatorTutorialView hint={"Client facturé quand cette ligne est réalisée."} />
                </div>
              ) : (
                <></>
              )}
            </div>
          }
          rules={[{ required: true, message: "" }]}
        >
          <CustomSelect showArrow={false} bordered={false} disabled={formValue.billedCustomer}>
            {clientFactureList.map((el: Client) => {
              return (
                <Option key={el.id} value={el.id}>
                  {el.code} - {el.nom}
                </Option>
              );
            })}
          </CustomSelect>
        </Form.Item>
        <Form.Item label=" " className={styles.submit}>
          {editable && (
            <Button type="primary" htmlType="submit" className={styles.bt}>
              Confirmer
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default ClientView;

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface LoadingReducer {
  status: boolean;
}

const initialState: LoadingReducer = {
  status: true,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = true;
    },
    finishLoading: (state) => {
      state.status = false;
    },
  },
});

export const { startLoading, finishLoading } = loadingSlice.actions;

export const selectLoading = (state: RootState) => state.loading.status;

export default loadingSlice.reducer;

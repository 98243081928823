import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';

interface DatesInterface {
  currentDate?: {
    date: string,
    state: boolean
  };
}

const initialState: DatesInterface = {
  currentDate: undefined
};

export const DatesValueSlice = createSlice({
  name: 'LotValue',
  initialState,
  reducers: {
    UpdateSelectedDates: (state, action: PayloadAction<{currentDate?: {date: string; state: boolean}}>) => {
      state.currentDate = action.payload.currentDate;
    }
  },
});

export const { UpdateSelectedDates } = DatesValueSlice.actions;

export const getSelectedDate = (state: RootState) => state.selectedDates;
export default DatesValueSlice.reducer;

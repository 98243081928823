import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransporteurDetail } from '../../../models/transporteurDetail.model';
import { RootState } from '../../store';

const initialState: TransporteurDetail[] = [];

export const ListTransporteurDetailsSlice = createSlice({
  name: 'listTransporteurDetails',
  initialState,
  reducers: {
    UpdateListTransporteurDetails: (state, action: PayloadAction<TransporteurDetail[]>) => {
      state = action.payload;
      return state
    }
  },
});

export const { UpdateListTransporteurDetails } = ListTransporteurDetailsSlice.actions;

export const getListTransporteurDetails = (state: RootState) => state.listTransporteurDetails;
export default ListTransporteurDetailsSlice.reducer;

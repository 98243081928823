/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BatchAverage, CaracteristiquesLignes } from "../../models";
import { ChoixTransporteur } from "../../models/choixTransporteur.model";
import { RootState } from "../store";
import { AssociationRate } from "../../models/associationRate.model";

const initialState: {
  caracteristiquesLigne: CaracteristiquesLignes;
  choixTransporteurs: ChoixTransporteur[];
  moyenneDeLotsMax: number;
  batchAverages: BatchAverage[];
  associationRates: AssociationRate[];
  loadingData: boolean;
  loadingAction: boolean;
  ppRate?: number;
} = {
  caracteristiquesLigne: {},
  choixTransporteurs: [],
  moyenneDeLotsMax: 24,
  batchAverages: [],
  associationRates: [],
  loadingData: false,
  loadingAction: false,
  ppRate: undefined,
};

const choixTransporteurSlice = createSlice({
  name: "choixTransporteur",
  initialState,
  reducers: {
    fetchDataChoixTransporteurs: (
      state,
      action: PayloadAction<{
        caracteristiquesLigne: CaracteristiquesLignes;
        choixTransporteurs: ChoixTransporteur[];
        moyenneDeLotsMax: number;
        batchAverages: BatchAverage[];
        associationRates: AssociationRate[];
        ppRate?: number;
      }>
    ) => {
      state.loadingData = false;
      state.caracteristiquesLigne = action.payload?.caracteristiquesLigne;
      state.choixTransporteurs = action.payload?.choixTransporteurs.sort((a: ChoixTransporteur, b: ChoixTransporteur) => {
        return (a.priorite ?? 0) - (b.priorite ?? 0);
      });
      state.moyenneDeLotsMax = action.payload?.moyenneDeLotsMax;
      state.batchAverages = action.payload?.batchAverages;
      state.associationRates = action.payload?.associationRates;
      state.ppRate = action.payload?.ppRate;
    },
    updatePPrate: (state, action: PayloadAction<{ ppRate: number }>) => {
      state.ppRate = action.payload?.ppRate;
    },
    fetchChoixTransporteurs: (
      state,
      action: PayloadAction<{
        choixTransporteurs: ChoixTransporteur[];
        associationRates: AssociationRate[];
      }>
    ) => {
      state.loadingData = false;
      state.choixTransporteurs = action.payload?.choixTransporteurs.sort((a: ChoixTransporteur, b: ChoixTransporteur) => {
        return (a.priorite ?? 0) - (b.priorite ?? 0);
      });
      state.associationRates = action.payload?.associationRates;
    },

    loadingData: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.loadingData = action.payload.loading;
    },
    loadingAction: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.loadingAction = action.payload.loading;
    },
  },
});

export const { fetchDataChoixTransporteurs, fetchChoixTransporteurs, updatePPrate } = choixTransporteurSlice.actions;
export const selectLigne = (state: RootState) => state.choixTransporteur.caracteristiquesLigne;
export const selectCaracteristiquesLigne = (state: RootState) => state.choixTransporteur.caracteristiquesLigne;
export const selectMoyenneDeLotsMax = (state: RootState) => state.choixTransporteur.moyenneDeLotsMax;
export const selectBatchAverages = (state: RootState) => state.choixTransporteur.batchAverages;
export const selectAssociationRates = (state: RootState) => state.choixTransporteur.associationRates;
export const selectPpRate = (state: RootState) => state.choixTransporteur.ppRate;

export default choixTransporteurSlice.reducer;

/** @format */

import { Checkbox, Col, Form, Row } from "antd";
import { capitalizeFirstLetter } from "../../../../../../helper/stringExtension";
import moment from "moment";
import { useAppSelector } from "../../../../../../app/hooks";
import { selectCaracteristiquesLigne } from "../../../../../../app/reducers/demandeClient.reducer";
import { getRangeDate } from "../../../../../../helper/getRangeDate";
import React, { useEffect, useState } from "react";
//import styles from "./MoisJour.module.css";
import { MonthsArr } from "../../../../const/form.const";
import { getCurrentDemande } from "../../../../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import { getSelectedDate } from "../../../../../../app/reducers/DemandeClient/SelectedDates.reducer";
import { DateInput } from "../../../../../../components/FullCalendar";
import { UpdateDatesObj } from "../../../../../../app/reducers/DemandeClient/DatesObject.reducer";
import { useDispatch } from "react-redux";
import { isValidDate } from "../../../../../../helper/validateDate";
import { ActionType, selectDemandeState } from "../../../../../../app/reducers/DemandeClient/DemandeState.reducer";
import HolidaySelect from "../../HolidaySelect";
import { AppColors } from "../../../../../../constants/colors.const";
import IndicatorTutorialView from "../../../../../../components/IndicatorTutorial";
import { selectIsShowTutorial } from "../../../../../../app/reducers/indicatorTutorial.reducer";

interface CheckBoxGroup {
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface FormModal {
  months: number[];
  days: number[];
}

interface MonthDayProps {
  onSelect: (months: number[], days: number[], dates: string[]) => void;
}

const MonthDaySelect = (props: MonthDayProps) => {
  const dispatch = useDispatch();
  const demandeState = useAppSelector(selectDemandeState);
  const currentLigne = useAppSelector(selectCaracteristiquesLigne);
  const currentDemande = useAppSelector(getCurrentDemande);
  const clickedDate = useAppSelector(getSelectedDate).currentDate;
  const isShowTutorial = useAppSelector(selectIsShowTutorial);
  const [form] = Form.useForm();

  const [monthOptions, setMonthOptions] = useState<CheckBoxGroup[]>(
    MonthsArr.map((month) => {
      return {
        label: capitalizeFirstLetter(moment().month(month).format("MMMM")),
        value: month,
      };
    })
  );
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [whiteList, setWhiteList] = useState<string[]>([]);
  const [holidays, setHolidays] = useState<string[]>([]);

  let initFormValues: FormModal = {
    months: [],
    days: [],
  };

  if (currentDemande?.demandeClientDates) {
    const listMonths: number[] = [];
    const listDays: number[] = [];
    currentDemande?.demandeClientDates.map((el) => {
      if (el.date) {
        const month = Number(el.date.slice(5, 7)) - 1;
        const day = Number(el.date.slice(-2));
        listMonths[month] = month;
        listDays[day] = day;
      }
    });
    initFormValues = {
      months: listMonths.filter((el) => el !== undefined),
      days: listDays.filter((el) => el !== undefined),
    };
  }

  useEffect(() => {
    if (currentDemande?.demandeClientDates) {
      const listDates: string[] = [];
      currentDemande?.demandeClientDates?.map((el) => {
        if (el.date) {
          listDates.push(el.date);
        }
      });
      setSelectedDates(listDates);
    }
  }, [currentDemande]);

  useEffect(() => {
    if (currentLigne.caracteristiques) {
      const [start, dateFin] = getRangeDate(currentLigne.caracteristiques);
      let dateInit = moment(start).startOf("month").format("YYYY/MM/DD");
      let monthArr: number[] = [];
      while (dateFin >= dateInit) {
        const month = new Date(dateInit).getMonth();
        monthArr[month] = month;
        dateInit = moment(dateInit).add(1, "month").format("YYYY/MM/DD");
      }

      monthArr = monthArr.filter((el) => el !== undefined);
      setMonthOptions(
        MonthsArr.map((month) => {
          return {
            label: capitalizeFirstLetter(moment().month(month).format("MMMM")),
            value: month,
            disabled: !monthArr.includes(month),
          };
        })
      );
    }
  }, [currentLigne]);

  // Update selected Date when click a date in calendar
  useEffect(() => {
    if (clickedDate && whiteList.includes(clickedDate.date) && !holidays.includes(clickedDate.date)) {
      let newSelect: string[];
      if (selectedDates.some((el) => el === clickedDate.date)) {
        newSelect = selectedDates.filter((el) => el !== clickedDate.date);
      } else {
        newSelect = [...selectedDates, clickedDate.date];
      }
      setSelectedDates(newSelect);

      const formValues: FormModal = form.getFieldsValue();
      const daysSelected = formValues.days;
      const listDays: string[][] = Array(32).fill([]);

      newSelect.map((el) => {
        const date = new Date(el).getDate();
        listDays[date] = [...listDays[date], el];
      });

      let newWhiteList = [...whiteList];
      daysSelected.map((item, index) => {
        if (listDays[item].length === 0) {
          daysSelected.splice(index, 1);
          newWhiteList = newWhiteList.filter((el) => new Date(el).getDate() !== item);
        }
      });

      setWhiteList(newWhiteList);
      form.setFieldsValue({
        ...formValues,
        days: daysSelected,
      });
    }
  }, [clickedDate]);

  useEffect(() => {
    const dateObj: DateInput[] = [];
    selectedDates.map((el) => {
      dateObj.push({
        date: el,
        color: "#fff",
        background: AppColors.COLOR_CALENDAR_DEFAULT_PRIMARY,
      });
    });
    dispatch(UpdateDatesObj(dateObj));
    const formValue = form.getFieldsValue();
    props.onSelect(formValue.months, formValue.days, selectedDates);
  }, [selectedDates]);

  const onValuesChange = () => {
    const formValues: FormModal = form.getFieldsValue();

    // TODO: set white list
    if (currentLigne.caracteristiques) {
      const [dateInit, dateFin] = getRangeDate(currentLigne.caracteristiques);
      const startYear = new Date(dateInit).getFullYear();
      const endYear = new Date(dateFin).getFullYear();
      const months = formValues.months;
      const days = formValues.days;

      const newDates: string[] = [];
      const newWhiteList: string[] = [];

      for (let year = startYear; year <= endYear; year++) {
        months.map((month) => {
          days.map((day) => {
            const date = year + "/" + ("0" + (month + 1)).slice(-2) + "/" + ("0" + day).slice(-2);
            if (isValidDate(date) && !holidays.includes(date)) {
              newWhiteList.push(date);
              if (date >= dateInit && date <= dateFin) {
                if (!selectedDates.includes(date)) {
                  newDates.push(date);
                }
              }
            }
          });
        });
      }

      const removeDate: string[] = whiteList.filter((date) => !newWhiteList.includes(date));

      setWhiteList(newWhiteList);
      setSelectedDates(
        [...selectedDates, ...newDates]
          .filter((el) => !removeDate.includes(el))
          .filter(function (x, i, a) {
            return a.indexOf(x) == i;
          })
      );
    }
  };

  const changeHolidays = (values: string[]) => {
    const addDays = whiteList.filter((el) => holidays.includes(el));
    const removeDays = selectedDates.filter((el) => values.includes(el));
    setSelectedDates([...selectedDates, ...addDays].filter((el) => !removeDays.includes(el)));
    setHolidays(values);
  };

  return (
    <>
      <Form form={form} name="MonthDaySelect" initialValues={initFormValues} onValuesChange={onValuesChange}>
        <Row style={{ marginTop: "2rem" }}>
          <Col span={10}>
            {isShowTutorial && (
              <div style={{ marginLeft: "2rem" }}>
                <IndicatorTutorialView hint={<>Mois pour les Jours sélectionnés.</>} />
              </div>
            )}
          </Col>
          <Col span={14}>
            {isShowTutorial && (
              <div style={{ marginLeft: "4rem" }}>
                <IndicatorTutorialView hint={<>Jours parmi les Mois sélectionnés.</>} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item name="months">
              <Checkbox.Group disabled={demandeState.actionType === ActionType.VIEW} options={monthOptions} />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item name="days">
              <Checkbox.Group disabled={demandeState.actionType === ActionType.VIEW}>
                <Row>
                  {Array(4)
                    .fill(0)
                    .map((_, col) => (
                      <Col span={6} key={col}>
                        {Array(10)
                          .fill(0)
                          .map((_, row) => {
                            const day = col * 10 + row + 1;
                            if (day <= 31) {
                              return (
                                <Checkbox key={row} value={day}>
                                  {("0" + day).slice(-2)}
                                </Checkbox>
                              );
                            }
                          })}
                      </Col>
                    ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <HolidaySelect getHolidays={(values) => changeHolidays(values)} />
    </>
  );
};

export default MonthDaySelect;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';

interface DatesObjectType {
  date: string;
  color?: string;
  background?: string;
  border?: number;
}

const initialState: DatesObjectType[] = []

export const DatesObjectSlice = createSlice({
  name: 'DateObjectTrans',
  initialState,
  reducers: {
    UpdateTransDatesObj: (state, action: PayloadAction<DatesObjectType[]>) => {
      state = action.payload;
      return state
    }
  },
});

export const { UpdateTransDatesObj } = DatesObjectSlice.actions;

export const getTransDateObject = (state: RootState) => state.transDatesObject;
export default DatesObjectSlice.reducer;

/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Caracteristiques, DemandeClient } from "../../models";
import { ChoixTransporteur } from "../../models/choixTransporteur.model";
import { CaracteristiquesRequest } from "../../models/requests/caracteristiquesRequest.model";
import { RootState } from "../store";

const initialState: {
  data: Caracteristiques;
  demandeClients: DemandeClient[];
  choixTransporteurs: ChoixTransporteur[];
  ligneId: string;
  ligneRequest: CaracteristiquesRequest;
} = {
  data: {},
  ligneId: "X".repeat(20),
  demandeClients: [],
  choixTransporteurs: [],
  ligneRequest: {},
};

const ligneSlice = createSlice({
  name: "line",
  initialState,
  reducers: {
    updateLigne: (state, action: PayloadAction<{ ligne: Caracteristiques }>) => {
      state.data = action.payload?.ligne;
    },
    updateLigneRequest: (state, action: PayloadAction<{ ligneRequest: CaracteristiquesRequest }>) => {
      state.ligneRequest = action.payload?.ligneRequest;
    },
    fetchDemandeClients: (state, action: PayloadAction<{ demandeClients: DemandeClient[] }>) => {
      state.demandeClients = action.payload.demandeClients;
    },
    fetchChoixTransporter: (state, action: PayloadAction<{ choixTransporteurs: ChoixTransporteur[] }>) => {
      state.choixTransporteurs = action.payload.choixTransporteurs;
    },
    updateChargements: (state, action: PayloadAction<{ ligne: Caracteristiques }>) => {
      state.data = action.payload?.ligne;
    },
    updateLineId: (state, action: PayloadAction<{ ligneId: string }>) => {
      state.ligneId = action.payload.ligneId;
    },
    resetLigne: (state) => {
      state.data = {};
    },
  },
});

export const { fetchDemandeClients, fetchChoixTransporter, updateLineId, resetLigne, updateLigne, updateLigneRequest } = ligneSlice.actions;
export const selectLigne = (state: RootState) => state.ligne.data;
export const selectLigneRequest = (state: RootState) => state.ligne.ligneRequest;
export const selectDemandeClients = (state: RootState) => state.ligne.demandeClients;
export const selectChoixTransporter = (state: RootState) => state.ligne.choixTransporteurs;
export const selectLigneId = (state: RootState) => state.ligne.ligneId;

export default ligneSlice.reducer;

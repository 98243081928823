/** @format */

import { Button, Col, Form, message, Row, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { tap } from "rxjs";
import { useAppSelector } from "../../../../app/hooks";
import { selectIsShowTutorial } from "../../../../app/reducers/indicatorTutorial.reducer";
import { selectLigne, selectLigneRequest, updateLigne, updateLigneRequest } from "../../../../app/reducers/ligne.reducer";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import CustomInputNumber from "../../../../components/CustomInputNumber";
import IndicatorTutorialView from "../../../../components/IndicatorTutorial";
import PopupConfirm from "../../../../components/PopupConfirm";
import SingleLoading from "../../../../components/SingleLoading";
import { UniteAPI } from "../../../../domain/apis/unite.api";
import { useRxEffect } from "../../../../helper/rxjs.helper";
import { Unite } from "../../../../models";
import { Alloue } from "../../../../models/alloue.model";
import styles from "./Allocation.module.css";
import AllocationItem from "./AllocationItem";

const { Title } = Typography;

interface FormModal {
  tauxParcPropre?: number;
  parcPropreContractuel?: boolean;
}

let globalSubmitArr: boolean[] = [];

const Allocation = (props: { editable: boolean }) => {
  const { editable } = props;
  const ligneData = useAppSelector(selectLigne);
  const ligneRequest = useAppSelector(selectLigneRequest);
  const chargementsLigne = ligneData.chargement;
  const [form] = Form.useForm();
  const sliderRef = useRef<Slider | null>(null);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);
  const dispatch = useDispatch();

  const [layout, setLayout] = useState<number>(8);
  const [actionSave, setActionSave] = useState<boolean>(false);
  const [plageState, setPlageState] = useState(false);
  const [alloueData, setAlloueData] = useState<Alloue[]>([]);
  const [taux, setTaux] = useState(false);
  const [submitArr, setSubmitArr] = useState(-1);
  const [formValue, setFormValue] = useState<FormModal>({
    parcPropreContractuel: ligneData.parcPropreContractuel ? ligneData.parcPropreContractuel : false,
    tauxParcPropre: ligneData.tauxParcPropre,
  });
  const [listUnit, setListUnit] = useState<Unite[]>([]);
  const [showPopupEditAllocation, setShowPopupEditAllocation] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [settings, setSettings] = useState({
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  useEffect(() => {
    updateLigne({ ligne: ligneData });
  }, []);

  useEffect(() => {
    if (ligneData.alloue && ligneData.alloue.length > 0) {
      setAlloueData(ligneData.alloue);
    }
    if (ligneData.plages) {
      setPlageState(ligneData.plages);
    }
    form.setFieldsValue({
      parcPropreContractuel: ligneData.parcPropreContractuel ? ligneData.parcPropreContractuel : false,
      tauxParcPropre: ligneData.tauxParcPropre,
    });
    setFormValue({
      ...formValue,
      parcPropreContractuel: ligneData.parcPropreContractuel ? ligneData.parcPropreContractuel : false,
      tauxParcPropre: ligneData.tauxParcPropre,
    });
  }, [ligneData]);

  useEffect(() => {
    if (alloueData.length > 1) {
      setLayout(18);
      setSettings({
        ...settings,
        slidesToShow: 2,
      });
    } else {
      setLayout(9);
      setSettings({
        ...settings,
        slidesToShow: 1,
      });
    }
    if (alloueData[0]?.taux) {
      setTaux(alloueData[0].taux);
    } else {
      setTaux(false);
    }
  }, [alloueData]);

  useEffect(() => {
    if (actionSave) {
      const values = form.getFieldsValue();
      if (globalSubmitArr.length === alloueData.length) {
        setActionSave(false);
        if (globalSubmitArr.every((el) => el)) {
          const data = {
            parcPropreContractuel: values.parcPropreContractuel ? values.parcPropreContractuel : false,
            tauxParcPropre: values.tauxParcPropre ? values.tauxParcPropre : null,
            currentStage: Number(ligneData.currentStage) + 1,
            alloue: alloueData,
          };
          dispatch(
            updateLigneRequest({
              ligneRequest: {
                ...ligneRequest,
                ...data,
              },
            })
          );
          dispatch(
            updateLigne({
              ligne: {
                ...ligneData,
                ...data,
              },
            })
          );
          return;
        }
        sliderRef.current?.slickGoTo(globalSubmitArr.indexOf(false));
        message.error("Merci de compléter toutes les colonnes.").then((r) => console.log(r));
        return;
      }
    }
  }, [submitArr]);

  useRxEffect(() => {
    setLoading(true);
    return UniteAPI.fetchAll().pipe(
      tap({
        next: (unites: Unite[]) => {
          setListUnit(unites);
          setLoading(false);
        },
      })
    );
  }, []);

  const onValuesChange = () => {
    const getFieldsValue = form.getFieldsValue();
    setFormValue(getFieldsValue);
  };

  const onFinishFailed = () => {
    message.error("Merci de compléter toutes les colonnes.").then((r) => console.log(r));
  };

  const formItemLayout = {
    labelCol: {
      lg: { span: 6 },
    },
    wrapperCol: {
      lg: { span: 18 },
    },
  };

  const submitHandel = (index: number, statusItem: boolean) => {
    setTimeout(() => {
      globalSubmitArr[index] = statusItem;
      setSubmitArr(index);
    }, index * 10);
  };

  const onFinish = () => {
    if (
      ligneData.ppRate &&
      ligneData.tauxParcPropre &&
      Number(Math.ceil(Number(ligneData.ppRate))) >= Number(Math.ceil(Number(ligneData.tauxParcPropre))) &&
      Number(Math.ceil(Number(ligneData.ppRate))) < Number(Math.ceil(Number(formValue.tauxParcPropre)))
    ) {
      setShowPopupEditAllocation(true);
    } else {
      setActionSave(true);
      setSubmitArr(-1);
      globalSubmitArr = [];
    }
  };

  const onConfirmAllocation = () => {
    setActionSave(true);
    setSubmitArr(-1);
    globalSubmitArr = [];
  };

  const updateItem = (index: number, itemData: Alloue) => {
    setAlloueData((prevState) => {
      const copyAlloueData = [...prevState];
      copyAlloueData[index] = itemData;
      return copyAlloueData;
    });
  };

  return (
    <>
      {loading && <SingleLoading />}
      <PopupConfirm
        visible={showPopupEditAllocation}
        title="Attention"
        okText="Oui"
        cancelText="Non"
        onOk={onConfirmAllocation}
        onCancel={() => setShowPopupEditAllocation(false)}
      >
        <>Le taux de Parc Propre Contractuel n&apos;atteindra plus l&apos;objectif fixé pour cette ligne. Souhaitez-vous continuer?</>
      </PopupConfirm>
      <Row>
        <Col xs={{ span: 9, offset: 6 }} sm={{ span: 7, offset: 10 }} md={{ span: 9, offset: 6 }}>
          <Title level={3} className={styles.title}>
            Allocation
          </Title>
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={10} md={8} xl={6}>
          {plageState && <div className={styles.plageOn} />}
          <ul className={styles.lableChargement}>
            <li>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                Volume total Client
                {isShowTutorial ? (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView hint={"Volume total proposé par le Client pour cette ligne (appels d'offres ou autre)."} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </li>
            <li>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                {taux ? `Taux alloué` : `Volume alloué`}
                {isShowTutorial ? (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView
                      hint={
                        taux
                          ? "Taux alloué en pourcentage à Transalliance, saisi ou calculé."
                          : "Volume alloué en nombre à Transalliance, saisi ou calculé."
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </li>
          </ul>
          <ul className={styles.lableChargement}>
            <li>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                {!taux ? `Taux alloué` : `Volume alloué`}
                {isShowTutorial ? (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView
                      hint={
                        !taux
                          ? "Taux alloué en pourcentage à Transalliance, saisi ou calculé."
                          : "Volume alloué en nombre à Transalliance, saisi ou calculé."
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </li>
          </ul>
        </Col>
        <Col xs={18} md={14} lg={12} xl={layout} className={styles.groupChargement}>
          <Slider {...settings} ref={sliderRef}>
            {alloueData.map((el, index) => {
              return (
                <div key={index}>
                  <AllocationItem
                    index={index}
                    itemData={el}
                    plageState={plageState}
                    actionSave={actionSave}
                    listUnit={listUnit}
                    taux={taux}
                    setTaux={setTaux}
                    itemCallBack={(index, item) => updateItem(index, item)}
                    submitCallBack={(index: number, statusItem: boolean) => submitHandel(index, statusItem)}
                    isShowTutorial={isShowTutorial}
                    chargementsLigne={chargementsLigne}
                    editAble={editable}
                  />
                </div>
              );
            })}
          </Slider>
        </Col>
      </Row>
      <Row>
        <Form
          form={form}
          name="clientForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          colon={false}
          onValuesChange={onValuesChange}
          {...formItemLayout}
          className={styles.fullWidth}
          disabled={!editable}
        >
          <Row>
            <Col span={14} xs={{ offset: 6 }} sm={{ offset: 10 }} md={{ offset: 8 }} xl={{ offset: 6 }}>
              <Form.Item valuePropName="checked" name="parcPropreContractuel" label="">
                <CustomCheckbox disabled={!editable}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Parc Propre Contractuel
                    {isShowTutorial ? (
                      <div style={{ marginLeft: "0.5rem" }}>
                        <IndicatorTutorialView hint={"À cocher si l'utilisation du Parc Propre Transalliance est contractuel."} />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </CustomCheckbox>
              </Form.Item>
            </Col>
          </Row>
          {formValue.parcPropreContractuel && (
            <Row>
              <Col span={6}>
                <ul className={styles.lableChargement}>
                  <li style={{ paddingTop: 10 }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                      Taux
                      {isShowTutorial ? (
                        <div style={{ marginLeft: "0.5rem" }}>
                          <IndicatorTutorialView hint={"Taux de Parc Propre Transalliance contractualisé avec le Client."} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </li>
                  <li />
                </ul>
              </Col>
              <Col span={9} className={styles.groupChargement}>
                <ul className={styles.inputChargement}>
                  <li className={styles.liCenter}>
                    <Row>
                      <Col span={12}>
                        <Form.Item name="tauxParcPropre" rules={[{ required: true, message: "" }]} className={styles.fullHeight}>
                          <CustomInputNumber step={1} precision={0} min={0} max={100} suffix="%" disabled={!editable} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </Col>
            </Row>
          )}

          <Row style={{ justifyContent: alloueData.length > 1 ? "center" : "flex-start" }}>
            <Col xs={{ span: 9, offset: 6 }} sm={{ span: 7, offset: 10 }} md={{ span: 9, offset: 8 }} xl={{ span: 9, offset: 6 }}>
              <Form.Item className={styles.submit} wrapperCol={{ span: 24 }}>
                {editable && (
                  <Button type="primary" htmlType="submit" className={`${styles.bt} ${styles.buttonFull}`}>
                    Confirmer
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};

export default Allocation;

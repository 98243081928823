/** @format */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosResponse } from "axios";
import { from, map, Observable } from "rxjs";
import { Method, request } from "../../helper/request.helper";
import { Ville } from "../../models";
import { VilleSearchRequestParams } from "../../models/requests/villeSearch.model";
import { VilleResponse } from "../../models/responses/villeSearch.model";
import { LoopbackAPI } from "./loopbackApi.api";

const COMPONENT_NAME = "Villes";

export class VilleAPI implements LoopbackAPI<Ville> {
  find = (filter?: unknown): Observable<Ville[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params: {
          filter,
        },
      })
    ).pipe(map((response: AxiosResponse<Ville[]>) => response.data));
  };

  count = (): Observable<number> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/count`,
      })
    ).pipe(map((response: AxiosResponse<number>) => response.data));
  };

  patch = (id: number, data: any): Observable<void> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbCreate = (data: any): Observable<void> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbDelete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/${id}`,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static fetchAll = (params?: unknown): Observable<Ville[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params,
      })
    ).pipe(map((response: AxiosResponse<Ville[]>) => response.data));
  };

  static search = (paramsSearch: VilleSearchRequestParams[], limit?: number, offset?: number): Observable<VilleResponse> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/search`,
        params: {
          search: paramsSearch,
          limit,
          offset,
        },
      })
    ).pipe(map((response: AxiosResponse<VilleResponse>) => response.data));
  };
}

/** @format */
import { CloseCircleFilled } from "@ant-design/icons";
import { Input, Modal, Pagination, Select } from "antd";
import { useEffect, useState } from "react";
import { finalize, noop, tap } from "rxjs";
import SearchIcon from "../../../../../assets/icons/search.svg";
import SingleLoading from "../../../../../components/SingleLoading";
import { VilleAPI } from "../../../../../domain/apis/ville.api";
import { doOnSubscribe } from "../../../../../helper/rxjs.helper";
import { VilleSearchRequestParams } from "../../../../../models/requests/villeSearch.model";
import { VilleResponse } from "../../../../../models/responses/villeSearch.model";
import { TrajetPopupType, VilleSearch, VilleSearchType } from "../models/TrajetModel";
import styles from "./modal.module.css";

const { Option } = Select;

type Props = {
  isOpen: boolean;
  onSubmit: (ville: VilleSearch) => void;
  popupType: TrajetPopupType;
  onCancel?: () => void;
};

const ModalVille = ({ isOpen, onSubmit, onCancel, popupType }: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState<string>(VilleSearchType[0].id);
  const [searchFields, setSearchFields] = useState<VilleSearchRequestParams[]>([]);
  const [selectedVille, setSelectedVille] = useState<VilleSearch | undefined>();
  const [villes, setVilles] = useState<VilleResponse>({
    total: 0,
    data: [],
  });
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(6);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (isOpen) {
      getVille();
    }
  }, [searchFields, page, limit]);

  const onSubmitSearch = () => {
    if (searchTerm === "") {
      return;
    }
    const newSearchFields = [...searchFields];
    const checkExistValue = newSearchFields.findIndex((el) => el.field === searchType && el.textSearch[0] === searchTerm);
    if (checkExistValue === -1) {
      newSearchFields.push({
        field: searchType,
        textSearch: [searchTerm],
      });
    }
    setSearchFields(newSearchFields);
    setPage(1);
    setSearchTerm("");
  };

  const getVille = () => {
    VilleAPI.search(searchFields, limit, (page - 1) * limit)
      .pipe(
        doOnSubscribe(() => setLoading(true)), // Show loading on start
        tap({
          next: (response: VilleResponse) => {
            setVilles(response);
          },
        }),
        finalize(() => setLoading(false)) // Hide loading on end
      )
      .subscribe({
        error: noop,
      });
  };

  const removeSearchTags = (index: number) => {
    const data = searchFields;
    data.splice(index, 1);
    setSearchFields([...data]);
  };

  const clearFilter = () => {
    setSearchFields([]);
    setSearchType(VilleSearchType[0].id);
    setSearchTerm("");
  };

  const onSelectVille = (villeData: VilleSearch) => {
    setSelectedVille(villeData);
  };

  return (
    <Modal className={styles.modalSearch} width={1112} footer={null} visible={isOpen} onCancel={onCancel}>
      {loading && <SingleLoading />}
      <h3>Liste {popupType === TrajetPopupType.CODE_POSTAL ? "Codes Postal" : "Villes"}</h3>
      <p className={styles.subtitle}>Ci-dessous la liste des Villes prises en compte dans ce logiciel</p>
      <div className={styles.searchHeader}>
        <div className={styles.searchBox}>
          <Select
            bordered={false}
            defaultValue={VilleSearchType[0].id}
            value={searchType}
            onChange={(e) => setSearchType(e)}
            style={{ width: "10rem" }}
          >
            {VilleSearchType.map((el, index) => {
              if (popupType !== TrajetPopupType.CODE_POSTAL && el.id === "Code_Postal") {
                return;
              }
              return (
                <Option key={index} value={el.id}>
                  {el.value}
                </Option>
              );
            })}
          </Select>
          <Input
            id="search"
            name="search"
            bordered={false}
            placeholder="Rechercher"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onSubmitSearch();
              }
            }}
            suffix={<img src={SearchIcon} onClick={onSubmitSearch} style={{ cursor: "pointer" }} alt="Ville" />}
          />
        </div>

        {searchFields?.length > 0 && (
          <div className={styles.list_tag}>
            {searchFields.map((e, index) => {
              return (
                <div key={index} className={styles.tag_container}>
                  <div className={styles.tag}>
                    <span>
                      {e.field} - {e.textSearch.toString()}
                    </span>
                    <CloseCircleFilled
                      onClick={() => removeSearchTags(index)}
                      color="EDEDED"
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-8px",
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <span onClick={clearFilter} style={{ textDecoration: "underline", cursor: "pointer" }}>
          Réinitialiser
        </span>
      </div>
      <div className={styles.result}>{searchFields.length > 0 && `${villes?.total} ${villes?.total > 1 ? "résultats" : "résultat"}`} </div>

      <table className={styles.tableLine}>
        <thead>
          <tr>
            <th>Pays</th>
            <th>Dept</th>
            <th>Ville</th>
            {popupType === TrajetPopupType.CODE_POSTAL && <th>Code Postal</th>}
          </tr>
        </thead>
        <tbody>
          {villes?.data.map((e, index) => {
            return (
              <tr
                key={index}
                onClick={() => e && onSelectVille(e)}
                className={selectedVille?.id === e.id ? styles.selected : styles.notSelect}
                style={{
                  background: selectedVille?.id === e.id ? "#014289" : "#F5F6F9",
                }}
              >
                <td>{e.paysNom}</td>
                <td>{e.departementCode}</td>
                <td>{e.nom}</td>
                {popupType === TrajetPopupType.CODE_POSTAL && <td>{e.codePostal}</td>}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ margin: "1rem 0 0 auto", display: "table" }}>
        <Pagination
          total={villes?.total}
          current={page}
          pageSize={limit}
          onChange={(page, pageSize) => {
            setLimit(pageSize);
            setPage(page);
          }}
        />
      </div>
      {selectedVille && (
        <button
          onClick={() => {
            onSubmit(selectedVille);
          }}
          className={`${[styles.bt, styles.submitButton].join(" ")}`}
        >
          Valider
        </button>
      )}
    </Modal>
  );
};

export default ModalVille;

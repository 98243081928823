/** @format */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosResponse } from "axios";
import { from, map, Observable } from "rxjs";
import { Method, request } from "../../helper/request.helper";
import { TransporteurDetail } from "../../models/transporteurDetail.model";
import { TransporteurDetailDate } from "../../models/transporteurDetailDate.model";
import { LoopbackAPI } from "./loopbackApi.api";
import { UpsertTransporteurDetailRequestParams } from "../../models/requests/upsertTransporteurDetail.model";
import { UpsertTransporteurDetailResponse } from "../../models/responses/upsertTransporteurDetail.model";

const COMPONENT_NAME = "TransporteurDetails";

export class TransporteurDetailAPI implements LoopbackAPI<TransporteurDetail> {
  find = (filter?: unknown): Observable<TransporteurDetail[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params: {
          filter,
        },
      })
    ).pipe(map((response: AxiosResponse<TransporteurDetail[]>) => response.data));
  };

  count = (): Observable<number> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/count`,
      })
    ).pipe(map((response: AxiosResponse<number>) => response.data));
  };

  patch = (id: number, data: any): Observable<void> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbCreate = (data: any): Observable<void> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbDelete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/${id}`,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static fetchAllByCaracteristiquesLigneId = (caracteristiquesLigneId: number): Observable<TransporteurDetail[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params: {
          filter: {
            include: ["transporteurDetailDates", "moisGlobalOptions", "choixTransporteur"],
            where: {
              caracteristiquesLigneId: caracteristiquesLigneId,
            },
          },
        },
      })
    ).pipe(map((response: AxiosResponse<TransporteurDetail[]>) => response.data));
  };

  static fetchAllByChoixTransporteurId = (choixTransporteurId: number): Observable<TransporteurDetail[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params: {
          filter: {
            include: ["transporteurDetailDates", "moisGlobalOptions", "choixTransporteur"],
            where: {
              choixTransporteurId,
            },
          },
        },
      })
    ).pipe(map((response: AxiosResponse<TransporteurDetail[]>) => response.data));
  };

  static create = (data: TransporteurDetail): Observable<TransporteurDetail> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<TransporteurDetail>) => response.data));
  };

  static update = (id: number, data: TransporteurDetail): Observable<void> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static delete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/deleteTransporteurDetails`,
        params: {
          transporteurDetailIds: [id],
        },
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static deleteTransporterDetail = (ids: number[]): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/deleteTransporteurDetails`,
        params: {
          transporteurDetailIds: ids,
        },
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static updateTransporteurDetailDate = (id: number, data: { transporteurDetailDates: TransporteurDetailDate[] }): Observable<void> => {
    return from(
      request({
        method: Method.PUT,
        url: `/${COMPONENT_NAME}/${id}/updateTransporteurDetailDate`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static upsertTransporteurDetail = (data: UpsertTransporteurDetailRequestParams): Observable<UpsertTransporteurDetailResponse> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/upsertTransporteurDetail`,
        data,
      })
    ).pipe(map((response: AxiosResponse<UpsertTransporteurDetailResponse>) => response.data));
  };
}

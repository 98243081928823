import React, { useEffect, useState } from 'react';
import { LinesImported } from './ImportLines';
import { Button } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';

enum TypeCopy {
  All = 'all',
  Row = 'row',
  Column = 'column'
}

interface CopyTableProps {
  listLine: LinesImported[];
  isCopy?: boolean;
  dataParse: string[][];
  header: string[];
  onParse: (regx: string[][]) => void;
}

const CopyTable  = (props: CopyTableProps) => {
  const [dataParseShow, setDataParseShow] = useState<string[][]>([]);
  const listLine = props.listLine;
  const [typeCopy, setTypeCopy] = useState(TypeCopy.All);
  const [indexRow, setIndexRow] = useState(0);
  const [indexColumn, setIndexColumn] = useState(0);

  useEffect(() => {
    addNewLine();
  }, [props.isCopy])

  useEffect(() => {
    const save = dataParseShow;
    if (listLine.length > 0) {
      listLine.forEach((value, idx) => {
        save[idx][4] = value.data.distance ? value.data.distance.toString() : save[idx][4];
        save[idx][1] = value.data.dateInit ? `${new Date(value.data.dateInit).getDate()}/${new Date(value.data.dateInit).getMonth() + 1}/${new Date(value.data.dateInit).getFullYear()}` : save[idx][1];
        save[idx][2] = value.data.dateFin ? `${new Date(value.data.dateFin).getDate()}/${new Date(value.data.dateFin).getMonth() + 1}/${new Date(value.data.dateFin).getFullYear()}` : save[idx][2];
      })
      setDataParseShow([...save]);
    }
  }, [listLine])

  const parseDataCopy = (regx: string) => {
    regx = regx.replace(/(\r\n|\n|\r)/g,"\n");
    const row = regx.split('\n');
    switch (typeCopy) {
      case TypeCopy.Row:
        parsedRow(row);
        break;
      case TypeCopy.Column:
        parsedColumn(row);
        break;
    }
  };

  const parsedColumn = (row: string[]) => {
    const save = dataParseShow;
    row.forEach((value, idx) => {
      if (!save[idx]) {
        addNewLine(save);
      }
      save[idx][indexColumn] = value;
    });
    setDataParseShow([...save]);
    props.onParse.call(this, [...props.dataParse, ...save]);
  }

  const parsedRow = (row: string[]) => {
    let column: string[];
    const save = dataParseShow;
    const current = dataParseShow.slice(indexRow, row.length);
    row.forEach((value, idx) => {
      column = value.split('\t');
      if (column.length > 72) {
        column.splice(72, column.length - 72);
      }
      if (column.length < 72) {
        for(let i = column.length; i < 72; i++) {
          column[i] = '';
        }
      }
      for(let i = 0; i < column.length; i++) {
        column[i].replace('\r', '');
      }
      const dataExist = column.find(value => value);
      if (dataExist) {
        current[idx] = column;
      }
    });
    current.forEach((value, idx) => {
      save[indexRow + idx] = value;
    })
    setDataParseShow([...save]);
    props.onParse.call(this,[...props.dataParse, ...save]);
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    const charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      alert('CTRL+S Pressed');
    } else if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
      alert('CTRL+C Pressed');
    } else if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
      const a = await navigator.clipboard.readText();
      parseDataCopy(a);
    }
  };

  const addNewLine = (current?: string[][]) => {
    setDataParseShow([...current ? current : dataParseShow, ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''
      , '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']]);
    if (current) {
      current.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''
        , '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
    }
  };

  const selectRow = (el: string[], index: number) => {
    const currentTable: HTMLTableElement = document.getElementById('tableExcel') as HTMLTableElement;
    if (currentTable) {
      for (let idx = 1; idx < currentTable.rows.length; idx++) {
        if (currentTable.rows[idx].classList.contains('selectedTd')) {
          currentTable.rows[idx].classList.remove('selectedTd');
        } else {
          for (let i = 1; i < currentTable.rows.length; i++) {
            currentTable.rows[i].classList.remove('selectedTd');
          }
          for (let i = 1; i < currentTable.rows[idx].cells.length; i++) {
            currentTable.rows[idx].cells[i].classList.remove('selectedTd');
          }
        }
      }
      currentTable.rows[index + 1].classList.add('selectedTd');
      setTypeCopy(TypeCopy.Row);
      setIndexRow(index);
    }
  };

  const selectColumn = (index: number) => {
    // $('#tableExcel').filter(value => value.)
    const currentTable: HTMLTableElement = document.getElementById('tableExcel') as HTMLTableElement;
    if (currentTable) {
      for (let idx = 1; idx < currentTable.rows.length; idx++) {
        if (currentTable.rows[idx].cells[index + 1].classList.contains('selectedTd')) {
          currentTable.rows[idx].cells[index + 1].classList.remove('selectedTd');
        } else {
          for (let i = 1; i < currentTable.rows[idx].cells.length; i++) {
            currentTable.rows[idx].cells[i].classList.remove('selectedTd');
          }
          for (let i = 1; i < currentTable.rows.length; i++) {
            currentTable.rows[i].classList.remove('selectedTd');
          }
          currentTable.rows[idx].cells[index + 1].classList.add('selectedTd');
          setTypeCopy(TypeCopy.Column);
          setIndexColumn(index);
        }
      }
    }
  };

  return <div style={{position: 'relative'}}>
    <Button style={{ position: 'absolute', top:'0px', right:'10px', backgroundColor: '#014289',borderColor:'#014289', color: 'white' }} icon={<PlusSquareOutlined/>}
    onClick={() => addNewLine()}/>
    <div style={{ width: '100%', overflow: 'scroll' }}>
      <table id="tableExcel" onKeyDown={handleKeyDown} contentEditable={true}
             suppressContentEditableWarning={true} className="tableLine">
        <thead>
        <tr className="borderth">
          <th/>
          {
            props.header.map((el, index) => {
              return <th onClick={() => selectColumn(index)} key={index}>{el ? el : 'N/A'}</th>;
            })
          }
        </tr>
        </thead>
        <tbody>
        {
          dataParseShow.map((el, index) => {
            return <tr key={index}
                       className={listLine[index]?.error && listLine[index]?.error?.error ? 'error trWithCopy' : 'trWithCopy'}>
              <td onClick={() => selectRow(el, index)}>{index + 1}</td>
              {
                el.map((value, idx) => {
                  return <td key={idx}>{value}</td>;
                })
              }
              {/*  <td>{el.dateInit?.toString()}</td>*/}
              {/*  <td>{el.dateFin?.toString()}</td>*/}
              {/*  <td>{el.typeDuree}</td>*/}
              {/*  <td>{el.duree} km</td>*/}
              {/*  <td>{el.distance}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.lienReception}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.delaiAcceptation}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.toutes}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.lienRDVChargement}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.lienRDVLivraison}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.tauxParcPropre}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.parcPropreContractuel}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.lineaire}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {*/}
              {/*      el.checkCoutsAdditionnels*/}
              {/*    }*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    10*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {true}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.typeGasoil}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.valueGasoil}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.currencyGasoil}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.typeTunnel}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.plageMin}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.plageMax}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.frequenceRevision}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.adr}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.besoinsSpecifiques}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.plages}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.clientId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.societeId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.exploitantId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.indiceGasoilId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.lieuxDepartId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.lieuxArriveeId}*/}
              {/*  </td><td>*/}
              {/*  {el.accountId}*/}
              {/*</td>*/}
              {/*  <td>*/}
              {/*    {el.commercialId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.clientFactureId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.societeFactureId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.agenceId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.marchandiseId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.typeVehiculeId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.modeTransportId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.paysDepartId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.paysArriveeId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.departementDepartId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.departementArriveeId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.villeDepartId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.villeArriveeId}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.codepostalarrivee}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.codepostaldepart}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.mplmin}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.mplmax}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.ftl}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.poidsmin}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.poidsmax}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.palettesmin}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.palettesmax}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.palettesmax}*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    {el.accountId}*/}
              {/*  </td>*/}
            </tr>;
          })
        }
        </tbody>
      </table>
    </div>
  </div>
}

export default CopyTable

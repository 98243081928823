/** @format */

import { Button, Col, Form, message, Radio, Row, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { noop, tap } from "rxjs";
import { useAppSelector } from "../../../app/hooks";
import { selectIsShowTutorial } from "../../../app/reducers/indicatorTutorial.reducer";
import { selectLigne, selectLigneRequest, updateLigneRequest } from "../../../app/reducers/ligne.reducer";
import SearchIcon from "../../../assets/icons/search.svg";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomInputNumber from "../../../components/CustomInputNumber";
import CustomSelect from "../../../components/CustomSelect";
import IndicatorTutorialView from "../../../components/IndicatorTutorial";
import { IndiceGasoilAPI } from "../../../domain/apis/indiceGasoil.api";
import { IndiceGasoil } from "../../../models";
import UpdateLigne from "../UpdateLigne";
import styles from "./main.module.css";

const { Title } = Typography;
const { Option } = Select;

interface FormModal {
  indiceGasoilId: number | null;
  typeGasoil: string;
  nomIndexation: string | null;
  valueGasoil: number | null;
  partGasoil: number | null;
  currencyGasoil: string | null;
  checkTunnel: boolean;
  typeTunnel: string | null;
  plageMin: number | null;
  plageMax: number | null;
  frequenceRevisionGasoil: string | null;
  frequenceRevisionTunnel: string | null;
}

enum TypeGasoil {
  INDICE = "Indice",
  PRIXCO = "Prix GO",
}

const FrequenceRevisionList = ["Mensuel", "Trimestriel", "Annuel"];

const Indexation = (props: { editable: boolean }) => {
  const { editable } = props;
  const lineData = useAppSelector(selectLigne);
  const ligneRequest = useAppSelector(selectLigneRequest);
  const [form] = Form.useForm();
  const isShowTutorial = useAppSelector(selectIsShowTutorial);

  const dispatch = useDispatch();

  const [indiceGasoils, setIndiceGasoils] = useState<IndiceGasoil[]>([]);
  const [formValue, setFormValue] = useState<FormModal>({
    typeGasoil: TypeGasoil.INDICE,
    nomIndexation: null,
    valueGasoil: null,
    partGasoil: null,
    currencyGasoil: null,
    indiceGasoilId: lineData.indiceGasoilId ? lineData.indiceGasoilId : null,
    checkTunnel: false,
    typeTunnel: TypeGasoil.INDICE,
    plageMin: null,
    plageMax: null,
    frequenceRevisionGasoil: "Mensuel",
    frequenceRevisionTunnel: "Mensuel",
  });
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      typeGasoil: lineData.typeGasoil ? lineData.typeGasoil : TypeGasoil.INDICE,
      valueGasoil: lineData.valueGasoil,
      partGasoil: lineData.partGasoil,
      currencyGasoil: lineData.currencyGasoil ? lineData.currencyGasoil : null,
      indiceGasoilId: lineData.indiceGasoilId ? lineData.indiceGasoilId : null,
      checkTunnel: !!lineData.typeTunnel,
      typeTunnel: lineData.typeTunnel ? lineData.typeTunnel : TypeGasoil.INDICE,
      plageMin: lineData.plageMin,
      plageMax: lineData.plageMax,
      frequenceRevisionGasoil: lineData.frequenceRevisionGasoil ? lineData.frequenceRevisionGasoil : "Mensuel",
      frequenceRevisionTunnel: lineData.frequenceRevisionTunnel ? lineData.frequenceRevisionTunnel : "Mensuel",
    });
    setFormValue({
      ...formValue,
      typeTunnel: lineData.typeTunnel ? lineData.typeTunnel : TypeGasoil.INDICE,
      checkTunnel: !!lineData.typeTunnel,
      currencyGasoil: lineData.currencyGasoil ? lineData.currencyGasoil : null,
      typeGasoil: lineData.typeGasoil ? lineData.typeGasoil : TypeGasoil.INDICE,
    });
  }, [lineData]);

  useEffect(() => {
    getIndiceGasoils();
  }, [formValue]);

  const getIndiceGasoils = () => {
    IndiceGasoilAPI.fetchAll({
      filter: {
        where: {
          type: formValue.typeGasoil,
        },
      },
    })
      .pipe(
        tap((indiceGasoils: IndiceGasoil[]) => {
          setIndiceGasoils(indiceGasoils);
        })
      )
      .subscribe({
        error: noop,
      });
  };

  const onValuesChange = () => {
    const getFieldsValue = form.getFieldsValue();
    if (getFieldsValue.typeGasoil !== formValue.typeGasoil) {
      setFormValue({
        ...getFieldsValue,
        indiceGasoilId: undefined,
      });
      form.setFieldsValue({
        ...getFieldsValue,
        indiceGasoilId: undefined,
      });
      return;
    }
    setFormValue(getFieldsValue);
  };

  const onFinish = (values: FormModal) => {
    if (values.plageMin === 0 && values.plageMax === 0) {
      return onFinishFailed();
    }
    if (values.typeGasoil === TypeGasoil.INDICE) {
      values.currencyGasoil = null;
    }
    if (values.typeGasoil == TypeGasoil.PRIXCO) {
      values.currencyGasoil = "€";
    }
    if (!values.checkTunnel) {
      values.typeTunnel = null;
      values.plageMin = null;
      values.plageMax = null;
      values.frequenceRevisionTunnel = null;
    }

    dispatch(
      updateLigneRequest({
        ligneRequest: {
          ...ligneRequest,
          indiceGasoilId: Number(values.indiceGasoilId),
          typeGasoil: values.typeGasoil,
          valueGasoil: values.valueGasoil,
          partGasoil: values.partGasoil,
          typeTunnel: values.typeTunnel,
          plageMin: values.plageMin,
          plageMax: values.plageMax,
          frequenceRevisionGasoil: values.frequenceRevisionGasoil,
          frequenceRevisionTunnel: values.frequenceRevisionTunnel,
          currentStage: Number(lineData.currentStage) + 1,
        },
      })
    );
    setIsSubmit(true);
  };
  const onFinishFailed = () => {
    message.error("Merci de compléter toutes les colonnes.").then((r) => console.log(r));
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 22 },
    },
  };
  return (
    <div className={styles.fontSize}>
      {isSubmit && <UpdateLigne />}
      <Form
        {...formItemLayout}
        form={form}
        name="periodForm"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        onFinishFailed={onFinishFailed}
        initialValues={formValue}
        colon={false}
        autoComplete="off"
        disabled={!editable}
      >
        <Row>
          <Col span={6} offset={6}>
            <Title level={3} className={styles.title}>
              Indexation
            </Title>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <ul className={`${styles.labelTrajetIndexPage} ${styles.left}`}>
              <li className={styles.fontWeightBold}>Gasoil</li>
            </ul>
          </Col>
          <Col span={10} offset={1}>
            <ul className={`${styles.labelTrajetIndexPage}`}>
              <li className="flex">
                <Form.Item name="typeGasoil" label="" className={styles.fullWidth}>
                  <Radio.Group className={styles.flexAlignCenter}>
                    <Radio value="Indice">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Indice
                        {isShowTutorial ? (
                          <div style={{ marginLeft: "0.5rem" }}>
                            <IndicatorTutorialView hint={"À cocher si l'indexation se fonde sur un Indice Gasoil officiel."} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Radio>
                    <Radio value="Prix GO" className="whitespace-nowrap">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Prix GO
                        {isShowTutorial ? (
                          <div style={{ marginLeft: "0.5rem" }}>
                            <IndicatorTutorialView hint={"À cocher si l'indexation se fonde sur un Prix Gasoil officiel."} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <ul className={`${styles.labelTrajetIndexPage} ${styles.left} ${styles.form}`}>
              <li>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Nom
                  {isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={"Nom de la référence ; de l'Indice ou du Prix."} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
              <li>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Valeur de référence
                  {isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={"Valeur de référence ; Indice (sans unité) ou Prix (en €/L)."} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
              <li>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Part
                  {isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={"Part Gasoil contractuelle pour cette ligne."} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
            </ul>
          </Col>
          <Col span={5} offset={1}>
            <ul className={`${styles.labelTrajetIndexPage} ${styles.form} ${styles.formContent}`}>
              <li>
                <Form.Item label="" name="indiceGasoilId" rules={[{ required: true, message: "" }]} style={{ marginBottom: 0, width: "100%" }}>
                  <CustomSelect bordered={false} showSearch={true} style={{ width: "100%" }}>
                    {indiceGasoils.map((el: IndiceGasoil) => {
                      return (
                        <Option key={el.id} value={el.id}>
                          {el.nom}
                        </Option>
                      );
                    })}
                  </CustomSelect>
                </Form.Item>
              </li>
              <li>
                <Row className={styles.flexAlignCenter}>
                  <Col>
                    <Form.Item name="valueGasoil" className={styles.marginBottomTitle} rules={[{ required: true, message: "" }]}>
                      <CustomInputNumber step={0.01} min={0.01} precision={2} max={formValue.typeGasoil === TypeGasoil.PRIXCO ? 10 : 1000} />
                    </Form.Item>
                  </Col>
                  <Col>{formValue.typeGasoil == "Prix GO" && <div className={styles.currency}>&nbsp; €/L</div>}</Col>
                </Row>
              </li>
              <li>
                <Row className="flex items-center">
                  <Form.Item name="partGasoil" rules={[{ required: true, message: "" }]} className={`flex-1`} style={{ marginBottom: 0 }}>
                    <CustomInputNumber step={0.01} min={0.01} max={100} precision={2} />
                  </Form.Item>
                  <div>%</div>
                </Row>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <ul className={`${styles.labelTrajetIndexPage} ${styles.left}`}>
              <li className={styles.fontWeightBold}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Fréquence révision
                  {isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={"Fréquence de révision de l'Indexation."} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
            </ul>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item label="" name="frequenceRevisionGasoil" rules={[{ required: true, message: "" }]} className={styles.frequenceRevision}>
              <CustomSelect bordered={false} showSearch={true} suffixIcon={<img className={styles.searchIcon} src={SearchIcon} alt="Search" />}>
                {FrequenceRevisionList.map((el: string, index: number) => {
                  return (
                    <Option key={index} value={el}>
                      {el}
                    </Option>
                  );
                })}
              </CustomSelect>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <ul className={`${styles.labelTrajetIndexPage} ${styles.left}`}>
              <li className={styles.fontWeightBold}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Tunnel
                  {isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={"À cocher si un Tunnel est convenu contractuellement pour cette ligne."} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
            </ul>
          </Col>
          <Col span={10} offset={1}>
            <ul className={styles.labelTrajetIndexPage}>
              <li>
                <Form.Item valuePropName="checked" name="checkTunnel" label="" className={styles.marginBottomTitle}>
                  <CustomCheckbox name="disabled" disabled={!editable}>
                    Tunnel
                  </CustomCheckbox>
                </Form.Item>
              </li>
            </ul>
          </Col>
        </Row>

        {formValue.checkTunnel && (
          <>
            <Row>
              <Col span={6}>
                <ul className={`${styles.labelTrajetIndexPage} ${styles.left}`}>
                  <li>Référence</li>
                </ul>
              </Col>
              <Col span={10} offset={1}>
                <ul className={styles.labelTrajetIndexPage}>
                  <li>
                    <Form.Item name="typeTunnel" label="" className={styles.fullWidth}>
                      <Radio.Group className={styles.flexAlignCenter}>
                        <Radio value="Indice">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            Indice
                            {isShowTutorial && (
                              <div style={{ marginLeft: "0.5rem" }}>
                                <IndicatorTutorialView
                                  hint={
                                    <>
                                      À cocher si le Tunnel se fonde sur l&apos;<i>Indice Gasoil</i> renseigné ci-dessus.
                                    </>
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </Radio>
                        <Radio value="Prix ctr.">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            Prix ctr.
                            {isShowTutorial ? (
                              <div style={{ marginLeft: "0.5rem" }}>
                                <IndicatorTutorialView
                                  hint={
                                    <>
                                      À cocher si le Tunnel se fonde sur le <i>Prix contractualisé</i> de la ligne renseigné dans la partie Budget.
                                    </>
                                  }
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <ul className={`${styles.labelTrajetIndexPage} ${styles.left}`}>
                  <li>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Plage
                      {isShowTutorial ? (
                        <div style={{ marginLeft: "0.5rem" }}>
                          <IndicatorTutorialView hint={"Plage de variation de la Valeur ci-dessous, dans laquelle l'Indexation ne se fait pas."} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </li>
                </ul>
              </Col>
              <Col span={16} offset={1}>
                <Row style={{ padding: ".5rem 0" }}>
                  <Form.Item name="plageMin" label="-" rules={[{ required: true, message: "" }]} className={styles.formPlage} style={{ width: 125 }}>
                    <CustomInputNumber step={1} min={formValue.plageMax === 0 ? 1 : 0} max={100} precision={0} />
                  </Form.Item>

                  <Form.Item
                    name="plageMax"
                    label="+"
                    rules={[{ required: true, message: "" }]}
                    className={styles.formPlageRight}
                    style={{ width: 125 }}
                  >
                    <CustomInputNumber type="number" step={1} min={formValue.plageMin === 0 ? 1 : 0} max={100} precision={0} />
                  </Form.Item>
                  <div className={styles.currency}>&nbsp; %</div>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <ul className={`${styles.labelTrajetIndexPage} ${styles.left}`}>
                  <li>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Valeur
                      {isShowTutorial ? (
                        <div style={{ marginLeft: "0.5rem" }}>
                          <IndicatorTutorialView hint={"Valeur de référence pour le Tunnel."} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </li>
                </ul>
              </Col>
              <Col span={10} offset={1}>
                <ul className={styles.labelTrajetIndexPage}>
                  {(formValue.typeTunnel == "Indice" || formValue.typeTunnel == undefined) && <li className="flex">Référence Gasoil ci-dessus</li>}
                  {formValue.typeTunnel == "Prix ctr." && <li className="flex">Prix contractualisé (Budget)</li>}
                </ul>
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <ul className={`${styles.labelTrajetIndexPage} ${styles.form} ${styles.left}`}>
                  <li>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Fréquence révision
                      {isShowTutorial ? (
                        <div style={{ marginLeft: "0.5rem" }}>
                          <IndicatorTutorialView hint={"Fréquence de révision de l'Indexation."} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </li>
                </ul>
              </Col>
              <Col span={5} offset={1}>
                <Form.Item label="" name="frequenceRevisionTunnel" rules={[{ required: true, message: "" }]} className={styles.frequenceRevision}>
                  <CustomSelect bordered={false} showSearch={true} suffixIcon={<img className={styles.searchIcon} src={SearchIcon} alt="Search" />}>
                    {FrequenceRevisionList.map((el: string, index: number) => {
                      return (
                        <Option key={index} value={el}>
                          {el}
                        </Option>
                      );
                    })}
                  </CustomSelect>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Form.Item className={styles.submit}>
          {editable && (
            <Button
              type="primary"
              htmlType="submit"
              className={styles.bt}
              disabled={formValue.typeGasoil === TypeGasoil.PRIXCO && formValue.valueGasoil ? formValue.valueGasoil > 10 : undefined}
            >
              Confirmer
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default Indexation;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChoixTransporteur } from "../../../models/choixTransporteur.model";
import { RootState } from "../../store";

const initialState: {
  choixTransporteur: ChoixTransporteur
} = {
  choixTransporteur: {}
};

const CurrentChoixTransporteurSlice = createSlice({
  name: "currentChoixTransporteur",
  initialState,
  reducers: {
    UpdateChoixTransporteur: (
      state,
      action: PayloadAction<{ choixTransporteur: ChoixTransporteur }>
    ) => {
      state.choixTransporteur = action.payload.choixTransporteur;
    }
  },
});

export const {
  UpdateChoixTransporteur
} = CurrentChoixTransporteurSlice.actions;
export const selectCurrentChoixTransporteur = (state: RootState) =>
  state.currentChoixTransporteur.choixTransporteur;


export default CurrentChoixTransporteurSlice.reducer;

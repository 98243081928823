/** @format */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosResponse } from "axios";
import { from, map, Observable } from "rxjs";
import { Method, request } from "../../helper/request.helper";
import { Lieux } from "../../models";
import { LieuxSearchParams } from "../../models/requests/lieuxSearchRequest.model";
import { LieuxSearchResponse } from "../../models/responses/lieuxSearch.model";
import { LoopbackAPI } from "./loopbackApi.api";

const COMPONENT_NAME = "Lieuxes";

export class LieuxAPI implements LoopbackAPI<Lieux> {
  find = (filter?: unknown): Observable<Lieux[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params: {
          filter,
        },
      })
    ).pipe(map((response: AxiosResponse<Lieux[]>) => response.data));
  };

  count = (): Observable<number> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/count`,
      })
    ).pipe(map((response: AxiosResponse<number>) => response.data));
  };

  patch = (id: number, data: any): Observable<void> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbCreate = (data: any): Observable<void> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbDelete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/${id}`,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static fetchAll = (params?: unknown): Observable<Lieux[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params,
      })
    ).pipe(map((response: AxiosResponse<Lieux[]>) => response.data));
  };

  static search = (paramsSearch: LieuxSearchParams[], limit?: number, offset?: number, type?: string): Observable<LieuxSearchResponse> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/search`,
        params: {
          search: paramsSearch,
          limit,
          offset,
          type,
        },
      })
    ).pipe(map((response: AxiosResponse<LieuxSearchResponse>) => response.data));
  };
}

export enum AppColors {
  COLOR_CALENDAR_DEFAULT_SECONDARY = "#FAECDF",
  COLOR_CALENDAR_DEFAULT_PRIMARY = "#F1862D",
  COLOR_CALENDAR_RED_SECONDARY = "rgba(235,72,65,0.3)",
  COLOR_CALENDAR_RED_PRIMARY  = "rgba(235,72,65,1)",
  COLOR_CALENDAR_ORANGE_SECONDARY = "rgba(244,136,71,0.3)",
  COLOR_CALENDAR_ORANGE_PRIMARY  = "rgba(244,136,71,1)",
  COLOR_CALENDAR_YELLOW_SECONDARY = "rgba(255,200,74,0.3)",
  COLOR_CALENDAR_YELLOW_PRIMARY  = "rgba(255,200,74,1)",
  COLOR_CALENDAR_LIGHT_GREEN_SECONDARY = "rgba(166,195,76,0.3)",
  COLOR_CALENDAR_LIGHT_GREEN_PRIMARY  = "rgba(166,195,76,1)",
  COLOR_CALENDAR_GREEN_SECONDARY = "rgba(78,192,78,0.3)",
  COLOR_CALENDAR_GREEN_PRIMARY  = "rgba(78,192,78,1)",
}

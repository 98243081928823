/** @format */

import { Form, Input, InputNumber, Select } from "antd";
import { CountryCode } from "../../../../models/countryCode.model";
import styles from "../main.module.css";

const { Option } = Select;

const SelectPhone = (props: { keyCode: string; keyPhone: string; fieldsError: (string | number)[]; countryCode: CountryCode[] }) => {
  const { keyCode, keyPhone, fieldsError, countryCode } = props;

  return (
    <div>
      <Input.Group compact>
        <Form.Item
          name={keyCode}
          className={[fieldsError.includes(keyCode) ? styles.contactError : styles.contactValid, styles.contactInput, "opacitySelect"].join(" ")}
          rules={[{ required: true, message: "" }]}
          style={{ width: "20%" }}
        >
          <Select style={{ width: 80 }}>
            {countryCode.map((el) => {
              return (
                <Option key={el.id} value={el.id}>
                  {el.code}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name={keyPhone}
          className={[fieldsError.includes(keyPhone) ? styles.contactError : styles.contactValid, styles.contactInput].join(" ")}
          rules={[{ required: true, message: "" }]}
          style={{ width: "80%" }}
        >
          <InputNumber type="number" style={{ width: "100%" }} bordered={false} />
        </Form.Item>
      </Input.Group>
    </div>
  );
};

export default SelectPhone;

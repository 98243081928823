/** @format */

import styles from "./Jour.module.css";
import { Button, Col, Row } from "antd";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import { Lots } from "../../../../../models/lots.model";
import { useAppSelector } from "../../../../../app/hooks";
import { ActionType, selectDemandeState } from "../../../../../app/reducers/DemandeClient/DemandeState.reducer";
import moment from "moment";
import { DATE_FORMAT } from "../../../../../helper/calendarHelper";

interface PropsInput {
  lotsDate: Lots[];
  onRemove?: (id: number) => void;
}

const LotsJour = (props: PropsInput) => {
  const { lotsDate, onRemove } = props;
  const demandeState = useAppSelector(selectDemandeState);

  return (
    <>
      <Row className={styles.jourHeader}>
        <Col span={9}>Date</Col>
        <Col span={5}>Min</Col>
        <Col span={5}>Max</Col>
      </Row>

      <div className={styles.jourList}>
        {lotsDate.map((el, index) => {
          return (
            <Row key={index} className={styles.addedListDate}>
              <Col span={9}>{moment(el.date).format(DATE_FORMAT)}</Col>
              <Col span={5}>{Number(el.lotMin)?.toFixed(0)}</Col>
              <Col span={5}>{Number(el.lotMax)?.toFixed(0)}</Col>
              {demandeState.actionType !== ActionType.VIEW && (
                <Col span={5}>
                  <Button
                    className={styles.removeBtn}
                    icon={<img src={DeleteIcon} alt="Delete" />}
                    onClick={() => (onRemove ? onRemove(index) : null)}
                  />
                </Col>
              )}
            </Row>
          );
        })}
      </div>
    </>
  );
};

export default LotsJour;

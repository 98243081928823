import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {TransporteurDetailSubType, TransporteurDetailType} from "../../../helper/calendarHelper";

export enum ActionType {
  VIEW = "view",
  CREATE = "create",
  UPDATE = "update"
}

interface TransporterStateReducer {
  actionType: ActionType
  mainType?: TransporteurDetailType;
  subType?: TransporteurDetailSubType;
}

const initialState: TransporterStateReducer = {
  actionType: ActionType.VIEW,
  mainType: undefined,
  subType: undefined
};

export const TransporteurDetailStateSlice = createSlice({
  name: 'transporteurDetailState',
  initialState,
  reducers: {
    UpdateTransporteurDetailState: (state, action: PayloadAction<{ actionType?: ActionType, mainType?: TransporteurDetailType; subType?: TransporteurDetailSubType }>) => {
      state.actionType = action.payload?.actionType ? action.payload?.actionType : state.actionType;
      state.mainType = action.payload?.mainType;
      state.subType = action.payload?.subType;
    }
  },
});

export const { UpdateTransporteurDetailState } = TransporteurDetailStateSlice.actions;

export const GetTransporteurDetailState = (state: RootState) => state.transporteurDetailState;
export default TransporteurDetailStateSlice.reducer;

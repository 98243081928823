/** @format */

/* eslint-disable @typescript-eslint/no-explicit-any */

// Need to use the React-specific entry point to import createApi
import { DemandeClientHistory } from "../../../models/demandeClientHistory";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { request } from "../../../helper/request.helper";
import { AxiosResponse } from "axios";
import { BatchAverage, DemandeClient } from "../../../models";
import { groupBy, maxBy, sumBy } from "lodash";
import { getRangeDate } from "../../../helper/calendarHelper";

export enum DemandeHistoryStatus {
  IDLE = "idle",
  PENDING = "pending",
  SUCCESS = "success",
  ERROR = "error",
}

type ReducerType = {
  demandeHistories: DemandeClientHistory[];
  historyDetails:
    | {
        demandeClients: DemandeClient[];
        moyenneDeLotsMax: number;
        batchAverages: BatchAverage[];
      }
    | undefined;
  status: "idle" | "pending" | "success" | "error";
  loadDetail: "idle" | "pending" | "success" | "error";
  error: string | null;
};

const initialState: ReducerType = {
  demandeHistories: [],
  historyDetails: undefined,
  status: DemandeHistoryStatus.IDLE,
  loadDetail: DemandeHistoryStatus.IDLE,
  error: null,
};

export const fetchDemandeHistories = createAsyncThunk("demande/fetchHistories", async (ligneId: number) => {
  const response = await request({
    url: "DemandeClientHistories",
    params: {
      filter: {
        where: {
          caracteristiquesLigneId: ligneId,
        },
        fields: { id: true, date: true, caracteristiquesLigneId: true },
      },
    },
  });
  return response.data;
});

// export const fetchDemandeHistoryDetail = createAsyncThunk("demande/fetchHistoryDetail", async (historyId: number) => {
export const fetchDemandeHistoryDetail = createAsyncThunk(
  "demande/fetchHistoryDetail",
  async (params: { caracteristiquesLigneId: number; date: string; startDate: Date; endDate: Date }) => {
    const response: AxiosResponse<{ demandeClients: DemandeClient[] }> = await request({
      url: `DemandeClients/getHistoryDemandeClient`,
      params: {
        caracteristiquesLigneId: params.caracteristiquesLigneId,
        date: params.date,
      },
    });

    const demandeClientDates = response.data.demandeClients.map((item) => item.demandeClientDates ?? []).flat();
    let demandeClientsDateByDay: any = groupBy(demandeClientDates, "date");
    demandeClientsDateByDay = Object.keys(demandeClientsDateByDay).map((date) => {
      return {
        date: date,
        totallotmin: sumBy(demandeClientsDateByDay[date], (item: { lotMin: string }) => parseFloat(item.lotMin)),
        totallotmax: sumBy(demandeClientsDateByDay[date], (item: { lotMax: string }) => parseFloat(item.lotMax)),
      };
    });

    const moyenneDeLotsMax = (await maxBy(demandeClientsDateByDay, "totallotmax"))
      ? (maxBy(demandeClientsDateByDay, "totallotmax") as any).totallotmax
      : 0;

    const batchAverages = getRangeDate(params.startDate, params.endDate).map((date) => {
      const dateFormat = date.toISOString().slice(0, 10).replaceAll("-", "/");

      const item = demandeClientsDateByDay.find((it: { date: string }) => it.date === dateFormat);

      return {
        date: dateFormat,
        value: item ? ((item.totallotmin + item.totallotmax) * 100) / (2 * moyenneDeLotsMax) : 0,
      };
    });

    return {
      demandeClients: response.data.demandeClients,
      moyenneDeLotsMax,
      batchAverages,
    };
  }
);

// Define a service using a base URL and expected endpoints
const demandeHistorySlice = createSlice({
  name: "demandeClientHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDemandeHistories.pending, (state) => {
      state.status = DemandeHistoryStatus.PENDING;
    });
    builder.addCase(fetchDemandeHistories.fulfilled, (state, action) => {
      state.demandeHistories = action.payload;
      state.status = DemandeHistoryStatus.SUCCESS;
    });
    builder.addCase(fetchDemandeHistories.rejected, (state) => {
      state.status = DemandeHistoryStatus.ERROR;
    });

    builder.addCase(fetchDemandeHistoryDetail.pending, (state) => {
      state.loadDetail = DemandeHistoryStatus.PENDING;
    });
    builder.addCase(fetchDemandeHistoryDetail.fulfilled, (state, action) => {
      state.historyDetails = action.payload;
      state.loadDetail = DemandeHistoryStatus.SUCCESS;
    });
    builder.addCase(fetchDemandeHistoryDetail.rejected, (state) => {
      state.loadDetail = DemandeHistoryStatus.ERROR;
    });
  },
});

export const selectDemandeHistoryStore = (state: RootState) => state.demandeHistory;

export default demandeHistorySlice.reducer;

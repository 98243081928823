/** @format */

import { Button, Col, Row, Select, Typography } from "antd";
import { associateRateFilterColors, AssociateRateFilterInterface } from "../../models/constants/associateRateFilter.const";
import { filterTypes } from "../../helper/calendarHelper";
import styles from "./FullCalendar.module.css";
import { CaracteristiquesLignes } from "../../models";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import IndicatorTutorialView from "../IndicatorTutorial";
import React from "react";

const { Option } = Select;

export interface HeaderCalendarProps {
  caracteristiquesLigne: CaracteristiquesLignes;
  disabledFilter?: boolean;
  startYear: number;
  endYear: number;
  selectedYear: number;
  onSelectYear?: (year: string | number) => void;
  selectedFilter?: number[];
  onSelectFilter?: (selectedFilter: AssociateRateFilterInterface) => void;
  showFilter?: boolean;
  isShowTutorial: boolean;
}

const HeaderCalendar = (props: HeaderCalendarProps) => {
  // Min selected year = startYear, max selected year = endYear
  const years = Array(props.endYear - props.startYear + 1)
    .fill(0)
    .map((_, index) => (props.startYear + index).toString());

  const caracteristiques = props.caracteristiquesLigne.caracteristiques;
  return (
    <header style={{ marginBottom: "-2rem" }}>
      <Row style={{ fontSize: "16px" }}>
        <Col>
          <Row className={styles.headerContainer}>
            <Col>Client</Col>
            <Col>{caracteristiques?.client ? `${caracteristiques?.client?.code} - ${caracteristiques?.client?.nom}` : ""}</Col>
          </Row>
        </Col>
        <Col style={{ marginLeft: "1rem" }}>
          <Row className={styles.headerContainer}>
            <Col>ID Ligne</Col>
            <Col>
              {props.caracteristiquesLigne?.idCaracteristiquesLigne
                ? props.caracteristiquesLigne?.caracteristiques?.chargement && props.caracteristiquesLigne?.caracteristiques?.chargement?.length > 1
                  ? props.caracteristiquesLigne?.idCaracteristiquesLigne
                  : props.caracteristiquesLigne?.caracteristiques?.idLigne
                : ""}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "10px",
          fontSize: "16px",
          justifyContent: "space-between",
        }}
      >
        <Col>
          <Row className={styles.headerContainer}>
            <Col>Trajet</Col>
            <Col>
              {caracteristiques?.paysDepart &&
                caracteristiques.departementDepart &&
                `${caracteristiques.paysDepart.nom}/${caracteristiques.departementDepart.code}`}
              {caracteristiques?.paysArrivee &&
                caracteristiques.departementArrivee &&
                ` - ${caracteristiques.paysArrivee.nom}/${caracteristiques.departementArrivee.code}`}
            </Col>
          </Row>
        </Col>
        {props.showFilter ? (
          <Col>
            <Row style={{ alignItems: "center" }}>
              <Col>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography.Title level={5} style={{ fontSize: "12px" }}>
                    Filtre :
                  </Typography.Title>
                  {props.isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={<>Possibilité d&apos;afficher un ou plusieurs Taux d’association sur le Calendrier.</>} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
              {/* Generate associate filters */}
              {[...associateRateFilterColors].reverse().map((item, index) => (
                <Col key={`Filter${index}`} style={{ marginLeft: "8px" }}>
                  <div
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <div style={{ position: "relative" }} onClick={() => !props.disabledFilter && props.onSelectFilter?.call(this, item)}>
                      <div
                        style={{
                          width: "18px",
                          height: "18px",
                          borderRadius: "3px",
                          border: (props.selectedFilter ?? []).includes(item.id) ? "1px solid black" : "none",
                          backgroundColor: item.colorSecondary,
                        }}
                      />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        ) : (
          <></>
        )}

        <Col>
          <Row>
            <Select className={styles.dateSelect} showArrow={true} bordered={false} defaultValue={filterTypes[0]}>
              {filterTypes.map((filterType: string) => (
                <Option key={`filter${filterType}`} value={filterType}>
                  {filterType}
                </Option>
              ))}
            </Select>

            <Button
              type="primary"
              icon={<LeftOutlined />}
              size={"large"}
              className={styles.dateSelectPrevious}
              disabled={props.selectedYear == props.startYear}
              onClick={() => {
                props.onSelectYear?.(props.selectedYear - 1);
              }}
            />
            <Select className={styles.dateSelect} showArrow={true} bordered={false} value={props.selectedYear} onSelect={props.onSelectYear}>
              {years.map((year: string) => (
                <Option key={`year${year}`} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
            <Button
              type="primary"
              icon={<RightOutlined />}
              size={"large"}
              className={styles.dateSelectNext}
              disabled={props.selectedYear == props.endYear}
              onClick={() => {
                props.onSelectYear?.(props.selectedYear + 1);
              }}
            />
          </Row>
        </Col>
      </Row>
    </header>
  );
};

export default HeaderCalendar;

/** @format */

import { Button, Col, Form, message, Row, Select, Typography } from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { selectIsShowTutorial } from "../../../app/reducers/indicatorTutorial.reducer";
import {
  selectChoixTransporter,
  selectDemandeClients,
  selectLigne,
  selectLigneRequest,
  updateLigneRequest,
} from "../../../app/reducers/ligne.reducer";
import Calendar from "../../../assets/icons/calendar.svg";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomSelect from "../../../components/CustomSelect";
import IndicatorTutorialView from "../../../components/IndicatorTutorial";
import { CaracteristiquesTypeDuree } from "../../../models";
import UpdateLigne from "../UpdateLigne";
import styles from "./main.module.css";
import PopupConfirm from "../../../components/PopupConfirm";
import { uniq } from "lodash";

const { Title } = Typography;

const { Option } = Select;

interface FormModal {
  dateInit: Date | null;
  dateFin: Date | null | undefined;
  checkDuree: boolean | false;
  typeDuree: string | null;
  duree: number | null;
}

let removeDemandeClientIds: number[] = [];
let removeChoixTransporteurIds: number[] = [];

const Periode = (props: { editable: boolean }) => {
  const { editable } = props;
  const lineData = useAppSelector(selectLigne);
  const ligneRequest = useAppSelector(selectLigneRequest);
  const dispatch = useDispatch();
  const demandeClients = useAppSelector(selectDemandeClients);
  const choixTransporters = useAppSelector(selectChoixTransporter);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);

  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<FormModal>({
    dateInit: null,
    dateFin: null,
    checkDuree: false,
    typeDuree: CaracteristiquesTypeDuree.MOIS,
    duree: 12,
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [isShowConfirmPeriod, setIsShowConfirmPeriod] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      dateInit: lineData.dateInit ? moment(lineData.dateInit) : null,
      dateFin: lineData.typeDuree ? null : lineData.dateFin ? moment(lineData.dateFin) : null,
      typeDuree: lineData.typeDuree ? lineData.typeDuree : CaracteristiquesTypeDuree.MOIS,
      checkDuree: !!lineData.typeDuree,
      duree: lineData.duree ? Number(lineData.duree) : 12,
    });
    setFormValue({
      ...formValue,
      dateInit: lineData.dateInit ? lineData.dateInit : null,
      dateFin: lineData.typeDuree ? null : lineData.dateFin,
      typeDuree: lineData.typeDuree ? lineData.typeDuree : CaracteristiquesTypeDuree.MOIS,
      checkDuree: !!lineData.typeDuree,
      duree: lineData.duree ? Number(lineData.duree) : 12,
    });
  }, [lineData]);

  const onConfirmPeriod = () => {
    setIsShowConfirmPeriod(false);
    setIsSubmit(true);
  };

  const onFinish = (values: FormModal) => {
    if (values.checkDuree) {
      values.dateFin = null;
    }
    if (!values.checkDuree) {
      values.duree = null;
      values.typeDuree = null;
    }

    dispatch(
      updateLigneRequest({
        ligneRequest: {
          ...ligneRequest,
          dateInit: moment(values.dateInit).format("DD-MM-YYYY") || undefined,
          dateFin: moment(values.dateFin).format("DD-MM-YYYY"),
          duree: values.duree,
          typeDuree: values.typeDuree,
          currentStage: Number(lineData.currentStage) + 1,
        },
      })
    );

    // Check current demande client dates out of range selected
    const dateInit = moment(values.dateInit).startOf("day");
    let dateFin: Moment;
    if (values.dateFin) {
      dateFin = moment(values.dateFin).endOf("day");
    } else {
      if (values.typeDuree?.toLowerCase() == CaracteristiquesTypeDuree.MOIS.toLowerCase()) {
        dateFin = moment(values.dateInit).add(values.duree, "months");
      } else {
        dateFin = moment(values.dateInit).add(values.duree, "years");
      }
    }

    // find demande client ids have dates out of range the selected dates
    const demandeClientIds: number[] = uniq(
      demandeClients
        .map((demandeClient) => demandeClient.demandeClientDates ?? [])
        .flat()
        .filter((demandeClientDate) => moment(demandeClientDate.date).isBefore(dateInit) || moment(demandeClientDate.date).isAfter(dateFin))
        .map((demandeClientDate) => demandeClientDate.demandeClientId)
    ) as number[];

    const demandeKeep = demandeClients.filter((item) => !demandeClientIds.includes(Number(item?.id)));

    const listKeepDates: string[] = [];

    demandeKeep.map((el) => {
      el.demandeClientDates?.map((item) => {
        item.date && listKeepDates.push(item.date);
      });
    });

    const transporteurDetails = choixTransporters.map((choixTransporter) => choixTransporter.transporteurDetails).flat();
    const transporterDetailIds = transporteurDetails
      .filter((el) => {
        if (el) {
          return el.transporteurDetailDates?.some((item) => item.date && !listKeepDates.includes(item.date));
        }
        return false;
      })
      .map((el) => Number(el?.id));

    if (demandeClientIds.length > 0 || transporterDetailIds.length > 0) {
      removeDemandeClientIds = demandeClientIds;
      removeChoixTransporteurIds = transporterDetailIds;
      setIsShowConfirmPeriod(true);
    } else {
      removeDemandeClientIds = [];
      setIsSubmit(true);
    }
  };

  const onValuesChange = () => {
    const getFieldsValue = form.getFieldsValue();
    setFormValue(getFieldsValue);

    if (getFieldsValue.typeDuree) {
      form.setFieldsValue({
        ...getFieldsValue,
        typeDuree: getFieldsValue.typeDuree,
      });
      setFormValue({
        ...getFieldsValue,
        typeDuree: getFieldsValue.typeDuree,
      });
    }
    if (getFieldsValue.dateInit && !getFieldsValue.dateFin) {
      form.setFieldsValue({
        ...getFieldsValue,
        dateFin: moment(getFieldsValue.dateInit).add("1", "months"),
      });
      setFormValue({
        ...getFieldsValue,
        dateFin: moment(getFieldsValue.dateInit).add("1", "months"),
      });
    }
    if (!getFieldsValue.dateInit && getFieldsValue.dateFin) {
      form.setFieldsValue({
        ...getFieldsValue,
        dateInit: moment(getFieldsValue.dateFin).subtract("1", "months"),
      });
      setFormValue({
        ...getFieldsValue,
        dateInit: moment(getFieldsValue.dateFin).subtract("1", "months"),
      });
    }
    if (getFieldsValue.duree > 5 && getFieldsValue.typeDuree === CaracteristiquesTypeDuree.AN) {
      form.setFieldsValue({
        ...getFieldsValue,
        duree: 1,
      });
      setFormValue({
        ...getFieldsValue,
        duree: 1,
      });
    }
  };

  const onFinishFailed = () => {
    message.error("Merci de compléter toutes les colonnes.").then((r) => console.log(r));
  };

  return (
    <>
      {isSubmit && <UpdateLigne removeDemandeClientIds={removeDemandeClientIds} removeChoixTransporteurIds={removeChoixTransporteurIds} />}
      <PopupConfirm
        visible={isShowConfirmPeriod}
        title="Attention"
        okText="Oui"
        cancelText="Non"
        onOk={onConfirmPeriod}
        onCancel={() => {
          removeDemandeClientIds = [];
          removeChoixTransporteurIds = [];
          setIsShowConfirmPeriod(false);
        }}
      >
        <>
          Les détails de Demande Client {removeChoixTransporteurIds.length > 0 ? "et de Transporteurs" : ""} conservés ne seront que ceux qui sont
          inclus dans ces nouvelles dates.
          <br /> Souhaitez-vous continuer ?
        </>
      </PopupConfirm>
      <Form
        form={form}
        name="periodForm"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        onFinishFailed={onFinishFailed}
        initialValues={formValue}
        colon={false}
        autoComplete="off"
        disabled={!editable}
      >
        <Title level={3} className={styles.title}>
          Période
        </Title>
        <Row style={{ justifyContent: "center", minWidth: "15rem" }}>
          <Col>
            <Form.Item
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  Du
                  {isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={"Date de départ du contrat de la ligne."} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              }
              name="dateInit"
              rules={[{ required: true, message: "" }]}
            >
              {formValue.checkDuree ? (
                <CustomDatePicker suffixIcon={<img src={Calendar} alt="" />} />
              ) : (
                <CustomDatePicker
                  disabledDate={(d) =>
                    !d || d.isAfter(moment().add(6, "years")) || d.isSameOrBefore(moment().subtract(2, "years").format("YYYY-MM-DD"))
                  }
                  suffixIcon={<img src={Calendar} alt="" />}
                  clearIcon={false}
                  onChange={(_, dateString) => {
                    if (form.getFieldsValue().dateFin) {
                      const dateInit = moment(dateString, "DD-MM-YYYY");
                      const dateFin = moment(form.getFieldsValue().dateFin);
                      if (
                        moment([dateFin.year(), dateFin.month(), dateFin.date()]).diff(
                          moment([dateInit.year(), dateInit.month(), dateInit.date()]),
                          "months",
                          true
                        ) < 1
                      ) {
                        form.setFieldsValue({
                          ...form.getFieldsValue(),
                          dateFin: moment([dateInit.year(), dateInit.month(), dateInit.date()]).add(1, "months"),
                        });
                        setFormValue({
                          ...form.getFieldsValue(),
                          dateFin: moment([dateInit.year(), dateInit.month(), dateInit.date()]).add(1, "months"),
                        });
                      }
                    }
                  }}
                />
              )}
            </Form.Item>
            <Form.Item valuePropName="checked" name="checkDuree" label="">
              <CustomCheckbox disabled={!editable}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Durée
                  {isShowTutorial ? (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={"À cocher si nous voulons compléter en durée depuis la date de départ."} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </CustomCheckbox>
            </Form.Item>
          </Col>
          {!formValue.checkDuree && (
            <Col>
              <Form.Item
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    au
                    {isShowTutorial ? (
                      <div style={{ marginLeft: "0.5rem" }}>
                        <IndicatorTutorialView hint={"Date de fin du contrat de la ligne."} />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                }
                name="dateFin"
                rules={[{ required: true, message: "" }]}
                className={styles.formAu}
              >
                <CustomDatePicker
                  clearIcon={false}
                  disabledDate={(d) =>
                    !d || d.isAfter(moment().add(11, "years")) || d.isSameOrBefore(moment().subtract(2, "years").format("YYYY-MM-DD"))
                  }
                  suffixIcon={<img src={Calendar} alt="" />}
                  defaultPickerValue={
                    formValue.dateInit
                      ? moment(formValue.dateInit).add("1", "months").subtract(1, "days")
                      : moment().add("1", "months").subtract(1, "days")
                  }
                  onChange={(_, dateString) => {
                    if (form.getFieldsValue().dateInit) {
                      const dateFin = moment(dateString, "DD-MM-YYYY");
                      const dateInit = moment(form.getFieldsValue().dateInit);
                      if (
                        moment([dateFin.year(), dateFin.month(), dateFin.date()]).diff(
                          moment([dateInit.year(), dateInit.month(), dateInit.date()]),
                          "months",
                          true
                        ) < 1
                      ) {
                        form.setFieldsValue({
                          ...form.getFieldsValue(),
                          dateInit: moment([dateFin.year(), dateFin.month(), dateFin.date()]).subtract(1, "months"),
                        });
                        setFormValue({
                          ...form.getFieldsValue(),
                          dateInit: moment([dateFin.year(), dateFin.month(), dateFin.date()]).subtract(1, "months"),
                        });
                      }
                    }
                  }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <Col>
            {formValue.checkDuree && (
              <div style={{ display: "flex", minWidth: "12rem" }}>
                <Form.Item name="typeDuree" label="" style={{ width: "100%", marginRight: "1rem" }}>
                  <CustomSelect showArrow={true} bordered={false}>
                    <Option value={CaracteristiquesTypeDuree.MOIS}>Mois</Option>
                    <Option value={CaracteristiquesTypeDuree.AN}>An</Option>
                  </CustomSelect>
                </Form.Item>
                {formValue.typeDuree == CaracteristiquesTypeDuree.AN ? (
                  <Form.Item name="duree" label="" style={{ width: "100%" }}>
                    <CustomSelect showArrow={true} bordered={false}>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                    </CustomSelect>
                  </Form.Item>
                ) : (
                  <Form.Item name="duree" label="" style={{ width: "100%" }}>
                    <CustomSelect showArrow={true} bordered={false}>
                      {Array.from(Array(60).keys()).map((el, index) => {
                        return (
                          <Option key={index} value={el + 1}>
                            {el + 1}
                          </Option>
                        );
                      })}
                    </CustomSelect>
                  </Form.Item>
                )}
              </div>
            )}
          </Col>
        </Row>

        <Form.Item className={styles.submit}>
          {editable && (
            <Button type="primary" htmlType="submit" className={styles.bt}>
              Confirmer
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default Periode;

/** @format */

import { AxiosError } from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { combineLatest, finalize, mergeMap, noop, Observable, of, tap } from "rxjs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CaracteristiquesPopupType, UpdateCaracteristiquesPopup } from "../../app/reducers/Caracteristiques/CaracteristiquesPopup.reducer";
import { fetchChoixTransporter, fetchDemandeClients, selectLigneId, selectLigneRequest, updateLigne } from "../../app/reducers/ligne.reducer";
import SingleLoading from "../../components/SingleLoading";
import { CaracteristiqueAPI } from "../../domain/apis/caracteristique.api";
import { DemandeClientAPI } from "../../domain/apis/demandeClient.api";
import { doOnSubscribe } from "../../helper/rxjs.helper";
import { Caracteristiques, CaracteristiquesLignes, DemandeClient } from "../../models";
import { ChoixTransporteur } from "../../models/choixTransporteur.model";
import { TransporteurDetailAPI } from "../../domain/apis/transporteurDetail.api";

const UpdateLigne = (props: { cbSubmit?: (state: boolean) => void; removeDemandeClientIds?: number[]; removeChoixTransporteurIds?: number[] }) => {
  const { cbSubmit, removeDemandeClientIds, removeChoixTransporteurIds } = props;
  const ligneId = useAppSelector(selectLigneId);
  const ligneRequest = useAppSelector(selectLigneRequest);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // update demande clients and choix transporteurs on redux after data changed
  const updateDemandeClientsAndChoixTransporteurs = (lineId: number): Observable<boolean> => {
    return CaracteristiqueAPI.fetchDetail(Number(lineId)).pipe(
      tap({
        next: (caracteristiques: Caracteristiques) => {
          let demandeClients: DemandeClient[] = [];
          let choixTransporteurs: ChoixTransporteur[] = [];
          if (caracteristiques.caracteristiquesLigne) {
            caracteristiques.caracteristiquesLigne.map((el: CaracteristiquesLignes) => {
              demandeClients = [...demandeClients, ...(el.demandeClients || [])];
            });
          }
          if (caracteristiques.caracteristiquesLigne) {
            caracteristiques.caracteristiquesLigne.map((el: CaracteristiquesLignes) => {
              choixTransporteurs = [...choixTransporteurs, ...(el.choixTransporteur || [])];
            });
          }
          dispatch(fetchDemandeClients({ demandeClients: demandeClients }));
          dispatch(fetchChoixTransporter({ choixTransporteurs: choixTransporteurs }));
        },
      }),
      mergeMap(() => of(true))
    );
  };

  const saveLigne = () => {
    cbSubmit && cbSubmit(true);
    const idLigne = ligneRequest.isCompleted ? ligneId : undefined;
    CaracteristiqueAPI.upsertCaracteristiques({
      ...ligneRequest,
      idLigne,
      dateInit: ligneRequest.dateInit && new Date(moment(ligneRequest.dateInit, "DD-MM-YYYY").format("YYYY-MM-DD")),
      dateFin: ligneRequest.dateFin && new Date(moment(ligneRequest.dateFin, "DD-MM-YYYY").format("YYYY-MM-DD")),
    })
      .pipe(
        doOnSubscribe(() => setIsLoading(true)), // Show loading on start
        tap({
          next: (caracteristique: Caracteristiques) => {
            dispatch(updateLigne({ ligne: caracteristique }));
            if (ligneRequest.currentStage === 9 && caracteristique.isCompleted) {
              console.log("update ligne");
              navigate("/dashboard/lines-complete");
            }
          },
          error: (errorMessage: AxiosError) => {
            if (errorMessage.response && errorMessage.response.status === 404) {
              dispatch(UpdateCaracteristiquesPopup({ type: CaracteristiquesPopupType.ERROR_EDIT_DELETED_ITEM }));
            } else {
              navigate("/dashboard");
            }
          },
        }),
        mergeMap((caracteristique: Caracteristiques) =>
          combineLatest([
            (removeDemandeClientIds ?? []).length > 0 ? DemandeClientAPI.deleteDemandeClients(removeDemandeClientIds ?? []) : of(true),
            (removeChoixTransporteurIds ?? []).length > 0
              ? TransporteurDetailAPI.deleteTransporterDetail(removeChoixTransporteurIds ?? [])
              : of(true),
          ]).pipe(mergeMap(() => of(Number(caracteristique.id))))
        ),
        mergeMap((ligneId: number) => updateDemandeClientsAndChoixTransporteurs(Number(ligneId))),
        finalize(() => setIsLoading(false)) // Hide loading on end
      )
      .subscribe({
        error: noop,
      });
  };

  useEffect(() => {
    saveLigne();
  }, []);

  return <>{isLoading && <SingleLoading />}</>;
};

export default UpdateLigne;

import { Modal } from 'antd';
import styles from '../../../transporteur/LineMenu/LineMenu.module.css';
import { CheckSquareFilled } from '@ant-design/icons';
import mainStyles from '../../../lines/components/main.module.css';
import React from 'react';

interface ModalSussessProps {
  isOpen?: boolean;
  onDone?: () => void;
  handleCancel?: () => void;
  title?: string;
  message?: string;
}

const ModalSussess = (props: ModalSussessProps) => {
  return (
    <Modal mask={false} width={643} visible={props.isOpen} footer={null} onCancel={props.handleCancel}>
      <div>
        <div className={styles.title_modal}>
          <span>{props.title}</span>
        </div>

        <div style={{ marginTop: '50px' }} className={styles.title_modal}>
          <CheckSquareFilled style={{ fontSize: '32px', color: '#2bbc47' }} />
        </div>
        <div style={{ marginTop: '10px' }} className={styles.title_modal}>
          <span className={styles.subtitle}>{props.message}</span>
        </div>
        <div className={styles.modalfooter}>
          <button onClick={props.onDone}
                  style={{ width:'254px', color: 'white', fontSize: '16px', cursor: 'pointer', marginLeft: '12px', background: '#014289' }}
                  className={`${[styles.btpopup, mainStyles.btBlue].join(' ')}`}>Terminer
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalSussess

/** @format */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosResponse } from "axios";
import { from, map, Observable } from "rxjs";
import { Method, request } from "../../helper/request.helper";
import { LoopbackAPI } from "./loopbackApi.api";
import { Holiday } from "../../models";

const COMPONENT_NAME = "Holidays";

export class HolidayAPI implements LoopbackAPI<Holiday> {
  find = (filter?: unknown): Observable<Holiday[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params: {
          filter,
        },
      })
    ).pipe(map((response: AxiosResponse<Holiday[]>) => response.data));
  };

  count = (): Observable<number> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/count`,
      })
    ).pipe(map((response: AxiosResponse<number>) => response.data));
  };

  patch = (id: number, data: any): Observable<void> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbCreate = (data: any): Observable<void> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbDelete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/${id}`,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static getHolidays = (countryCode: string, startYear: number, endYear: number): Observable<Map<string, { date: string }[]>> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/getHolidays`,
        params: {
          countryCode,
          startYear,
          endYear,
        },
      })
    ).pipe(map((response: AxiosResponse<Map<string, { date: string }[]>>) => response.data));
  };
}

/** @format */
import { LocationFormModel, LocationModel } from "./models/TrajetModel";
import { Method, request } from "../../../../helper/request.helper";
import { Pays, Ville } from "../../../../models";
import axios from "axios";
import { AppConfig } from "../../../../AppConfig";

export async function fetchLocation({ location, paysData, villeData }: { location: LocationModel; paysData: Pays[]; villeData: Ville[] }) {
  const requestPTV = await getPTVrequest({ values: location, paysData: paysData, villeData: villeData });
  if (requestPTV !== null) {
    try {
      const getData = await axios({
        method: "GET",
        url: "https://api.myptv.com/geocoding/v1/locations/by-address",
        headers: {
          ApiKey: AppConfig.PTVKey,
        },
        params: requestPTV,
      });

      if (getData.data.locations.length === 0) {
        return null;
      }
      return getData.data.locations[0].referencePosition.latitude + "," + getData.data.locations[0].referencePosition.longitude;
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
}

async function getPTVrequest({ values, paysData, villeData }: { values: LocationModel; paysData: Pays[]; villeData: Ville[] }) {
  if (values.departementId) {
    let newVille = values.ville;
    if (!values.codePostal && !values.ville) {
      newVille = villeData.find((item) => item.capitale && item.departementId === values.departementId)?.nom;
      if (!newVille) {
        // can not find ville is capital
        return null;
      }
    }
    try {
      const newDepart = await request({
        method: Method.GET,
        url: `/Departements/${values.departementId}`,
      });
      if (newDepart.data?.code) {
        return {
          country: paysData.find((item) => item.id === values.paysId)?.nom,
          state: newDepart.data?.code,
          postalCode: values.codePostal,
          locality: newVille,
          street: values.adresse,
        };
      }
      return {
        country: paysData.find((item) => item.id === values.paysId)?.nom,
        state: undefined,
        postalCode: values.codePostal,
        locality: newVille,
        street: values.adresse,
      };
    } catch (e) {
      return {
        country: paysData.find((item) => item.id === values.paysId)?.nom,
        state: undefined,
        postalCode: values.codePostal,
        locality: newVille,
        street: values.adresse,
      };
    }
  }
}

export async function calcDistance(departLocation: string, arriveLocation: string) {
  try {
    return await axios({
      method: "GET",
      url: `https://api.myptv.com/routing/v1/routes?waypoints=${departLocation}&waypoints=${arriveLocation}&options[trafficMode]=AVERAGE`,
      headers: {
        ApiKey: AppConfig.PTVKey,
      },
    })
      .then((res) => {
        return +(+res.data.distance / 1000).toFixed(2);
      })
      .catch(() => {
        return undefined;
      });
  } catch (e) {
    return undefined;
  }
}

export async function fetchLatLng(values: LocationFormModel, listPays: Pays[], listVille: Ville[]) {
  return await fetchLocation({ location: values, paysData: listPays, villeData: listVille });
}

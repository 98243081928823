/** @format */

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../../app/hooks";
import { getCurrentDemande } from "../../../../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import { MonthsArr } from "../../../../const/form.const";
import { capitalizeFirstLetter } from "../../../../../../helper/stringExtension";
import moment from "moment";
import { DayOfWeek } from "../../../../../../helper/calendarHelper";
import { Checkbox, Col, Row } from "antd";
import CustomInputNumber from "../../../../../../components/CustomInputNumber";
import React, { useEffect, useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { selectCaracteristiquesLigne } from "../../../../../../app/reducers/demandeClient.reducer";
import { getRangeDate } from "../../../../../../helper/getRangeDate";
import { getSelectedDate } from "../../../../../../app/reducers/DemandeClient/SelectedDates.reducer";
import { Lots } from "../../../../../../models/lots.model";
import { DateInput } from "../../../../../../components/FullCalendar";
import { UpdateDatesObj } from "../../../../../../app/reducers/DemandeClient/DatesObject.reducer";
import { UpdateNewDemande } from "../../../../../../app/reducers/DemandeClient/NewDemande.reducer";
import { ActionType, selectDemandeState } from "../../../../../../app/reducers/DemandeClient/DemandeState.reducer";
import HolidaySelect from "../../HolidaySelect";
import { AppColors } from "../../../../../../constants/colors.const";
import { selectIsShowTutorial } from "../../../../../../app/reducers/indicatorTutorial.reducer";
import IndicatorTutorialView from "../../../../../../components/IndicatorTutorial";

interface MonthInterface {
  month: number;
  label: string;
  selected?: boolean;
  disable?: boolean;
}

interface DaysInterface {
  day: number;
  label: string;
  selected?: boolean;
  lotMin?: number;
  lotMax?: number;
}

interface OrdinalInterface {
  value: number;
  label: string;
  selected?: boolean;
}

const MoisAutre = () => {
  const dispatch = useDispatch();
  const currentDemande = useAppSelector(getCurrentDemande);
  const currentLigne = useAppSelector(selectCaracteristiquesLigne).caracteristiques;
  const clickedDate = useAppSelector(getSelectedDate).currentDate;
  const demandeState = useAppSelector(selectDemandeState);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);

  const initSelectDate: string[] = [];

  const monthsArr: MonthInterface[] = [];
  MonthsArr.map((month) => {
    monthsArr[month] = {
      month,
      label: capitalizeFirstLetter(moment().month(month).format("MMMM")),
      disable: false,
    };
  });

  const daysArr: DaysInterface[] = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY,
    DayOfWeek.SATURDAY,
    DayOfWeek.SUNDAY,
  ].map((day) => ({
    day,
    label: capitalizeFirstLetter(moment().day(day).format("dddd")),
  }));

  const OrdinalArr: OrdinalInterface[] = [
    {
      value: 0,
      label: "Premier",
    },
    {
      value: 1,
      label: "Deuxième",
    },
    {
      value: 2,
      label: "Troisième",
    },
    {
      value: 3,
      label: "Quatrième",
    },
    {
      value: 4,
      label: "Dernier",
    },
  ];

  if (currentDemande?.demandeClientDates) {
    currentDemande?.demandeClientDates.map((el) => {
      if (el.date) {
        initSelectDate.push(el.date);
        const date = new Date(el.date);
        const month = date.getMonth();
        const monthIndex = monthsArr.findIndex((el) => el.month === month);

        monthsArr[monthIndex] = {
          ...monthsArr[monthIndex],
          selected: true,
        };

        const dateOfMonth = Number(el.date.slice(-2));
        const position = Math.floor((dateOfMonth - 1) / 7);
        const posIndex = OrdinalArr.findIndex((el) => el.value === position);
        OrdinalArr[posIndex] = {
          ...OrdinalArr[posIndex],
          selected: true,
        };

        const day = date.getDay();
        const dayIndex = daysArr.findIndex((el) => el.day === day);
        daysArr[dayIndex] = {
          ...daysArr[dayIndex],
          selected: true,
          lotMin: el.lotMin,
          lotMax: el.lotMax,
        };
      }
    });
  }

  const [monthsOfYear, setMonthsOfYear] = useState(monthsArr);
  const [OrdinalDays, setOrdinalDays] = useState(OrdinalArr);
  const [daysOfWeeek, setDaysOfWeeek] = useState(daysArr);

  const [monthAvailable, setMonthAvailable] = useState<number[]>([]);
  const [selectedDates, setSelectedDates] = useState<string[]>(initSelectDate);
  const [whiteList, setWhiteList] = useState<string[]>([]);
  const [holidays, setHolidays] = useState<string[]>([]);

  useEffect(() => {
    if (currentLigne) {
      const [startDate, endDate] = getRangeDate(currentLigne);
      let dateInit = moment(startDate).startOf("month").format("YYYY/MM/DD");
      const monthArr = [];

      while (endDate > dateInit) {
        monthArr.push(new Date(dateInit).getMonth());
        dateInit = moment(dateInit).add(1, "month").format("YYYY/MM/DD");
      }

      setMonthAvailable(monthArr);
    }
  }, [currentLigne]);

  useEffect(() => {
    const months = monthsOfYear.filter((el) => el.selected).map((el) => el.month);
    const ordinals = OrdinalDays.filter((el) => el.selected).map((el) => el.value);
    const days = daysOfWeeek.filter((el) => el.selected).map((el) => el.day);

    const newDates: string[] = [];
    const newWhiteList: string[] = [];

    if (currentLigne) {
      const [startDate, endDate] = getRangeDate(currentLigne);
      for (let d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
        const c_month = d.getMonth();

        const c_ordinal = Math.floor((d.getDate() - 1) / 7);
        const c_day = d.getDay();
        if (months.includes(c_month) && ordinals.includes(c_ordinal) && days.includes(c_day)) {
          const date = d.getFullYear() + "/" + ("0" + (d.getMonth() + 1)).slice(-2) + "/" + ("0" + d.getDate()).slice(-2);

          newWhiteList.push(date);
          if (!whiteList.includes(date) && !holidays.includes(date)) {
            newDates.push(date);
          }
        }
      }
    }

    const removeDate: string[] = whiteList.filter((date) => !newWhiteList.includes(date));

    setWhiteList(newWhiteList);
    setSelectedDates(
      [...selectedDates, ...newDates]
        .filter((el) => !removeDate.includes(el))
        .filter(function (x, i, a) {
          return a.indexOf(x) == i;
        })
    );
  }, [monthsOfYear, OrdinalDays, daysOfWeeek]);

  // Update selected Date when click a date in calendar
  useEffect(() => {
    if (clickedDate && whiteList.includes(clickedDate.date) && !holidays.includes(clickedDate.date)) {
      let newSelect: string[];
      if (selectedDates.some((el) => el === clickedDate.date)) {
        newSelect = selectedDates.filter((el) => el !== clickedDate.date);
      } else {
        newSelect = [...selectedDates, clickedDate.date];
      }
      setSelectedDates(newSelect);

      const monthSelected = monthsOfYear.filter((el) => !!el.selected).map((el) => el.month);
      const listMonths: string[][] = Array(12).fill([]);

      newSelect.map((el) => {
        const month = new Date(el).getMonth();
        listMonths[month] = [...listMonths[month], el];
      });

      const removedMonth: number[] = [];

      let newWhiteList = [...whiteList];
      monthSelected.map((item) => {
        if (listMonths[item].length === 0) {
          removedMonth.push(item);
          newWhiteList = newWhiteList.filter((el) => new Date(el).getMonth() !== item);
        }
      });

      setWhiteList(newWhiteList);

      const newMonthOfYear = [...monthsOfYear];

      removedMonth.map((el) => {
        newMonthOfYear[el] = {
          ...newMonthOfYear[el],
          selected: false,
        };
      });

      setMonthsOfYear(newMonthOfYear);
    }
  }, [clickedDate]);

  useEffect(() => {
    if (demandeState.actionType !== ActionType.VIEW) {
      // TODO: update Lots date
      const lotsDate: Lots[] = [];
      const dateObject: DateInput[] = [];
      selectedDates.map((el) => {
        const day = new Date(el).getDay();
        const dayLot = daysOfWeeek.find((el) => el.day === day);
        lotsDate.push({
          date: el,
          lotMin: dayLot?.lotMin,
          lotMax: dayLot?.lotMax,
        });
        dateObject.push({
          date: el,
          color: "#fff",
          background: AppColors.COLOR_CALENDAR_DEFAULT_PRIMARY,
        });
      });
      dispatch(UpdateDatesObj(dateObject));
      dispatch(UpdateNewDemande({ demandeClientDates: lotsDate }));
    }
  }, [selectedDates]);

  const selectMonth = (e: CheckboxChangeEvent, month: number) => {
    const newMonths = [...monthsOfYear];
    const index = newMonths.findIndex((el) => el.month === month);
    newMonths[index].selected = e.target.checked;
    setMonthsOfYear(newMonths);
  };

  const selectOrdinal = (e: CheckboxChangeEvent, ordinal: number) => {
    const newOrdinal = [...OrdinalDays];
    const index = newOrdinal.findIndex((el) => el.value === ordinal);
    newOrdinal[index].selected = e.target.checked;
    setOrdinalDays(newOrdinal);
  };

  const selectDay = (e: CheckboxChangeEvent, day: number) => {
    const newDays = [...daysOfWeeek];
    const index = newDays.findIndex((el) => el.day === day);
    newDays[index].selected = e.target.checked;
    setDaysOfWeeek(newDays);
  };

  const onChangeMin = (value: number, day: number) => {
    const listDays = [...daysOfWeeek];
    const index = listDays.findIndex((el) => el.day === day);
    listDays[index].lotMin = value;
    setDaysOfWeeek(listDays);
  };

  const onChangeMax = (value: number, day: number) => {
    const listDays = [...daysOfWeeek];
    const index = listDays.findIndex((el) => el.day === day);
    listDays[index].lotMax = value;
    setDaysOfWeeek(listDays);
  };

  const changeHolidays = (values: string[]) => {
    const addDays = whiteList.filter((el) => holidays.includes(el));
    const removeDays = selectedDates.filter((el) => values.includes(el));
    setSelectedDates([...selectedDates, ...addDays].filter((el) => !removeDays.includes(el)));
    setHolidays(values);
  };

  return (
    <div className="MoisAutre" style={{ marginTop: "2rem" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {isShowTutorial && (
          <div style={{ marginLeft: "2rem" }}>
            <IndicatorTutorialView hint={<>Mois pour les Jours sélectionnés.</>} />
          </div>
        )}
      </div>
      {monthsOfYear.map((monthOfYear, index) => {
        return (
          <Checkbox
            key={index}
            value={monthOfYear.month}
            checked={monthOfYear.selected}
            onChange={(e) => selectMonth(e, monthOfYear.month)}
            disabled={demandeState.actionType === ActionType.VIEW || !monthAvailable.includes(monthOfYear.month)}
          >
            {monthOfYear.label}
          </Checkbox>
        );
      })}

      <HolidaySelect getHolidays={(values) => changeHolidays(values)} />

      <Row style={{ marginTop: "2rem" }}>
        <Col span={9} style={{ marginTop: "1.5rem" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {isShowTutorial && (
              <div style={{ marginLeft: "2rem" }}>
                <IndicatorTutorialView hint={<>Rang des Jours de la semaine sélectionnés.</>} />
              </div>
            )}
          </div>
          {OrdinalDays.map((ordinal, index) => {
            return (
              <Checkbox
                key={index}
                value={ordinal.value}
                checked={ordinal.selected}
                onChange={(e) => selectOrdinal(e, ordinal.value)}
                disabled={demandeState.actionType === ActionType.VIEW}
              >
                {ordinal.label}
              </Checkbox>
            );
          })}
        </Col>
        <Col span={15}>
          <Row>
            <Col span={6} offset={12}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="smallTitle" style={{ paddingLeft: "0.75rem" }}>
                  Min
                </span>
              </div>
            </Col>
            <Col span={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="smallTitle" style={{ paddingLeft: "0.75rem" }}>
                  Max
                </span>
              </div>
            </Col>
          </Row>
          <div style={{ display: "flex", alignItems: "center" }}>
            {isShowTutorial && (
              <div style={{ marginLeft: "2rem" }}>
                <IndicatorTutorialView hint={<>Jours de la semaine sélectionnés.</>} />
              </div>
            )}
          </div>
          {daysOfWeeek.map((item, index) => {
            return (
              <Row key={item.day}>
                <Col span={12}>
                  <Checkbox
                    key={index}
                    value={item.day}
                    checked={item.selected}
                    disabled={demandeState.actionType === ActionType.VIEW}
                    onChange={(e) => selectDay(e, item.day)}
                  >
                    {item.label}
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <CustomInputNumber
                    style={{ height: "1.375rem" }}
                    bordered={false}
                    placeholder="-"
                    precision={0}
                    onChange={(e) => onChangeMin(Number(e), item.day)}
                    value={item.lotMin}
                    disabled={demandeState.actionType === ActionType.VIEW || !item.selected}
                  />
                  {index === 0 && isShowTutorial && (
                    <div style={{ position: "absolute", top: "0.5rem", right: 0 }}>
                      <IndicatorTutorialView hint={<>Nombre de lots minimum pour les Jours sélectionnés.</>} />
                    </div>
                  )}
                </Col>
                <Col span={6}>
                  <CustomInputNumber
                    style={{ height: "1.375rem" }}
                    bordered={false}
                    placeholder="-"
                    precision={0}
                    onChange={(e) => onChangeMax(Number(e), item.day)}
                    value={item.lotMax}
                    disabled={demandeState.actionType === ActionType.VIEW || !item.selected}
                  />
                  {index === 0 && isShowTutorial && (
                    <div style={{ position: "absolute", top: "0.5rem", right: 0 }}>
                      <IndicatorTutorialView hint={<>Nombre de lots maximum pour les Jours sélectionnés.</>} />
                    </div>
                  )}
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

export default MoisAutre;

/** @format */

import { Client } from "./client.model";
import { Commercial } from "./commercial.model";
import { Agence } from "./agence.model";
import { Societe } from "./societe.model";
import { Exploitant } from "./exploitant.model";
import { Depart } from "./depart.model";
import { Arrivee } from "./arrivee.model";
import { Chargement } from "./chargement.model";
import { IndiceGasoil } from "./indiceGasoil.model";
import { Budget } from "./budget.model";
import { CoutsAdditionnel } from "./coutsAdditionnel.model";
import { Alloue } from "./alloue.model";
import { Marchandise } from "./marchandise.model";
import { TypeVehicule } from "./typevehicule.model";
import { Departement } from "./departement.model";
import { Pays } from "./pays.model";
import { CaracteristiquesLignes } from "./caracteristiquesLignes.model";

export enum CaracteristiquesTypeDuree {
  MOIS = "MOIS",
  AN = "AN",
}

export interface Caracteristiques {
  idLigne?: string;
  dateInit?: Date;
  dateFin?: Date | null;
  typeDuree?: string | null;
  duree?: number | null;
  arrivee?: Arrivee;
  depart?: Depart;

  reception?: string;
  receptionValue?: string;
  receptionCodeId?: number;

  delaiAcceptation?: number;
  toutes?: boolean;

  chargementContact?: string;
  chargementContactCodeId?: number;
  chargementContactValue?: string;

  livraison?: string;
  livraisonCodeId?: number;
  livraisonValue?: string;

  lienReception?: string | null;
  lienRDVChargement?: string | null;
  lienRDVLivraison?: string | null;

  payantPourLinearie?: boolean;
  margeVisee?: number;
  currentStage?: number;
  isCompleted?: boolean;
  typeGasoil?: string;
  valueGasoil?: number | null;
  partGasoil?: number | null;
  currencyGasoil?: string | null;
  typeTunnel?: string | null;
  plageMin?: number | null;
  plageMax?: number | null;
  // remove when fix import ligne
  frequenceRevision?: string | null;
  frequenceRevisionGasoil?: string | null;
  frequenceRevisionTunnel?: string | null;
  id?: number;
  clientId?: number;
  client?: Client;
  societeId?: number;
  societe?: Societe;
  exploitantId?: number;
  exploitant?: Exploitant;
  uniteId?: number;
  indiceGasoilId?: number;
  indiceGasoil?: IndiceGasoil;
  deviseId?: number;
  accountId?: number;
  commercialId?: number;
  commercial?: Commercial;
  clientFactureId?: number;
  societeFactureId?: number;
  agenceId?: number;
  agence?: Agence;
  createdAt?: Date;
  updatedAt?: Date;
  coutsAdditionnelId?: number;
  chargement?: Chargement[];

  // Trajet Start
  lieuxDepartId?: number;
  paysDepartId?: number;
  paysDepart?: Pays;
  departementDepartId?: number;
  departementDepart?: Departement;
  codePostalDepart?: string;
  villeDepart?: string;
  adresseDepart?: string;

  lieuxArriveeId?: number;
  paysArriveeId?: number;
  paysArrivee?: Pays;
  departementArriveeId?: number;
  departementArrivee?: Departement;
  codePostalArrivee?: string;
  villeArrivee?: string;
  adresseArrivee?: string;

  distance?: number;
  distanceReelle?: number;
  // Trajet End

  modeTransportId?: number;
  typeVehiculeId?: number;
  typeVehicule?: TypeVehicule;
  marchandiseId?: number;
  marchandise?: Marchandise;
  adr?: boolean;
  plages?: boolean;
  besoinsSpecifiques?: string;
  budget?: Budget[];
  lineaire?: boolean;
  checkCoutsAdditionnels?: boolean;
  coutsAdditionnel?: CoutsAdditionnel[];
  alloue?: Alloue[];
  tauxParcPropre?: number; // Objectif
  parcPropreContractuel?: boolean;
  caracteristiquesLigne?: CaracteristiquesLignes[];
  ppRate?: number;

  editById?: number;
}

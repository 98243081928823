/** @format */

import { Col, Row } from "antd";
import MonthCalendar, { DateTooltipProps } from "./MonthCalendar";
import styles from "./FullCalendar.module.css";
export interface DateInput {
  date: string;
  color?: string;
  background?: string;
  border?: number;
  progress?: number;
}

export interface CalendarProps {
  selectedYear: number;
  startDate?: string;
  endDate?: string;
  selectedDates?: DateInput[];
  holidays?: string[];
  onChangeDate?: (day: string) => void;
  whiteList?: string[];
  isShowTooltips: boolean;
  dateTooltips?: Map<string, DateTooltipProps[]>;
}

const FullCalendar = (props: CalendarProps) => {
  const { onChangeDate } = props;

  const numberOfRows = 3;
  const numberOfColumns = 4;

  return (
    <>
      <div className={styles.yearCalendar}>
        <Row className={styles.wrapRowCalendar}>
          {Array(numberOfRows)
            .fill(0)
            .map((_, rowIndex) => {
              return Array(numberOfColumns)
                .fill(0)
                .map((_, colIndex) => {
                  return (
                    <Col key={`monthCalendarCol${rowIndex}_${colIndex}`} className={styles.colCalendar}>
                      <MonthCalendar
                        {...props}
                        month={rowIndex * numberOfColumns + colIndex}
                        year={props.selectedYear}
                        isShowTooltips={props.isShowTooltips}
                        dateTooltips={props.dateTooltips ?? new Map()}
                        onChangeDate={onChangeDate}
                      />
                    </Col>
                  );
                });
            })}
        </Row>
      </div>
    </>
  );
};

export default FullCalendar;

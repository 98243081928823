/** @format */

import { ReactNode } from "react";
import HeaderComponent from "../components/Header";
import styles from "./Dashboard.module.css";

const DashBoardLayout = ({ children, fullWidth }: { children?: ReactNode | undefined; fullWidth?: boolean }) => {
  return (
    <div className={styles.dashboard}>
      <HeaderComponent />
      {fullWidth ? <>{children}</> : <div className="container">{children}</div>}
    </div>
  );
};

export default DashBoardLayout;

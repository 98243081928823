/** @format */

import { uniqBy } from "lodash";
import moment from "moment";
import { Moment } from "moment";
import { CaracteristiquesTypeDuree, Holiday } from "../models";

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMAT_LITE = "DD/MM/YY";
export const DATE_FORMAT_YYYY_MM_DD = "YYYY/MM/DD";

export enum TransporteurDetailType {
  JOUR = "JOUR",
  SEMAINE = "SEMAINE",
  MOIS = "MOIS",
}
export enum TransporteurDetailSubType {
  JOUR = "JOUR",
  GLOBAL = "GLOBAL",
}
export enum DemandeClientType {
  JOUR = "JOUR",
  SEMAINE = "SEMAINE",
  MOIS = "MOIS",
}

export enum DemandeClientSubType {
  JOUR = "JOUR",
  GLOBAL = "GLOBAL",
  AUTRE = "AUTRE",
}

export enum DayOfWeek {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 0,
}

export enum MonthOfYear {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
}

export enum DayInMonthOrder {
  PREMIER = 0,
  DEUXIEME = 1,
  TROISIEME = 2,
  QUATRIEME = 3,
  DERNIER = 4,
}

export enum CalendarFilterType {
  YEAR = "Année",
  MONTH = "Mois",
  WEEK = "Semaine",
}

export const filterTypes = [CalendarFilterType.YEAR, CalendarFilterType.MONTH, CalendarFilterType.WEEK];

/**
 * Find all weekdays in range time
 *
 * @param startDate time begin
 * @param endDate time end
 * @param weekdays [monday = 1,... sunday = 0]
 */
export function findAllWeekdaysInTime(startDate: Moment, endDate: Moment, weekdays: number[]): Moment[] {
  const weekdaysInTime: Moment[] = [];

  weekdays.forEach((weekday) => {
    const startWeekday = moment(startDate).isoWeekday(weekday).startOf("day");

    // Find weekday in range of time
    while (startWeekday.isSameOrBefore(endDate.startOf("day"))) {
      const isDateInRange = startWeekday.isSameOrAfter(startDate) && startWeekday.isSameOrBefore(endDate.startOf("day"));
      if (isDateInRange) {
        // Add to list
        weekdaysInTime.push(moment(startWeekday));
      }

      // Increase time to next week
      startWeekday.add(1, "week");
    }
  });
  return weekdaysInTime;
}

/**
 * Find all full weekdays in range time
 *
 * @param startDate time begin
 * @param endDate time end
 */
export function findAllFullWeekdaysInTime(startDate: Moment, endDate: Moment): Moment[] {
  const weekdaysInTime: Moment[] = [];
  const startDayOfWeek = moment(startDate).startOf("week").startOf("day");

  if (startDayOfWeek.isBefore(startDate.startOf("day"))) {
    startDayOfWeek.add(1, "week");
  }

  while (startDayOfWeek.isSameOrAfter(startDate.startOf("day")) && startDayOfWeek.isSameOrBefore(endDate.startOf("day"))) {
    const endDayOfWeek = moment(startDayOfWeek).endOf("week");
    if (endDayOfWeek.isSameOrBefore(endDate.startOf("day"))) {
      for (let i = 0; i < 7; i++) {
        weekdaysInTime.push(moment(startDayOfWeek).add(i, "day"));
      }
    }
    startDayOfWeek.add(1, "week");
  }
  return weekdaysInTime;
}

/**
 * Find all weekdays in range time
 *
 * @param startDate time begin
 * @param endDate time end
 * @param weekdays [monday = 1,... sunday = 0]
 */
export function findOrderDaysInMonths(startDate: Moment, endDate: Moment, order: DayInMonthOrder, weekdays: number[]): Moment[] {
  const daysInTime: Moment[] = [];

  weekdays.forEach((weekday) => {
    // Find start day of month
    const startDayOfMonth = moment(startDate).startOf("month").startOf("day");

    // Find weekday with order in range of time
    while (startDayOfMonth.isSameOrBefore(endDate.startOf("day"))) {
      const startWeekdayOfMonth = moment(startDayOfMonth).isoWeekday(weekday);

      if (order === DayInMonthOrder.DERNIER) {
        // Last day of month
        const lastDayOfMonth = moment(startDate).endOf("month").startOf("day");
        // Find last weekday of month
        startWeekdayOfMonth.add(Number(DayInMonthOrder.DERNIER), "week");
        // Check the day off current month -> subtract 1 week
        if (startWeekdayOfMonth.isAfter(lastDayOfMonth)) {
          startWeekdayOfMonth.subtract(1, "week");
        }
      } else {
        // Find [1st, 2sd, 3rd, 4th] weekday of month
        startWeekdayOfMonth.add(Number(order), "week");
      }

      const isDateInRange = startWeekdayOfMonth.isSameOrAfter(startDate) && startWeekdayOfMonth.isSameOrBefore(endDate.startOf("day"));
      if (isDateInRange) {
        // Add to list
        daysInTime.push(moment(startWeekdayOfMonth));
      }

      // Increase time to next month
      startDayOfMonth.add(1, "month");
    }
  });
  return daysInTime;
}

/**
 * Find all dates between 2 times
 *
 * @param startDate start time
 * @param endDate end time
 * @returns list days between startDate and endDate
 */
export function getAllDaysBetweenDates(startDate: Moment, endDate: Moment): Moment[] {
  const daysInTime: Moment[] = [];

  while (startDate.startOf("day").isSameOrBefore(endDate.startOf("day"))) {
    daysInTime.push(moment(startDate));
    startDate.add(1, "day");
  }

  return daysInTime;
}

/**
 * Check date is holiday
 *
 * @param date date need to check
 * @param holidays list holidays
 * @returns boolean date is holiday
 */
export function isHoliday(date: Moment, holidays: Moment[]): boolean {
  return holidays.map((item) => item.format(DATE_FORMAT_YYYY_MM_DD)).includes(date.format(DATE_FORMAT_YYYY_MM_DD));
}

/**
 * Remove all holidays from the list
 *
 * @param dates list of dates need remove holidays
 * @param holidays list of holidays
 * @returns list days except holidays
 */
export function removeHolidays(dates: Moment[], holidays: Moment[]): Moment[] {
  return dates.filter((date) => !holidays.map((item) => item.format(DATE_FORMAT_YYYY_MM_DD)).includes(date.format(DATE_FORMAT_YYYY_MM_DD)));
}

/**
 *
 * @param holidaysData
 * @param startYear
 * @param endYear
 * @returns
 */
export function removeDuplicateHolidays(holidaysData: { [key: string]: Holiday[] }, startYear: number, endYear: number): Moment[] {
  let holidays: Moment[] = [];
  for (let i = startYear; i <= endYear; i++) {
    const data: Holiday[] = (holidaysData[i] ?? []).filter((item) => item.global);
    holidays.push(...data.map((item) => moment(item.date, "YYYY-MM-DD")));
  }
  holidays = uniqBy(holidays, (item) => item.format(DATE_FORMAT_YYYY_MM_DD));
  return holidays;
}

export function getRangeDate(start: Date, end: Date) {
  const arr = [];
  for (let dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
}

export function addDays(dateInit: Date, type: string, duration: number) {
  const date = new Date(dateInit);
  if (type === CaracteristiquesTypeDuree.MOIS) {
    date.setMonth(date.getMonth() + duration);
  } else if (type === CaracteristiquesTypeDuree.AN) {
    date.setFullYear(date.getFullYear() + duration);
  }
  return date;
}

/** @format */

import { useAppSelector } from "../../../../app/hooks";
import { DemandeClientSubType, DemandeClientType } from "../../../../helper/calendarHelper";
import JourInput from "./Jour";
import SemaineJour from "./Semaine/SemaineJour";
import SemaineGlobal from "./Semaine/SemaineGlobal";
import MoisJour from "./Mois/MoisJour";
import MoisGlobal from "./Mois/MoisGlobal";
import MoisAutre from "./Mois/MoisAutre";
import { useEffect, useState } from "react";
import { getCurrentDemande } from "../../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import { selectDemandeState } from "../../../../app/reducers/DemandeClient/DemandeState.reducer";

const SelectMinMax = () => {
  const currentDemande = useAppSelector(getCurrentDemande);
  const currentState = useAppSelector(selectDemandeState);
  const [currentType, setCurrentType] = useState<{
    mainType?: DemandeClientType;
    subType?: DemandeClientSubType;
  }>({
    mainType: undefined,
    subType: undefined,
  });

  useEffect(() => {
    if (currentDemande?.type) {
      setCurrentType({
        mainType: currentDemande?.type,
        subType: currentDemande?.subType,
      });
    } else {
      setCurrentType({
        mainType: DemandeClientType.JOUR,
        subType: undefined,
      });
    }
  }, [currentDemande]);

  useEffect(() => {
    if (currentState.mainType) {
      setCurrentType({
        mainType: currentState?.mainType,
        subType: currentState?.subType,
      });
    }
  }, [currentState]);

  return (
    <>
      {currentType.mainType === DemandeClientType.JOUR && <JourInput />}
      {currentType.mainType === DemandeClientType.SEMAINE && (
        <>
          {currentType.subType === DemandeClientSubType.JOUR && <SemaineJour />}
          {currentType.subType === DemandeClientSubType.GLOBAL && <SemaineGlobal />}
        </>
      )}
      {currentType.mainType === DemandeClientType.MOIS && (
        <>
          {currentType.subType === DemandeClientSubType.JOUR && <MoisJour />}
          {currentType.subType === DemandeClientSubType.GLOBAL && <MoisGlobal />}
          {currentType.subType === DemandeClientSubType.AUTRE && <MoisAutre />}
        </>
      )}
    </>
  );
};

export default SelectMinMax;

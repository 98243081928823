import {DatesObjectType} from "../app/reducers/Transporter/TransporteurDetailDateType.reducer";

interface DemandeClientDateProps {
  date: string
  min: number
  max: number
}

export default function GroupObjectByMultipleProperties(arr: DemandeClientDateProps[]) {

  const listDate: DatesObjectType[] = [];

  arr.map((el) => {
    if (el.date) {
      const index = listDate.findIndex(item => item.date === el.date);
      if (index >= 0) {
        listDate[index] = {
          date: el.date,
          min: listDate[index].min + el.min,
          max: listDate[index].max + el.max,
        };
      } else {
        listDate.push({
          date: el.date,
          min: el.min,
          max: el.max
        });
      }
    }
  })

  return listDate;
}

/** @format */

import { CaretLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { finalize, tap } from "rxjs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUser } from "../../app/reducers/auth.reducer";
import { CaracteristiquesPopupType, UpdateCaracteristiquesPopup } from "../../app/reducers/Caracteristiques/CaracteristiquesPopup.reducer";
import {
  fetchChoixTransporter,
  fetchDemandeClients,
  selectDemandeClients,
  selectLigne,
  updateLigne,
  updateLigneRequest,
} from "../../app/reducers/ligne.reducer";
import GlobalLoading from "../../components/GlobalLoading.component";
import { CaracteristiqueAPI } from "../../domain/apis/caracteristique.api";
import { doOnSubscribe, useRxEffect } from "../../helper/rxjs.helper";
import { Caracteristiques, CaracteristiquesLignes, DemandeClient, UserLevels } from "../../models";
import { ChoixTransporteur } from "../../models/choixTransporteur.model";
import {
  Allocation,
  Assignation,
  BudgetView,
  ChargementView,
  ClientView,
  CommercialPilote,
  Contacts,
  Indexation,
  Periode,
  TrajetLocation,
} from "./components";
import ImportLines from "./components/ImportLines/ImportLines";
import ListLigne from "./components/ListLigne/ListLigne";
import PopupDetail from "./components/Popup/PopupDetail";
import styles from "./ContractLines.module.css";
import moment from "moment";
import "moment-timezone";

const { Title } = Typography;

const ContractLines: React.FC = () => {
  const { lineId } = useParams();
  const dispatch = useAppDispatch();
  const lineData = useAppSelector(selectLigne);
  const demandeClients = useAppSelector(selectDemandeClients);
  const currentUser = useAppSelector(selectUser);
  const navigate = useNavigate();

  const [stage, setStage] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [isImport, setIsImport] = useState(false);
  const [editable, setEditable] = useState<boolean>(true);

  useEffect(() => {
    if (lineData.id) {
      checkActiveEdit();
      const interValCheckActive = setInterval(() => {
        checkActiveEdit();
      }, 30000);
      return () => clearInterval(interValCheckActive);
    }
  }, [lineData.id]);

  useRxEffect(
    () =>
      CaracteristiqueAPI.fetchDetail(Number(lineId)).pipe(
        doOnSubscribe(() => setLoading(true)), // Show loading on start
        tap({
          next: (caracteristiques: Caracteristiques) => {
            const alloue = caracteristiques.alloue?.map((el) => {
              delete el.chargement;
              delete el.volumeFrequency;
              return el;
            });
            const budget = caracteristiques.budget?.map((el) => {
              delete el.chargement;
              return el;
            });
            dispatch(
              updateLigneRequest({
                ligneRequest: {
                  id: Number(lineId),
                  chargement: caracteristiques.chargement,
                  alloue: alloue,
                  budget: budget,
                },
              })
            );
            dispatch(updateLigne({ ligne: caracteristiques }));
            let demandeData: DemandeClient[] = [];
            let choixTransporteurs: ChoixTransporteur[] = [];
            if (caracteristiques.caracteristiquesLigne) {
              caracteristiques.caracteristiquesLigne.map((el: CaracteristiquesLignes) => {
                demandeData = [...demandeData, ...(el.demandeClients || [])];
              });
            }
            if (caracteristiques.caracteristiquesLigne) {
              caracteristiques.caracteristiquesLigne.map((el: CaracteristiquesLignes) => {
                choixTransporteurs = [...choixTransporteurs, ...(el.choixTransporteur || [])];
              });
            }
            dispatch(fetchDemandeClients({ demandeClients: demandeData }));
            dispatch(fetchChoixTransporter({ choixTransporteurs: choixTransporteurs }));
          },
          error: (errorMessage: AxiosError) => {
            if (errorMessage.response && errorMessage.response.status === 404) {
              dispatch(UpdateCaracteristiquesPopup({ type: CaracteristiquesPopupType.ERROR_EDIT_DELETED_ITEM }));
            } else {
              navigate("/dashboard");
            }
          },
        }),
        finalize(() => setLoading(false)) // Hide loading on end
      ),
    []
  );

  useEffect(() => {
    setStage(Number(lineData.currentStage));
  }, [lineData]);

  useEffect(() => {
    /// standard ligne có quyền edit khi ligne chưa có demandeclients
    if (currentUser?.typeRole === UserLevels.STANDARD && (currentUser?.roles ?? []).map((role) => role.name).includes("STANDARDLIGNE")) {
      if (lineData.isCompleted) {
        setEditable(demandeClients.length === 0);
      } else {
        setEditable(true);
      }

      return;
    }
    // standard chỉ view
    if (currentUser?.typeRole === UserLevels.STANDARD) {
      setEditable(false);
    }
  }, [demandeClients, currentUser, lineData]);

  const checkActiveEdit = () => {
    if (!editable) {
      return;
    }
    // Update updatedAt
    if (lineData.id) {
      if (lineData.updatedAt && lineData.editById) {
        const updatedAt = new Date(lineData.updatedAt);
        const currentTime = new Date();
        const preDay = moment(updatedAt).tz("Europe/Paris").format("YYYY/MM/DD");
        const currentDay = moment(currentTime).tz("Europe/Paris").format("YYYY/MM/DD");
        const diff = (currentTime.getTime() - updatedAt.getTime()) / 1000;

        if (lineData.editById === currentUser?.id && preDay !== currentDay) {
          CaracteristiqueAPI.update(Number(lineId), { editById: currentUser?.id }).subscribe((res) => {
            dispatch(updateLigne({ ligne: res }));
            navigate("/dashboard/lines-complete");
          });
          return;
        }

        if (lineData.editById !== currentUser?.id && diff <= 30) {
          navigate("/dashboard/lines-complete");
        } else {
          CaracteristiqueAPI.update(Number(lineId), { editById: currentUser?.id });
        }
        return;
      }
      if (lineId && currentUser?.id) {
        CaracteristiqueAPI.update(Number(lineId), { editById: currentUser.id });
      }
    }
  };

  const backHome = () => {
    dispatch(UpdateCaracteristiquesPopup({ type: CaracteristiquesPopupType.CANCEL }));
  };

  const renderSwitch = () => {
    switch (Number(stage)) {
      case 0:
        return <ClientView editable={editable} />;
      case 1:
        return <CommercialPilote editable={editable} />;
      case 2:
        return <Assignation editable={editable} />;
      case 3:
        return <Periode editable={editable} />;
      case 4:
        return <Indexation editable={editable} />;
      case 5:
        return <TrajetLocation editable={editable} />;
      case 6:
        return <Contacts editable={editable} />;
      case 7:
        return <ChargementView editable={editable} />;
      case 8:
        return <Allocation editable={editable} />;
      case 9:
        return <BudgetView editable={editable} />;
      default:
        return <ClientView editable={editable} />;
    }
  };

  return (
    <>
      {loading ? (
        <GlobalLoading />
      ) : (
        <>
          <PopupDetail />
          <div style={{ position: "relative" }}>
            {isImport && (
              <Button style={{ position: "absolute", top: "10px", left: "50px" }} onClick={() => setIsImport(!isImport)} type="text">
                <CaretLeftOutlined />
                <span style={{ fontSize: "16px" }}>Retour</span>
              </Button>
            )}
            {!isImport && (
              <span style={{ position: "absolute", cursor: "pointer" }} onClick={() => backHome()}>
                <CaretLeftOutlined />
                Annuler la saisie
              </span>
            )}
            <Title level={2} className="common-title">
              Caractéristiques
            </Title>
          </div>
          {!isImport && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={() => setIsImport(!isImport)} className={`${[styles.bt, styles.btBlue].join(" ")}`} icon={<DownloadOutlined />}>
                Importer Données
              </Button>
            </div>
          )}
          {!isImport && (
            <Row
              justify="center"
              style={{
                marginTop: "3rem",
              }}
            >
              <Col xs={24} sm={11} md={10} xl={8}>
                <ListLigne currentStage={stage} editable={editable} />
              </Col>
              <Col xs={24} sm={13} md={14} xl={16} className="pl-2">
                {renderSwitch()}
              </Col>
            </Row>
          )}
          {isImport && (
            <Row
              justify="center"
              style={{
                marginTop: "50px",
              }}
            >
              <Col span={21}>
                <ImportLines />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default ContractLines;

/** @format */

import { RootState } from "../../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum CaracteristiquesPopupType {
  NONE = "none",
  ERROR_EDIT_MORE_ONE_PERSON = "error_edit_more_one_person",
  ERROR_EDIT_MORE_ONE_DEVICE = "error_edit_more_one_device",
  ERROR_EDIT_DELETED_ITEM = "error_edit_deleted_item",
  CANCEL = "cancel",
  EDIT_PERIODE = "edit_periode",
  EDIT_PERIODE_WITH_TRANSPORTEURS = "edit_periode_with_transporteurs",
  EDIT_TRAJET = "edit_trajet",
  EDIT_ALLOCATION = "edit_allocation",
  EDIT_BUDGET = "edit_budget",
}

const initialState: { type: CaracteristiquesPopupType } = {
  type: CaracteristiquesPopupType.NONE,
};

export const CaracteristiquesPopupSlice = createSlice({
  name: "caracteristiquesPopup",
  initialState,
  reducers: {
    UpdateCaracteristiquesPopup: (state, action: PayloadAction<{ type: CaracteristiquesPopupType }>) => {
      state.type = action.payload.type;
    },
  },
});

export const { UpdateCaracteristiquesPopup } = CaracteristiquesPopupSlice.actions;

export const getCaracteristiquesPopup = (state: RootState) => state.caracteristiquesPopup;

export default CaracteristiquesPopupSlice.reducer;

/** @format */

import moment from "moment";
import { Caracteristiques, CaracteristiquesTypeDuree } from "../models";

export const getRangeDate = (getLigne: Caracteristiques) => {
  const dateInit = moment(getLigne?.dateInit).format("YYYY/MM/DD");
  let dateFin = moment(getLigne?.dateFin).format("YYYY/MM/DD");
  if (getLigne?.typeDuree) {
    if (getLigne?.typeDuree?.toLowerCase() === CaracteristiquesTypeDuree.MOIS?.toLowerCase()) {
      dateFin = moment(getLigne?.dateInit).add(getLigne?.duree, "months").format("YYYY/MM/DD");
    }
    if (getLigne?.typeDuree?.toLowerCase() === CaracteristiquesTypeDuree.AN?.toLowerCase()) {
      dateFin = moment(getLigne?.dateInit).add(getLigne?.duree, "years").format("YYYY/MM/DD");
    }
  }

  return [dateInit, dateFin];
};

/** @format */

export interface FormModel {
  lieuxDepartId?: number;
  paysDepartId?: number;
  departementDepartId?: number;
  codePostalDepart?: string;
  villeDepart?: string;
  adresseDepart?: string;

  lieuxArriveeId?: number;
  paysArriveeId?: number;
  departementArriveeId?: number;
  codePostalArrivee?: string;
  villeArrivee?: string;
  adresseArrivee?: string;

  distance?: number;
  distanceReelle?: number;
}

export interface LocationModel {
  lieuxId?: number;
  paysId?: number;
  departementId?: number;
  codePostal?: string;
  ville?: string;
  adresse?: string;
}

export enum LocationType {
  DEPART = "DEPART",
  ARRIVEE = "ARRIVEE",
}

export enum TrajetPopupType {
  LIEUX = "Lieu",
  VILLE = "Ville",
  CODE_POSTAL = "CodePostal",
}

export enum SelectType {
  LIEU = "LIEU",
  VILLE = "VILLE",
}

export interface LieuxSearch {
  code?: string;
  nom?: string;
  id?: number;
  paysId?: number;
  departementId?: number;
  villeId?: number;
  villeCodePostal?: string;
  paysNom?: string;
  departementCode?: string;
  villeNom?: string;
  adresse?: string;
  latitudeX?: string;
  longitudeY?: string;
}

export interface VilleSearch {
  id?: number;
  nom?: string;
  codePostal?: string;
  departementId?: number;
  departementCode?: string;
  paysId?: number;
  paysNom?: string;
}

export interface VilleSearchObj {
  field: string;
  textSearch: string[];
}

export const VilleSearchType = [
  {
    id: "Pays",
    value: "Pays",
  },
  {
    id: "Dept",
    value: "Dept",
  },
  {
    id: "Ville",
    value: "Ville",
  },
  {
    id: "Code_Postal",
    value: "Code Postal",
  },
];

export type LocationFormModel = {
  lieuxId?: number;
  paysId?: number;
  departementId?: number;
  codePostal?: string;
  ville?: string;
  adresse?: string;
};

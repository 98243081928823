/** @format */

import MonthDaySelect from "./MonthDaySelect";
import React, { useEffect, useState } from "react";
import { Lots } from "../../../../../../models/lots.model";
import { Col, Row } from "antd";
import styles from "./MoisJour.module.css";
import CustomInputNumber from "../../../../../../components/CustomInputNumber";
import { useDispatch } from "react-redux";
import { UpdateNewDemande } from "../../../../../../app/reducers/DemandeClient/NewDemande.reducer";
import { useAppSelector } from "../../../../../../app/hooks";
import { getCurrentDemande } from "../../../../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import { ActionType, selectDemandeState } from "../../../../../../app/reducers/DemandeClient/DemandeState.reducer";
import { selectIsShowTutorial } from "../../../../../../app/reducers/indicatorTutorial.reducer";
import IndicatorTutorialView from "../../../../../../components/IndicatorTutorial";

interface LotsMois {
  day: number;
  lotMin?: number;
  lotMax?: number;
}

enum InputType {
  MIN = "MIN",
  MAX = "MAX",
}

const MoisJour = () => {
  const demandeState = useAppSelector(selectDemandeState);
  const dispatch = useDispatch();
  const currentDemande = useAppSelector(getCurrentDemande);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);

  let initLotValues: LotsMois[] = [];
  if (currentDemande?.demandeClientDates) {
    currentDemande?.demandeClientDates.map((el) => {
      if (el.date) {
        const day = Number(el.date.slice(-2));
        initLotValues[day] = {
          day,
          lotMin: el.lotMin,
          lotMax: el.lotMax,
        };
      }
    });
    initLotValues = initLotValues.filter((el) => !!el);
  }

  const [lotsDate, setLotsDate] = useState<Lots[]>([]);
  const [lotsArr, setLotArr] = useState<LotsMois[]>(initLotValues);
  const [listDays, setListDays] = useState<number[]>([]);
  const [listMonths, setListMonths] = useState<number[]>([]);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  useEffect(() => {
    const newLots = selectedDates.map((date) => {
      const day = Number(date.slice(-2));
      const lotOfDay = lotsArr.find((lot) => lot.day === day);
      return {
        date: date,
        lotMin: lotOfDay?.lotMin ? lotOfDay.lotMin : undefined,
        lotMax: lotOfDay?.lotMax ? lotOfDay.lotMax : undefined,
      };
    });

    setLotsDate(newLots);
  }, [selectedDates, lotsArr]);

  useEffect(() => {
    dispatch(UpdateNewDemande({ demandeClientDates: lotsDate }));
  }, [lotsDate]);

  const onSelect = (months: number[], days: number[], dates: string[]) => {
    setListDays(days);
    setListMonths(months);
    setSelectedDates(dates);
  };

  /**
   *
   * @param day: day of month
   * @param type: type of input
   * @param value: value of input
   */
  const updateMinMax = (day: number, type: InputType, value: number) => {
    const newLotsArr = [...lotsArr];
    if (newLotsArr.every((el) => el.day !== day)) {
      newLotsArr.push({
        day,
        lotMin: type === InputType.MIN ? value : undefined,
        lotMax: type === InputType.MAX ? value : undefined,
      });
    } else {
      const index = newLotsArr.findIndex((el) => el.day === day);
      if (type === InputType.MIN) {
        newLotsArr[index].lotMin = value;
      } else {
        newLotsArr[index].lotMax = value;
      }
    }

    setLotArr(newLotsArr);
  };

  return (
    <div className="MoisJour">
      <MonthDaySelect onSelect={onSelect} />
      <Row>
        <Col span={11}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="smallTitle">Min</span>
          </div>
          <Row>
            {Array(4)
              .fill(0)
              .map((_, col) => (
                <Col span={6} key={col} className={styles.LotsGroup}>
                  {Array(10)
                    .fill(0)
                    .map((_, row) => {
                      const day = col * 10 + row + 1;
                      const dayEditAble = listDays.includes(day) && listMonths.length > 0;
                      return (
                        day <= 31 && (
                          <>
                            <CustomInputNumber
                              key={day}
                              className={[styles.lotInput, dayEditAble ? styles.editAble : ""].join(" ")}
                              placeholder="-"
                              disabled={!listDays.includes(day) || demandeState.actionType === ActionType.VIEW}
                              value={lotsArr.find((lot) => lot.day === day)?.lotMin || undefined}
                              onChange={(e) => updateMinMax(day, InputType.MIN, Number(e))}
                              bordered={false}
                            />
                            {day === 1 && isShowTutorial && (
                              <div style={{ position: "absolute", top: "0.5rem", right: "-0.375rem" }}>
                                <IndicatorTutorialView hint={<>Nombre de lots minimum pour le numéro de Jour des Mois sélectionnés.</>} />
                              </div>
                            )}
                          </>
                        )
                      );
                    })}
                </Col>
              ))}
          </Row>
        </Col>
        <Col span={11} offset={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="smallTitle">Max</span>
          </div>
          <Row>
            {Array(4)
              .fill(0)
              .map((_, col) => (
                <Col span={6} key={col} className={styles.LotsGroup}>
                  {Array(10)
                    .fill(0)
                    .map((_, row) => {
                      const day = col * 10 + row + 1;
                      const dayEditAble = listDays.includes(day) && listMonths.length > 0;
                      return (
                        day <= 31 && (
                          <>
                            <CustomInputNumber
                              key={day}
                              className={[styles.lotInput, dayEditAble ? styles.editAble : ""].join(" ")}
                              placeholder="-"
                              disabled={!listDays.includes(day) || demandeState.actionType === ActionType.VIEW}
                              value={lotsArr.find((lot) => lot.day === day)?.lotMax || undefined}
                              onChange={(e) => updateMinMax(day, InputType.MAX, Number(e))}
                              bordered={false}
                            />
                            {day === 1 && isShowTutorial && (
                              <div style={{ position: "absolute", top: "0.5rem", right: "-0.375rem" }}>
                                <IndicatorTutorialView hint={<>Nombre de lots maximum pour le numéro de Jour des Mois sélectionnés.</>} />
                              </div>
                            )}
                          </>
                        )
                      );
                    })}
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MoisJour;

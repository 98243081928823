/** @format */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosResponse } from "axios";
import { Observable, from, map } from "rxjs";
import { Method, request } from "../../helper/request.helper";
import { AccessToken } from "../../models/accessToken.model";
import { Account } from "../../models";
import { Role } from "../../models/role.model";
import { LoopbackAPI } from "./loopbackApi.api";

const COMPONENT_NAME = "Accounts";

export class AccountAPI implements LoopbackAPI<Account> {
  find = (filter?: unknown): Observable<Account[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params: {
          filter,
        },
      })
    ).pipe(map((response: AxiosResponse<Account[]>) => response.data));
  };

  count = (): Observable<number> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/count`,
      })
    ).pipe(map((response: AxiosResponse<number>) => response.data));
  };

  patch = (id: number, data: any): Observable<void> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbCreate = (data: any): Observable<void> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbDelete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/${id}`,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static login = ({ email, password }: { email: string; password: string }): Observable<AccessToken> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/login`,
        data: {
          email,
          password,
        },
      })
    ).pipe(map((response: AxiosResponse<AccessToken>) => response.data));
  };

  static logout = (): Observable<void> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/logout`,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static getMe = (): Observable<Account> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/get-me`,
      })
    ).pipe(map((response: AxiosResponse<Account>) => response.data));
  };

  static fetchById = (id: number): Observable<Account> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/${id}`,
      })
    ).pipe(map((response: AxiosResponse<Account>) => response.data));
  };

  static getUserRoles = (id: number): Observable<Role[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/${id}/roles`,
      })
    ).pipe(map((response: AxiosResponse<Role[]>) => response.data));
  };

  static getAllWithTotal = (params?: unknown): Observable<{ total: number; accounts: Account[] }> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params,
      })
    ).pipe(
      map((response: AxiosResponse<Account[]>) => {
        return {
          total: Number(response.headers["x-total-count"]),
          accounts: response.data,
        };
      })
    );
  };

  static create = (data: Account): Observable<Account> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<Account>) => response.data));
  };

  static update = (id: number, data: Account): Observable<void> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static delete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/${id}`,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static sendEmail = (email: string): Observable<void> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/reset`,
        data: { email: email },
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static resetPassword = (newPassword: string, token: string): Observable<void> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/reset-password`,
        data: { newPassword: newPassword },
        headers: {
          Authorization: token,
        },
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static getMeWithToken = (token: string): Observable<Account> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/get-me`,
        headers: {
          Authorization: token,
        },
      })
    ).pipe(map((response: AxiosResponse<Account>) => response.data));
  };

  static updateRoles = ({ accountId, rolesId }: { accountId: number; rolesId: number[] }): Observable<void> => {
    return from(
      request({
        method: Method.PUT,
        url: `/${COMPONENT_NAME}/updateRoles`,
        params: {
          accountId,
          rolesId: JSON.stringify(rolesId),
        },
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };
}

/** @format */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosResponse } from "axios";
import { from, map, Observable } from "rxjs";
import { Method, request } from "../../helper/request.helper";
import { Caracteristiques } from "../../models";
import { CaracteristiquesRequest } from "../../models/requests/caracteristiquesRequest.model";
import { LoopbackAPI } from "./loopbackApi.api";

const COMPONENT_NAME = "Caracteristiques";

export class CaracteristiqueAPI implements LoopbackAPI<Caracteristiques> {
  find = (filter?: unknown): Observable<Caracteristiques[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params: {
          filter,
        },
      })
    ).pipe(map((response: AxiosResponse<Caracteristiques[]>) => response.data));
  };

  count = (): Observable<number> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/count`,
      })
    ).pipe(map((response: AxiosResponse<number>) => response.data));
  };

  patch = (id: number, data: any): Observable<void> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbCreate = (data: any): Observable<void> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  dbDelete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/${id}`,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static fetchAll = (params?: unknown): Observable<Caracteristiques[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}`,
        params,
      })
    ).pipe(map((response: AxiosResponse<Caracteristiques[]>) => response.data));
  };

  static fetchDetail = (id: number): Observable<Caracteristiques> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/${id}`,
        params: {
          filter: {
            include: [
              "client",
              "societe",
              "exploitant",
              "indiceGasoil",
              "chargement",
              "account",
              "commercial",
              "clientFacture",
              "societeFacture",
              "agence",
              "lieuxDepart",
              "lieuxArrivee",
              "coutsAdditionnel",
              "paysDepart",
              "paysArrivee",
              "departementDepart",
              "departementArrivee",
              "chargement",
              "marchandise",
              {
                relation: "caracteristiquesLigne",
                scope: {
                  include: [
                    {
                      relation: "demandeClients",
                      scope: {
                        include: ["demandeClientDates", "caracteristiquesLigne"],
                      },
                    },
                    {
                      relation: "choixTransporteur",
                      scope: {
                        include: [
                          {
                            relation: "transporteurDetails",
                            scope: {
                              include: ["transporteurDetailDates"],
                            },
                          },
                          "caracteristiquesLigne",
                          "transporteur",
                        ],
                      },
                    },
                  ],
                },
              },
              {
                relation: "alloue",
                scope: {
                  include: [
                    {
                      relation: "chargement",
                    },
                    {
                      relation: "volumeFrequency",
                    },
                  ],
                },
              },
              {
                relation: "budget",
                scope: {
                  include: [
                    {
                      relation: "chargement",
                    },
                  ],
                },
              },
            ],
          },
        },
      })
    ).pipe(map((response: AxiosResponse<Caracteristiques>) => response.data));
  };

  static create = (data: Caracteristiques): Observable<Caracteristiques> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<Caracteristiques>) => response.data));
  };

  static update = (id: number, data: Caracteristiques): Observable<Caracteristiques> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<Caracteristiques>) => response.data));
  };

  static delete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${COMPONENT_NAME}/${id}`,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static checkUnquieIdLigne = (idLigne: string): Observable<boolean> => {
    return from(
      request({
        method: Method.GET,
        url: `/${COMPONENT_NAME}/check-unquie-idLigne`,
        params: {
          idLigne,
        },
      })
    ).pipe(map((response: AxiosResponse<boolean>) => response.data));
  };

  static insertWithFiles = (data?: unknown): Observable<void> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/insertWithFiles`,
        data,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };

  static upsertCaracteristiques = (data: CaracteristiquesRequest): Observable<Caracteristiques> => {
    return from(
      request({
        method: Method.POST,
        url: `/${COMPONENT_NAME}/upsertCaracteristiques`,
        data: {
          data,
        },
      })
    ).pipe(map((response: AxiosResponse<Caracteristiques>) => response.data));
  };
}

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {DateInput} from "../../../components/FullCalendar";

const initialState: DateInput[] = []

export const DatesObjectSlice = createSlice({
  name: 'DateObject',
  initialState,
  reducers: {
    UpdateDatesObj: (state, action: PayloadAction<DateInput[]>) => {
      state = action.payload;
      return state
    }
  },
});

export const { UpdateDatesObj } = DatesObjectSlice.actions;

export const getDateObject = (state: RootState) => state.DatesObject;
export default DatesObjectSlice.reducer;

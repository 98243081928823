/** @format */

import React, { useState } from "react";
import styles from "./header.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/question_mark.svg";
import BellIcon from "../../assets/icons/bell.svg";
import { Button, Dropdown, Menu, Modal, Popover } from "antd";
import { DownOutlined, ExclamationCircleOutlined, MenuOutlined, UserOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUser, userLogout } from "../../app/reducers/auth.reducer";
import { selectIsShowTutorial, toggleShowIndicatorTutorial, showIndicatorTutorial } from "../../app/reducers/indicatorTutorial.reducer";
import RightArrow from "../../assets/icons/rightArrow.svg";
import { getStepGuide, StepGuide, updateGuide } from "../../app/reducers/indicatorGuide.reducer";

const { confirm } = Modal;

const HeaderComponent = () => {
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);
  const stepGuide = useAppSelector(getStepGuide);
  const firstLogin = localStorage.getItem("firstLogin");

  const gotoDashboard = () => {
    navigate("/dashboard");
  };

  const confirmLogout = () => {
    confirm({
      title: "Voulez-vous vous déconnecter ?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(userLogout());
      },
      okText: "OK",
      cancelText: "Annuler",
    });
  };

  const checkFirstLogin = () => {
    return firstLogin === "true" && Number(currentUser?.countLogins) <= 5;
  };

  const onClickIndicatorTutorial = () => {
    checkFirstLogin() ? dispatch(showIndicatorTutorial()) : dispatch(toggleShowIndicatorTutorial());
    dispatch(updateGuide({ type: StepGuide.IN_PROGRESS }));
  };

  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };

  const menu = (
    <Menu>
      <Menu.Item>{currentUser?.name ? currentUser?.name : currentUser?.username}</Menu.Item>
      <Menu.Item danger onClick={confirmLogout}>
        Se déconnecter
      </Menu.Item>
    </Menu>
  );

  const title = <span>Title Notification</span>;
  const content = (
    <div>
      <p>Content</p>
    </div>
  );

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.flexHeader}>
          <img src={Logo} alt="Logo" style={{ cursor: "pointer" }} onClick={gotoDashboard} />
          <div className={`${styles.mobileNav} ${activeMenu ? styles.active : null}`}>
            <nav className={styles.mainNav}>
              {currentUser?.typeRole === "ADMIN" ||
              currentUser?.typeRole === "SUPERADMIN" ||
              (currentUser?.roles ?? []).map((role) => role.name).includes("STANDARDLIGNE") ? (
                <NavLink to="/dashboard/contracted-line" className={(navData) => (navData.isActive ? styles.active : "")} end>
                  Nouvelle ligne Contractualisée
                </NavLink>
              ) : null}

              <NavLink to="/dashboard/lines-complete" className={(navData) => (navData.isActive ? styles.active : "")} end>
                Lignes à Compléter
              </NavLink>
              <NavLink to="/dashboard/lines-contracted" className={(navData) => (navData.isActive ? styles.active : "")} end>
                Lignes Contractualisées
              </NavLink>
              {currentUser?.typeRole === "ADMIN" || currentUser?.typeRole === "SUPERADMIN" ? (
                <NavLink to="/dashboard/tables" className={(navData) => (navData.isActive ? styles.active : "")} end>
                  Tables
                </NavLink>
              ) : null}
              {currentUser?.typeRole === "ADMIN" || currentUser?.typeRole === "SUPERADMIN" ? (
                <NavLink to="/dashboard/list-user" end>
                  <Button className={styles["btn-create-user"]}>Utilisateur</Button>
                </NavLink>
              ) : null}
            </nav>
            <div className={styles.mobileRightNav}>
              <Popover placement="bottomRight" title={title} content={content} trigger="click">
                <div className={styles.notification}>
                  <img src={BellIcon} alt="Notification" />
                </div>
              </Popover>
              <Dropdown overlay={menu} placement="bottomRight">
                <div className={styles.userMenu}>
                  <UserOutlined className={styles.userIcon} />
                  <DownOutlined style={{ color: "#111" }} />
                </div>
              </Dropdown>
            </div>
          </div>
          <div className={styles.rightNav}>
            <div className={styles.groupIndicator} style={{ position: "relative", zIndex: stepGuide === StepGuide.START ? 10 : 0 }}>
              <div
                id="indicatorTutorial"
                className={styles.indicatorTutorial}
                onClick={onClickIndicatorTutorial}
                style={{ background: isShowTutorial ? "black" : "#F6F6F9", position: "relative", zIndex: stepGuide === StepGuide.START ? 10 : 0 }}
              >
                <QuestionMarkIcon fill={isShowTutorial ? "white" : "black"} />
              </div>
              {checkFirstLogin() && stepGuide === StepGuide.START && (
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "baseline",
                    top: "100%",
                    right: "0.7vw",
                    width: "100vw",
                    flexDirection: "row-reverse",
                  }}
                >
                  <img src={RightArrow} alt="left-arrow" style={{ zIndex: 10, width: "28vw", marginRight: "-0.5%", transform: "translateX()" }} />
                  <div style={{ lineHeight: 2, marginRight: "1rem" }}>
                    <p style={{ transform: "translateY(-40%)", color: "#fff" }}>
                      Découvrez l&apos;ensemble des fonctionnalités <br /> de cette application en cliquant ici.
                    </p>
                  </div>
                </div>
              )}
            </div>
            <Popover placement="bottomRight" title={title} content={content} trigger="click">
              <div className={styles.notification}>
                <img src={BellIcon} alt="Notification" />
              </div>
            </Popover>
            <Dropdown overlay={menu} placement="bottomRight">
              <div className={styles.userMenu}>
                <UserOutlined className={styles.userIcon} />
                <DownOutlined style={{ color: "#111" }} />
              </div>
            </Dropdown>
          </div>
          <MenuOutlined className={styles.iconMenu} onClick={toggleMenu} />
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;

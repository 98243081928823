/** @format */

import { DayOfWeek } from "../../../../../helper/calendarHelper";
import { capitalizeFirstLetter } from "../../../../../helper/stringExtension";
import moment from "moment";
import { Col, Row } from "antd";
import CustomCheckbox from "../../../../../components/CustomCheckbox";
import CustomInputNumber from "../../../../../components/CustomInputNumber";
import { selectCaracteristiquesLigne } from "../../../../../app/reducers/demandeClient.reducer";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { useDispatch } from "react-redux";
import { Lots } from "../../../../../models/lots.model";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { DateInput } from "../../../../../components/FullCalendar";
import { UpdateDatesObj } from "../../../../../app/reducers/DemandeClient/DatesObject.reducer";
import { UpdateNewDemande } from "../../../../../app/reducers/DemandeClient/NewDemande.reducer";
import { getCurrentDemande } from "../../../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import { getSelectedDate } from "../../../../../app/reducers/DemandeClient/SelectedDates.reducer";
import { getRangeDate } from "../../../../../helper/getRangeDate";
import HolidaySelect from "../HolidaySelect";
import { ActionType, selectDemandeState } from "../../../../../app/reducers/DemandeClient/DemandeState.reducer";
import { AppColors } from "../../../../../constants/colors.const";
import IndicatorTutorialView from "../../../../../components/IndicatorTutorial";
import { selectIsShowTutorial } from "../../../../../app/reducers/indicatorTutorial.reducer";

interface SemaineJourDate {
  date: number;
  lotMin?: number;
  lotMax?: number;
  selected: boolean;
}

const SemaineJour = () => {
  const dispatch = useDispatch();
  const currentLigne = useAppSelector(selectCaracteristiquesLigne);
  const clickedDate = useAppSelector(getSelectedDate).currentDate;
  const currentDemande = useAppSelector(getCurrentDemande);
  const demandeState = useAppSelector(selectDemandeState);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);

  const initWeekDays: SemaineJourDate[] = Array(7)
    .fill(0)
    .map((_, index) => {
      return {
        date: index,
        lotMin: undefined,
        lotMax: undefined,
        selected: false,
      };
    });
  const initSelectDate: string[] = [];

  if (currentDemande) {
    currentDemande.demandeClientDates?.map((el) => {
      if (el.date) {
        const currentValue = initWeekDays[new Date(el.date).getDay()];
        initWeekDays[new Date(el.date).getDay()] = {
          date: currentValue.date,
          lotMin: el.lotMin,
          lotMax: el.lotMax,
          selected: true,
        };
      }
    });

    currentDemande.demandeClientDates?.map((el) => {
      if (el.date) {
        initSelectDate.push(el.date);
      }
    });
  }

  const [weekDays, setWeekDays] = useState<SemaineJourDate[]>(initWeekDays);
  const [prevDays, setPrevDays] = useState<SemaineJourDate[]>(initWeekDays);

  const [lotsDate, setLotsDate] = useState<Lots[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [holidays, setHolidays] = useState<string[]>([]);

  const [selectedDates, setSelectedDates] = useState<string[]>(initSelectDate);
  const [whiteList, setWhiteList] = useState<string[]>([]);

  // Init/Update WhiteList when weekdays update
  useEffect(() => {
    if (currentLigne?.caracteristiques) {
      const listAccepted: string[] = [];
      const [dateInit, dateFin] = getRangeDate(currentLigne?.caracteristiques);
      const start = new Date(dateInit);
      const end = new Date(dateFin);

      const currentSelect = weekDays.filter((el) => el.selected).map((el) => el.date);

      for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        if (currentSelect.includes(d.getDay())) {
          const date = d.getFullYear() + "/" + ("0" + (Number(d.getMonth()) + 1)).slice(-2) + "/" + ("0" + d.getDate()).slice(-2);
          if (!holidays.includes(date)) {
            listAccepted.push(date);
          }
        }
      }
      setWhiteList(listAccepted);
    }
  }, [weekDays]);

  // Update selected Date when click a date in calendar
  useEffect(() => {
    if (clickedDate && whiteList.includes(clickedDate.date) && !holidays.includes(clickedDate.date)) {
      let newSelect: string[];
      if (selectedDates.some((el) => el === clickedDate.date)) {
        newSelect = selectedDates.filter((el) => el !== clickedDate.date);
      } else {
        newSelect = [...selectedDates, clickedDate.date];
      }
      setSelectedDates(newSelect);

      // TODO: deselect date
      const newWeekDays = [...weekDays];
      const listDays: string[][] = Array(7).fill([]);
      newSelect.map((el) => {
        const day = new Date(el).getDay();
        listDays[day] = [...listDays[day], el];
      });

      listDays.map((item, index) => {
        if (item.length === 0) {
          newWeekDays[index] = {
            ...newWeekDays[index],
            selected: false,
          };
        }
      });

      setWeekDays(newWeekDays);
    }
  }, [clickedDate]);

  useEffect(() => {
    if (currentLigne?.caracteristiques) {
      const [dateInit, dateFin] = getRangeDate(currentLigne?.caracteristiques);
      setStartDate(dateInit);
      setEndDate(dateFin);
    }
  }, [currentLigne]);

  // Update selected Date when Weekdays changed
  useEffect(() => {
    if (startDate && endDate) {
      const newDates: string[] = [];
      const removeDate: string[] = [];
      const start = new Date(startDate);
      const end = new Date(endDate);

      const currentSelect = weekDays.filter((el) => el.selected).map((el) => el.date);
      const prevSelect = prevDays.filter((el) => el.selected).map((el) => el.date);

      if (currentSelect.length > prevSelect.length) {
        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
          if (currentSelect.includes(d.getDay()) && !prevSelect.includes(d.getDay())) {
            const date = d.getFullYear() + "/" + ("0" + (Number(d.getMonth()) + 1)).slice(-2) + "/" + ("0" + d.getDate()).slice(-2);
            newDates.push(date);
          }
        }
        setSelectedDates([...selectedDates, ...newDates]);
      } else {
        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
          if (!currentSelect.includes(d.getDay()) && prevSelect.includes(d.getDay())) {
            const date = d.getFullYear() + "/" + ("0" + (Number(d.getMonth()) + 1)).slice(-2) + "/" + ("0" + d.getDate()).slice(-2);
            removeDate.push(date);
          }
        }
        const filterDates = selectedDates.filter((el) => !removeDate.includes(el));
        setSelectedDates(filterDates);
      }
    }
  }, [weekDays]);

  useEffect(() => {
    // TODO: update Lots date
    const newLots: Lots[] = [];
    const acceptedDay = weekDays.filter((el) => el.selected).map((el) => el.date);
    selectedDates.map((el) => {
      const day = new Date(el).getDay();
      if (acceptedDay.includes(day)) {
        newLots.push({
          date: el,
          lotMin: weekDays[day].lotMin,
          lotMax: weekDays[day].lotMax,
        });
      }
    });
    setLotsDate(newLots);
  }, [selectedDates]);

  useEffect(() => {
    if (demandeState.actionType !== ActionType.VIEW) {
      const dateObject: DateInput[] = [];
      lotsDate.map((el) => {
        dateObject.push({
          date: el.date,
          color: "#fff",
          background: AppColors.COLOR_CALENDAR_DEFAULT_PRIMARY,
        });
      });
      dispatch(UpdateDatesObj(dateObject));
      dispatch(UpdateNewDemande({ demandeClientDates: lotsDate }));
      setPrevDays(weekDays);
    }
  }, [lotsDate]);

  const daysOfWeeek = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY,
    DayOfWeek.SATURDAY,
    DayOfWeek.SUNDAY,
  ].map((day) => ({
    day,
    label: capitalizeFirstLetter(moment().day(day).format("dddd")),
  }));

  const selectJour = (e: CheckboxChangeEvent, day: number) => {
    const filterJour = [...weekDays];
    filterJour[day] = {
      date: day,
      lotMin: undefined,
      lotMax: undefined,
      selected: e.target.checked,
    };
    setWeekDays(filterJour);
  };

  const onChangeMin = (value: number, day: number) => {
    const listDays = [...weekDays];
    listDays[day].lotMin = value;
    setWeekDays(listDays);
  };

  const onChangeMax = (value: number, day: number) => {
    const listDays = [...weekDays];
    listDays[day].lotMax = value;
    setWeekDays(listDays);
  };

  const changeHolidays = (values: string[]) => {
    const addDays = whiteList.filter((el) => holidays.includes(el));
    const removeDays = selectedDates.filter((el) => values.includes(el));
    setSelectedDates([...selectedDates, ...addDays].filter((el) => !removeDays.includes(el)));
    setHolidays(values);
  };

  return (
    <div className="SemaineJour">
      <Row>
        <Col span={5} offset={10}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="smallTitle" style={{ paddingLeft: "0.75rem" }}>
              Min
            </span>
          </div>
        </Col>
        <Col span={5}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="smallTitle" style={{ paddingLeft: "0.75rem" }}>
              Max
            </span>
          </div>
        </Col>
      </Row>
      {daysOfWeeek.map((item, index) => {
        return (
          <Row key={item.day}>
            <Col span={10}>
              <CustomCheckbox
                disabled={demandeState.actionType === ActionType.VIEW}
                checked={weekDays[item.day].selected}
                onChange={(e) => selectJour(e, item.day)}
              >
                {item.label ?? ""}
              </CustomCheckbox>
            </Col>
            <Col span={5}>
              <CustomInputNumber
                placeholder="-"
                bordered={false}
                disabled={demandeState.actionType === ActionType.VIEW}
                precision={0}
                onChange={(e) => onChangeMin(Number(e), item.day)}
                value={weekDays[item.day].lotMin}
              />
              {index === 0 && isShowTutorial && (
                <div style={{ position: "absolute", right: "0.5rem", top: "0.25rem" }}>
                  <IndicatorTutorialView hint={<>Nombre de lots minimum pour les jours de la semaine sélectionnés.</>} />
                </div>
              )}
            </Col>
            <Col span={5}>
              <CustomInputNumber
                placeholder="-"
                bordered={false}
                disabled={demandeState.actionType === ActionType.VIEW}
                precision={0}
                onChange={(e) => onChangeMax(Number(e), item.day)}
                value={weekDays[item.day].lotMax}
              />
              {index === 0 && isShowTutorial && (
                <div style={{ position: "absolute", right: "0.5rem", top: "0.25rem" }}>
                  <IndicatorTutorialView hint={<>Nombre de lots maximum pour les jours de la semaine sélectionnés.</>} />
                </div>
              )}
            </Col>
          </Row>
        );
      })}

      <HolidaySelect getHolidays={(values) => changeHolidays(values)} />
    </div>
  );
};

export default SemaineJour;

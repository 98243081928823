/** @format */

import { Button, Col, Form, notification, Radio, RadioChangeEvent, Row, Typography } from "antd";
import "./trajet.css";
import IndicatorTutorialView from "../../../../components/IndicatorTutorial";
import CustomInputNumber from "../../../../components/CustomInputNumber";
import EditIcon from "../../../../assets/icons/edit.svg";
import MapDirection from "./components/MapDirection";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { selectIsShowTutorial } from "../../../../app/reducers/indicatorTutorial.reducer";
import { selectLigne, selectLigneRequest, updateLigneRequest } from "../../../../app/reducers/ligne.reducer";
import { useDispatch } from "react-redux";
import { useRxEffect } from "../../../../helper/rxjs.helper";
import { combineLatest, tap } from "rxjs";
import { LieuxAPI } from "../../../../domain/apis/lieux.api";
import { PayAPI } from "../../../../domain/apis/pay.api";
import { VilleAPI } from "../../../../domain/apis/ville.api";
import { Lieux, Pays, Ville } from "../../../../models";
import PickLocation from "./components/PickLocation";
import { FormModel, LocationFormModel, LocationType } from "./models/TrajetModel";
import UpdateLigne from "../../UpdateLigne";
import SingleLoading from "../../../../components/SingleLoading";
import { calcDistance, fetchLatLng } from "./trajet.helper";

const { Title } = Typography;

export type PTVLocation = {
  country?: string;
  state?: string;
  postalCode?: string;
  locality?: string;
  street?: string;
};

export type RequestType = {
  arrive?: PTVLocation;
  depart?: PTVLocation;
};

export default function TrajetLocation({ editable }: { editable: boolean }) {
  const lineData = useAppSelector(selectLigne);
  const isShowTutorial = useAppSelector(selectIsShowTutorial);
  const ligneRequest = useAppSelector(selectLigneRequest);
  const dispatch = useDispatch();
  const [formTrajet] = Form.useForm();

  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [initValues, setInitValues] = useState<FormModel>({});
  const [trajetValues, setTrajetValues] = useState<FormModel>({});
  const [information, setInformation] = useState<boolean>(true);
  const [departLocation, setDepartLocation] = useState<string | null>();
  const [arriveLocation, setArriveLocation] = useState<string | null>();
  const [listLieux, setListLieux] = useState<Lieux[]>([]);
  const [listPays, setListPays] = useState<Pays[]>([]);
  const [listVille, setListVille] = useState<Ville[]>([]);
  const [distanceChange, setDistanceChange] = useState<number | undefined>();
  const [distance, setDistance] = useState<number | undefined>();
  const [onValidate, setOnValidate] = useState(0);

  useRxEffect(
    () =>
      combineLatest([LieuxAPI.fetchAll(), PayAPI.fetchAll(), VilleAPI.fetchAll()]).pipe(
        tap({
          next: ([lieuxs, pays, villes]: [Lieux[], Pays[], Ville[]]) => {
            setListLieux(lieuxs);
            setListPays(pays);
            setListVille(villes);
          },
        })
      ),
    []
  );

  useEffect(() => {
    lineData.distanceReelle && setDistanceChange(lineData.distanceReelle);
    lineData.distance && setDistance(lineData.distance);
    setInitValues(lineData);
    setTrajetValues(lineData);
    formTrajet.setFieldsValue({
      distance: lineData.distanceReelle ? lineData.distanceReelle : lineData.distance,
    });
  }, [JSON.stringify(lineData)]);

  useEffect(() => {
    if (departLocation && arriveLocation) {
      handleDistance();
    }
  }, [departLocation, arriveLocation]);

  function changeRadio(e: RadioChangeEvent) {
    setInformation(e.target.value === "information");
  }

  async function getLatLng(values: LocationFormModel, type: LocationType) {
    if (!values.paysId || !values.departementId) {
      setDistance(undefined);
      setDistanceChange(undefined);
      formTrajet.setFieldsValue({
        distance: undefined,
      });
      setDepartLocation(null);
      setArriveLocation(null);
      return;
    }

    if (!values.codePostal && !values.ville) {
      const newVille = listVille.find((item) => item.capitale && item.departementId === values.departementId)?.nom;
      if (!newVille) {
        notification.warning({
          key: "capital",
          message: "Capitale [Département Départ] and/ or [Département Arrivée] introuvable. Prévenez votre administrateur.",
          placement: "top",
        });
        return;
      }
    }

    setIsLoading(true);
    const getLatLngData = await fetchLatLng(values, listPays, listVille);
    setIsLoading(false);
    if (type === LocationType.DEPART) {
      setDepartLocation(getLatLngData);
    } else {
      setArriveLocation(getLatLngData);
    }
    if (getLatLngData === null) {
      // can not get location
      setDistance(undefined);
      setDistanceChange(undefined);
      formTrajet.setFieldsValue({
        distance: undefined,
      });
      notification.warning({
        key: "notCalc",
        message: "La distance n'a pas pu être calculée. Merci de vérifier les champs qui définissent le Départ et l'Arrivée",
        placement: "top",
      });
    }
  }

  async function handleDistance() {
    if (departLocation && arriveLocation) {
      setIsLoading(true);
      const newDistance = await calcDistance(departLocation, arriveLocation);
      setIsLoading(false);
      if (newDistance) {
        formTrajet.setFieldsValue({
          distance: newDistance,
        });
        setDistance(newDistance);
        setDistanceChange(newDistance);
      } else {
        notification.warning({
          key: "notCalc",
          message: "La distance n'a pas pu être calculée. Merci de vérifier les champs qui définissent le Départ et l'Arrivée",
          placement: "top",
        });
        setDistance(undefined);
        setDistanceChange(undefined);
        formTrajet.setFieldsValue({
          distance: undefined,
        });
      }
    } else {
      setDistance(undefined);
      setDistanceChange(undefined);
      formTrajet.setFieldsValue({
        distance: undefined,
      });
    }
  }

  function onFormChange() {
    const values = formTrajet.getFieldsValue();
    setDistanceChange(values.distance);
  }

  function submitTrajet() {
    formTrajet.validateFields();
    const trajetFormValue = formTrajet.getFieldsValue();

    if (
      trajetValues.paysDepartId &&
      trajetValues.paysArriveeId &&
      trajetValues.departementDepartId &&
      trajetValues.departementArriveeId &&
      trajetFormValue.distance
    ) {
      // submit
      dispatch(
        updateLigneRequest({
          ligneRequest: {
            ...ligneRequest,
            ...trajetValues,
            distance: trajetFormValue.distance !== distanceChange ? undefined : trajetFormValue.distance,
            distanceReelle: trajetFormValue.distance === distanceChange ? undefined : distanceChange,
            currentStage: Number(lineData.currentStage) + 1,
          },
        })
      );
      setIsSubmit(true);
      return;
    }
    setOnValidate(Math.random());
  }

  return (
    <>
      {isSubmit && <UpdateLigne />}
      {isLoading && <SingleLoading />}
      <Title level={3} style={{ textAlign: "center", marginBottom: "1rem" }}>
        Trajet
      </Title>
      <Radio.Group
        className="trajetTab"
        value={information ? "information" : "vuecarte"}
        defaultValue="information"
        buttonStyle="solid"
        onChange={(e) => changeRadio(e)}
      >
        <Radio.Button value="information">Informations</Radio.Button>
        <Radio.Button value="vuecarte">Vue carte</Radio.Button>
      </Radio.Group>
      <div className={information ? "" : "hideTrajet"}>
        <Row>
          <Col xs={8} md={5} className="mt-8">
            <ul className="labelTrajet">
              <li>
                {isShowTutorial && (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView hint={"Code Lieu issu de TraPlus. Ce champ peut rester vide."} />
                  </div>
                )}
                Code Lieu
              </li>
              <li>
                {isShowTutorial && (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView hint={"Ce champ ne peut pas rester vide."} />
                  </div>
                )}
                Pays
              </li>
              <li>
                {isShowTutorial && (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView hint={"Département, ou deux premiers caractères du Code Postal. Ce champ ne peut pas rester vide."} />
                  </div>
                )}
                Département
              </li>
              <li>
                {isShowTutorial && (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView hint={"Ce champ peut rester vide."} />
                  </div>
                )}
                Code Postal
              </li>
              <li>
                {isShowTutorial && (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView hint={"Ce champ peut rester vide."} />
                  </div>
                )}
                Ville
              </li>
              <li>Adresse</li>
            </ul>
          </Col>
          <Col xs={16} md={9} xl={7} xxl={6}>
            <p style={{ textAlign: "center" }}>Départ</p>
            <PickLocation
              initValues={{
                lieuxId: initValues.lieuxDepartId,
                paysId: initValues.paysDepartId,
                departementId: initValues.departementDepartId,
                codePostal: initValues.codePostalDepart,
                ville: initValues.villeDepart,
                adresse: initValues.adresseDepart,
              }}
              lieuxData={listLieux}
              paysData={listPays}
              locationType={LocationType.DEPART}
              onValidate={onValidate}
              callBackData={(data) => {
                setTrajetValues({
                  ...trajetValues,
                  lieuxDepartId: data.lieuxId,
                  paysDepartId: data.paysId,
                  departementDepartId: data.departementId,
                  codePostalDepart: data.codePostal,
                  villeDepart: data.ville,
                  adresseDepart: data.adresse,
                });

                console.log("data", data);
                console.log("trajetValues", trajetValues);
                getLatLng(data, LocationType.DEPART);

                getLatLng(
                  {
                    lieuxId: trajetValues.lieuxArriveeId,
                    paysId: trajetValues.paysArriveeId,
                    departementId: trajetValues.departementArriveeId,
                    codePostal: trajetValues.codePostalArrivee,
                    ville: trajetValues.villeArrivee,
                    adresse: trajetValues.adresseArrivee,
                  },
                  LocationType.ARRIVEE
                );
              }}
            />
          </Col>
          <Col xs={{ span: 16, offset: 8 }} md={{ span: 9, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 6, offset: 1 }}>
            <p style={{ textAlign: "center" }}>Arrivée</p>
            <PickLocation
              initValues={{
                lieuxId: initValues.lieuxArriveeId,
                paysId: initValues.paysArriveeId,
                departementId: initValues.departementArriveeId,
                codePostal: initValues.codePostalArrivee,
                ville: initValues.villeArrivee,
                adresse: initValues.adresseArrivee,
              }}
              lieuxData={listLieux}
              paysData={listPays}
              locationType={LocationType.ARRIVEE}
              onValidate={onValidate}
              callBackData={(data) => {
                setTrajetValues({
                  ...trajetValues,
                  lieuxArriveeId: data.lieuxId,
                  paysArriveeId: data.paysId,
                  departementArriveeId: data.departementId,
                  codePostalArrivee: data.codePostal,
                  villeArrivee: data.ville,
                  adresseArrivee: data.adresse,
                });
                getLatLng(data, LocationType.ARRIVEE);

                getLatLng(
                  {
                    lieuxId: trajetValues.lieuxDepartId,
                    paysId: trajetValues.paysDepartId,
                    departementId: trajetValues.departementDepartId,
                    codePostal: trajetValues.codePostalDepart,
                    ville: trajetValues.villeDepart,
                    adresse: trajetValues.adresseDepart,
                  },
                  LocationType.DEPART
                );
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8} md={5}>
            <ul className="labelDistance">
              <li>
                {isShowTutorial && (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView hint={"Distance calculée automatiquement et pouvant être modifiée par la suite."} />
                  </div>
                )}
                Distance
              </li>
            </ul>
          </Col>
          <Col xs={16} md={9} xl={7} xxl={6}>
            <ul className={"inputDistance " + (distanceChange && distance !== distanceChange && "distanceEts")}>
              <li style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Form
                  form={formTrajet}
                  onFieldsChange={() => onFormChange()}
                  initialValues={{ distance: initValues.distanceReelle ? initValues.distanceReelle : initValues.distance }}
                >
                  <Form.Item name="distance" rules={[{ required: true, message: "" }]} style={{ marginBottom: 0 }}>
                    <CustomInputNumber
                      min={0.01}
                      max={8000}
                      disabled={!distanceChange && !distance}
                      suffix={
                        <>
                          km&nbsp;&nbsp;&nbsp;
                          <img className="editIcon" src={EditIcon} alt="Edit" />
                        </>
                      }
                      onPressEnter={(e) => {
                        e.preventDefault();
                      }}
                      precision={2}
                    />
                  </Form.Item>
                </Form>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
      <div className={`trajetMap ${information ? "hideMap" : ""}`}>
        <MapDirection initData={trajetValues} departLatlng={departLocation} arriveLatlng={arriveLocation} villeData={listVille} paysData={listPays} />
      </div>
      <Form.Item className="submit">
        {editable && (
          <Button type="primary" htmlType="submit" className="bt" onClick={() => submitTrajet()}>
            Confirmer
          </Button>
        )}
      </Form.Item>
    </>
  );
}

/** @format */

import {
  fetchDemandeHistories,
  fetchDemandeHistoryDetail,
  selectDemandeHistoryStore,
} from "../../../app/reducers/DemandeClient/DemandeClientHistory.reducer";
import React, { useEffect, useRef, useState } from "react";
import { DemandeClientHistory } from "../../../models/demandeClientHistory";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectCaracteristiquesLigne, updateHistoryData } from "../../../app/reducers/demandeClient.reducer";
import SingleLoading from "../../../components/SingleLoading";
import moment from "moment";
import { addDays } from "../../../helper/calendarHelper";
import { useDraggable } from "react-use-draggable-scroll";

import styles from "./main.module.css";
import { ActionType, selectDemandeState, UpdateDemandeState } from "../../../app/reducers/DemandeClient/DemandeState.reducer";

const DemandeHistory = () => {
  const dispatch = useAppDispatch();
  const [detailId, setDetailId] = useState<number>();
  const ligneData = useAppSelector(selectCaracteristiquesLigne);
  const demandeHistoryStore = useAppSelector(selectDemandeHistoryStore);
  const demandeState = useAppSelector(selectDemandeState);

  // We will use React useRef hook to reference the wrapping div:
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:

  const [listHistories, setListHistories] = useState<DemandeClientHistory[]>([]);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [curentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    dispatch(fetchDemandeHistories(Number(ligneData?.id)));
  }, [ligneData?.id]);

  useEffect(() => {
    initHistory();
  }, [demandeHistoryStore.demandeHistories]);

  useEffect(() => {
    debounceGetDetail();
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ref!.current!.scrollTop = ref?.current?.scrollHeight as number;
      setCurrentIndex(listHistories.length - 1);
    }, 200);
  }, [listHistories]);

  function debounceGetDetail() {
    let callState: NodeJS.Timeout | undefined;

    const startCall = (scrollHeight: number) => {
      callState = setTimeout(function () {
        const index = Math.floor((Number(scrollHeight) - 43) / 45.594 + 1.4);
        const itemId = listHistories[index]?.id;
        if (itemId) {
          setDetailId(itemId);
        }
      }, 1000);
    };

    const stopCall = () => {
      clearTimeout(callState);
    };

    ref?.current?.addEventListener("scroll", function () {
      setScrollHeight(this.scrollTop);
      setCurrentIndex(Math.floor((Number(this.scrollTop) - 43) / 45.594 + 1.4));
      stopCall();
      startCall(this.scrollTop);
    });
  }

  useEffect(() => {
    // set disable edit
    if (detailId) {
      dispatch(UpdateDemandeState({ editAble: listHistories[listHistories.length - 1]?.id === detailId }));
    } else {
      if (listHistories.length > 0) {
        dispatch(UpdateDemandeState({ editAble: false }));
      }
    }
    if (detailId && ligneData.caracteristiques) {
      const demandeHistory = listHistories.find((item) => item.id == detailId);
      // dispatch(fetchDemandeHistoryDetail(detailId));
      const { dateInit, dateFin, typeDuree, duree } = ligneData.caracteristiques;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const startDate = new Date(dateInit!);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const endDate: Date = dateFin ? new Date(dateFin) : addDays(startDate, typeDuree!, duree!);

      dispatch(
        fetchDemandeHistoryDetail({
          caracteristiquesLigneId: Number(ligneData.id),
          date: demandeHistory?.date ?? "",
          startDate,
          endDate,
        })
      );
    }
  }, [detailId]);

  useEffect(() => {
    if (demandeHistoryStore.historyDetails) {
      dispatch(updateHistoryData(demandeHistoryStore.historyDetails));
    }
  }, [demandeHistoryStore.historyDetails]);

  function initHistory() {
    const filterHistory = demandeHistoryStore.demandeHistories.filter((el, index) => {
      const currentItemTime = moment(el.date).tz("Europe/Paris").format("YYYY/MM/DD");
      if (index === demandeHistoryStore.demandeHistories.length - 1) {
        return true;
      }
      const nextItemTime = moment(demandeHistoryStore.demandeHistories[index + 1].date)
        .tz("Europe/Paris")
        .format("YYYY/MM/DD");
      return currentItemTime !== nextItemTime;
    });
    setListHistories(filterHistory);
  }

  function selectHistory(selectedId?: number) {
    if (!selectedId) {
      return;
    }
    const itemIndex = listHistories.findIndex((el) => el.id === selectedId);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ref!.current!.scrollTop = itemIndex * 45.6;
  }

  function calcScale(index: number) {
    // 1.0116
    const offsetTop = (index + 1) * 45.6 + 5 * 45.6 - scrollHeight;
    if (offsetTop > 520 || offsetTop < 0) {
      return 0;
    }
    return 0.4 + Math.abs(Math.abs(offsetTop / 45.6 - 6) - 6) / 9;
  }

  return (
    <div style={{ width: "16%", padding: "0 15px", height: "500px", position: "relative" }}>
      {(demandeHistoryStore.status === "pending" || demandeHistoryStore.loadDetail === "pending") && <SingleLoading />}
      <div
        className={[
          "historySwiper max-w-sm space-y-3",
          styles.historyContainer,
          demandeState.actionType !== ActionType.VIEW ? styles.disableScroll : "",
        ].join(" ")}
        {...(demandeState.actionType !== ActionType.VIEW ? {} : events)}
        ref={ref}
      >
        {Array.from(Array(5).keys()).map((el, index) => {
          return (
            <div key={index} className={styles.fakeWheel}>
              <span></span>
            </div>
          );
        })}
        {listHistories.map((el: DemandeClientHistory, index: number) => {
          return (
            <div
              key={el?.id}
              className={[
                "wheelDate",
                calcScale(index) > 1.011 ? styles.wheelActive : "",
                curentIndex >= 5 && index === curentIndex - 5 ? styles.filledItem : "", // first item
                curentIndex < listHistories.length - 6 && index === curentIndex + 5 ? styles.filledItem : "", // last item
              ].join(" ")}
              onClick={() => {
                if (demandeState.actionType !== ActionType.VIEW) {
                  return;
                }
                if (curentIndex >= 5 && index === curentIndex - 5) {
                  // go to first item
                  setDetailId(listHistories[0].id);
                  selectHistory(listHistories[0].id);
                  return;
                }
                if (curentIndex < listHistories.length - 6 && index === curentIndex + 5) {
                  // go to last item
                  setDetailId(listHistories[listHistories.length - 1].id);
                  selectHistory(listHistories[listHistories.length - 1].id);
                  return;
                }
                setDetailId(Number(el?.id));
                selectHistory(el?.id);
              }}
              style={{
                transform: `scale(${calcScale(index)})`,
              }}
            >
              <span>{moment(el?.date).tz("Europe/Paris").format("DD/MM/YYYY")}</span>
            </div>
          );
        })}
        {Array.from(Array(5).keys()).map((el, index) => {
          return (
            <div key={index} className={styles.fakeWheel}>
              <span></span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DemandeHistory;

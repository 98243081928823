/** @format */

import { Button } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { combineLatest, finalize, mergeMap, noop, Observable, of, tap } from "rxjs";
import { useAppSelector } from "../../../app/hooks";
import { fetchDemandeData, selectCaracteristiquesLigne, selectDemandeClients } from "../../../app/reducers/demandeClient.reducer";
import { getCurrentDemande } from "../../../app/reducers/DemandeClient/CurrentDemande.reducer";
import { ActionType, selectDemandeState, UpdateDemandeState } from "../../../app/reducers/DemandeClient/DemandeState.reducer";
import { updatePays } from "../../../app/reducers/DemandeClient/ListPays.reducer";
import { getNewDemande } from "../../../app/reducers/DemandeClient/NewDemande.reducer";
import { PopupType, updatePopUp } from "../../../app/reducers/DemandeClient/Popup.reducer";
import PopupConfirm from "../../../components/PopupConfirm";
import SingleLoading from "../../../components/SingleLoading";
import { CaracteristiquesLigneAPI } from "../../../domain/apis/caracteristiquesLigne.api";
import { DemandeClientAPI } from "../../../domain/apis/demandeClient.api";
import { PayAPI } from "../../../domain/apis/pay.api";
import { doOnSubscribe, useRxEffect } from "../../../helper/rxjs.helper";
import { submitDemandeClient } from "../../../helper/submitDemandeClient";
import { DemandeClient, MoyenneDeLots, Pays } from "../../../models";
import SelectDateType from "./Forms/SelectDateType";
import SelectMinMax from "./Forms/SelectMinMax";
import SelectTime from "./Forms/SelectTime";
import menuStyle from "./LineMenu.module.css";
import { UpsertDemandeClientResponse } from "../../../models/responses/upsertDemandeClient.model";
import { CalculateDiffTotalTransporteurResponse } from "../../../models/responses/calculateDiffTotalTransporteur.model";
import { uniq } from "lodash";
import { DemandeClientSubType, DemandeClientType } from "../../../helper/calendarHelper";
import { CalculateDiffTotalTransporteurRequestParams } from "../../../models/requests/calculateDiffTotalTransporteur.model";
import { GetPrePprateResponse } from "../../../models/responses/getPrePprate.model";
import { GetPrePprateRequestParams } from "../../../models/requests/getPrePprate.model.ts";
import moment from "moment";

const AddDemandeClient = () => {
  const dispatch = useDispatch();
  const demandeClients = useAppSelector(selectDemandeClients);
  const currentLigne = useAppSelector(selectCaracteristiquesLigne);
  const currentDemande = useAppSelector(getCurrentDemande);
  const newDemande = useAppSelector(getNewDemande);
  const demandeState = useAppSelector(selectDemandeState);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [oldInputDate, setOldInputDate] = useState<string[]>([]);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  // const [isHaveTransporteur, setIsHaveTransporteur] = useState<boolean>(false);
  const [isDiffTransporteur, setIsDiffTransporteur] = useState<boolean>(false);
  const [isPPrateDecrease, setIsPPrateDecrease] = useState<boolean>(false);
  const [diffTotalTransporteurs, setDiffTotalTransporteurs] = useState<{ diff: number; startDate: string; endDate: string }[]>([]);

  useEffect(() => {
    let listDemande: (string | undefined)[] = [];
    if (demandeClients) {
      demandeClients
        .filter((item) => item.id !== currentDemande?.id)
        .map((el) => {
          let arrDate = el.demandeClientDates?.map((item) => item.date?.toString());
          if (arrDate) {
            arrDate = arrDate.filter((item) => item !== undefined);
            listDemande = [...listDemande, ...arrDate];
          }
        });
      const arrDate: string[] = [];
      listDemande.map((el) => {
        if (el !== undefined) {
          arrDate.push(el);
        }
      });
      setOldInputDate(arrDate);
    }
  }, [demandeClients]);

  useRxEffect(() => {
    let getPays$ = of(true);
    if (currentLigne.caracteristiques && currentLigne.caracteristiques.paysArrivee && currentLigne.caracteristiques.paysDepart) {
      if (currentLigne.caracteristiques.paysArrivee?.code !== currentLigne.caracteristiques.paysDepart?.code) {
        getPays$ = PayAPI.fetchAll().pipe(
          tap({
            next: (pays: Pays[]) => {
              const paysData: Pays[] = pays.sort((a, b) => {
                const aCode =
                  a.code === currentLigne.caracteristiques?.paysArrivee?.code
                    ? "1"
                    : a.code === currentLigne.caracteristiques?.paysDepart?.code
                    ? "2"
                    : a.code ?? "";
                const bCode =
                  b.code === currentLigne.caracteristiques?.paysArrivee?.code
                    ? "1"
                    : b.code === currentLigne.caracteristiques?.paysDepart?.code
                    ? "2"
                    : b.code ?? "";
                return aCode?.localeCompare(bCode);
              });
              dispatch(updatePays(paysData));
            },
          }),
          mergeMap(() => of(true))
        );
      } else {
        const pay: Pays = currentLigne.caracteristiques.paysArrivee;
        getPays$ = of(true).pipe(
          tap({
            next: () => {
              dispatch(updatePays([pay]));
            },
          })
        );
      }
    }
    return getPays$;
  }, [currentLigne]);

  const createUpdateDemandeClient$ = (): Observable<boolean> => {
    if (!newDemande) {
      return of(true);
    }

    // Create or update new demande client
    const newLots = newDemande.demandeClientDates
      ? newDemande.demandeClientDates.map((el) => {
          return {
            date: el.date || "",
            lotMin: el.lotMin,
            lotMax: el.lotMax,
          };
        })
      : [];

    return submitDemandeClient(
      {
        ...newDemande,
        id: currentDemande?.id,
        caracteristiquesLigneId: currentLigne.id,
      },
      newLots
    ).pipe(
      mergeMap((response: UpsertDemandeClientResponse) => {
        if (response.status === "OK" && response.id != undefined) {
          if (currentLigne.caracteristiques && currentLigne.id) {
            return combineLatest([
              DemandeClientAPI.fetchAllByCaracteristiquesLigneId(currentLigne.id),
              CaracteristiquesLigneAPI.calculateBatchAverage(currentLigne.id),
            ]).pipe(
              tap({
                next: ([demandeClients, moyenneDeLots]: [DemandeClient[], MoyenneDeLots]) => {
                  dispatch(updatePopUp({ type: PopupType.SUCCESS }));
                  dispatch(UpdateDemandeState({ actionType: ActionType.VIEW }));
                  dispatch(
                    fetchDemandeData({
                      caracteristiquesLigne: currentLigne,
                      demandeClients: demandeClients,
                      moyenneDeLotsMax: moyenneDeLots.max ?? 24,
                      batchAverages: moyenneDeLots.batchAverages ?? [],
                    })
                  );
                },
              }),
              mergeMap(() => of(true))
            );
          }
        } else {
          dispatch(updatePopUp({ type: PopupType.ERROR }));
        }
        return of(true);
      }),
      tap({
        error: () => {
          dispatch(updatePopUp({ type: PopupType.ERROR }));
        },
      })
    );
  };

  const checkDisable = (): boolean => {
    if (demandeState.enableTime && !(newDemande.chargementTime || newDemande.dechargementTime)) {
      return true;
    }
    if (!newDemande.demandeClientDates || newDemande.demandeClientDates?.length === 0) {
      return true;
    }

    return newDemande.demandeClientDates.some((el) => {
      if (!el.lotMax || !el.lotMin) {
        return true;
      }
      const lotMin = Number(el.lotMin);
      const lotMax = Number(el.lotMax);
      return lotMin < lotMax / 5 || lotMax > lotMin * 5 || lotMin > lotMax;
    });
  };

  const onClickConfirmer = () => {
    checkDuplicate$()
      .pipe(
        doOnSubscribe(() => setIsLoading(true)), // Show loading on start
        tap({
          error: () => {
            dispatch(updatePopUp({ type: PopupType.ERROR }));
          },
        }),
        finalize(() => setIsLoading(false)) // Hide loading on end
      )
      .subscribe({
        error: noop,
      });
  };

  const checkDuplicate$ = (): Observable<boolean> => {
    if (
      newDemande.demandeClientDates &&
      newDemande.demandeClientDates.some((el) => {
        return el.date && oldInputDate.includes(el.date);
      })
    ) {
      setIsDuplicate(true);
      return of(true);
    } else {
      // action type is CREATE or  UPDATE
      return checkDiffTotalTransporteur$();
    }
  };

  const checkDiffTotalTransporteur$ = (): Observable<boolean> => {
    const data: CalculateDiffTotalTransporteurRequestParams[] = generateChangedDates(currentDemande, newDemande);
    return CaracteristiquesLigneAPI.calculateDiffTotalTransporteur(Number(currentLigne.id), data).pipe(
      mergeMap((response: CalculateDiffTotalTransporteurResponse[]) => {
        const diffs = response
          .filter((diff) => diff.diff !== 0)
          .map((el) => {
            return {
              ...el,
              diff: Number(el.diff?.toFixed(2)),
            };
          });
        if (diffs.length > 0) {
          const diffTransporteurs = [];
          let tempDiff = { diff: 0, startDate: "", endDate: "string" };
          for (let i = 0; i < diffs.length; i++) {
            const diff = diffs[i];
            if (i != 0) {
              const beforeDiff = diffs[i - 1];
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const diffDate = new Date(diff.date!);
              diffDate.setDate(diffDate.getDate() - 1);
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const beforeDiffDate = new Date(beforeDiff.date!);
              if (diff.diff == beforeDiff.diff && diffDate.getTime() === beforeDiffDate.getTime()) {
                // dates in a row
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                tempDiff.endDate = diff.date!;
              } else {
                // add
                diffTransporteurs.push(tempDiff);
                // reset
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                tempDiff = { diff: diff.diff!, startDate: diff.date!, endDate: diff.date! };
              }
            } else {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              tempDiff = { diff: diff.diff!, startDate: diff.date!, endDate: diff.date! };
            }
          }
          diffTransporteurs.push(tempDiff);
          setDiffTotalTransporteurs(diffTransporteurs);

          setIsDiffTransporteur(true);
          return of(true);
        } else {
          return checkPprateDecrease$();
        }
      })
    );
  };

  const checkPprateDecrease$ = (): Observable<boolean> => {
    const data: GetPrePprateRequestParams[] = generateChangedDates(
      currentDemande,
      newDemande
      // currentDemande?.demandeClientDates ?? [],
      // newDemande?.demandeClientDates ?? []
    );
    if (
      currentLigne.caracteristiques?.ppRate != undefined &&
      currentLigne.caracteristiques.ppRate >= (currentLigne.caracteristiques?.tauxParcPropre ?? 0)
    ) {
      return CaracteristiquesLigneAPI.getPrePprate(Number(currentLigne.id), data).pipe(
        mergeMap((response: GetPrePprateResponse) => {
          if (response.ppRate != undefined && response.ppRate < (currentLigne.caracteristiques?.tauxParcPropre ?? 0)) {
            setIsPPrateDecrease(true);
            return of(true);
          }
          return createUpdateDemandeClient$();
        })
      );
    } else {
      return createUpdateDemandeClient$();
    }
  };

  const generateChangedDates = (
    currentDemande?: DemandeClient,
    newDemande?: DemandeClient
  ): CalculateDiffTotalTransporteurRequestParams[] | GetPrePprateRequestParams[] => {
    const currentDemandeDates = currentDemande?.demandeClientDates ?? [];
    const newDemandeDates = newDemande?.demandeClientDates ?? [];
    const allDates: string[] = uniq(
      currentDemandeDates
        .concat(newDemandeDates)
        .map((demandeDate) => demandeDate.date ?? "")
        .filter((date) => date.length > 0)
    );
    const data: CalculateDiffTotalTransporteurRequestParams[] = [];
    allDates.forEach((date) => {
      let diff = 0;
      const currentDemandeDate = currentDemandeDates.find((demandeDate) => demandeDate.date === date);
      const newDemandeDate = newDemandeDates.find((demandeDate) => demandeDate.date === date);
      if (currentDemandeDate == undefined && newDemandeDate != undefined) {
        // create new
        if (newDemande?.type === DemandeClientType.SEMAINE && newDemande?.subType === DemandeClientSubType.GLOBAL) {
          diff = (newDemande?.totalMaxLot ?? 0) / 7;
        } else if (newDemande?.type === DemandeClientType.MOIS && newDemande?.subType === DemandeClientSubType.GLOBAL) {
          const totalMaxLot = newDemande.moisGlobalOptions?.find((item) => item.month === new Date(date).getMonth())?.totalMaxLot ?? 0;
          diff = (totalMaxLot * 12) / 365;
        } else {
          diff = newDemandeDate.lotMax ?? 0;
        }
      } else if (currentDemandeDate != undefined && newDemandeDate != undefined) {
        // update
        if (newDemande?.type === DemandeClientType.SEMAINE && newDemande?.subType === DemandeClientSubType.GLOBAL) {
          diff = ((newDemande?.totalMaxLot ?? 0) - (currentDemande?.totalMaxLot ?? 0)) / 7;
        } else if (newDemande?.type === DemandeClientType.MOIS && newDemande?.subType === DemandeClientSubType.GLOBAL) {
          const currentTotalMaxLot = currentDemande?.moisGlobalOptions?.find((item) => item.month === new Date(date).getMonth())?.totalMaxLot ?? 0;
          const newTotalMaxLot = newDemande.moisGlobalOptions?.find((item) => item.month === new Date(date).getMonth())?.totalMaxLot ?? 0;
          diff = ((newTotalMaxLot - currentTotalMaxLot) * 12) / 365;
        } else {
          diff = (newDemandeDate.lotMax ?? 0) - (currentDemandeDate.lotMax ?? 0);
        }
      } else if (currentDemandeDate != undefined && newDemandeDate == undefined) {
        // delete
        if (newDemande?.type === DemandeClientType.SEMAINE && newDemande?.subType === DemandeClientSubType.GLOBAL) {
          diff = -((currentDemande?.totalMaxLot ?? 0) / 7);
        } else if (newDemande?.type === DemandeClientType.MOIS && newDemande?.subType === DemandeClientSubType.GLOBAL) {
          const currentTotalMaxLot = currentDemande?.moisGlobalOptions?.find((item) => item.month === new Date(date).getMonth())?.totalMaxLot ?? 0;
          diff = -((currentTotalMaxLot * 12) / 365);
        } else {
          diff = -(currentDemandeDate.lotMax ?? 0);
        }
      }

      data.push({
        ...(newDemande?.type == DemandeClientType.JOUR ||
        (newDemande?.type == DemandeClientType.SEMAINE && newDemande?.subType === DemandeClientSubType.JOUR) ||
        (newDemande?.type == DemandeClientType.MOIS &&
          (newDemande?.subType === DemandeClientSubType.JOUR || newDemande?.subType === DemandeClientSubType.AUTRE))
          ? { dJour: diff }
          : newDemande?.type == DemandeClientType.SEMAINE
          ? { dSemaine: diff }
          : { dMois: diff }),
        date: date,
      });
    });
    return data;
  };

  return (
    <div className="addDemande">
      {isLoading && <SingleLoading />}
      <SelectTime />
      <SelectDateType />
      <SelectMinMax />
      <Button className={menuStyle.btConfirm} type="primary" disabled={checkDisable()} htmlType="submit" onClick={onClickConfirmer}>
        Confirmer
      </Button>

      <PopupConfirm
        visible={isDuplicate}
        cancelText="Non"
        okText="Oui"
        title="Importation de fichiers"
        onCancel={() => setIsDuplicate(false)}
        onOk={() => {
          setIsDuplicate(false);
          checkDiffTotalTransporteur$()
            .pipe(
              doOnSubscribe(() => setIsLoading(true)), // Show loading on start
              tap({
                error: () => {
                  dispatch(updatePopUp({ type: PopupType.ERROR }));
                },
              }),
              finalize(() => setIsLoading(false)) // Hide loading on end
            )
            .subscribe({ error: noop });
          // checkHaveTransporteur();
        }}
        hideClose={false}
      >
        <span>Voulez-vous ajouter ces éléments en plus de ceux déjà présents sur ces dates ?</span>
      </PopupConfirm>

      <PopupConfirm
        visible={isDiffTransporteur}
        cancelText="Non"
        okText="Oui"
        title="Attention"
        onCancel={() => setIsDiffTransporteur(false)}
        onOk={() => {
          setIsDiffTransporteur(false);
          checkPprateDecrease$()
            .pipe(
              doOnSubscribe(() => setIsLoading(true)), // Show loading on start
              tap({
                error: () => {
                  dispatch(updatePopUp({ type: PopupType.ERROR }));
                },
              }),
              finalize(() => setIsLoading(false)) // Hide loading on end
            )
            .subscribe({ error: noop });
        }}
        hideClose={true}
        childrenDetail={
          <>
            <span>
              Le nombre de lots pris passe en moyenne quotidiennement :
              {diffTotalTransporteurs.map((diffTotalTransporteur) =>
                diffTotalTransporteur.startDate === diffTotalTransporteur.endDate ? (
                  <>
                    <br />- de {diffTotalTransporteur.diff.toFixed(2)} du {moment(diffTotalTransporteur.startDate).format("DD/MM/YYYY")}
                  </>
                ) : (
                  <>
                    <br />- de {diffTotalTransporteur.diff.toFixed(2)} du {moment(diffTotalTransporteur.startDate).format("DD/MM/YYYY")} au{" "}
                    {moment(diffTotalTransporteur.endDate).format("DD/MM/YYYY")}
                  </>
                )
              )}
            </span>
          </>
        }
        showDetail={true}
      >
        <span>
          Êtes-vous sûr de vouloir modifier la partie Demande Client sachant que la partie Transporteurs a déjà été complétée ?
          <br />
          Les taux pris seront conservés bien que la Demande Client ait changé. Merci de confirmer la cohérence
        </span>
      </PopupConfirm>

      <PopupConfirm
        visible={isPPrateDecrease}
        cancelText="Non"
        okText="Oui"
        title="Attention"
        onCancel={() => setIsPPrateDecrease(false)}
        onOk={() => {
          setIsPPrateDecrease(false);
          createUpdateDemandeClient$()
            .pipe(
              doOnSubscribe(() => setIsLoading(true)), // Show loading on start
              tap({
                error: () => {
                  dispatch(updatePopUp({ type: PopupType.ERROR }));
                },
              }),
              finalize(() => setIsLoading(false)) // Hide loading on end
            )
            .subscribe({ error: noop });
        }}
        hideClose={true}
      >
        <span>Le taux de Parc Propre Contractuel n&apos;atteindra plus l&apos;objectif fixé pour cette ligne. Souhaitez-vous continuer?</span>
      </PopupConfirm>
    </div>
  );
};

export default AddDemandeClient;

import React from 'react';

const NotFound = () => {

  return (
    <div className="container" style={{ height: '100vh', display: 'flex' }}>
      <div>
        NotFound
      </div>
    </div>
  );
};

export default NotFound;

/** @format */

import { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { CaracteristiquesPopupType, UpdateCaracteristiquesPopup } from "../../../../app/reducers/Caracteristiques/CaracteristiquesPopup.reducer";
import PopupConfirm from "../../../../components/PopupConfirm";

interface PopupContainerProps {
  okText?: string;
  cancelText?: string;
  children?: ReactElement;
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
}

const PopupContainer = (props: PopupContainerProps) => {
  const { okText, cancelText, children, onOk } = props;
  const dispatch = useDispatch();

  const removePopup = () => {
    dispatch(UpdateCaracteristiquesPopup({ type: CaracteristiquesPopupType.NONE }));
  };

  return (
    <PopupConfirm visible={true} title="Attention" okText={okText} cancelText={cancelText} onOk={onOk} onCancel={removePopup} hideClose>
      {children}
    </PopupConfirm>
  );
};

export default PopupContainer;

/** @format */

import ModalLieux from "./ModalLieux";
import ModalVille from "./ModalVille";
import { LieuxSearch, LocationFormModel, LocationType, TrajetPopupType, VilleSearch } from "../models/TrajetModel";

type Props = {
  isShow: boolean;
  popupType: TrajetPopupType;
  dataForm: LocationFormModel;
  onCancel: () => void;
  onSelected: (value: LocationFormModel) => void;
  locationType: LocationType;
};

/* expect
 * ville and codepostal select fill => fill other fields
 * lieux select fill => fill other fields
 *  */

export default function LocaltionModal({ isShow, popupType, onCancel, onSelected, locationType }: Props) {
  const filterSelect = (item: LieuxSearch | VilleSearch) => {
    switch (popupType) {
      case TrajetPopupType.LIEUX: {
        const lieuxData = item as LieuxSearch;
        onSelected({
          lieuxId: lieuxData.id,
          paysId: lieuxData.paysId,
          departementId: lieuxData.departementId,
          codePostal: lieuxData.villeCodePostal,
          ville: lieuxData.villeNom,
          adresse: lieuxData.adresse,
        });
        return;
      }

      case TrajetPopupType.VILLE: {
        const villeData = item as VilleSearch;
        onSelected({
          lieuxId: undefined,
          ville: villeData.nom,
          paysId: villeData.paysId,
          departementId: villeData.departementId,
          adresse: undefined,
        });
        return;
      }

      case TrajetPopupType.CODE_POSTAL: {
        const postalData = item as VilleSearch;
        onSelected({
          lieuxId: undefined,
          codePostal: postalData.codePostal,
          paysId: postalData.paysId,
          departementId: postalData.departementId,
          adresse: undefined,
        });
        return;
      }
    }
    onCancel();
  };

  switch (popupType) {
    case TrajetPopupType.LIEUX:
      return <ModalLieux type={locationType} onCancel={onCancel} onSubmit={filterSelect} isOpen={isShow} />;
    case TrajetPopupType.VILLE:
    case TrajetPopupType.CODE_POSTAL:
      return <ModalVille onCancel={onCancel} onSubmit={filterSelect} isOpen={isShow} popupType={popupType} />;
  }
}

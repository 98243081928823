/** @format */

import { AxiosResponse } from "axios";
import { from, map, Observable } from "rxjs";
import { Method, request } from "../../helper/request.helper";
import { Chargement } from "../../models";

export class ChargementAPI {
  static readonly COMPONENT_NAME: string = "Chargements";

  static fetchAll = (params?: unknown): Observable<Chargement[]> => {
    return from(
      request({
        method: Method.GET,
        url: `/${this.COMPONENT_NAME}`,
        params,
      })
    ).pipe(map((response: AxiosResponse<Chargement[]>) => response.data));
  };

  static create = (data: Chargement): Observable<Chargement> => {
    return from(
      request({
        method: Method.POST,
        url: `/${this.COMPONENT_NAME}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<Chargement>) => response.data));
  };

  static update = (id: number, data: Chargement): Observable<Chargement> => {
    return from(
      request({
        method: Method.PATCH,
        url: `/${this.COMPONENT_NAME}/${id}`,
        data,
      })
    ).pipe(map((response: AxiosResponse<Chargement>) => response.data));
  };

  static delete = (id: number): Observable<void> => {
    return from(
      request({
        method: Method.DELETE,
        url: `/${this.COMPONENT_NAME}/${id}`,
      })
    ).pipe(map((response: AxiosResponse<void>) => response.data));
  };
}

/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Pays } from "../../../models";

const initialState: Pays[] = [];

export const paysSlice = createSlice({
  name: "currentDemande",
  initialState,
  reducers: {
    updatePays: (state, action: PayloadAction<Pays[]>) => {
      return action.payload;
    },
  },
});

export const { updatePays } = paysSlice.actions;

export const getListPays = (state: RootState) => state.listPays;

export default paysSlice.reducer;
